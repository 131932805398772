import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Register from '_components/Register';
// import '_assets/landingPage/css/bootstrap.css'
// import '_assets/landingPage/css/main.css'
import Navigation from './Navigation'
import {Footer} from './Footer'
import LeftCircle from '_assets/landingPage/images/left-circle.svg'
import Brand from '_assets/landingPage/images/brand.svg'
import Stock3 from '_assets/landingPage/images/iStock3.jpg'
import Stock4 from '_assets/landingPage/images/iStock4.jpg'
import Stock2 from '_assets/landingPage/images/iStock4.jpg'
import Img from '_assets/landingPage/images/img.jpg'
import Shape4 from '_assets/landingPage/images/shape-4.svg'
import Logo from '_assets/landingPage/images/logo.png'

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Login from '_components/Login';

export function HowItWorks() {
  const dispatch = useDispatch();
  const [showRegister, setShowRegister] = useState(false)
  const [showLogin, setShowLogin] = useState(false)



  return (
    <div style={{backgroundColor: '#fff'}}>
    <link href="_assets/landingPage/css/main.css" rel="stylesheet" />
    <link href="'_assets/landingPage/css/bootstrap.css" rel="stylesheet" />
      <Navigation showLogin={setShowLogin} showRegister={setShowRegister} active="HowItWorks" />


    {/* offer area of the page */}
    <section className="offer-holder booking">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-6">
            <div className="signup-holder booking">
              <h1>Fast and easy booking</h1>
              <ul className="custom-list">
                <li>
                  <span className="c-count">1</span>
                  Easy registration process. Just fill in your company details.
                </li>
                <li>
                  <span className="c-count">2</span>
                  Enter your shipment information and our search engine
                  instantly provides you prices.
                </li>
                <li>
                  <span className="c-count">3</span>
                  Choose forwarder and fill in details.{" "}
                </li>
                <li>
                  <span className="c-count">4</span>
                  Confirm and your shipment is booked.{" "}
                </li>
                <li>
                  <span className="c-count">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 24 24"
                      width={24}
                      height={24}
                    >
                      <path fill="none" d="M0 0h24v24H0z" />
                      <path d="M10 15.172l9.192-9.193 1.415 1.414L10 18l-6.364-6.364 1.414-1.414z" />
                    </svg>
                  </span>
                  Manage your bookings easily. Booking history, attachments, and
                  reports all under on platform.
                </li>
              </ul>
              <button  type="button" onClick={() => setShowRegister(true)} className="btn btn-secondary d-inline-flex" >Register now
              <img src={LeftCircle} alt="" /></button>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="img-holder">
              <img src={Img} alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* details area of the page */}
    <div className="container">
      <div className="details-holder">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-6">
            <div className="img-holder">
              <img src={Stock3} alt="" />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="text center">
              <div className="text-block">
                <h1>Free to use </h1>
                <p>
                  Small and medium size companies often don’t have enough
                  transportation volumes to negotiate better prices from big
                  carriers. We are here to help. FreightOpt combines these
                  companies under one platform to get the best possible price
                  for you. We want to offer this service to you free of charge,
                  so that we can grow our volumes together.
                </p>
                <p>
                  Together we can all improve our logistical processes and
                  improve our competitiveness.
                </p>
              </div>
              <button  type="button" onClick={() => setShowRegister(true)} className="btn btn-primary d-inline-flex" >   Check your prices
              <img src={LeftCircle} alt="" /></button>
           
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* steps area of the page */}
    <section className="steps-holder">
      <div className="container">
        <div className="head text-center">
          <h1>Start now. Only 4 Steps.</h1>
        </div>
        <div className="cards-holder">
          <div className="row">
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card">
                <div className="badge">Register</div>
                <h3>Easy registration process. </h3>
                <p>
                  Just fill in your company details: company name, email, phone
                  number and business ID.
                </p>
                <p>
                  The platform is free to use, you only pay for the freights,
                  which FreightOpt will invoice from your company.{" "}
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card freight">
                <div className="badge">Freight details</div>
                <h3>Enter your freight info. </h3>
                <p>
                  Pickup and delivery locations, unit type and measurements.
                </p>
                <p>
                  Our system instantly provides you the prices from possible
                  carriers.{" "}
                </p>
                <p>Easy and fast price comparison.</p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card carrier">
                <div className="badge">Choose carrier</div>
                <h3>Choose the carrier you prefer. </h3>
                <p>
                  Fill in the rest of the cargo details, like addresses, contact
                  info and when goods are ready for pick up.
                </p>
                <p>
                  You can easily add documents and messages to carriers directly
                  in here.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 col-xl-3">
              <div className="card manage">
                <div className="badge">Confirm &amp; Manage</div>
                <h3>Confirm and your shipment is booked. </h3>
                <p>
                  You will receive confirmation email including labels from your
                  booking.{" "}
                </p>
                <p>
                  Manage your bookings easily. Booking info, attachments, and
                  tracking all under on platform.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* details area of the page */}
    <div className="details-holder process">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-6 order-lg-2">
            <div className="img-holder">
              <img src={Stock4} alt="" />
            </div>
          </div>
          <div className="col-12 col-lg-6 order-lg-1">
            <div className="text">
              <div className="text-block">
                <h1>Manage the entire process</h1>
                <p>
                  FreightOpt allows you to share all necessary shipping
                  documents and other information directly within the system.
                </p>
                <p>
                  You can always view your booking history and compare shipping
                  options. FreightOpt also produces analytics on your past
                  shipping choices.
                </p>
              </div>
        
              <button  type="button" onClick={() => setShowRegister(true)} className="btn btn-secondary d-inline-flex" >    Register now
              <img src={LeftCircle} alt="" /></button>
            </div>
          </div>
        </div>
        <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-6">
            <div className="img-holder">
              <img src={Stock2} alt="" />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="text center">
              <div className="text-block">
                <h1>Get instant freight rates</h1>
                <p>
                  Completely free of charge search engine and a booking portal
                  for European road freights. Get prices instantly and book your
                  freights. Make full use of FreightOpt contract prices!
                </p>
              </div>
              <button  type="button" onClick={() => setShowRegister(true)} className="btn btn-secondary d-inline-flex" >    Try it out
              <img src={LeftCircle} alt="" /></button>
           
            </div>
          </div>
        </div>
      </div>
    </div>

    {/* appointment area of the page */}
    <section className="appointment-holder price">
      <div className="container">
        <div className="align-center head">
          <h1>Check your price for free </h1>
        </div>
        <form action="#">
          <div className="row d-flex align-items-end">
            <div className="col-12 col-xl-12 mb-0">
              <div className="row row d-flex align-items-end">
                <div className="col-12 col-lg">
                  <label>Pickup from</label>
                  <div className="form-item">
                    <input id="country_selector" type="text" />
                    <label
                      htmlFor="country_selector"
                      style={{ display: "none" }}
                    >
                      Select a country here...
                    </label>
                  </div>
                  <div className="form-item" style={{ display: "none" }}>
                    <input
                      type="text"
                      id="country_selector_code"
                      name="country_selector_code"
                      data-countrycodeinput={1}
                      readOnly="readonly"
                      placeholder="Selected country code will appear here"
                    />
                    <label htmlFor="country_selector_code">
                      ...and the selected country code will be updated here
                    </label>
                  </div>
                  <button type="submit" style={{ display: "none" }}>
                    Submit
                  </button>
                </div>
                <div className="col-12 col-lg">
                  <label>Zipcode</label>
                  <div>
                    <input
                      id="country_selector"
                      className="form-control"
                      placeholder="00000"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg">
                  <label>Delivery to</label>
                  <div className="form-item">
                    <input id="country_selector_1" type="text" />
                    <label
                      htmlFor="country_selector"
                      style={{ display: "none" }}
                    >
                      Select a country here...
                    </label>
                  </div>
                  <div className="form-item" style={{ display: "none" }}>
                    <input
                      type="text"
                      id="country_selector_code"
                      name="country_selector_code"
                      data-countrycodeinput={1}
                      readOnly="readonly"
                      placeholder="Selected country code will appear here"
                    />
                    <label htmlFor="country_selector_code">
                      ...and the selected country code will be updated here
                    </label>
                  </div>
                  <button type="submit" style={{ display: "none" }}>
                    Submit
                  </button>
                </div>
                <div className="col-12 col-lg">
                  <label>Zipcode</label>
                  <div>
                    <input
                      id="country_selector"
                      className="form-control"
                      placeholder="00000"
                    />
                  </div>
                </div>
                <div className="col-12 col-lg">
                  <label>Billing weight</label>
                  <div className="input-holder weight">
                    <div className="form-group weight">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={800}
                      />
                    </div>
                  </div>
                </div>
                <div>
                  <div className="col-12 col-xl-3 float-end">
                    <button
                      type="submit"
                      className="btn btn-primary d-inline-flex"
                    >
                      Check the price
                      <img src={LeftCircle} alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>

    {/* clients area of the page */}
    <section className="clients-holder">
      <div className="container">
        <div className="details-holder">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-6">
              <div className="img-holder">
                <img src={Stock3} alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="text center">
                <div className="text-block">
                  <h1>Get instant freight rates</h1>
                  <p>
                    Completely free of charge search engine and a booking portal
                    for European road freights. Get prices instantly and book
                    your freights. Make full use of FreightOpt contract prices!
                  </p>
                </div>
                <button  type="button" onClick={() => setShowRegister(true)} className="btn btn-secondary d-inline-flex" >              Book now for free
              <img src={LeftCircle} alt="" /></button>
           
              </div>
            </div>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-6 order-lg-2">
              <div className="img-holder">
                <img src={Img} alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 order-lg-1">
              <div className="text">
                <div className="text-block">
                  <h1>One Platform</h1>
                  <p>
                    Comprehensive freight management with one system –
                    FreightOpt is a system for shippers to search, book and
                    track shipments. The system is browser-based and requires
                    zero installation. Savings achieved with multiple
                    transparent options, higher volumes and automation which
                    enables international competitiveness.
                  </p>
                </div>
             
                <button  type="button" onClick={() => setShowRegister(true)} className="btn btn-primary d-inline-flex" > Register now
                <img src={LeftCircle} alt="" /></button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    {/* shape area of the page */}
    <div className="shape-holder">
      <div className="container">
        <img src={Shape4} alt="" />
      </div>
    </div>





      <Footer/>

      <Register show={showRegister} hide={setShowRegister} />
      <Login show={showLogin} hide={setShowLogin} />





    </div>
  );

}


