import React from 'react';
import Link from "@mui/material/Link";
import TopnavItem from './TopnavItem';
import { useLocation, NavLink } from "react-router-dom";
import { useDispatch } from 'react-redux';
import { useHistory } from "react-router-dom";


export default function TopnavElement({ type, name, icon, title, noCollapse, route, href, color, action, routeKey }) {

    const location = useLocation();
    const { pathname } = location;
    const collapseName = pathname.split("/").slice(1)[0];
    const dispatch = useDispatch();
    const history = useHistory();
    console.log(collapseName)
    console.log(routeKey)
    function TriggerAction(act, href){
        console.log("route:" + href)
        if(href){
            history.push(href);
        }
        
        dispatch(act)
    }

    let returnValue;
    if (type === "collapse") {
        returnValue = href ? (
            <Link
                href={href}

                target="_blank"
                rel="noreferrer"
                sx={{ textDecoration: "none" }}
            >
                <TopnavItem
                    color={color}
                    name={name}
                    icon={icon}
                    active={routeKey === collapseName}
                    noCollapse={noCollapse}
                />
            </Link>
        ) : (
            <NavLink to={route} >
                <TopnavItem
                    color={color}

                    name={name}
                    icon={icon}
                    active={routeKey === collapseName}
                    noCollapse={noCollapse}
                />
            </NavLink>
        );
        }
    else if (type === "button") {
        returnValue = (<Link
            onClick={() => TriggerAction(action, href)}
            sx={{ textDecoration: "none", color:"#183B59" }}
        >
            <TopnavItem
                color={color}
                name={name}
                icon={icon}
                active={routeKey === collapseName}
                noCollapse={noCollapse}
            />
        </Link>)
    }



    return (
        <React.Fragment>
            {returnValue}

        </React.Fragment>
    )
}