
import { adminConstants } from '../_constants';


const costsRow = {
  id: 0,
  carrierId: "",
  costType: 1,
  amountType: 1,
  countries: [],
  startDate: "",
  updatedDate: '',
  amount: "",
}

const initialState = {
  loading: false,
  roles: [],
  customers: [],
  carriers: [],
  selectedCustomer: 0,

  customerInput: {
    id: null,
    name: '',
    bic: '',
    cmrId: '',
    paymentTerm:'',
    margin4pl:''
  },
  users: [],
  selectedUser: 0,
  userInput: {
    id:null,
    username:'',
    firstName:'',
    lastName:'',
    email:'',
    phone:'',
    password:'',
    passwordConfirm:'',
    roles:[],
    customerId:null,
    carrierId:null,
    activated:false,
    emailConfirmed:false,
  },
  carriers: [],
  selectedCarrier: 0,
  carrierInput: {
    id: null,
    name: ''
  },
  calculators: [],
  selectedCalculator: 0,
  calculatorInput: {
    cellRows: [{}],
    file: {}
  },
  changingCosts: [].concat(costsRow),
  invoicePatches:[],
  importableCustomers:[],
  eventLogs:[]


}


export function admin(state = initialState, action) {
  switch (action.type) {

    case adminConstants.LOADING:
      return {
        ...state,
        loading: action.value

      };

    case adminConstants.UPDATE_CUSTOMER_INPUT_VALUE:
      return {
        ...state,
        customerInput: {
          ...state.customerInput,
          [action.data.name]: action.data.value
        }
      };

    case adminConstants.STORE_CUSTOMERS:
      return {
        ...state,
        loading: false,
        customers: action.data
      }

    case adminConstants.STORE_ROLE:
      return {
        ...state,
        loading: false,
        roles: state.roles.concat(action.data)
      }

    case adminConstants.STORE_ROLES:
      return {
        ...state,
        loading: false,
        roles: action.data,
        userInput: {
          ...state.userInput,
          allRoles: action.data
        }
      }

    case adminConstants.ADD_CUSTOMER_TO_STORE:

      const newListWithoutCurrent = state.customers.filter(x => x.id !== action.data.id)

      return {
        ...state,
        loading: false,
        customers: newListWithoutCurrent.concat(action.data)
      }

    case adminConstants.UPDATE_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomer: action.id
      }

    case adminConstants.DELETE_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomer: 0,
        loading: false,
        customers: state.customers.filter(x => x.id !== action.data)
      }

    case adminConstants.INITATE_SELECTED_CUSTOMER:
      return {
        ...state,
        customerInput: state.customers.find(x => x.id === action.id),
        selectedCustomer: action.id
      }

    case adminConstants.CLEAR_SELECTED_CUSTOMER:
      return {
        ...state,
        selectedCustomer: 0,
        customerInput: initialState.customerInput
      }

    case adminConstants.STORE_USERS:
      return {
        ...state,
        loading: false,
        users: action.data
      }

    case adminConstants.STORE_SELECTED_USER:
      return {
        ...state,
        loading: false,
        selectedUser: action.data.id,
        userInput: action.data
      }

    case adminConstants.UPDATE_USER_INPUT_VALUE:
      return {
        ...state,
        userInput: {
          ...state.userInput,
          [action.data.name]: action.data.value
        }
      };

    case adminConstants.UPDATE_USER_ROLE_VALUE:
      const newRole = {
        name: action.data.name,
        selected: action.data.value
      }
      const newRoles = state.userInput.allRoles.map(x => x.name !== action.data.name ? x : newRole)


      return {
        ...state,
        userInput: {
          ...state.userInput,
          allRoles: newRoles
        }
      };

    case adminConstants.CLEAR_SELECTED_USER:
      return {
        ...state,
        selectedUser: 0,
        userInput: {
          ...initialState.userInput,
          allRoles: state.roles

        }

      }

    case adminConstants.ADD_USER_TO_STORE:

      const newListWithoutCurrentUser = state.users.filter(x => x.id !== action.data.id)

      return {
        ...state,
        loading: false,
        users: newListWithoutCurrentUser.concat(action.data)
      }

    case adminConstants.DELETE_SELECTED_USER:
      return {
        ...state,
        selectedUser: 0,
        loading: false,
        users: state.users.filter(x => x.id !== action.data),
        userInput: {
          ...initialState.userInput
        }
      }




    // CARRIER

    case adminConstants.STORE_CARRIERS:
      return {
        ...state,
        loading: false,
        carriers: action.data
      }

    case adminConstants.UPDATE_SELECTED_CARRIER:
      return {
        ...state,
        selectedCarrier: action.id
      }

    case adminConstants.INITATE_SELECTED_CARRIER:
      return {
        ...state,
        carrierInput: state.carriers.find(x => x.id === action.id),
        selectedCarrier: action.id
      }

    case adminConstants.UPDATE_CARRIER_INPUT_VALUE:
      return {
        ...state,
        carrierInput: {
          ...state.carrierInput,
          [action.data.name]: action.data.value
        }
      };
      

    case adminConstants.CLEAR_SELECTED_CARRIER:
      return {
        ...state,
        selectedCarrier: 0,
        carrierInput: initialState.carrierInput
      }

    case adminConstants.ADD_CARRIER_TO_STORE:

      return {
        ...state,
        loading: false,
        carriers: state.carriers.filter(x => x.id !== action.data.id).concat(action.data)
      }

    case adminConstants.DELETE_SELECTED_CARRIER:
      return {
        ...state,
        selectedCarrier: 0,
        loading: false,
        carriers: state.carriers.filter(x => x.id !== action.data)
      }






    //CALCULATOR

    case adminConstants.STORE_CALCULATORS:
      return {
        ...state,
        loading: false,
        calculators: action.data
      }

    case adminConstants.STORE_SELECTED_CALCULATOR:
      return {
        ...state,
        loading: false,
        selectedCalculator: action.data.id,
        calculatorInput: {
          ...action.data,
          file: {}
        }

      }

    case adminConstants.UPDATE_CALCULATOR_INPUT_VALUE:
      return {
        ...state,
        calculatorInput: {
          ...state.calculatorInput,
          [action.data.name]: action.data.value
        }
      };

    case adminConstants.CLEAR_SELECTED_CALCULATOR:
      return {
        ...state,
        selectedCalculator: 0,
        calculatorInput: initialState.calculatorInput

      }

    case adminConstants.ADD_CALCULATOR_TO_STORE:

      return {
        ...state,
        loading: false,
        calculators: state.calculators.filter(x => x.id !== action.data.id).concat(action.data)
      }

    case adminConstants.DELETE_SELECTED_CALCULATOR:
      return {
        ...state,
        selectedCalculator: 0,
        loading: false,
        calculators: state.calculators.filter(x => x.id !== state.selectedCalculator),
        userInput: {}
      }

    case adminConstants.STORE_CALCULATOR_FILE:
      return {
        ...state,
        loading: false,
        calculatorInput: {
          ...state.calculatorInput,
          // file: [].concat(action.file)
          file: action.file
        }
      }

    case adminConstants.ADD_CALCULATOR_CELL_ROW:
      return {
        ...state,
        calculatorInput: {
          ...state.calculatorInput,
          cellRows: state.calculatorInput.cellRows.concat({})
        }
      }

    case adminConstants.REMOVE_CALCULATOR_CELL_ROW:
      return {
        ...state,
        calculatorInput: {
          ...state.calculatorInput,
          cellRows: state.calculatorInput.cellRows.filter((x, i) => i !== action.index)
        }

      }

    case adminConstants.UPDATE_CALCULATOR_CELL_ROW_VALUE:


      const rowToChange = state.calculatorInput.cellRows[action.data.index]
      const changedRow = {
        ...rowToChange,
        [action.data.name]: action.data.value
      }

      const newRows = state.calculatorInput.cellRows.map((row, index) =>
        index !== action.data.index ? row : changedRow

      )

      return {
        ...state,
        calculatorInput: {
          ...state.calculatorInput,
          cellRows: newRows

        }
      }

    case adminConstants.UPDATE_CHANGING_COSTS_INPUT_VALUE:

      const costToChange = state.changingCosts[action.data.index]
      const changedCost = {
        ...costToChange,
        [action.data.name]: action.data.value
      }

      const newCosts = state.changingCosts.map((row, index) =>
        index !== action.data.index ? row : changedCost

      )


      return {
        ...state,
        changingCosts: newCosts

      }

    case adminConstants.ADD_CHANGING_COSTS_ROW:
      return {
        ...state,
        changingCosts: state.changingCosts.concat(costsRow)
      }

      case adminConstants.STORE_INVOICE_PATCHES:
        return {
          ...state,
          invoicePatches: action.data
        }

    case adminConstants.UPDATE_CHANGING_COST_IN_STORE:

      const updatedCost = {
        ...state.changingCosts[action.data.index],
        id: action.data.payload.id,
        updatedDate: action.data.payload.updatedDate
      }

      return {
        ...state,
        changingCosts: state.changingCosts.map((row, index) => index !== action.data.index ? row : updatedCost)
      }

      case adminConstants.STORE_CHANGING_COSTS:
        return {
          ...state,
          loading: false,
          changingCosts: action.data
        }

        case adminConstants.STORE_IMPORTABLE_CUSTOMERS:
        return {
          ...state,
          importableCustomers: action.data
        }
        case adminConstants.STORE_EVENT_LOGS:
          return {
            ...state,
            importableCustomers: action.data,
            loading: false
          }
  






    default:
      return state
  }
}