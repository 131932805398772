export const userConstants = {
    LOGIN_REQUEST: 'USERS_LOGIN_REQUEST',
    LOGIN_SUCCESS: 'USERS_LOGIN_SUCCESS',
    LOGIN_FAILURE: 'USERS_LOGIN_FAILURE',
    UPDATE_VALUE: 'REGISTER_UPDATE_VALUE',
    LOGOUT: 'USERS_LOGOUT',
    LOADING: 'USER_LOADING',
    CLEAR: 'USER_CLEAR',
    SET_USER: 'USER_SET_USER',
    UPDATE_USER: 'USER_UPDATE_USER',
    UPDATE_USER_EMAIL_NOTIFICATION: 'USER_UPDATE_USER_EMAIL_NOTIFICATION',
    REGISTRATION_OK: 'USER_REGISTERATION_OK',
    REGISTRATION_FAILURE: 'USER_REGISTERATION_FAILURE',
    CONFIRMATION_OK: 'USER_CONFIRMATION_OK',
    CONFIRMATION_FAILURE: 'USER_CONFIRMATION_FAILURE',

};
