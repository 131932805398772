import { ShipmentInformationFormConstants } from '../_constants';
import { instantPricingConstants, locationConstants, shipmentConstants, customerSpotRequestTableConstants } from '../_constants';
import { instantPricingService, shipmentService,locationService } from '../_services';
import { locationActions,alertActions, instantPricingActions, userActions, shipmentActions } from '.';
import { useSelector } from 'react-redux';
import { instantPricing } from '../_reducers/instantPricing.reducer';
import { mapShipmentInformationModalToInstantPricingRequestDTO } from '_helpers/instantPricing';
import { userService } from '_services/userService';
import { customerSpotRequestTable } from '_reducers/customerSpotRequestTable.reducer';




export const ShipmentInformationFormActions = {
  open,
  close,
  updateLocationValue,
  updateGoodsLine,
  updateAdrRow,
  addGoodsLine,
  removeGoodsLine,
  updateBasicValue,
  createShipment,
  setSavedLocation,
  getBillingWeight,
  showPreview,
  clearLocationInputs,
  handleAcceptTerms,
  storeFile,
  deleteFile,
  previous,
  next,
  setCollapseVisibility,
  resetGoods,
  clearModal,
  setReady,
  clearClickAction,
  copy,
  refreshPrice,
  getUnCodesForClass,
  GetDescriptionsForUnCode,
  updateAdrDescription,
  addAdrLine,
  removeAdrLine,
};
function createShipment(data) {

  return dispatch => {
    dispatch(loading());

    shipmentService.createShipment(data)
      .then(
          data => {

          if(data.success){
            dispatch(updateStore(data.payload));
            dispatch(success(data));
          }
          else{
            dispatch(failure(data.payload));
          }
          

        },
        error => {
          dispatch(failure(error));

        }
      )

    function updateStore(data) { return { type: ShipmentInformationFormConstants.UPDATE_SHIPMENT_TYPE_IN_STORE, data } }
    function loading() { return { type: ShipmentInformationFormConstants.LOADING } }
    function success(data) { return { type: ShipmentInformationFormConstants.SUCCESS, data } }
    function failure(error) { return { type: ShipmentInformationFormConstants.ERROR, error } }

  }


}

function refreshPrice(inputs, calculatorId){
  return dispatch => {
    dispatch(calculating())
    instantPricingService.getPrices(inputs)
      .then(
          data => {
              console.log(data);
          const newPrice = data.find(x=> x.calculatorId === calculatorId)
          dispatch(success(newPrice))
        },
        error => {
          dispatch(alertActions.error(error));
        }
      )
   
    function success(data) { return { type: ShipmentInformationFormConstants.SET_PRICE, data } }
    function calculating() { return { type: ShipmentInformationFormConstants.CALCULATING_PRICE, } }

  }
}


function previous() {
  return { type: ShipmentInformationFormConstants.PREVIOUS_PHASE };
}

function addGoodsLine() {
  return { type: ShipmentInformationFormConstants.ADD_GOODSLINE };
}

function resetGoods() {
  return { type: ShipmentInformationFormConstants.RESET_GOODS };
}

function removeGoodsLine(index) {
  const data = { index }
  return { type: ShipmentInformationFormConstants.REMOVE_GOODSLINE, data };
}

function updateLocationValue(name, value, index) {
  const data = { name, value, type: 'location', index }
  return { type: ShipmentInformationFormConstants.UPDATE_VALUE, data };
}

function updateGoodsLine(name, value, index) {


  return dispatch => {
    const type = name === 'typeCode' ? 'typeCode' : 'goodsLine'
    const data = { name, value, type: type, index }
    dispatch(update(data));

  }

  function update(data) { return { type: ShipmentInformationFormConstants.UPDATE_VALUE, data } }


}

function updateAdrRow(name, value, index, adrIndex) {

  return dispatch => {
    const data = { name, value, index, adrIndex }
    dispatch(update(data));

  }

  function update(data) { return { type: ShipmentInformationFormConstants.UPDATE_ADR_ROW, data } }


}

function getBillingWeight(rows, selectedCarrierId, calculationRule,shipmentType) {


  return dispatch => {

    const model = { rows: rows, carrierId: selectedCarrierId ?? 1, calculationRule: calculationRule ?? 0, shipmentType: shipmentType ?? 2}
    dispatch(calculating());
    dispatch(calculatingPrice());
    instantPricingService.getBillingWeight(model)
      .then(
        data => {
          console.log(data)
          const updateData = { data: data.payload, type: 'billingWeight'}
          dispatch(update(updateData));
        },
        error => {
          dispatch(failure(error));

        }
      )


  }
  function calculating() { return { type: ShipmentInformationFormConstants.CALCULATING, } }
  function calculatingPrice() { return { type: ShipmentInformationFormConstants.CALCULATING_PRICE, } }
  function update(data) { return { type: ShipmentInformationFormConstants.UPDATE_VALUE, data } }
  function failure(error) { return { type: ShipmentInformationFormConstants.ERROR, error } }

}

function updateBasicValue(name, value) {
  const data = { name, value, type: 'basic' }
  return { type: ShipmentInformationFormConstants.UPDATE_VALUE, data };
}

function open(prefills) {


  
  return dispatch => {
    dispatch(loading())

    userService.getShipmentFormDataForUser()
    .then(
      data => {
        dispatch(success(data.allCountries));
        
        dispatch(store(data.savedLocations));
        const params = {
          prefills: prefills,
          legacyMode: data.legacyMode
        }
        dispatch(initateForm(params));

      },
      error => {
        dispatch(alertActions.error(error));
      } 
    )

  }

 
  function initateForm(params) { return { type: ShipmentInformationFormConstants.OPEN, params } }
  function success(data) { return { type: instantPricingConstants.UPDATE_COUNTRIES, data } }
  function store(data) { return { type: locationConstants.STORE, data } }
  function loading() { return { type: ShipmentInformationFormConstants.LOADING } }
}

function setSavedLocation(location, index) {
  console.log(location)
  const data = { location, index }
  return { type: ShipmentInformationFormConstants.SET_SAVED_LOCATION, data };

}

function clearLocationInputs(index, instant) {

  const data = {index,instant}

  return { type: ShipmentInformationFormConstants.CLEAR_LOCATION, data };

}

function close() {
  const params = {}
  return { type: ShipmentInformationFormConstants.CLOSE, params };
}

function handleAcceptTerms(value) {

  return { type: ShipmentInformationFormConstants.HANDLE_ACCEPT_TERMS, value };
}

function storeFile(file) {

  return { type: ShipmentInformationFormConstants.STORE_FILE, file };
}

function deleteFile(index) {

  return { type: ShipmentInformationFormConstants.DELETE_FILE, index };
}

function setCollapseVisibility(name,value) {
  const params ={name,value}
  return { type: ShipmentInformationFormConstants.SET_COLLAPSE_VISIBILITY, params };
}

function next(type, instant) {
  console.log(type)
  return dispatch => {

    switch(type) {
      case 'details':
          dispatch(validateDetails(instant));
      break;

      case 'locations':
        dispatch(validateLocations());
      break;

      case 'goods':
        dispatch(validateGoods());
      break;


    }

    
  }

  function validateDetails(instant) { return { type: ShipmentInformationFormConstants.VALIDATE_DETAILS, instant} }
  function validateGoods() { return { type: ShipmentInformationFormConstants.VALIDATE_GOODS} }
  function validateLocations() { return { type: ShipmentInformationFormConstants.VALIDATE_LOCATIONS} }
}


function showPreview(show, state, instant) {
  return dispatch => {
    if(!instant && show){
      const params = { preview: show, totalPrice: state.totalPrice, instant}
      dispatch(setPreview(params))
    }
    else if (show) {
      // validointi
      // uuden hinnan laskeminen
      const model =  mapShipmentInformationModalToInstantPricingRequestDTO(state)

      instantPricingService.getPrices(model)
        .then(
          data => {
            console.log(data)
            const params = { preview: show,totalPrice: data[0].totalPrice, selectedQuote: data[0], instant }
            console.log(params)
            // const updateData = { data: data.payload, type: 'billingWeight', index }
            dispatch(setPreview(params));

          },
          error => {
            dispatch(failure(error));

          }
        )


    } else {

      const params = { preview: show, totalPrice: state.totalPrice, instant}
      dispatch(setPreview(params))
    }
  }


  function failure(error) { return { type: ShipmentInformationFormConstants.ERROR, error } }
  function setPreview(params) { return { type: ShipmentInformationFormConstants.SHOW_PREVIEW, params } }
}

function clearModal() { return { type: ShipmentInformationFormConstants.SET_INITIAL_STATE } }
function setReady() { return { type: ShipmentInformationFormConstants.SET_READY } }
function clearClickAction() { return { type: ShipmentInformationFormConstants.CLEAR_CLICK_ACTION} }

function copy(data) { 
  
    return dispatch => {
      dispatch(closeModalBooking())
      dispatch(closeModalSpot())
      dispatch(clearSelection())
      locationService.getCountriesForInstantPricing()
      .then(
        data => {
          dispatch(success(data));
        }
      )
      dispatch(copy())
  }
  function success(data) { return { type: instantPricingConstants.UPDATE_COUNTRIES, data } }
  function copy() { return { type: ShipmentInformationFormConstants.COPY, data} }
  function closeModalBooking() { return { type: shipmentConstants.CLOSE_MODAL} }
  function clearSelection() { return { type: shipmentConstants.CLEAR} }
  function closeModalSpot() { return { type: customerSpotRequestTableConstants.CLOSE_MODAL} }
  function clearSelection() { return { type: customerSpotRequestTableConstants.CLEAR} }
}

function getUnCodesForClass(adrClass, adrIndex, goodsIndex) {
  return dispatch => {

       shipmentService.getUnCodesForClass(adrClass)
          .then(
              data => {
                const formattedData = data.map(item => ({
                  value: item.unCode,
                  label: item.unCode,
                }));
                  dispatch(success(formattedData));

              },
              error => {
                dispatch(failure(error));
              }
          )

      function success(data) { return { type: ShipmentInformationFormConstants.UPDATE_UNCODE_OPTIONS, data, adrIndex, goodsIndex } }
      function failure(error) { return { type: ShipmentInformationFormConstants.ERROR, error } }

  }
}

function GetDescriptionsForUnCode(unCode, goodsIndex, adrIndex) {
  return dispatch => {

       shipmentService.GetDescriptionsForUnCode(unCode)
          .then(
              data => {
                const formattedData = data.map(item => ({
                  value: item.description,
                  label: item.description + ' / ' + item.packingGroup + ' / (' + item.tunnelCode + ')',
                  subsidiaryRisk: item.subsidiaryRisk,
                  technicalNameRequired: item.technicalNameRequired,
                  tunnelCode: item.tunnelCode,
                  packingGroup: item.packingGroup
                }));
                  dispatch(success(formattedData));

              },
              error => {
                dispatch(failure(error));
              }
          )

      function success(data) { return { type: ShipmentInformationFormConstants.UPDATE_ADRDESCRIPTION_OPTIONS, data, goodsIndex, adrIndex } }
      function failure(error) { return { type: ShipmentInformationFormConstants.ERROR, error } }

  }
}

function updateAdrDescription(name, value, subsidiaryRisk, technicalNameRequired, tunnelCode, packingGroup, index, adrIndex) {
  
  return dispatch => {
    const data = { name, value, subsidiaryRisk, technicalNameRequired, tunnelCode, packingGroup, index, adrIndex }
    dispatch(update(data));

  }

  function update(data) { return { type: ShipmentInformationFormConstants.UPDATE_ADR_ROW, data } }


}

function addAdrLine(goodsIndex) {
  return { type: ShipmentInformationFormConstants.ADD_ADRLINE, goodsIndex };
}

function removeAdrLine(goodsIndex, adrIndex) {

  const data = { goodsIndex, adrIndex }
  return { type: ShipmentInformationFormConstants.REMOVE_ADRLINE, data };
}




