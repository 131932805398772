import React, { useState } from 'react';
import Collapse from '@mui/material/Collapse';
import logo from '_assets/landingPage/images/logo.png'
import Grow from '@mui/material/Grow';



export default function Navigation ({showLogin, showRegister, active}){
  const [open,setOpen] = useState("navbar-collapse collapse")


    return (
        <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container">
          <a className="navbar-brand" href="/login">
            <img src={logo} alt="" />
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
            onClick={() => setOpen(open === "navbar-collapse collapse" ? "navbar-collapse" : "navbar-collapse collapse")}
          >
            <span className="navbar-toggler-icon" />
          </button>
          {/* <Grow in={true}> */}
          <div className="d-flex justify-content nav-holder">
            <div
              className={open}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                <li className="nav-item">
                  <a className={active === 'HowItWorks' ? 'nav-link active' : 'nav-link'} aria-current="page" href="/howitworks">
                    How it works
                  </a>
                </li>
                <li className="nav-item">
                  <a className={active === 'AboutUs' ? 'nav-link active' : 'nav-link'} href="/aboutus">
                    About us
                  </a>
                </li>
                <li className="nav-item">
                  <a className={active === 'FAQ' ? 'nav-link active' : 'nav-link'} href="/faq">
                    FAQ
                  </a>
                </li>
              </ul>
              <div className="btn-holder">
                <button  type="button" onClick={() => showLogin(true)} className="btn btn-primary me-2" >Login</button>
                <button  type="button" onClick={() => showRegister(true)} className="btn btn-primary" >Sign up</button>
         
              </div>
            </div>
          </div>
          {/* </Grow> */}
        </div>
      </nav>


    )
}