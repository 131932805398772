import { combineReducers } from 'redux';
import { authentication } from './authentication.reducer';
import { ShipmentInformationForm } from './shipmentInformationForm.reducer';
import { instantPricing } from './instantPricing.reducer';
import { misc } from './misc.reducer';
import { alert } from './alert.reducer';
import { location } from './location.reducer';
import { shipment } from './shipment.reducer';
import { customerSpotRequestTable} from './customerSpotRequestTable.reducer';
import { carrierSpotRequestTable } from './carrierSpotRequestTable.reducer';
import { admin } from './admin.reducer';
import { spotRequest } from './spotRequest.reducer';
import { user } from './user.reducer';
import { dashboard } from './dashboard.reducer';

const rootReducer = combineReducers({
  authentication,
  alert,
  ShipmentInformationForm,
  instantPricing,
  misc,
  location,
  shipment,
  admin,
  spotRequest,
  user,
  dashboard,
  customerSpotRequestTable,
  carrierSpotRequestTable,
});

export default rootReducer;