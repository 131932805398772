import { userConstants } from '../_constants';
import { validateRegisterForm} from '../_helpers'


let user = JSON.parse(localStorage.getItem('user'));
const initialState = user ?
  { user } :
  {
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    password: '',
    passwordConfirm: '',
    companyName: '',
    bic: '',
    firstNameError: '',
    lastNameError: '',
    emailError: '',
    phoneNumberError: '',
    passwordError: '',
    companyNameError: '',
    bicError: '',
    terms: false,
    valid: true,
    loading: false,
    confirmed: false,
    saved: false,

  };

export function authentication(state = initialState, action) {
  switch (action.type) {
    case userConstants.LOGIN_REQUEST:
      return {
        loading: true,
        user: action.user
      };
      case userConstants.LOADING:
        return {
          ...state,
          loading: action.value,
        };
    case userConstants.LOGIN_SUCCESS:
      return {
        loggedIn: true,
        user: action.user
      };
      case userConstants.CLEAR:
        return {
         ...initialState
        };
    case userConstants.LOGIN_FAILURE:
      return {};
    case userConstants.LOGOUT:
      return {};

      case userConstants.UPDATE_VALUE:

        let newState = {
          ...state,
          [action.data.name + 'Error']: false,
          [action.data.name]:action.data.value
        }

        return {
          ...validateRegisterForm(newState),
      
        }


        case userConstants.REGISTRATION_FAILURE:

        return {
          ...state,
          firstNameError: action.data.firstName && action.data.firstName,
          lastNameError:  action.data.lastName && action.data.lastName,
          emailError:  action.data.email && action.data.email,
          phoneNumberError:  action.data.phoneNumber && action.data.phoneNumber,
          passwordError:  action.data.password && action.data.password,
          passwordConfirmError:  action.data.passwordConfirm && action.data.passwordConfirm,
          companyNameError:  action.data.companyName && action.data.companyName,
          bicError:  action.data.bic && action.data.bic,
          errorMessage: action.data.message,
          valid:false,
          loading:false
        }

        case userConstants.CONFIRMATION_FAILURE:

        return {
          ...initialState,
          errorMessage: action.value
        }

        case userConstants.CONFIRMATION_OK:

        return {

          ...initialState,
          confirmed: true
        }

  
          case userConstants.REGISTRATION_OK:
  
          return {
  
            ...initialState,
            saved: true,
            loading: false,
          }

    default:
      return state
  }
}