import React from 'react';
import freightoptLogoSmall from '../../_assets/images/parcel.png'
import Grid from '@mui/material/Grid';
import DisplayWithLabel from '_components/FormFields/DisplayWithLabel'
import { useDispatch, useSelector } from 'react-redux';
import SuiBox from "_softUI/components/SuiBox";
import CardMedia from "@mui/material/CardMedia";
import SuiTypography from "_softUI/components/SuiTypography";
import Divider from '@mui/material/Divider';
import TextField from '@mui/material/TextField';
import ExcelCellInfoRow from './ExcelCellInfoRow';
import { adminActions } from '_actions/admin.actions';


export default function ExcelCellInfoContainer({state}) {
    const dispatch = useDispatch()
    const groupOptions= [{value:1,label:'Parameters'},{value:2,label:'OutputDetails'},{value:3,label:'Selections'},{value:4,label:'PricingDetails'}]
    const typeOptions = [{value:'input',label:'input'},{value:'output',label:'output'}]
    const valueTypeOptions = [{value:'string',label:'string'},{value:'double',label:'double'},{value:'pno',label:'pno'},{value:'bool',label:'bool'},{value:'percent',label:'percent'}]

    function addRow() {
        dispatch(adminActions.addCellrow());
      }
    
      function removeRow(index) {
        dispatch(adminActions.removeCellrow(index));
      }

      function handleRowValueChange(name,value,index){
        dispatch(adminActions.updateCalculatorCellRowValue(name,value,index));
      }

    return (
        <React.Fragment >
            <Grid container sx={{ mb: '1rem' }}>
            <Grid item container spacing={1} xs={12} sx={{ position: 'relative' }}>
                    <Grid item xs={2} sx={{textAlign:'center'}}>
                        Group
                    </Grid>
                    <Grid item xs={2} sx={{textAlign:'center'}}>
                      Range
                    </Grid>
                    <Grid item xs={2} sx={{textAlign:'center'}}>
                        Name
                    </Grid>
                    <Grid item xs={2} sx={{textAlign:'center'}}>
                       Type
                    </Grid>
                    <Grid item xs={2} sx={{textAlign:'center'}}>
                        Value Type
                    </Grid>
                    <Grid item xs={2} sx={{textAlign:'center'}}>
                      
                    </Grid>

                </Grid>
         
                <Grid item  xs={12} >
                  
                    {state.map((x,i) => (
                        <ExcelCellInfoRow key={i} groupOptions={groupOptions} typeOptions={typeOptions} valueTypeOptions={valueTypeOptions} index={i} last={i === state.length -1} addRow={addRow} removeRow={removeRow} state={x} handleChange={handleRowValueChange}  />
                    ))}

 
                </Grid>

            </Grid>

        </React.Fragment>
    );

}