
import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { locationActions } from '_actions/location.actions';
import Grid from '@mui/material/Grid';

import SuiButton from '_softUI/components/SuiButton';
import DataTable from '_components/Tables/DataTable';
import { AddressBookModal } from '_components/AddressBookTable/AddressBookModal';
import ReactCountryFlag from "react-country-flag"



import { Paper } from '@mui/material';

export function AddressBook() {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);

    useEffect(() => {
        dispatch(locationActions.loadSavedLocations());
    }, [dispatch]);

    const locations = useSelector(state => state.location.locations);
    const locationState = useSelector(state => state.location);

    const data = useMemo(() => ({
        columns: [
            { Header: "ID", accessor: "id", show: false },
            { Header: "COMPANY", accessor: "name" },
            { Header: "STREET ADDRESS", accessor: "streetAddress" },
            { Header: "CITY", accessor: "city" },
            { Header: "ZIP", accessor: "zip" },
            {
                Header: "COUNTRY",
                accessor: "countryAlpha2",
                Cell: ({ value }) => (
                    <>
                        {value}
                        <ReactCountryFlag svg countryCode={value} height="16" style={{ marginLeft: '10px' }} />
                    </>
                )
            },
            { Header: "CONTACT", accessor: "contactName" },
        ],
        rows: locations.map(x => ({
            'id': x.id,
            'name': x.name,
            'streetAddress': x.streetAddress,
            'city': x.city,
            'zip': x.zip,
            'countryAlpha2': x.countryAlpha2,
            'contactName': x.contactName
        }))
    }), [locations]);

    const handleRowClick = (id) => {
        dispatch(locationActions.initateSelectedLocation(id));
        setModalOpen(true);
    }

    const handleModalClose = () => {
        dispatch(locationActions.clearSelectedLocation());
        setModalOpen(false);
    }

    const handleNewModalOpen = () => {
        dispatch(locationActions.clearSelectedLocation());
        setModalOpen(true);
    }

    return (
        <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" sx={{ height: '100%' }}>
            <Grid container item xs={12} sx={{ height: '100%' }}>
                <Paper sx={{ width: '100%' }}>
                    <DataTable
                        handleRowClick={handleRowClick}
                        canSearch
                        table={data}
                        loading={modalOpen ? false : locationState.loading}
                        sx={{ maxHeight: 'calc(100% - 120px)' }}
                        title={'Address book'}
                        subtitle={'Click a row to view or edit address information'}
                        actionButton={<SuiButton color="secondary" onClick={handleNewModalOpen}>New address</SuiButton>}
                    />
                </Paper>
            </Grid>
            <AddressBookModal open={modalOpen} handleClose={handleModalClose} />
        </Grid>
    );
}