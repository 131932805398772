import React, {  useEffect } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import { miscActions, registerActions, userActions } from '../_actions';
import { Grid } from '@mui/material';

import {  useLocation } from "react-router-dom";

import SuiTypography from "_softUI/components/SuiTypography";

import { Loading } from '_components';
import { Success } from '_components';

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export function ConfirmBooking() {
    const dispatch = useDispatch();
    const state = useSelector(state => state.misc);
    let query = useQuery();

    useEffect(() => {
        const model = {
            id: query.get('id'),
            key: query.get('key')
        }
        dispatch(miscActions.confirmBooking(model));

    }, [])



    return (
        <>
            <Grid containerjustifyContent="center"
                alignItems="center" textAlign="center">
                {state.loading && <Loading />}
                {!state.loading && state.confirmed && state.confirmStatus !== "Already confirmed" &&
                    <Grid item xs={12}>
                        <Success title="Booking confirmed" />
                        <SuiTypography>  Booking is now confirmed and the customer will be notified.</SuiTypography>

                    </Grid>
                }

                {!state.loading && state.confirmed && state.confirmStatus === "Already confirmed" &&
                    <Grid item xs={12}>
                        <Success title="Booking has already been confirmed" />
                        <SuiTypography>  Booking has already been confirmed, confirmation message will NOT be sent again.</SuiTypography>

                    </Grid>
                }


                {!state.loading && !state.confirmed && state.errorMessage &&
                    <Grid item>

                        Something went wrong.

                    </Grid>


                }



            </Grid>

        </>
    );

}


