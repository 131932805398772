import { spotRequestConstants, alertConstants } from '../_constants';
import {spotRequestService} from '../_services'
import { carrierSpotRequestTableActions } from './carrierSpotRequestTable.actions';
import { shipmentActions } from './shipment.actions';

export const spotRequestActions = {
    saveQuote,
    updateValue,
    storeFile,
    deleteFile
};

function storeFile(file) {

    return { type: spotRequestConstants.STORE_FILE, file };
  }
  
  function deleteFile(index) {
  
    return { type: spotRequestConstants.DELETE_FILE, index };
  }

function saveQuote(model) {
    return dispatch => {
        dispatch(loading(true));

        spotRequestService.saveQuote(model)
            .then(
                data => {
                    if(data.success){
                        dispatch(carrierSpotRequestTableActions.getShipmentByIdForCarrier(model.shipmentId))
                        dispatch(carrierSpotRequestTableActions.initateCarrier())
                        dispatch(success('Saved!'));
                        dispatch(loading(false));
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                    }
                  

                },
                error => {
                    dispatch(failure(error));
                    dispatch(loading(false));
                 
                }
            )

        function failure(message) { return { type: alertConstants.ERROR, message } }
        function success(message) { return { type: alertConstants.SUCCESS, message } }
        function loading(value = true) { return { type: spotRequestConstants.LOADING, value } }

    }
}

function updateValue(name, value) {


    return dispatch => {

      const data = { name, value }
      dispatch(update(data));
  
    }
  
    function update(data) { return { type: spotRequestConstants.UPDATE_VALUE, data }}
   
  
  }