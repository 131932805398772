import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputWithLabel from '_components/FormFields/InputWithLabel';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiButton from '_softUI/components/SuiButton';
import { adminActions } from '_actions/admin.actions';
import { useDispatch, useSelector } from 'react-redux';
import SelectWithLabel from '_components/FormFields/SelectWithLabel';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormGroup from '@mui/material/FormGroup';
import DisplayWithLabel from '_components/FormFields/DisplayWithLabel';
import { useConfirm } from 'material-ui-confirm';
import { Loading } from '_components/Shared/LoadingSpinner'

export function UserInput({ open, handleClose, title, save }) {
    const dispatch = useDispatch();
    const confirm = useConfirm();
    const state = useSelector(state => state.admin);

    function handleUserInputChange(event) {
        dispatch(adminActions.updateUserInputValue(event.target.name, event.target.value));
    }

    function handleSelectChange(name, event) {

        dispatch(adminActions.updateUserInputValue(name, event.value));
    }

    function handleUserRoleChange(event, name) {

        dispatch(adminActions.updateUserRoleValue(name, event.target.checked));

    }

    function handleCheckboxChange(event) {
        dispatch(adminActions.updateUserInputValue(event.target.name, event.target.checked));
    }

    const handleDeleteClick = () => {
        confirm({ description: 'Deleting a user cannot be undone' })
            .then(() => {
                dispatch(adminActions.deleteUser(state.selectedUser, handleClose))
            })
            .catch(() => { /* ... */ });
    };

    const handleSendClick = () => {
        confirm({ description: 'Password will be sent to user' })
            .then(() => {
                dispatch(adminActions.newPasswordForUser(state.selectedUser, handleClose))
            })
            .catch(() => { /* ... */ })
    };


    const roles = state.userInput.allRoles ?? []

    const customerList = state.customers && state.customers.map(x => (
        {
            value: x.id,
            label: x.name
        }))

    const carrierList = state.carriers && state.carriers.map(x => (
        {
            value: x.id,
            label: x.name
        }))


    return (

        <React.Fragment>
            <Dialog
                disableScrollLock
                fullWidth={true}
                maxWidth='sm'
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
                scroll="body"

                sx={{ overflowY: 'unset', '.MuiPaper-root': { overflowY: 'unset' } }}
            >
                {state.loading &&
                    <Grid sx={{ marginTop: '32px' }}>
                        <Loading />
                    </Grid>
                }
                {!state.loading && <>
                </>
                }
                <DialogTitle>
                    {title}
                </DialogTitle>

                <DialogContent sx={{ overflowY: 'unset' }} >

                    <Grid container spacing={2}>
                        <Grid item xs={6} >
                            <DisplayWithLabel label="Id" value={state.userInput.id ?? ''} />
                        </Grid>
                        <Grid item xs={6} >

                            <InputWithLabel label="Username" name="username" value={state.userInput.username ?? ''} onChange={(e) => handleUserInputChange(e)} ></InputWithLabel>

                        </Grid>

                        <Grid item xs={6} >

                            <InputWithLabel label="First name" name="firstName" value={state.userInput.firstName ?? ''} onChange={(e) => handleUserInputChange(e)} ></InputWithLabel>

                        </Grid>
                        <Grid item xs={6} >

                            <InputWithLabel label="Last name" name="lastName" value={state.userInput.lastName ?? ''} onChange={(e) => handleUserInputChange(e)}  ></InputWithLabel>

                        </Grid>
                        <Grid item xs={6} >

                            <InputWithLabel label="Email" name="email" value={state.userInput.email ?? ''} onChange={(e) => handleUserInputChange(e)} ></InputWithLabel>

                        </Grid>
                        <Grid item xs={6} >

                            <InputWithLabel label="Phone" name="phone" value={state.userInput.phone ?? ''} onChange={(e) => handleUserInputChange(e)} ></InputWithLabel>

                        </Grid>
                        <Grid item xs={6} >

                            <InputWithLabel label="Password" name="password" value={state.userInput.password ?? ''} onChange={(e) => handleUserInputChange(e)} ></InputWithLabel>

                        </Grid>
                        <Grid item xs={6} >

                            <InputWithLabel label="Confirm password" name="passwordConfirm" value={state.userInput.passwordConfirm ?? ''} onChange={(e) => handleUserInputChange(e)} ></InputWithLabel>

                        </Grid>
                        <Grid item xs={12} >
                            Roles
                            <Grid container sx={{ pl: 2 }} spacing={2}>
                                <Grid item xs={11} >
                                    <FormGroup row={true}>
                                        {roles.map((x) => <FormControlLabel control={<Checkbox checked={x.selected} onChange={(e) => handleUserRoleChange(e, x.name)} />} key={x.name} label={x.name} />)}
                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12} >

                            <SelectWithLabel
                                value={customerList.find(obj => {
                                    return obj.value === state.userInput.customerId
                                })}

                                onChange={e => handleSelectChange('customerId', e)}
                                label="Customer" options={customerList}
                                name="customerId" />

                        </Grid>

                        <Grid item xs={12} >

                            <SelectWithLabel
                                value={carrierList.find(obj => {
                                    return obj.value === state.userInput.carrierId
                                })}

                                onChange={e => handleSelectChange('carrierId', e)}
                                label="Carrier" options={carrierList}
                                name="carrierId" />

                        </Grid>
                        <Grid item xs={12} >
                            Other
                            <Grid container sx={{ pl: 2 }} spacing={2}>
                                <Grid item xs={11} >
                                    <FormGroup row={true}>
                                        <FormControlLabel name="activated" control={<Checkbox checked={state.userInput.activated} onChange={(e) => handleCheckboxChange(e,)} />} label="Activated" />
                                        <FormControlLabel name="emailConfirmed" control={<Checkbox checked={state.userInput.emailConfirmed} onChange={(e) => handleCheckboxChange(e)} />} label="Email confirmed" />

                                    </FormGroup>
                                </Grid>
                            </Grid>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="flex-start">
                        <Grid item >
                            <SuiButton onClick={handleClose} variant="contained" color="warning">
                                Cancel
                            </SuiButton>
                        </Grid>
                        {state.selectedUser > 0 &&
                            <Grid item >
                                <SuiButton onClick={handleDeleteClick} variant="contained" color="error">
                                    Delete
                                </SuiButton>
                            </Grid>}

                        <Grid item >
                            <SuiButton onClick={handleSendClick} variant="contained" color="secondary" disabled={state.selectedUser === 0}>
                                Send password
                            </SuiButton>
                        </Grid>

                        <Grid item>

                            <SuiButton onClick={save} variant="contained" color="primary">
                                Save
                            </SuiButton>




                        </Grid>
                    </Grid>
                </DialogActions>

            </Dialog>
        </React.Fragment>
    )

}