export const ShipmentInformationFormConstants = {
  LOADING: 'SHIPMENT_INFORMATION_MODAL_LOADING',
  GOODSLINE_LOADING: 'SHIPMENT_INFORMATION_MODAL_GOODSLINE_LOADING',
  OPEN: 'SHIPMENT_INFORMATION_MODAL_OPEN',
  CALCULATING: 'SHIPMENT_INFORMATION_MODAL_CALCULATING',
  CALCULATING_PRICE: 'SHIPMENT_INFORMATION_MODAL_CALCULATING_PRICE',
  CALCULATING_ADR: 'SHIPMENT_INFORMATION_MODAL_CALCULATING_ADR',
  CALCULATING_INSURANCE: 'SHIPMENT_INFORMATION_MODAL_CALCULATING_INSURANCE',
  CLOSE: 'SHIPMENT_INFORMATION_MODAL_CLOSE',
  UPDATE_VALUE: 'SHIPMENT_INFORMATION_MODAL_UPDATE_VALUE',
  REMOVE_GOODSLINE: 'SHIPMENT_INFORMATION_MODAL_REMOVE_GOODSLINE',
  ADD_GOODSLINE: 'SHIPMENT_INFORMATION_MODAL_ADD_GOODSLINE',
  SUCCESS: 'SHIPMENT_INFORMATION_MODAL_SUCCESS',
  ERROR: 'SHIPMENT_INFORMATION_MODAL_ERROR',
  // PREFILL: 'SHIPMENT_INFORMATION_MODAL_PREFILL',
  SET_SAVED_LOCATION: 'SHIPMENT_INFORMATION_MODAL_SET_SAVED_LOCATION',
  SHOW_PREVIEW: 'SHIPMENT_INFORMATION_MODAL_SHOW_PREVIEW',
  CLEAR_LOCATION: 'SHIPMENT_INFORMATION_MODAL_CLEAR_LOCATION',
  HANDLE_ACCEPT_TERMS: 'SHIPMENT_INFORMATION_MODAL_HANDLE_ACCEPT_TERMS',
  STORE_FILE: 'SHIPMENT_INFORMATION_MODAL_STORE_FILE',
  DELETE_FILE: 'SHIPMENT_INFORMATION_MODAL_DELETE_FILE',
  SET_INITIAL_STATE: 'SHIPMENT_INFORMATION_MODAL_SET_INITIAL_STATE',
  NEXT_PHASE: 'SHIPMENT_INFORMATION_MODAL_NEXT_PHASE',
  PREVIOUS_PHASE: 'SHIPMENT_INFORMATION_MODAL_PREVIOUS_PHASE',
  VALIDATE_DETAILS: 'SHIPMENT_INFORMATION_MODAL_VALIDATE_DETAILS',
  VALIDATE_GOODS: 'SHIPMENT_INFORMATION_MODAL_VALIDATE_GOODS',
  VALIDATE_LOCATIONS: 'SHIPMENT_INFORMATION_MODAL_VALIDATE_LOCATIONS',
  SET_COLLAPSE_VISIBILITY: 'SHIPMENT_INFORMATION_MODAL_SET_COLLAPSE_VISIBILITY',
  SET_PRICE: 'SHIPMENT_INFORMATION_MODAL_SET_PRICE',
  SET_READY: 'SHIPMENT_INFORMATION_MODAL_SET_READY',
  RESET_GOODS: 'SHIPMENT_INFORMATION_MODAL_RESET_GOODS',
  UPDATE_SHIPMENT_TYPE_IN_STORE: 'SHIPMENT_INFORMATION_MODAL_UPDATE_SHIPMENT_TYPE_IN_STORE',
  CLEAR_CLICK_ACTION: 'SHIPMENT_INFORMATION_MODAL_CLEAR_CLICK_ACTION',
  COPY: 'SHIPMENT_INFORMATION_MODAL_COPY',
  UPDATE_UNCODE_OPTIONS: 'SHIPMENT_UPDATE_UNCODE_OPTIONS',
  UPDATE_ADRDESCRIPTION_OPTIONS: 'SHIPMENT_UPDATE_ADRDESCRIPTION_OPTIONS',
  UPDATE_ADR_ROW: 'SHIPMENT_UPDATE_ADR_ROW',
  ADD_ADRLINE: 'SHIPMENT_INFORMATION_MODAL_ADD_ADRLINE',
  REMOVE_ADRLINE: 'SHIPMENT_INFORMATION_MODAL_REMOVE_ADRLINE',
};