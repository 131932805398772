import { locationConstants } from '../_constants';
import { validateLocations } from '../_helpers'

const initialState = {
    loading: false,
    showHints: false,
    locations: [{
        locationType: 1,
        title: 'Pickup',
        locationOrder: 1,
        name: '',
        streetAddress: '',
        addressInfo: '',
        city: '',
        openingHours: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        instructions: '',
        zip: '',
        countryAlpha2: '',
        country: '',
        nameError: false,
        streetAddressError: false,
        cityError: false,
        contactNameError: false,
        contactEmailError: false,
        contactPhoneError: false,
        zipError: false,
        valid: true
    }],
    selectedLocation: 0,
    countries: [],
    locationInput: {
        locationType: 1,
        locationOrder: 1,
        title: 'Pickup',
        name: '',
        streetAddress: '',
        contactName: '',
        contactEmail: '',
        contactPhone: '',
        instructions: '',
        zip: '',
        openingHours: '',
        countryAlpha2: '',
        addressInfo: '',
        city: '',
        country: null,
        nameError: false,
        streetAddressError: false,
        cityError: false,
        contactNameError: false,
        contactEmailError: false,
        contactPhoneError: false,
        zipError: false,
        valid: false,
    },
};

let newState = {}

export function location(state = initialState, action) {
    switch (action.type) {
        case locationConstants.LOADING:
            console.log(action)
            return {
                ...state,
                loading: true
            };

        case locationConstants.STORE:
            console.log('actiondata', action.data)
            return {
                ...state,
                locations: action.data,
                loading: false,
            };

        case locationConstants.ADD:
            const newLocs = state.locations.concat(action.data)
            console.log('newLocs', newLocs)
            return {
                ...state,
                locations: newLocs,
                loading: false,
            };

        case locationConstants.INITATE_SELECTED_LOCATION:
            return {
                ...state,
                locationInput: state.locations.find(x => x.id === action.id),
                selectedLocation: action.id
            }

        case locationConstants.UPDATE_LOCATION_INPUT_VALUE:

            const changedLine = {
                ...state.locationInput,
                [action.data.name]: action.data.value,
            }
            const validatedLocation = validateLocations(changedLine)

            console.log('locationupdate', action.data)
            return {
                ...state,
                locationInput: state.showHints ? validatedLocation :
                    {
                        ...state.locationInput,
                        [action.data.name]: action.data.value,
                        valid: validatedLocation.valid
                    }
            };

        case locationConstants.SHOW_HINTS:
            const validatedLocationInput = validateLocations(state.locationInput)


            return {
                ...state,
                showHints: true,
                locationInput: validatedLocationInput
            }

        case locationConstants.UPDATE_COUNTRIES:

            newState = {
                ...state,
                loading: false,
                results: [],
                countries: action.data,
            }

            return {
                ...newState,
                /*                 valid: validateState(newState) */

            };

        case locationConstants.CLEAR_SELECTED_LOCATION:
            return {
                ...state,
                showHints: false,
                selectedLocation: 0,
                locationInput: initialState.locationInput
            }

        case locationConstants.DELETE_SELECTED_LOCATION:
            return {
                ...state,
                loading: false,
                locations: state.locations.filter(x => x.id !== action.data)
            }

        case locationConstants.UPDATE_LOCATION_LIST:
            const newListWithoutCurrent = state.locations.filter(x => x.id !== action.data.id)
            return {
                ...state,
                loading: false,
                locations: newListWithoutCurrent.concat(action.data)
            }

        default:
            return state
    }
}