import { userConstants } from '../_constants';
import { authService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';
import { userService } from '_services/userService';
import { alertConstants } from "_constants/alert.constants";
import { instantPricingConstants } from "_constants/instantPricing.constants";
import { ShipmentInformationFormConstants } from '_constants/shipmentInformationForm.constants';
import { carrierSpotRequestTableConstants } from '_constants/carrierSpotRequestTable.constants';
import { customerSpotRequestTableConstants } from '_constants/customerSpotRequestTable.constants';
import { shipmentConstants } from '../_constants';

export const userActions = {
    login,
    logout,
    getUserInformation,
    updateUserInformation,
    saveUserInformation,
    saveUserPassword,
    updateUserEmailNotification,
    forgotPassword
};

function failure(message) { return { type: alertConstants.ERROR, message } }
function success(message) { return { type: alertConstants.SUCCESS, message } }
function loading(value = true) { return { type: userConstants.LOADING, value } }

function login(username, password, redirectUrl = '/') {


    return dispatch => {
        dispatch(request({ username }));

        authService.login(username, password)
            .then(
                user => {
                    console.log(user);

                    dispatch(success(user));
                    dispatch(resetBookings());
                    dispatch(resetInstantPricing());
                    dispatch(resetShipmentForm());
                    dispatch(resetCustomerTable());
                    dispatch(resetCarrierTable());
                    history.push(redirectUrl);
                },
                error => {
                    dispatch(failure(error));
                    dispatch(alertActions.error(error));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
    function resetInstantPricing() { return { type: instantPricingConstants.SET_INITIAL_STATE} }
    function resetShipmentForm() { return { type: ShipmentInformationFormConstants.SET_INITIAL_STATE} }
    function resetCustomerTable() { return { type: customerSpotRequestTableConstants.SET_INITIAL_STATE} }
    function resetCarrierTable() { return { type: carrierSpotRequestTableConstants.SET_INITIAL_STATE} }
    function resetBookings() { return { type: shipmentConstants.SET_INITIAL_STATE} }
}

function logout() {
    authService.logout();
    return { type: userConstants.LOGOUT };
}

function getUserInformation() {

    return dispatch => {
        dispatch(loading());

        userService.getUserInformation()
            .then(
                user => {
                    console.log(user);
                    dispatch(setData(user));
                },
                error => {
                    dispatch(alertActions.error(error));
                }
            );
    };

    function loading() { return { type: userConstants.LOADING } }
    function setData(user) { return { type: userConstants.SET_USER, user } }


}

function updateUserInformation(name, value) {
    const data = { name, value }
    return { type: userConstants.UPDATE_USER, data };
}

function updateUserEmailNotification(name, value) {
    const data = { emailType: name, unsubscribed:  !value }
    return { type: userConstants.UPDATE_USER_EMAIL_NOTIFICATION, data };
}


function saveUserInformation(model) {
    return dispatch => {
        dispatch(loading());
        userService.saveUserInformation(model)
            .then(
                data => {
                    console.log(data)
                    if (data.success) {
                        dispatch(success(data.message))
                        dispatch(loading(false));
                    }
                    else {
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                    }
                },
                error => {

                    dispatch(failure(error));
                }
            )
    }


}

function saveUserPassword(model) {
    return dispatch => {
        dispatch(loading());
        userService.savePassword(model)
            .then(
                data => {
                    console.log(data)
                    if (data.success) {
                        dispatch(success(data.message))
                        dispatch(loading(false));
                    }
                    else {
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                    }
                },
                error => {

                    dispatch(failure(error));
                }
            )
    }
}

function forgotPassword(model) {
    return dispatch => {
        dispatch(loading());
        authService.forgotPassword(model)
            .then(
                data => {
                    if (data.success) {
                        dispatch(success(data.message))
                        dispatch(loading(false));
                    }
                    else {
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                    }
                },
                error => {

                    dispatch(failure(error));
                }
            )
    }
}


