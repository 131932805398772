import React, { useCallback } from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import icon from '../../_assets/images/pdfIcon.png'
import SuiBox from "_softUI/components/SuiBox";
import Dropzone from '_components/Common/Dropzone'
import { arrayBufferToBase64 } from '_helpers/file'
import { ShipmentInformationFormActions } from '../../_actions';
import SuiTypography from "_softUI/components/SuiTypography";

export default function AddFiles({files,storeFile,deleteFile}) {

    const dispatch = useDispatch();
    const handleFile = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {

                const entry = {
                    name: file.path,
                    data: arrayBufferToBase64(reader.result),
                    type: file.type,
                    fileType: 3
                }

                dispatch(storeFile(entry))
            }
            reader.readAsArrayBuffer(file)
        })

    }, [])

    const handleDelete = (i) => {
        dispatch(deleteFile(i))
    }


    return (
        <React.Fragment>
           
                    <Grid item container xs={12}>
                   
                        <Grid container item xs={12} direction="column" justifyContent="flex-start" alignItems="flex-start" >
                        <Dropzone action={handleFile} handleDelete={handleDelete} files={files} acceptedFileFormats='application/pdf' />

                        </Grid>

                    
                    </Grid>
            
        </React.Fragment>
    );

}





