import { miscConstants } from '../_constants';
import { publicService } from '_services';

export const miscActions = {
    confirmBooking

};


function confirmBooking(model) {


    return dispatch => {
        dispatch(request(true));

        publicService.confirmBooking(model)
            .then(
                data => { 
                    if(data.success){
                        dispatch(success(data));
                    }
                    else{
                        dispatch(failure(data.message));
                    }
                   
                },
                error => {
                    failure(error)
                }
            );
    };
    function request(value) { return { type: miscConstants.LOADING, value} }

    function success(data) { return { type: miscConstants.BOOKING_CONFIRMATION_OK, data} }
    function failure(value) { return { type: miscConstants.BOOKING_CONFIRMATION_FAILURE,value } }
 
}