import {handleResponse} from './util'

export const publicService = {
    confirmBooking
};

function confirmBooking(model) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(model)
    };

  return fetch(process.env.REACT_APP_API_URL + 'public/confirmBooking', requestOptions)
        .then(handleResponse);
}