import { userConstants,alertConstants } from '../_constants';
import { authService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';

export const registerActions = {
    updateValue,
    register,
    confirmEmail

};

function failure(message) { return { type: alertConstants.ERROR, message } }
function success(message) { return { type: alertConstants.SUCCESS, message } }

function updateValue(name, value) {


    return dispatch => {
        const data = { name, value }
        dispatch(update(data));
    
      }
    
      function update(data) { return { type: userConstants.UPDATE_VALUE, data }}
     
}

function register(model) {

    return dispatch => {
        dispatch(request(true));
       
        const newModel ={
            firstName: model.firstName,
            lastName: model.lastName,
            email: model.email,
            phoneNumber: model.phoneNumber,
            password: model.password,
            companyName: model.companyName,
            bic: model.companyName,
            passwordConfirm: model.passwordConfirm,
            country: model.country
        }

        authService.register(newModel)
            .then(
                data => {
                    if(data.success){
                        dispatch(registrationOK(data));
                    }
                    else{
                        dispatch(registrationError(data));
                    }
          
          

                },
                error => {
                
                    dispatch(registrationError(error));
                 
                }
            )
        function registrationOK(value) { return { type: userConstants.REGISTRATION_OK, value} }
        function registrationError(data) { return { type: userConstants.REGISTRATION_FAILURE, data} }
        function request(value) { return { type: userConstants.LOADING, value} }


    }
     
}

function confirmEmail(model) {


    return dispatch => {
        dispatch(request(true));

        authService.confirmEmail(model)
            .then(
                data => { 
                    if(data.success){
                        dispatch(success());
                    }
                    else{
                        dispatch(failure(data.message));
                    }
                   
                },
                error => {
                    failure(error)
                }
            );
    };
    function request(value) { return { type: userConstants.LOADING, value} }

    function success() { return { type: userConstants.CONFIRMATION_OK} }
    function failure(value) { return { type: userConstants.CONFIRMATION_FAILURE,value } }
 
}


