import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Register from '_components/Register';
// import '_assets/landingPage/css/bootstrap.css'
// import '_assets/landingPage/css/main.css'
import Navigation from './Navigation'
import { Footer } from './Footer'
import LeftCircle from '_assets/landingPage/images/left-circle.svg'
import Star from '_assets/landingPage/images/star.svg'
import Stock3 from '_assets/landingPage/images/iStock3.jpg'
import Stock4 from '_assets/landingPage/images/iStock4.jpg'
import Stock2 from '_assets/landingPage/images/iStock4.jpg'
import Img from '_assets/landingPage/images/img.jpg'
import Shape4 from '_assets/landingPage/images/shape-4.svg'
import Logo from '_assets/landingPage/images/logo.png'

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Login from '_components/Login';

export function FI() {
  const dispatch = useDispatch();
  const [showRegister, setShowRegister] = useState(false)
  const [showLogin, setShowLogin] = useState(false)



  return (
    <div style={{ backgroundColor: '#fff', fontWeight:400, fontSize:'0.875rem', lineHeight:'1.8571' }}>
      <link href="_assets/landingPage/css/main.css" rel="stylesheet" />
      <link href="'_assets/landingPage/css/bootstrap.css" rel="stylesheet" />
      <Navigation showLogin={setShowLogin} showRegister={setShowRegister} active="" />


      <section class="landingBanner">
        <div class="container ">
          <div class="row d-flex align-items-center">
            <div class="col-12 ">
              <div class="text bannerText">
                <div class="text-holder">

                  <h1>Helpoin tapa vertailla, tilata ja seurata Euroopan maantierahtikuljetuksia. Ilmaiseksi. </h1>
                  <p class="subText">FreightOpt on kotimainen ja ilmainen rahtien tilauskanava. Hae ja tilaa kuljetuksia luotettavilta kuljetusliikkeiltä. </p>
                </div>
              </div>
              <div class="col-12 ">
                <div class="cta-holder align-center">
                       
              <button  type="button" onClick={() => setShowRegister(true)} className="btn btn-secondary d-inline-flex" >    Rekisteröidy ilmaiseksi
              <img src={LeftCircle} alt="" /></button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="videoBanner">
        <div class="container">


          <div class="row" style={{"height": "100%;"}}>
            <div class="col-12 col-lg-6">
              <div class="video-container section-class" style={{"height": "100%;"}}>
                <div class="video-wrapper">
                  <iframe src="https://player.vimeo.com/video/815290921?h=378552a888" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 videoList-wrapper">
              <div class="videoList-container margin-left-md ">
                <ul class="list" style={{"display": "grid;"}}>
                  <li class="videoListItem"><span style={{"fontWeight": "700"}}>Kilpailukykyiset hinnat</span><br />Euroopanlaajuisesti luotettavilta kuljetusliikkeiltä.</li>
                  <li class="videoListItem"><span style={{"fontWeight": "700"}}>Yksi alusta</span><br />Erittäin helppokäyttöinen. Hae, tilaa ja seuraa lähetyksiäsi.</li>
                  <li class="videoListItem"><span style={{"fontWeight": "700"}}>Helppo tilaus</span><br/>Useita vaihtoehtoja, tilaa suoraan järjestelmästä. </li>
                </ul>
                <div class="col-12 row videoList-btn-row" style={{"height": "100%;"}}>
                  <div class="col-md-12 col-lg-6 videoList-btn">
                    <div>


<button  type="button" onClick={() => setShowRegister(true)} className="btn btn-secondary d-inline-flex" >    Kokeile ilmaiseksi
              <img src={LeftCircle} alt="" /></button>

                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6 text-center-sm video-cta-col">
                    <span class="video-cta">Liity nyt yli 3000+ käyttäjän joukkoon!</span>
                  </div>

                </div>
              </div>
            </div>
          </div>

          </div>


      </section>


      <section class="appointment-holder" style={{"padding": "50px"}}>
        <div class="container">
          <div class="align-center head">
            <h1>Katso kuljetuksen hinta heti ilmaiseksi kirjautumalla järjestelmään</h1>
          </div>

        </div>
      </section>

      <div class="details-holder process">
        <div class="container">
          <div class="row d-flex align-items-center">

            <div class="col-12 col-lg-6 order-lg-2 details-order-2">
              <div class="text">
                <div class="text-block margin-left-md" style={{"margin-right": "0px !important;"}} >
                  <h1>Täydellinen työkalu pienille ja keskisuurille yrityksille</h1>
                  <p>Helppokäyttöinen alustamme Euroopan maantierahtikuljetuksille palvelee parhaiten pieniä ja keskisuuria yrityksiä, joilla kansainvälistä rahtia ei liiku ihan päivittäin. Tarjoamme kilpailukykyisen hintatason yrityksesi koostasi riippumatta. Hinnat ovat valmiiksi vertailtuja, joten mitään piilokustannuksia ei synny.</p>
                </div>



<button  type="button" onClick={() => setShowRegister(true)} className="btn btn-primary btn-dark d-inline-flex margin-left-md" >    Rekisteröidy nyt
              <img src={LeftCircle} alt="" /></button>

                

              </div>
            </div>
            <div class="col-12 col-lg-6 order-lg-1 details-order-1">
              <div class="img-holder">
                <img src={Stock2} alt=""/>
              </div>
            </div>
          </div>
          <div class="row d-flex align-items-center">
            <div class="col-12 col-lg-6 order-lg-2 details-order-3">
              <div class="img-holder">
                <img src={Img} alt=""/>
              </div>
            </div>
            <div class="col-12 col-lg-6 order-lg-1 details-order-4">
              <div class="text">
                <div class="text-block">
                  <h1>Käyttö on ilmaista, maksat vain kuljetuksista</h1>
                  <p>FreightOptin käyttö on maksutonta ja maksat vain toteutuneista kuljetustilauksista. Vertailemme hinnat puolestasi, joten voit valita helposti parhaan vaihtoehdon. Hae, vertaile, tilaa ja seuraa kuljetuksia, kaikki saman katon alta.</p>
                </div>


<button  type="button" onClick={() => setShowRegister(true)} className="btn btn-primary btn-dark d-inline-flex" >    Rekisteröidy nyt
              <img src={LeftCircle} alt="" /></button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="reference-banner">
        <div class="container">
          <div class="head" style={{"margin-bottom":"50px;"}}>
            <h2 style={{"color": "#D1D5CE"}}>"FreightOpt on hyvä vaihtoehto yritykselle, jolla on paljon vientiä ja tuontia.
              <br/>
                Ohjelmistoa on helppo ja nopea käyttää ja kaikki tarjoukset löytyvät samasta paikasta.
                <br/>
                  Hyvä tapa säästää aikaa, rahaa ja hermoja. "</h2>
                <p style={{"color": "white"}}>- Amanda Nygård, Finn-Korkki Oy</p>
              </div>
              <div class="stars-wrapper">
              <img src={Star} alt="" />
              <img src={Star} alt="" />
              <img src={Star} alt="" />
              <img src={Star} alt="" />
              <img src={Star} alt="" />
     
                        </div>
                      </div>

                    </section>
                    {/* <section>
                      <div class="callToAction">
                        <div>
                          <a href="/contact.aspx" class="btn btn-dark cta-btn">Haluatko kuulla lisää? Jätä yhteystietosi tästä</a>
                        </div>
                      </div>
                    </section> */}
                    <section class="offer-holder">
                      <div class="container">
                        <div class="row d-flex align-items-center">
                          <div class="col-12 col-lg-6">
                            <div class="signup-holder">
                              <h1>Aloita nyt ilmaiseksi</h1>
                              <ul class="list">
                                <li>Helppo ja nopea rekisteröityminen. Näet hinnat ja voit tilata ensimmäisen kuljetuksen vain muutamassa minuutissa</li>
                                <li>Saat käyttöösi kaikki FreightOptin sopimushinnat kuljetusliikkeiltä </li>
                                <li>Maksat vain rahdeista, palvelun käyttö on täysin ilmaista</li>
                                <li>Hallitse koko prosessia rahtidokumenteista seurantaan samalla alustalla</li>

                              </ul>
                              <div class="logo">
                                <a href="#">
                                  <img src={Logo} alt=""/>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <h1>Hallinnoi koko prosessia helposti</h1>
                            <p>FreightOptin avulla voit jakaa kaikki tarvittavat lähetysasiakirjat ja muut tiedot suoraan järjestelmän sisällä. Hallinnoi koko kuljetusta tarjouksesta toimitukseen helposti. </p>

                            <p>Voit tarkastella lähetyshistoriaasi ja hintojen kehitystä bookings välilehdeltä. </p>
                            {/* <asp:LinkButton ID="LinkButton5" runat="server" class="btn btn-primary d-inline-flex" data-toggle="modal" data-target="#registerModal" Text="Rekisteröidy nyt" OnClitClick="return false" ClientIDMode="static" OnClick="LinkButton1_Click">
                              <asp:Image runat="server" ImageUrl="../../Content/LandingPage/images/left-circle.svg" />

                            </asp:LinkButton> */}
<button  type="button" onClick={() => setShowRegister(true)} className="btn btn-primary btn-dark d-inline-flex" >    Rekisteröidy nyt
              <img src={LeftCircle} alt="" /></button>
                          </div>
                        </div>
                      </div>
                    </section>


                    <section class="price-holder">
                      <div class="container">
                        <div class="text">
                          <div class="head text-center">
                            <h1>Paranna kilpailukykyäsi</h1>
                          </div>
                          <p>Täytä kuljetuksen tiedot järjestelmään ja me tarjoamme heti parhaat vaihtoehdot kuljetuksellesi. Voit tilata rahdin suoraan helposti ja nopeasti.</p>

                          <p>Useat vaihtoehdot varmistavat, että hinnat ovat kilpailukykyiset mahdollisimman monilla alueilla. </p>
                          <p>Lähetysten kilpailutus tuottaa selkeitä säästöjä ja tehostaa yrityksesi toimintaa.</p>
                          <ul class="timeline">
                            <li>
                              <span class="count">1</span>
                              <strong style={{"fontSize": "1.2em"}}>Rahdin tiedot</strong>
                            </li>
                            <li>
                              <span class="count">2</span>
                              <strong style={{"fontSize": "1.2em"}}>Valitse palveluntarjoaja </strong>
                            </li>
                            <li>
                              <span class="count">3</span>
                              <strong style={{"fontSize": "1.2em"}}>Lähetyksen tiedot </strong>
                            </li>
                            <li>
                              <span class="count">4</span>
                              <strong style={{"fontSize": "1.2em"}}>Vahvista</strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </section>




                    <Footer />

                    <Register show={showRegister} hide={setShowRegister} />
                    <Login show={showLogin} hide={setShowLogin} />





                  </div>
                  );

}


