import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Register from '_components/Register';
// import '_assets/landingPage/css/bootstrap.css'
// import '_assets/landingPage/css/main.css'
import Navigation from './Navigation'
import {Footer} from './Footer'
import LeftCircle from '_assets/landingPage/images/left-circle.svg'
import Brand from '_assets/landingPage/images/brand.svg'
import Stock3 from '_assets/landingPage/images/iStock3.jpg'
import Stock4 from '_assets/landingPage/images/iStock4.jpg'
import Stock2 from '_assets/landingPage/images/iStock2.jpg'
import Stock1 from '_assets/landingPage/images/iStock1.jpg'
import Stock5 from '_assets/landingPage/images/iStock5.jpg'
import Img from '_assets/landingPage/images/img.jpg'
import Shape4 from '_assets/landingPage/images/shape-4.svg'
import Logo from '_assets/landingPage/images/logo.png'
import IcoCheck from '_assets/landingPage/images/ico-check.svg'

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Login from '_components/Login';

export function FAQ() {
  const dispatch = useDispatch();
  const [showRegister, setShowRegister] = useState(false)
  const [showLogin, setShowLogin] = useState(false)



  return (
    <div style={{backgroundColor: '#fff'}}>

      <Navigation showLogin={setShowLogin} showRegister={setShowRegister} active="FAQ" />

     {/* banner area of the page */}
     <div className="faq-banner">
      <div className="container">
        <div className="text">
          <h1>Frequently asked questions</h1>
          <p>
            Something unclear? See below if you can find and an answer from
            here.{" "}
          </p>
        </div>
      </div>
    </div>
    {/* faq details area of the page */}
    <section className="faq-holder text-center">
      <div className="container">
        <div className="other-services">How to get started?</div>
        <div className="block-holder">
          <div className="block">
            <h1>How much does it cost to use the system?</h1>
            <p>
              It’s free of charge. You can search, book and track your European
              road freights directly from our platform. FreightOpt compares the
              final prices for you, no hidden fees.
            </p>
          </div>
          <div className="block">
            <h1>What company details do I need to fill in?</h1>
            <p>
              General company and contact information. We are browser-based, no
              installation needed.
            </p>
          </div>
          <div className="block">
            <h1>How do I book shipments in the system?</h1>
            <p>
              Just enter the shipping needs, choose a forwarder and finalize the
              booking by filling the pickup and delivery instructions. Your
              shipment is on its way!
            </p>
          </div>
        </div>
      </div>
    </section>
    {/* faq details area of the page */}
    <section className="faq-holder text-center bg-white">
      <div className="container">
        <div className="other-services">Good to know</div>
        <div className="block-holder">
          <div className="block">
            <h1>Who is this platform meant for?</h1>
            <p>
              If you have European road freights, it’s meant for you. Whether
              you have imports or exports you can find competitive prices in our
              search engine.{" "}
            </p>
          </div>
          <div className="block">
            <h1>What kind of service providers can be found in the system?</h1>
            <p>
              We have chosen the biggest and the most trusted service providers
              to our system. You can find names such as DHL Freight or DSV Road
              in our system among other big names in the industry.
            </p>
          </div>
          <div className="block">
            <h1>What modes of transport does this platform support?</h1>
            <p>
              Our free of charge search engine and instant booking portal
              supports European road freights. With a small license fee, you can
              also request sea and air freight quotes. Use our network to your
              advantage!
            </p>
          </div>
        </div>
      </div>
    </section>
    {/* faq details area of the page */}
    <section className="faq-holder text-center">
      <div className="container">
        <div className="other-services">Getting into details</div>
        <div className="block-holder">
          <div className="block">
            <h1>How are tracking notifications updated?</h1>
            <p>
              If the carrier supports tracking, tracking notifications are
              updated directly to the system. Just open the bookings tab and you
              can see the status of your shipment immediately. Tracking is
              automatic, so you don’t need to fill in your tracking numbers
              anywhere.{" "}
            </p>
          </div>
          <div className="block">
            <h1>
              How can I access the service provider’s contact details if
              necessary?
            </h1>
            <p>
              If you make a spot request, you can chat with the service provider
              in the system. Open the request and send messages directly.
            </p>
          </div>
          <div className="block">
            <h1>How can I view my previous RFQs?</h1>
            <p>
              Booking history is available for you in the system and you can use
              the previously saved pickup and delivery locations in your
              bookings. It’s very simple; with pre saved information, booking is
              finalized in seconds.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section className="booking-banner mb-20">
      <div className="container">
        <div className="head">
          <h1>
            Did not find an answer to your question? <br /> Contact us, we are
            here to help!
          </h1>
        </div>
        <a href="/contact" className="btn btn-dark">
          Contact us now
        </a>
      </div>
    </section>


      <Footer/>

      <Register show={showRegister} hide={setShowRegister} />
      <Login show={showLogin} hide={setShowLogin} />





    </div>
  );

}


