import { miscConstants } from '../_constants';

const initialState = {
    mainMenuActiveIndex: 0,
    loading: false,
};

export function misc(state = initialState, action) {
    switch (action.type) {
        case miscConstants.LOADING:
            return {
                ...state,
                loading: action.value,
            };

        case miscConstants.BOOKING_CONFIRMATION_OK:

            return {

                ...initialState,
                confirmed: true,
                confirmStatus: action.data.message,
            }


        case miscConstants.BOOKIG_REGISTRATION_OK:

            return {

                ...initialState,
                saved: true,
                loading: false,
            }

        default:
            return state
    }
}