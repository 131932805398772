import React  from 'react';

import AppBar from '@mui/material/AppBar';

import { useState, useEffect } from 'react';






export default function BottomBar(props) {

  const [stuck, setStuck] = useState(false)

  const shadow = !stuck ? (props.legacy ? "0px 2px 1px -1px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 1px 3px 0px rgba(0,0,0,0.12)" :  'rgba(0, 0, 0, 0.16) 0px 1px 4px')  : 'rgba(17, 17, 26, 0.1) 0px 8px 24px, rgba(17, 17, 26, 0.1) 0px 16px 56px, rgba(17, 17, 26, 0.1) 0px 24px 80px'
  


  function Sticky({
    position,
    stuckClasses = "",
    unstuckClasses = "",
    stuckStyles = {},
    unstuckStyles = {},
    children,
    triggers,
    root
  }) {
   
    const ref = React.createRef()
  
    const classes = stuck ? stuckClasses : unstuckClasses
    const styles = stuck ? stuckStyles : unstuckStyles
  
    const inlineStyles = {
      position: "sticky",
      width:'100%',
      [position]: -17,
      ...styles
    }

 
  
    useEffect(() => {
      console.log('test')
      const cachedRef = ref.current
  
      const obs= new IntersectionObserver(entries => {
        console.log(entries)
      })
      obs.observe(ref.current)
      console.log(root)
  
      const observer = new IntersectionObserver(
        ([e]) => setStuck(e.intersectionRatio < 1),
        { threshold: [1],
       }
      )
      console.log(observer)
      observer.observe(cachedRef)
      return () => observer.unobserve(cachedRef)
    },[ref, triggers])
  
    return (
      <div style={inlineStyles} className={classes} ref={ref}>
        {children}
      </div>
    )
  }

    return(
        <React.Fragment>
          <Sticky
          position="bottom"
          // triggers={props.triggers}
          // root={props.root}

          stuckStyles={{

            paddingBottom:'16px',

          }}

          >
          <AppBar position="static" 
          sx={{
            bottom:0,
            top: 'auto',
            borderRadius:'4px',
            backgroundColor:'#fff',
            padding:'0.5rem',
            boxShadow: shadow,
           }}>
            <div>
              {props.children}
            </div>
          </AppBar>
      
        </Sticky>
      </React.Fragment>

    )
}


