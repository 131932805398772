import { FormatColorReset } from '@mui/icons-material';
import { shipmentConstants } from '../_constants';
import { goodsRowModel } from './models';

const initialState = {
    loading: false,
    tableUpdateLoading: false,
    modalOpen: false,
    modalLoading: false,
    data: {
        rows: []
    },
    selectedId: 0,
    selectedShipment: {
        carrierLogoUrl:'',
        senderReference: '',
        receiverReference: '',
        pickupStart: '',
        deliveryStart: '',
        messageToCarrier: '',
        selectedCarrierName: '',
        selectedCarrierLogo: '',
        totalPrice: '',
        docFwdCustomerReceiver:'',
        docFwdCustomerLang:'',
        docFwdSenderReceiver:'',
        prodctuName:'',
        file: [],
        locations: [
            {
                title: '',
                name: '',
                streetAddress: '',
                addressInfo: '',
                city: '',
                openingHours: '',
                contactName: '',
                contactEmail: '',
                contactPhone: '',
                messageToContact: '',
                zip: '',
                countryAlpha2: '',
            },
            {
                title: '',
                name: '',
                streetAddress: '',
                addressInfo: '',
                city: '',
                openingHours: '',
                contactName: '',
                contactEmail: '',
                contactPhone: '',
                messageToContact: '',
                zip: '',
                countryAlpha2: '',

            }
        ],
        goods: [
            goodsRowModel
        ],
        quotes: [
            
        ]
    }
};

export function shipment(state = initialState, action) {
    switch (action.type) {
        case shipmentConstants.LOADING:
            switch (action.loader) {
                case 'initial':
                    return {
                        ...state,
                        loading: true

                    };
                case 'update':
                    return {
                        ...state,
                        tableUpdateLoading: true
                    };
            }


        case shipmentConstants.MODAL_LOADING:
            return {
                ...state,
                modalLoading: action.value,
                modalOpen: true,
            };

            case shipmentConstants.SET_INITIAL_STATE:
                return {
                    ...initialState
                };


        case shipmentConstants.ERROR:
            return {
                ...state,
                loading: false
            };

        case shipmentConstants.UPDATE_BOOKINGS_TABLE:
            return {
                ...state,
                loading: false,
                tableUpdateLoading: false,
                data: {
                    ...state.data,
                    rows: action.data
                }
            };

            case shipmentConstants.UPDATE_CARRIER_TABLE:
                return {
                    ...state,
                    loading: false,
                    data: {
                        ...state.data,
                        rows: action.data
                    }
                };

        case shipmentConstants.UPDATE_VALUE:


                
                
            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    [action.data.name]: action.data.value
                }
            }

        case shipmentConstants.CLEAR:
            return {
                ...state,
                selectedShipment: {
                    ...initialState.selectedShipment
                }
            };

        case shipmentConstants.CLOSE_MODAL:
            return {
                ...state,
                modalOpen: false,
            }



        case shipmentConstants.UPDATE_SELECTED_SHIPMENT:

        const updatedGoods = action.data.goods.map(item => {
            if (item.adrRow.length > 0 && item.adrRow[0]?.class !== '') {
              return { ...item, adr: true };
            } else {
              return item; 
            }
          });

            return {
                ...state,
                loading: false,
                
                selectedShipment: {
                    ...action.data,
                    goods: updatedGoods
                  },
                modalOpen: true,
                modalLoading: false,

            };



        case shipmentConstants.SET_SELECTED_QUOTE:
            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    selectedQuote: action.id
                }


            };

            case shipmentConstants.SET_CANCELLED:

            return {
                ...state,
                loading: false,
                modalLoading: false,
                selectedShipment: {
                    ...state.selectedShipment,
                    cancelled: action.value
                }
             

            };

        default:
            return state
    }

}
