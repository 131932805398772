import React from 'react';
import Grid from '@mui/material/Grid';
import { useDispatch, useSelector } from 'react-redux';
import BasicInfoView from '_components/ShipmentInformationPreview/BasicInfoView'
import LocationView from '_components/ShipmentInformationPreview/LocationView'
import { ShipmentInformationFormActions } from '../../_actions';
import AcceptTerms from './AcceptTerms';
import BookNow from './BookNow';
import { GoodsPreview } from '_components/Common/Goods/GoodsPreview';
import ShipmentPreviewSectionContainer from '_components/Common/ShipmentPreviewSectionContainer';
import AddFiles from './AddFiles';
import Pricing from './Pricing';
import AdditionalInformationView from './AdditionalInformationView';


export default function ShipmentInformationPreview({instant}) {


const state = useSelector(state => state.ShipmentInformationForm);

const pricingModel = state.data.selectedQuote && {
  totalPrice:state.data.totalPrice,
  currency: state.data.currency,
   rows: state.data.selectedQuote.details.filter(function(e) {
    if(e.valueType === 'double' && e.doubleValue > 0){
      return e
    }

   }
    
   ).map(x=> ({name: x.name, value: x.doubleValue}))
}
console.log(pricingModel)

  return (
    
    <React.Fragment > 

          <BasicInfoView state={state.data} instant={instant} currency={state.data.currency}  />
        
          <LocationView state={state.data.locations[0]} />
 
          <LocationView state={state.data.locations[1]} />
          <ShipmentPreviewSectionContainer title="Goods">
          <GoodsPreview goods={state.data.goods} state={state.data} />
          </ShipmentPreviewSectionContainer>
          <ShipmentPreviewSectionContainer title="Attachments">
            <AddFiles files={state.data.file} storeFile={ShipmentInformationFormActions.storeFile} deleteFile={ShipmentInformationFormActions.deleteFile}/>
          </ShipmentPreviewSectionContainer>
          {!instant && 
          <ShipmentPreviewSectionContainer title="Additional information">
            <AdditionalInformationView state={state.data.additionalInformation} />
          </ShipmentPreviewSectionContainer>
          }

      
          {/* {!instant && <BookNow state={state.data}/>} */}
          {instant  &&
              <ShipmentPreviewSectionContainer title="Book">
          <Grid container item xs={12} spacing={3}>
            <Grid item container xs={7}>
            <AcceptTerms handleAcceptTerms state={state.data}/>
            </Grid>
            <Grid item container xs={5}>
            <Pricing state={pricingModel}/>
            </Grid>
          </Grid> 
          </ShipmentPreviewSectionContainer>}
         
       

    
        
        
 
    </React.Fragment>
  );

}





