import {nullOrZeroOrEmptyOrError, nullEmptyOrError} from './helpers'


export function validate(input, includeDescription = false, includeAdrClass = false) {


  var special = input.typeCode === 5 || input.typeCode === 6

  let vals = []

 

  let newOutput = {
    ...input,
    quantityError: nullOrZeroOrEmptyOrError(input.quantity, input.quantityError,vals),
    weightError: nullOrZeroOrEmptyOrError(input.weight, input.weightError,vals),
    heightError: false,
    lengthError: false,
    widthError: false,


  }

  if(!special){
    let excludeFromSpecial ={
      heightError: nullOrZeroOrEmptyOrError(input.height, input.heightError,vals),
      lengthError: nullOrZeroOrEmptyOrError(input.length, input.lengthError,vals),
      widthError: nullOrZeroOrEmptyOrError(input.width, input.widthError,vals),
    }

    newOutput = {
      ...newOutput,
      ...excludeFromSpecial
    }
  }
 
  // if(input.typeCode === 5){
  //   newOutput = {
  //     ...newOutput,
  //     fullTrailerTypeError: nullOrZeroOrEmptyOrError(input.fullTrailerType,input.fullTrailerTypeError,vals),
  //   }
  // }

  if(input.typeCode === 6){
    newOutput = {
      ...newOutput,
      containerTypeError: nullOrZeroOrEmptyOrError(input.containerType,input.containerTypeError,vals),
    }
  }


  if(includeDescription){
    newOutput = {
      ...newOutput,
      descriptionError: nullEmptyOrError(input.description,vals),
    }
  }

  if(input.adr && includeAdrClass){
    newOutput = {
      ...newOutput,
      adrClassError: nullEmptyOrError(input.adrClass[0], vals)
    }
  }
 
  console.log(vals)
  let valid = !vals.includes(false)

  const output = {
    ...newOutput,
    valid: valid
  }


  return output
}







