import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";
import Grid from '@mui/material/Grid';

function Label({value,icon,sx,...props }) {
    return (
        <>
            <SuiBox mb={0} sx={{width:'100%'}}>
                <SuiBox mb={0} ml={0.5} lineHeight={0} sx={{width:'100%'}} display="inline-block">
                    <Grid container alignItems="center" >
                        {icon && 

                            icon
                       
                        }
                        <Grid item xs={10} sx={{ pt: 0, mt: '-0.25rem', ml: icon ? "0.5rem" : "unset" }}>
                            <SuiTypography
                            variant="button" 
                            fontWeight="regular" 
                            color="text"
                        
                            lineHeight="1.2rem"
                            sx={sx}

                            >
                              
                                {value === '' ? "n/a" : value}
                                {props.children}
                            </SuiTypography>
                        </Grid>
                    </Grid>
                </SuiBox>


            </SuiBox>
        </>
    );
}



export default Label;