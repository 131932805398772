import React, { useState } from 'react';

import { useDispatch} from 'react-redux';
import Register from '_components/Register';
// import '_assets/landingPage/css/bootstrap.css'
// import '_assets/landingPage/css/main.css'
import Navigation from './Navigation'
import {Footer} from './Footer'
import LeftCircle from '_assets/landingPage/images/left-circle.svg'

import Stock2 from '_assets/landingPage/images/iStock2.jpg'
import Stock1 from '_assets/landingPage/images/iStock1.jpg'
import Stock5 from '_assets/landingPage/images/iStock5.jpg'

import Logo from '_assets/landingPage/images/logo.png'
import IcoCheck from '_assets/landingPage/images/ico-check.svg'


import Login from '_components/Login';

export function AboutUs() {
  const dispatch = useDispatch();
  const [showRegister, setShowRegister] = useState(false)
  const [showLogin, setShowLogin] = useState(false)



  return (
    <div style={{backgroundColor: '#fff'}}>

      <Navigation showLogin={setShowLogin} showRegister={setShowRegister} active="AboutUs" />


 {/* offer area of the page */}
 <section className="banner-inner">
      <div className="container">
        <div className="head">
          <h1>
            We create a more efficient logistics market for the benefit of our
            customers.
          </h1>
        </div>
        <div className="info-badges">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-3">
              <div className="badge simple">
                +600 <br /> Registered <br /> companies
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="badge simple">
                +4,000 <br />
                RFQ'S per <br />
                year
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="badge simple">
                +1,500,000€ <br /> Bookings per <br />
                year
              </div>
            </div>
            <div className="col-12 col-md-6 col-lg-3">
              <div className="badge simple">
                +15,000 <br /> Quotes per <br />
                year
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* details area of the page */}
    <div className="details-holder process">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-6 order-lg-2">
            <div className="img-holder">
              <img src={Stock2} alt="" />
            </div>
          </div>
          <div className="col-12 col-lg-6 order-lg-1">
            <div className="text">
              <div className="text-block">
                <h1>For small and medium size companies </h1>
                <p>
                  Our platform serves best small and midsize companies, who
                  don’t necessarily have large transport volumes on their own.
                  We provide an easy-to-use interface along with competitive
                  prices for your road transports. Search, book and track your
                  shipments from one platform. Rates are compared And you can
                  immediately see the final price of your shipment.
                </p>
              </div>
              <button  type="button" onClick={() => setShowRegister(true)} className="btn btn-primary btn-dark d-inline-flex" > Register now
                <img src={LeftCircle} alt="" /></button>
        
            </div>
          </div>
        </div>
      </div>
    </div>
    {/*  Our services tag area of the page */}
    <div className="container services-holder">
      <div className="other-services">Our services</div>
      <div className="row">
        <div className="col-12 col-lg-6">
          <div className="card">
            <div className="card-body">
              <h1>Instant pricing</h1>
              <h3>Book your freight now.</h3>
              <p>
                Fill in your road freight details and get access to FreightOpt
                contract prices. Choose the best price and book your freight
                directly for European road freights.
              </p>
              <span className="info-tag">
                <img src={IcoCheck} alt="" />
                Free to use. Pay only for your freights.
              </span>
            </div>
          </div>
        </div>
        <div className="col-12 col-lg-6">
          <div className="card">
            <div className="card-body">
              <h1>Spot Request</h1>
              <h3>Best price of today for all routes.</h3>
              <p>
                Just enter your shipping information and with a few clicks your
                RFQ will reach the most suitable service providers. Easy and
                efficient!{" "}
              </p>
              <span className="info-tag">
                <img src={IcoCheck}  alt="" />
                Monthly fee. Cost efficient solution to find prices for each
                shipment. Also covers air and sea shipments.
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <div className="logo-holder">
            <div className="triangle-up" />
            <div className="logo">
              <a href="/login">
                <img src={Logo} alt="" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div className="other-services">All under one platform</div>
    </div>
    {/* details area of the page */}
    <div className="details-holder process">
      <div className="container">
        <div className="row d-flex align-items-start">
          <div className="col-12 col-lg-6">
            <div className="img-holder">
              <img src={Stock5} alt="" />
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <div className="text">
              <h1>Improving competitiveness together</h1>
              <p>
                FreigthOpt is a unique software developed to offer our customers
                a more cost-efficient way to manage their freights. Logistics is
                one of the most important factors influencing competitiveness.
                On average, small and medium-sized enterprises spend 4.6% of
                their annual turnover exclusively on transports.{" "}
              </p>
              <p>
                We always strive to deliver a customer experience that exceeds
                expectations rather than promises too much. Our operations are
                flexible, reliable and efficient. We strive to develop
                innovative solutions that provide real added value to our
                customers and partners.
              </p>
            </div>
          </div>
        </div>
        <div className="row d-flex align-items-start">
          <div className="col-12 col-lg-6 order-lg-2">
            <div className="img-holder">
              <img src={Stock1} alt="" />
            </div>
          </div>
          <div className="col-12 col-lg-6 order-lg-1">
            <div className="text">
              <h1>Based in Finland</h1>
              <p>
                Since our start in 2021, we have done our best to provide the
                easiest way to book road freights with competitive prices – all
                across Europe.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div className="container">
      <div className="row">
        <div className="col-12 text-center contact-btn-holder">
          <a href="/contact" className="btn btn-secondary full">
            Contact us now{" "}
          </a>
        </div>
      </div>
    </div>





      <Footer/>

      <Register show={showRegister} hide={setShowRegister} />
      <Login show={showLogin} hide={setShowLogin} />





    </div>
  );

}


