export const locationConstants = {
  ADD: 'SAVED_LOCATION_ADD',
  STORE: 'SAVED_LOCATION_STORE',
  LOADING: 'SAVED_LOCATION_LOADING',
  ERROR: 'SAVED_LOCATION_ERROR',
  INITATE_SELECTED_LOCATION: 'ADMIN_INITATE_SELECTED_LOCATION',
  UPDATE_LOCATION_INPUT_VALUE: 'LOCATIONS_UPDATE_LOCATION_INPUT_VALUE',
  UPDATE_COUNTRIES: 'LOCATIONS_UPDATE_COUNTRIES',
  CLEAR_SELECTED_LOCATION: 'LOCATIONS_CLEAR_SELECTED_LOCATION',
  DELETE_SELECTED_LOCATION: 'LOCATIONS_DELETE_SELECTED_LOCATION',
  UPDATE_LOCATION_LIST: 'UPDATE_LOCATION_LIST',
  SHOW_HINTS: 'SAVED_LOCATION_SHOW_HINTS'
};