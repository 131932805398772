import React, { useEffect, useMemo, useRef } from 'react';
import { shipmentActions } from '../_actions';
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import { ShipmentDisplayModal } from '_components/ShipmentDisplayModal/ShipmentDisplayModal';
import BookingsTable from '_components/BookingsTable';
import { Paper } from '@mui/material';
import { useLocation } from "react-router-dom";
import {Id, Customer, References, Location, Logo, BookedPrice,Status} from '_components/BookingsTable/CellFunctions'
import { CSVLink, CSVDownload } from "react-csv";
import e2eimg from "_assets/images/export2excel.png";


function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

function removeUrlParameter(paramKey) {
  const url = window.location.href
  console.log("url", url)
  var r = new URL(url)
  r.searchParams.delete(paramKey)
  const newUrl = r.href
  console.log("r.href", newUrl)
  window.history.pushState({ path: newUrl }, '', newUrl)
}

export function Bookings() {

  let query = useQuery();

  const state = useSelector(state => state.shipment)

  const dispatch = useDispatch()
 useEffect(() => {
    const loadtype = state.data.rows.length === 0 ? 'initial': 'update'
    dispatch(shipmentActions.initate(2, loadtype)); // ShipmentTypeEnum.InstantBooking

    var selectedId = query.get('id')

    if(selectedId){
      dispatch(shipmentActions.getShipmentById(selectedId));
      removeUrlParameter("id")
    }



  }, [])

  const handleRowClick = (id) => {

    dispatch(shipmentActions.getShipmentById(id));
  }


 const  rows = state.data.rows.map(x=> (

  {
    ...x,
    idHidden: x.id,
    bookingDate: x.bookedDate,
    id: x.id,
    idSearch: x.id + ", " + x.bookingDate,
    customer: x.customer,
    user: x.user,
    firstName: x.firstName ?? " ",
    lastName: x.lastName ? x.lastName.charAt(0) + "." : " " ,
    customerSearch: x.customer + " " + x.user,
    pickupCity: x.pickupCity,
    pickupCountry: x.pickupCountry,
    pickupCompany: x.pickupCompany,
    pickupSearch: x.pickupCity + " " + x.pickupCountry + " " + x.pickupCompany,
    deliveryCity:x.deliveryCity,
    deliveryCountry:x.deliveryCountry,
    deliveryCompany:x.deliveryCompany,
    deliverySearch: x.deliveryCity + " " + x.deliveryCountry + " " + x.deliveryCompany,
    carrierLogo: x.carrierLogo,
    carrier: x.carrier,
    carrierSearch: x.carrier,
    pickupReference:x.pickupReference,
    deliveryReference: x.deliveryReference,
    referenceSearch: x.pickupReference + " " + x.deliveryReference,
    bookedPrice: x.bookedPrice,
    bookedPriceSearch: x.bookedPrice,
    status: x.status,
    state: x.state, 
    statusSearch: x.status

  })) 

  const tableData = {
 
    columns: [
        { Header: "id",width:'58px',accessor: "idSearch", Cell: (props) => {return (<Id id={props.row.original.id} date={props.row.original.bookingDate} />) }},
        { Header: "customer", accessor: "customerSearch", Cell: (props) => {return (<Customer customer={props.row.original.customer} user={props.row.original.user} firstName={props.row.original.firstName} lastName={props.row.original.lastName} />) }},
        { Header: "reference", accessor: "referenceSearch", Cell: (props) => {return (<References pickup={props.row.original.pickupReference} delivery={props.row.original.deliveryReference} />) }},
        { Header: "pickup", accessor: "pickupSearch", Cell: (props) => {return (<Location city={props.row.original.pickupCity} country={props.row.original.pickupCountry} name={props.row.original.pickupCompany}/>) }},
        { Header: "delivery", accessor: "deliverySearch", Cell: (props) => {return (<Location city={props.row.original.deliveryCity} country={props.row.original.deliveryCountry} name={props.row.original.deliveryCompany}/>) }},
        { Header: "carrier",width:'100px', accessor: "carrierSearch", Cell: (props) => {return (<Logo url={process.env.PUBLIC_URL +props.row.original.carrierLogo} name={props.row.original.carrier} />) }} ,
        { Header: "booked price", accessor: "bookedPriceSearch", Cell: (props) => {return (<BookedPrice price={props.row.original.bookedPrice} />) }},
        { Header: "status", accessor: "statusSearch", Cell: (props) => {return (<Status status={props.row.original.status} state={props.row.original.state} />) }},
    ],
    rows: rows 
}



const exportHeader=[
  {label:"Id", key:"id"},
  {label:"Booking date", key:"bookingDate"},
  {label:"Reference", key:"reference"},
  {label:"Pickup", key:"pickupSearch"},
  {label:"Delivery", key:"deliverySearch"},
  {label:"Carrier", key:"carrier"},
  {label:"Ldm", key:"totalLdm"},
  {label:"Volume", key:"totalVolume"},
  {label:"Weight", key:"totalWeight"},
  {label:"Booked Price", key:"bookedPrice"},]

const exportBody = state.data.rows.map(x=> (

  {

    id: x.id,
    bookingDate: x.bookedDate,
    reference: (x.pickupReference ?? "") + " / " + (x.deliveryReference ?? ""),
    pickupSearch: x.pickupCompany + ", " + x.pickupCountry + ", " + x.pickupCity,
    deliverySearch: x.deliveryCompany + ", " + x.deliveryCountry + ", " + x.deliveryCity,
    carrier: x.carrier,
    totalLdm: x.totalLdm,
    totalVolume: x.totalVolume,
    totalWeight: x.totalWeight,
    bookedPrice: x.bookedPrice,
  })) 

  const exportButton = () => {

    return (
      <CSVLink
        data={exportBody}
        headers={exportHeader}
        separator={";"}
        filename={"bookings.csv"}
        style={{fontSize:'14px'}}
        target="_blank">
          
          <img src={e2eimg} alt="" width="30px" style={{paddingRight:'0.5rem'}} />
        Export CSV
      </CSVLink>)
  }








  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      
      sx={{ height: 'calc(100%)'  }}>
    
   

      <Grid container item xs={12}  sx={{height:'100%'}} >
        <Paper sx={{width:'100%'}}>
       
        {useMemo(()=>   <BookingsTable exportButton={exportButton}  canSearch loading={state.loading} tableUpdateLoading={state.tableUpdateLoading} handleRowClick={handleRowClick} table={tableData} />
        ,[state.data,state.loading,state.tableUpdateLoading])}
      
         
        </Paper>
        {/* <Card sx={{ width: '100%',background:'rgba(255,255,255,0.9)', height: 'calc(100%)', boxShadow:'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',   border: '1px solid rgb(233,233,233)',backdropFilter:'saturate(200%) blur(1.875rem)' }}>
       */}
      
        {/* </Card> */}
      </Grid>
      <ShipmentDisplayModal state={state}/>
    </Grid>
  )

}

