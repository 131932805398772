export const adminConstants = {
    LOADING: 'ADMIN_LOADING',
    SUCCESS: 'ADMIN_SUCCESS',
    ERROR: 'ADMIN_ERROR',
    STORE_ROLE: 'ADMIN_STORE_ROLE',
    STORE_ROLES: 'ADMIN_STORE_ROLES',
    STORE_CUSTOMERS: 'ADMIN_STORE_CUSTOMERS',
    UPDATE_CUSTOMER_INPUT_VALUE: 'ADMIN_UPDATE_CUSTOMER_INPUT_VALUE',
    UPDATE_SELECTED_CUSTOMER: 'ADMIN_UPDATE_SELECTED_CUSTOMER',
    INITATE_SELECTED_CUSTOMER: 'ADMIN_INITATE_SELECTED_CUSTOMER',
    CLEAR_SELECTED_CUSTOMER: 'ADMIN_CLEAR_SELECTED_CUSTOMER',
    DELETE_SELECTED_CUSTOMER: 'ADMIN_DELETE_SELECTED_CUSTOMER',
    ADD_CUSTOMER_TO_STORE: 'ADMIN_ADD_CUSTOMER_TO_STORE',
    STORE_USERS: 'ADMIN_STORE_USERS',
    UPDATE_USER_INPUT_VALUE: 'ADMIN_UPDATE_USER_INPUT_VALUE',
    STORE_SELECTED_USER: 'ADMIN_STORE_SELECTED_USER',
    CLEAR_SELECTED_USER: 'ADMIN_CLEAR_SELECTED_USER',
    ADD_USER_TO_STORE: 'ADMIN_ADD_USER_TO_STORE',
    UPDATE_USER_ROLE_VALUE: 'ADMIN_UPDATE_USER_ROLE_VALUE',
    DELETE_SELECTED_USER: 'ADMIN_DELETE_SELECTED_USER',
    STORE_CARRIERS: 'ADMIN_STORE_CARRIERS',
    UPDATE_CARRIER_INPUT_VALUE: 'ADMIN_UPDATE_CARRIER_INPUT_VALUE',
    UPDATE_SELECTED_CARRIER: 'ADMIN_UPDATE_SELECTED_CARRIER',
    INITATE_SELECTED_CARRIER: 'ADMIN_INITATE_SELECTED_CARRIER',
    CLEAR_SELECTED_CARRIER: 'ADMIN_CLEAR_SELECTED_CARRIER',
    DELETE_SELECTED_CARRIER: 'ADMIN_DELETE_SELECTED_CARRIER',
    ADD_CARRIER_TO_STORE: 'ADMIN_ADD_CARRIER_TO_STORE',
    STORE_CALCULATORS: 'ADMIN_STORE_CALCULATORS',
    STORE_SELECTED_CALCULATOR: 'ADMIN_STORE_SELECTED_CALCULATOR',
    UPDATE_CALCULATOR_INPUT_VALUE: 'ADMIN_UPDATE_CALCULATOR_INPUT_VALUE',
    CLEAR_SELECTED_CALCULATOR: 'ADMIN_CLEAR_SELECTED_CALCULATOR',
    ADD_CALCULATOR_TO_STORE: 'ADMIN_ADD_CALCULATOR_TO_STORE',
    DELETE_SELECTED_CALCULATOR: 'ADMIN_DELETE_SELECTED_CALCULATOR',
    STORE_CALCULATOR_FILE: 'ADMIN_STORE_CALCULATOR_FILE',
    ADD_CALCULATOR_CELL_ROW: 'ADMIN_ADD_CALCULATOR_CELL_ROW',
    REMOVE_CALCULATOR_CELL_ROW: 'ADMIN_REMOVE_CALCULATOR_CELL_ROW',
    UPDATE_CALCULATOR_CELL_ROW_VALUE: 'ADMIN_UPDATE_CALCULATOR_CELL_ROW_VALUE',
    UPDATE_CHANGING_COSTS_INPUT_VALUE: 'ADMIN_UPDATE_CHANGING_COSTS_INPUT_VALUE',
    ADD_CHANGING_COSTS_ROW: 'ADMIN_ADD_CHANGING_COSTS_ROW',
    UPDATE_CHANGING_COST_IN_STORE: 'ADMIN_UPDATE_CHANGING_COST_IN_STORE',
    STORE_CHANGING_COSTS: 'ADMIN_STORE_CHANGING_COSTS',
    DELETE_SELECTED_CHANGING_COST: 'ADMIN_DELETE_SELECTED_CHANGING_COST',
    STORE_INVOICE_PATCHES: 'ADMIN_STORE_INVOICE_PATCHES',
    STORE_IMPORTABLE_CUSTOMERS: 'ADMIN_STORE_IMPORTABLE_CUSTOMERS',
    STORE_EVENT_LOGS: 'ADMIN_STORE_EVENT_LOGS'
};