import React from 'react';
import Grid from '@mui/material/Grid';
import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";



export default function ShipmentPreviewSectionContainer({ title, ...props }) {






  return (
    <React.Fragment>
      <Grid container sx={{ mb: '1rem' }}>
        <SuiBox mb={0} mt={2} lineHeight={0} display="inline-block" borderRadius="0.75rem" sx={{ width: '100%', height: "100%" }} >

          <Grid item container xs={12}>
            <Grid item xs={2} mb={2}>
              <SuiTypography variant="h5" fontWeight="bold"  >
              {title}
              </SuiTypography>
            </Grid>
            <Grid item xs={10} >
            
                <Grid container item xs={12}>
                 {props.children}
                </Grid>
             
            </Grid>


          </Grid>


        </SuiBox>

      </Grid>

    </React.Fragment>
  );

}





