import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import Chip from '@mui/material/Chip';
import DirectionsBoatSharpIcon from '@mui/icons-material/DirectionsBoatSharp';
import FlightSharpIcon from '@mui/icons-material/FlightSharp';
import LocalShippingSharpIcon from '@mui/icons-material/LocalShippingSharp';
import DonutLargeIcon from '@mui/icons-material/DonutLarge';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';
import CardMedia from "@mui/material/CardMedia";

import {convertUTCDateToLocalDate} from '_helpers'
import CheckCircleOutlinedIcon from '@mui/icons-material/CheckCircleOutlined';
import LocalShippingOutlinedIcon from '@mui/icons-material/LocalShippingOutlined';
import CancelOutlinedIcon from '@mui/icons-material/CancelOutlined';
import LoopOutlinedIcon from '@mui/icons-material/LoopOutlined';
import PendingActionsIcon from '@mui/icons-material/PendingActions';
import TimerOffOutlinedIcon from '@mui/icons-material/TimerOffOutlined';
import ReactCountryFlag from "react-country-flag"

export function Location({country, city, name}){

    return(
      <Grid container item xs={12}>
          <Grid item container xs={12} >
            <Grid item sx={{borderRadius:'20px', marginRight:'0.5rem'}}>
            <ReactCountryFlag svg   countryCode={country} style={{borderRadius:'3px'}}   width="20" />
            </Grid>
            <Grid item >
              <Typography variant="caption" sx={{color:'rgb(72, 72, 72)',fontWeight:'bold'}}>
 
              {city.length > 11 ? city.substring(0,10) + "..." : city}
              </Typography>
              
            </Grid>
          </Grid>
          <Grid item container xs={12}>
          <Grid item >
          <Typography variant="caption" sx={{color:'rgb(104, 104, 104)'}}>
              {name.length > 15 ? name.substring(0,14) + "..." : name}
              </Typography>
            </Grid>
            </Grid>
      </Grid>
    )
  }
  
  export  function Logo({url,name}){


    if(url){
      return(
        <CardMedia
              src={url}
              component="img"
              title={name}
              alt={name}
              sx={{
                maxWidth: "60px",
                maxHeight: "45px",
                margin: 0,
                // boxShadow: ({ boxShadows: { md } }) => md,
                objectFit: "cover",
                objectPosition: "center",
                borderRadius: '0.2rem',
                textAlign: 'center',
                marginLeft:'0.2rem'
        
              }}
            />
        
          )
    } else {
      return (
        <Grid container item xs={12}>
    
          <Grid item container xs={12} justifyContent="flex-start">
            <Grid item sx={{paddingRight:'2.5rem'}}>
            <Typography variant="caption"  sx={{color:'rgb(72, 72, 72)',fontWeight:'bold', textAlign:'left'}}>
                {name}
              </Typography>
            </Grid>
          </Grid>
    
        </Grid>
      )
    }
  

  }
  
  export  function Id({id,date}){

   
    return(
      <Grid container item xs={12}>
  
      <Grid item container xs={12}>
        <Grid item >
          <Typography variant="caption"  sx={{color:'rgb(72, 72, 72)',fontWeight:'bold'}}>
            {id}
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item >
          <Typography variant="subtitle" sx={{ color: 'rgb(104, 104, 104)' }}>
         
         {date}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    )
  }
  
  export  function References({ pickup, delivery }) {
    return (
      <Grid container item xs={12}>
  
        <Grid item container xs={12}>
          <Grid item >
            <Typography variant="caption" sx={{ color: 'rgb(104, 104, 104)' }}>
              
            { pickup  !== null && pickup.length > 15 ? pickup.substring(0,14) + "..." : pickup}

            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item >
            <Typography variant="caption" sx={{ color: 'rgb(104, 104, 104)' }}>
    
              {delivery !== null && delivery.length > 15 ? delivery.substring(0,14) + "..." : delivery}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  
  export  function BookedPrice({ price }) {
    return (
      <Grid container item xs={12}>
  
        <Grid item container xs={12} justifyContent="flex-start">
          <Grid item sx={{paddingRight:'2.5rem'}}>
          <Typography variant="caption"  sx={{color:'rgb(72, 72, 72)',fontWeight:'bold', textAlign:'left'}}>
              {price}
            </Typography>
          </Grid>
        </Grid>
  
      </Grid>
    )
  }

  export  function Deadline({ deadline }) {
    const deadlineTime = new Date(deadline)
    const converted = convertUTCDateToLocalDate(deadlineTime)
    return (
      <Grid container item xs={12}>
  
        <Grid item container xs={12}>
          <Grid item >
            <Typography variant="caption" sx={{ color: 'rgb(104, 104, 104)' }}>
              {converted.toLocaleDateString()}
            </Typography>
          </Grid>
        </Grid>
        <Grid item container xs={12}>
          <Grid item >
            <Typography variant="caption" sx={{ color: 'rgb(104, 104, 104)' }}>
              {converted.toLocaleTimeString()}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    )
  }
  
  export function Status({status, state}){
    if (state === 'active' && status === "Processing") {
      return (
        <Chip   variant="outlined" size="medium" sx={{ width: '120px'}} icon={<CheckOutlinedIcon sx={{color:'#A7936A !important',fontSize:'1.2rem !important' }}/>} label="Booked"/>
      ) 
    } else if(state === 'active' && status === "InTransit"){
      return (
        <Chip   variant="outlined" size="medium" sx={{width: '120px'}} icon={<LocalShippingOutlinedIcon sx={{color:'#397087 !important',fontSize:'1.2rem !important' }}/>} label="In transit" />
      )
    } else if(state === 'active'){
      return (
        <Chip   variant="outlined" size="medium" sx={{width: '120px'}} icon={<LoopOutlinedIcon sx={{color:'#D1D5CE !important',fontSize:'1.2rem !important'}}/>} label="Handling" />
      )
    } else if (state === 'ready'){
      return (
        <Chip   variant="outlined" size="medium" sx={{width: '120px'}} icon={<CheckCircleOutlinedIcon sx={{fontSize: '1.2rem !important', color: '#83f28f !important'}}></CheckCircleOutlinedIcon>} label="Delivered" />
      )
    } else if (state === 'deleted'){
      return (
        <Chip   variant="outlined" size="medium" sx={{width: '120px'}} icon={<CancelOutlinedIcon  sx={{fontSize: '1.2rem !important', color: '#f88 !important'}}></CancelOutlinedIcon>} label="Cancelled" />
      )
    }
  }

  export function SpotStatus({status}){
    return(
      <>
      {status === "Booked" && <Chip variant="outlined" size="medium" sx={{ width: '150px'}}  icon={<CheckCircleOutlinedIcon sx={{fontSize: '1.2rem !important', color: '#83f28f !important'}}/>} label={status} /> }
      {status === "Waiting for quotes" && <Chip variant="outlined" size="medium" sx={{ width: '150px'}}  icon={<DonutLargeIcon sx={{color:'#A7936A !important',fontSize:'1.2rem !important'}}/>} label="Awaiting quotes" /> }
      {status === "Waiting for booking" && <Chip variant="outlined" size="medium" sx={{ width: '150px'}}  icon={<PendingActionsIcon sx={{color:'#397087 !important',fontSize:'1.2rem !important'}}/>} label="Awaiting booking" /> }
      {status === "Quotes expired" && <Chip variant="outlined" size="medium" sx={{ width: '150px'}}  icon={<TimerOffOutlinedIcon sx={{color:'#183b59 !important',fontSize:'1.2rem !important'}}/>} label={status} /> }
      {status === "Cancelled" && <Chip variant="outlined" size="medium" sx={{ width: '150px'}}  icon={<CancelOutlinedIcon sx={{fontSize: '1.2rem !important', color: '#f88 !important'}}/>} label={status} /> }
      {status === "Quote expired" && <Chip variant="outlined" size="medium" sx={{ width: '150px'}}  icon={<TimerOffOutlinedIcon sx={{color:'#183b59 !important',fontSize:'1.2rem !important'}}/>} label={status} /> }
     
      </>
    )

  }
  
  export function Customer({customer, firstName, lastName}){

    const fullName = firstName + " " + lastName
    
    return(
      <Grid container item xs={12}>
  
      <Grid item container xs={12}>
        <Grid item >
          <Typography variant="caption"  sx={{color:'rgb(72, 72, 72)',fontWeight:'bold'}}>
          {customer.length > 15 ? customer.substring(0,14) + "..." : customer}
        
          </Typography>
        </Grid>
      </Grid>
      <Grid item container xs={12}>
        <Grid item >
          <Typography variant="subtitle" sx={{ color: 'rgb(104, 104, 104)' }}>
            {fullName.length > 15 ? fullName.substring(0, 14) + "..." : fullName}
          </Typography>
        </Grid>
      </Grid>
    </Grid>
    )
  }

  export function SpotType({type}){
    
          return (
          <Grid container item xs={12} width="30px" justifyContent="center">
            <Grid item sx={{textAlign:"center"}}>
            {type === "Sea" && <DirectionsBoatSharpIcon fontSize="small" sx={{color: '#183B59'}} /> }
            {type === "Road" && <LocalShippingSharpIcon fontSize="small"  sx={{color: '#183B59'}}/> }
            {type === "Air" && <FlightSharpIcon fontSize="small" sx={{color: '#183B59'}} /> }

              <Grid item >
                <Typography variant="caption" sx={{ color: 'rgb(104, 104, 104)' }}>
                  {type}
                </Typography>
              </Grid>
            </Grid>
            </ Grid> )
    
  }