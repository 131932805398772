import React from 'react';
import ReactDOM from "react-dom/client";
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from "react-router-dom";
import { store } from './_helpers';
import App  from './App/App';
import { SoftUIControllerProvider } from "_softUI/context";
import { history } from '_helpers';


if (process.env.REACT_APP_STAGE === "prod"){
    console.log = function no_console() {};
    console.warn = function no_console() {};
}
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <Provider store={store}>
        <Router history={history}>
            <SoftUIControllerProvider>
                <App  />
            </SoftUIControllerProvider>
        </Router>
    </Provider>,
    // document.getElementById('root')
);