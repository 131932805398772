import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import { shipmentInformationInputModalActions } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import SelectWithLabel from "_components/FormFields/SelectWithLabel";
import InputWithLabel from "_components/FormFields/InputWithLabel";
import TodayIcon from '@mui/icons-material/Today';
import SuiBox from '_softUI/components/SuiBox'
import SuiTypography from "_softUI/components/SuiTypography";
import { incoterms, transportationTypes } from '_helpers'
import DisplayWithLabel from '_components/FormFields/DisplayWithLabel';
import AdditionalInformationView from '_components/ShipmentInformationPreview/AdditionalInformationView';



export function CurrentQuote({currency}) {


  const state = useSelector(state => state.spotRequest.quoteCurrent)





  return (




    <React.Fragment>
      <Grid container sx={{ mb: '1rem' }}>
        <SuiBox mb={0} ml={0.5} p={3} b lineHeight={0} display="inline-block" borderRadius="0.75rem" sx={{ width: '100%', height: "100%" }} >

          <Grid item container xs={12}>
            <Grid item xs={2}>
              <SuiTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                Current quote
              </SuiTypography>
            </Grid>
            <Grid item xs={10} >
              <Grid container spacing={1}>
                <Grid item xs={4}>
                <DisplayWithLabel value={state.quoteValid && state.quoteValid.slice(0,10)} label="Quote valid until date" />
                </Grid>
                <Grid item xs={4}>
                <DisplayWithLabel value={state.estimatedTransitTime ? state.estimatedTransitTime.toString()+ 'days': 'n/a'} label="Estimated transit time" />
                  </Grid>
                  <Grid item xs={4}>
                  <DisplayWithLabel value={state.totalPrice ? state.totalPrice.toString()+ " " + currency: 'n/a' } label="Total price" />
                  </Grid>
   
                
     
        
              </Grid>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12}>
                <SuiTypography variant="overline" >
                Additional information
              </SuiTypography>

              <AdditionalInformationView state={state.additionalInformation} />
                </Grid>

              </Grid>

            </Grid>
          </Grid>


    </SuiBox >

    </Grid >

  </React.Fragment >





  )
}