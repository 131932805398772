import {  useEffect, Suspense } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import {  useSelector } from 'react-redux';
import theme from "_softUI/assets/theme";

import { PrivateRoute } from "_components"
import routes from "./routes";
import { Loading } from "_components/Shared"

import CustomAlert from "_components/Shared/Alert";
import { ConfirmProvider } from 'material-ui-confirm';


function App() {

    const isAuthenticated = useSelector(state => state.authentication).user;
    var authState = useSelector(state => state.authentication);

    

    const carrier = authState?.user?.roles?.find(x => x === "Carrier") 
    const customer = authState?.user?.roles?.find(x => x === "Customer") 

    const redirectToSpotCarrier = carrier && !customer


    const { pathname } = useLocation();

      const redirectUrl = '/login' +(window.location.search !=="" ? '?url='+window.location.pathname+window.location.search :'')

    const getRoutes = (allRoutes) =>
        allRoutes.map((route) => {
            if (route.collapse) {
                return getRoutes(route.collapse);
            }

            if (route.route) {
                if (route.private) {
                    if (isAuthenticated) {
                        if (route.requiredRoles && route.requiredRoles.length > 0) {
                            let print = false;
                            for (let i = 0; i < route.requiredRoles.length && print === false; i++) {
                                print = authState.user.roles && authState.user.roles.find(x => x === route.requiredRoles[i])

                            }

                            if (print) {
                                console.log(routes)
                                console.log(route.key)


                                return <PrivateRoute exact routes={routes} path={route.route} component={route.component} key={route.key} />;


                            }
                        }
                        else {

                            return <PrivateRoute exact routes={routes} path={route.route} component={route.component} key={route.key} />;

                        }
                    }

                }
                else {
                    return <Route exact path={route.route} component={route.component} key={route.key} />;
                }

            }

            return null;
        });

    useEffect(() => {
        document.documentElement.scrollTop = 0;
        document.scrollingElement.scrollTop = 0;
    }, [pathname]);

    return (
        <ThemeProvider theme={theme}>
            <ConfirmProvider>
                <CssBaseline />
                <Suspense fallback={<Loading />}>
                    <Switch>
                        {getRoutes(routes)}
                        {/* <Redirect from="*" to={isAuthenticated ? '/' : '/login'} /> */}
                        <Redirect from="*" to={isAuthenticated ? (redirectToSpotCarrier ? '/SpotRequestsCarrier' : '/') : redirectUrl} />
                    </Switch>
                </Suspense>
                {/* <ShipmentInformationForm /> */}
                <CustomAlert />
            </ConfirmProvider>
        </ThemeProvider>
    );
}

export default App;
