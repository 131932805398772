import React, {useEffect} from 'react'
import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import { Location } from './Location';
import { Goods } from './Goods';
import { BasicInfo } from './BasicInfo'
import LegacyContainerBox from './LegacyContainerBox';
import { AdditionalInformation } from './AdditionalInformation';
import { AdditionalServiceContainer } from './AdditionalServiceFormContainer';
import Customs from './Customs';
import { useSelector } from 'react-redux';

export default function LegacyForm({state, validateLength, instant}){

  const stateParent = useSelector(state => state.ShipmentInformationForm)


    return (
        <Paper sx={{ mb: 2 }}>



        <Grid item container xs={12} sx={{ p: 3 }}>
          <LegacyContainerBox title="Shipment details" >
            <Grid id="details" sx={{width:'100%'}}>
            <BasicInfo visible={true} validateLength={validateLength} type={state.type} instant={instant} />
            </Grid>
          </LegacyContainerBox>
          <LegacyContainerBox title="Locations" >
            <Grid id="location">
            <Location visible={true} validateLength={validateLength} title="Pickup" index={0} instant={instant} />

            <Location visible={true} validateLength={validateLength} title="Delivery" index={1} instant={instant} />
            </Grid>
          </LegacyContainerBox>
          <LegacyContainerBox title="Goods" >
          <Grid id="goods">
            <Goods visible={true} calculating={state.calculating} validateLength={validateLength} />
            </Grid>
          </LegacyContainerBox>
          <LegacyContainerBox title="Additional services" >
            <Grid sx={{width:'100%'}} id="details">

            <AdditionalServiceContainer visible={true} validateLength={validateLength} type={state.type} instant={instant} stateParent={stateParent} />
            </Grid>
          </LegacyContainerBox>
          {!instant && 
          <LegacyContainerBox  title="Additional information" >
              <AdditionalInformation />
          </LegacyContainerBox>
          }
          {
            state.data.customsNeeded && instant && 
            <LegacyContainerBox sx={{mt:2}} title="Customs" >
              
              <Customs/>



        </LegacyContainerBox>
          }
        </Grid>

      </Paper>


    )
}