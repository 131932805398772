import React from 'react';
import { useLocation } from 'react-router-dom';

import { ShipmentInformationForm } from '../_components';





export function InstantBookingProcess() {

  const location = useLocation();
  const isFromPush = location.state && location.state.fromPush;
  const copyBooking = isFromPush ? true : false

  return (
    <>
    <ShipmentInformationForm instant={true} copyBooking={copyBooking}/>
    </>
  )

}
