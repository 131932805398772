import { spotRequestConstants, carrierSpotRequestTableConstants, miscConstants } from '../_constants';
import { shipmentService, spotRequestService } from '../_services';


export const carrierSpotRequestTableActions = {
    initateCarrier, 
    getShipmentByIdForCarrier,
    closeModal,
};



  function closeModal() {


    return dispatch => {
        dispatch(initateCarrier('update'));
        dispatch(close());


        function close() {  return { type: carrierSpotRequestTableConstants.CLOSE_MODAL} }

    }



}

function initateCarrier(loader) {
    return dispatch => {
        dispatch(request());

        shipmentService.getAllShipmentsForCarrier()
            .then(
                data => {
                    dispatch(success(data));

                },
                error => {
                    dispatch(failure(error));
                 
                }
            )

        function request() { return { type: carrierSpotRequestTableConstants.LOADING, loader } }
        function success(data) { return { type: carrierSpotRequestTableConstants.UPDATE_CARRIER_TABLE, data } }
        function failure(error) { return { type: carrierSpotRequestTableConstants.ERROR, error } }

    }
}

function getShipmentByIdForCarrier(id) {
    return dispatch => {
        dispatch(request(true));

        spotRequestService.getShipmentByIdForCarrier(id)
            .then(
                data => {
                    clear();
                    var quoteModel = {
                        currentQuote: data.quotes[0] ?? {},
                        shipmentId: data.id
                    }
                    dispatch(initateQuote(quoteModel))
                    dispatch(success(data));

                },
                error => {
                    dispatch(failure(error));
                 
                }
            )

        function request(value) { return { type: carrierSpotRequestTableConstants.MODAL_LOADING, value} }
        function clear() { return { type: carrierSpotRequestTableConstants.CLEAR } }
        function success(data) { return { type: carrierSpotRequestTableConstants.UPDATE_SELECTED_SHIPMENT, data } }
        function failure(error) { return { type: carrierSpotRequestTableConstants.ERROR, error } }
        function initateQuote(model) {return {type: spotRequestConstants.INITATE, model}}

    }
}