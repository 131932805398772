import { render } from '@testing-library/react';
import React from 'react';
import { Route, Redirect,useLocation } from 'react-router-dom';
import { Main } from '../_components';
import { TextSnippetTwoTone } from '@mui/icons-material';
import { LoginPage } from 'LoginPage';
import { history } from '../_helpers';
export const PrivateRoute = ({ component: Component,header,routes,sidenavColor, ...rest }) => {


  const redirectUrl = '/login' + (window.location.search !=="" ? '?url='+window.location.pathname+window.location.search :'')

    if(localStorage.getItem('user') == null){
        history.push(redirectUrl,);
    }
    return (
    <Route {...rest} render={props => (
        <Main routes={routes}  header={true}><Component {...props} /></Main>
           
    )} />)
}