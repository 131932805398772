import { FormatColorReset } from '@mui/icons-material';
import { carrierSpotRequestTableConstants } from '../_constants';
import { goodsRowModel } from './models';

const initialState = {
    loading: false,
    modalOpen: false,
    modalLoading: false,
    tableUpdateLoading: false,
    data: {
        rows: []
    },
    selectedId: 0,
    selectedShipment: {
        senderReference: '',
        receiverReference: '',
        pickupStart: '',
        deliveryStart: '',
        messageToCarrier: '',
        selectedCarrierName: '',
        selectedCarrierLogo: '',
        totalPrice: '',
        docFwdCustomerReceiver: '',
        docFwdCustomerLang: '',
        docFwdSenderReceiver: '',
        file: [],
        locations: [
            {
                title: '',
                name: '',
                streetAddress: '',
                addressInfo: '',
                city: '',
                openingHours: '',
                contactName: '',
                contactEmail: '',
                contactPhone: '',
                messageToContact: '',
                zip: '',
                countryAlpha2: '',
            },
            {
                title: '',
                name: '',
                streetAddress: '',
                addressInfo: '',
                city: '',
                openingHours: '',
                contactName: '',
                contactEmail: '',
                contactPhone: '',
                messageToContact: '',
                zip: '',
                countryAlpha2: '',

            }
        ],
        goods: [
            goodsRowModel
        ],
        quotes: [

        ]
    }
};


export function carrierSpotRequestTable(state = initialState, action) {
    switch (action.type) {
        case carrierSpotRequestTableConstants.LOADING:
            switch (action.loader) {
                case 'initial':
                    return {
                        ...state,
                        loading: true

                    };
                case 'update':
                    return {
                        ...state,
                        loading: false,
                        tableUpdateLoading: true
                    };
            }

        case carrierSpotRequestTableConstants.MODAL_LOADING:
            return {
                ...state,
                modalLoading: action.value,
                modalOpen: true,
            };

        case carrierSpotRequestTableConstants.ERROR:
            return {
                ...state,
                loading: false
            };

            case carrierSpotRequestTableConstants.SET_INITIAL_STATE:
                return {
                    ...initialState
                };


        case carrierSpotRequestTableConstants.UPDATE_CARRIER_TABLE:
            return {
                ...state,
                loading: false,
                tableUpdateLoading: false,
                data: {
                    ...state.data,
                    rows: action.data
                }
            };

        case carrierSpotRequestTableConstants.UPDATE_SELECTED_SHIPMENT:
            return {
                ...state,
                loading: false,
                selectedShipment: action.data,
                modalOpen: true,
                modalLoading: false,

            };

        case carrierSpotRequestTableConstants.CLEAR:
            return {
                ...state,
                selectedShipment: {
                    ...initialState.selectedShipment
                }
            };

        case carrierSpotRequestTableConstants.CLOSE_MODAL:
            return {
                ...state,
                modalOpen: false,
            }

        default:
            return state
    }
}