import React, {  useState } from 'react';

import {  useDispatch } from 'react-redux';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiBox from "_softUI/components/SuiBox";

import Card from "@mui/material/Card";

import Grid from '@mui/material/Grid';

import InputWithLabel from '_components/FormFields/InputWithLabel';

import SuiButton from "_softUI/components/SuiButton";
import { adminActions } from '_actions/admin.actions';

export default function ManageRoles() {
    const dispatch = useDispatch();
    const [roleValue, setRoleValue] = useState('');
    const create = () => {

        dispatch(adminActions.createRole(roleValue));
    }



    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start" sx={{ height: '100%' }}>

     

            <Grid container item xs={12} alignItems="strecth" justifyContent="center">
                <Card sx={{ overflow: "visible", p: 3 }}>
                    <SuiBox>
                        <SuiTypography variant="h5">Create a role</SuiTypography>
                        <SuiBox mt={3}>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12}>
                                    <InputWithLabel label="Role name" onChange={e => setRoleValue(e.target.value)} fullWidth></InputWithLabel>
                                </Grid>
                                <Grid item xs={12} sm={12}>
                                    <SuiButton color="secondary" variant="contained" onClick={create} >
                                        Save
                                    </SuiButton>
                                </Grid>

                            </Grid>
                        </SuiBox>
                    </SuiBox>
                </Card>
            </Grid>
        </Grid>
    )

}