import { dashboardConstants } from '../_constants';
import { goodsRowModel } from './models';

const initialState = {
    loading: false,
    searchHistorySelected: false,
    model: {
        name: '',
        // searchHistories: [{}]
    },
    selectedSearchHistoryIndex: null,
    selectedSearch: {
        originCountry: { value: '', label: '' },
        originZip: { value: '', label: '' },
        deliveryCountry: { value: '', label: '' },
        deliveryZip: { value: '', label: '' },
        goods: [goodsRowModel],
    },
    pickupCountry: '',
    deliveryCountry: '',
    pickupZip: '',
    deliveryZip: '',
    goods: [goodsRowModel],
};

export function dashboard(state = initialState, action) {
    let newState = {}
    switch (action.type) {

        case dashboardConstants.LOADING:
            return {
                ...state,
                loading: true
            };
        case dashboardConstants.INITATE:
            return {
                ...state,
                loading: false,
                model: action.data,
            };
        
        case dashboardConstants.INITATE_SEARCH_HISTORY:
            return {
                ...state,
                loading: false,
                selectedSearchHistoryIndex: null,
                selectedSearchHistoryPickupCountry: '',
                selectedSearchHistoryDeliveryCountry: '',
                selectedSearchHistoryPickupZip: '',
                selectedSearchHistoryDeliveryZip: '',
                searchHistorySelected: false,
            };

        case dashboardConstants.SET_SELECTED_SEARCH:
            const newRows = action.data.searchHistoryGoodsRows.map((row) => {
                const { itemWidth, itemHeight, itemLength, itemWeight, ...rest } = row;
                return {
                    ...goodsRowModel,
                    ...rest,
                    width: itemWidth,
                    height: itemHeight,
                    length: itemLength,
                    weight: itemWeight,
                };
            });
            return {
                ...state,
                selectedSearchHistoryIndex: action.id,
                searchHistorySelected: true,
                selectedSearch: {
                    originCountry: { value: action.data.pickupCountry, label: action.data.pickupCountry },
                    originZip: { value: action.data.pickupZip, label: action.data.pickupZip },
                    deliveryCountry: { value: action.data.deliveryCountry, label: action.data.deliveryCountry },
                    deliveryZip: { value: action.data.deliveryZip, label: action.data.deliveryZip },
                    goods: newRows,
                },
            };


        default:
            return state
    }
}