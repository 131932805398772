import React, { useEffect } from 'react';
import Grid from '@mui/material/Grid';

import { useDispatch, useSelector } from 'react-redux';
import { ShipmentInformationFormActions } from '../../_actions';
import { GoodsRow } from '../Common/GoodsRow'
import SuiButton from '_softUI/components/SuiButton';

import Collapse from '@mui/material/Collapse';

import Chip from '@mui/material/Chip';

import { GoodsPreview } from '_components/Common/Goods/GoodsPreview';

export function Goods({next, visible,  calculating, validateLength }) {
  const dispatch = useDispatch()
 
  const goods = useSelector(state => state.ShipmentInformationForm.data.goods)
    const state = useSelector(state => state.ShipmentInformationForm.data)



  console.log(state)

  function handleRowChange(event, index) {

    dispatch(ShipmentInformationFormActions.updateGoodsLine(event.target.name, event.target.value, index));

  }

  function handleRowChangeWithMaxLenght(event, index,label,maxLength) {
    console.log(event.target.name)
    dispatch(ShipmentInformationFormActions.updateGoodsLine(event.target.name, validateLength(label,event.target.value,maxLength), index));

  }

  function addGoodsLine() {
    dispatch(ShipmentInformationFormActions.addGoodsLine());
  }

  function removeGoodsLine(index) {
    dispatch(ShipmentInformationFormActions.removeGoodsLine(index));
  }

  function handleStackableChange(event, index) {
    console.log(event)
    dispatch(ShipmentInformationFormActions.updateGoodsLine('stackable', event.target.checked, index));
  }


  // function calculateBillingWeight(row, index) {
  //   dispatch(ShipmentInformationFormActions.getBillingWeight(row, index))
  // }

  function setCollapseUnvisible() {

    next('goods');
  

  }


  return (


   



      <Grid container item xs={12} spacing={2} justify="space-evenly" alignItems="stretch" sx={{pb:3}}>
      
      
     

          {!visible &&
            <>
              <Grid item container xs={12} >
                <GoodsPreview goods={goods} state={state} />
           


              </Grid>

            </>
          }

          <Grid item container xs={12} >
            <Collapse in={visible}>
              <Grid container item xs={12} >

                {goods.map((x, index) =>
                  <GoodsRow
                    key={index}
                    index={index}
                    handleRowChange={handleRowChange}
                    handleRowChangeWithMaxLenght={handleRowChangeWithMaxLenght}
                    addGoodsLine={addGoodsLine}
                    removeGoodsLine={removeGoodsLine}
                    handleStackableChange={handleStackableChange}
                    // handleAdrClassChange={handleAdrClassChange}
                    calculating={calculating}
                    goods={goods}
                    showDescription={true}
                    refreshPriceOnChange={true}
                    state={state}
                    adrSelection={state.adr}
                  />
                )}
                <Grid item xs={12} container spacing={2} justifyContent="space-between" alignItems="flex-start" >
                  {(goods[0].typeCode !== 5 && goods[0].typeCode !== 6)  && 
                  <Grid item xs={6} >

                    <SuiButton variant="outlined" size="small" color="primary" onClick={addGoodsLine} >+ Cargo item row</SuiButton>
                  </Grid>
                   } 
                  {next && 
                  <Grid item xs={6} sx={{ textAlign: 'right' }}>
                    <SuiButton color="secondary" variant="contained" onClick={setCollapseUnvisible}>
                      Apply
                    </SuiButton>
                  </Grid>
                }
                    {state.showError && 
            <Grid item xs={12}>
            <Chip label={state.errorMessage} sx={{width:'100%', borderRadius:'5px'}} size="small" color="error" variant="outlined" />
            </Grid>
            }
               {state.showWarning && 
            <Grid item xs={12}>
            <Chip label={state.errorMessage} sx={{width:'100%', borderRadius:'5px'}} size="small" color="warning" variant="outlined" />
            </Grid>
            }
                </Grid>

              </Grid>
            </Collapse>
          </Grid>
     

      </Grid>

   

  )
}