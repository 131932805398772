import { adminService } from "_services/admin.service";
import { adminConstants } from "_constants/admin.constants";
import { alertConstants } from "_constants/alert.constants";

export const adminActions = {
    createRole,
    updateCustomerInputValue,
    initateCustomers,
    initateSelectedCustomer,
    changeSelectedCustomer,
    clearSelectedCustomer,
    createOrUpdateCustomer,
    deleteCustomer,
    initateUsers,
    newPasswordForUser,
    updateUserInputValue,
    initateSelectedUser,
    clearSelectedUser,
    createOrUpdateUser,
    initateRoles,
    updateUserRoleValue,
    deleteUser,
    deleteCarrier,
    createOrUpdateCarrier,
    initateCarriers,
    initateSelectedCarrier,
    updateCarrierInputValue,
    clearSelectedCarrier,
    changeSelectedCarrier,
    updateCalculatorInputValue,
    clearSelectedCalculator,
    initateSelectedCalculator,
    initateCalculators,
    createOrUpdateCalculator,
    deleteCalculator,
    storeFile,
    addCellrow,
    removeCellrow,
    updateCalculatorCellRowValue,
    refreshCalculatorsInCache,
    updateChangingCostsInputValue,
    addCostRow,
    saveChangingCostsRow,
    initateChangingCosts,
    deleteChangingCost,
    generateInvoicePatch,
    getAllInvoicePatches,
    getAllCustomersToImport,
    importCustomers,
    setCanceled,
    setCompleted,
    refreshCache,
    getEventLogs
};

function failure(message) { return { type: alertConstants.ERROR, message } }
function success(message) { return { type: alertConstants.SUCCESS, message } }
function loading(value = true) { return { type: adminConstants.LOADING, value } }

function createRole(roleName) {
    return dispatch => {

        dispatch(loading());
        adminService.createRole(roleName)
            .then(
                data => {

                    dispatch(store(data));

                },
                error => {

                    dispatch(failure(error));

                }
            )
    }

    function store(data) { return { type: adminConstants.STORE_ROLE, data } }

}

function createOrUpdateCustomer(model, handleClose) {
    return dispatch => {
        dispatch(loading());
        console.log(model)
        adminService.createOrUpdateCustomer(model)
            .then(
                data => {
                    console.log(data)
                    if(data.success){
                        model.id = data.id
                        dispatch(store(model));
                        console.log(model)
                        dispatch(success(data.message))
                        dispatch(loading(false));
                        handleClose();
                    
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false));

                    }
                    

                },
                error => {

                    dispatch(failure(error));

                }
            )
    }
    function store(data) { return { type: adminConstants.ADD_CUSTOMER_TO_STORE, data } }

}

function updateCustomerInputValue(name, value) {
    const data = { name, value }
    return { type: adminConstants.UPDATE_CUSTOMER_INPUT_VALUE, data };
}

function updateUserInputValue(name, value) {
    const data = { name, value }
    return { type: adminConstants.UPDATE_USER_INPUT_VALUE, data };
}

function updateUserRoleValue(name, value) {
    const data = { name, value }
    console.log(data)
    return { type: adminConstants.UPDATE_USER_ROLE_VALUE, data };
}

function initateCustomers(){
    return dispatch => {
        dispatch(loading());
        adminService.getAllCustomers()
            .then(
                data => {
                    dispatch(store(data));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }
    function store(data) { return { type: adminConstants.STORE_CUSTOMERS, data } }
}

function initateRoles(){
    return dispatch => {
        dispatch(loading());
        adminService.getAllRoles()
            .then(
                data => {
                    dispatch(store(data));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }
    function store(data) { return { type: adminConstants.STORE_ROLES, data } }
}

function deleteCustomer(id, handleClose){
    return dispatch => {

        dispatch(loading());
        adminService.deleteCustomer(id)
            .then(
                data => {
                    if(data.success){
                        dispatch(deleteFromList(id));
                        dispatch(success(data.message));
                        handleClose()
                    }
                    else{
                        dispatch(loading(false));
                        console.log(data)
                        dispatch(failure(data.message))
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }
    function deleteFromList(data) { return { type: adminConstants.DELETE_SELECTED_CUSTOMER, data } }
}

    function newPasswordForUser(id, handleClose){
        return dispatch => {
            dispatch(loading())
            adminService.newPasswordForUser(id)
                .then(
                    data => {
                        console.log(data)
                        if(data.success){
                            dispatch(success('Sent!'))
                            dispatch(loading(false))
                            handleClose();
                        }
                        else{
                            dispatch(failure(data.message))
                            dispatch(loading(false));
                        }
                    }
                )
        }
    }

function changeSelectedCustomer(id) {
    return { type: adminConstants.UPDATE_SELECTED_CUSTOMER, id };
}

function clearSelectedCustomer() {
    return { type: adminConstants.CLEAR_SELECTED_CUSTOMER };
}

function initateSelectedCustomer(id) {
    return { type: adminConstants.INITATE_SELECTED_CUSTOMER, id };
}

function clearSelectedUser() {
    return { type: adminConstants.CLEAR_SELECTED_USER };
}

function initateSelectedUser(id) {

    return dispatch => {

        dispatch(loading());
        adminService.getUser(id)
            .then(
                data => {
                    dispatch(store(data));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function store(data) { return { type: adminConstants.STORE_SELECTED_USER, data } }
}

function setCanceled(id) {

    return dispatch => {

        dispatch(loading());
        adminService.setCanceled(id)
            .then(
                data => {
                    dispatch(success(data.message));
                },
                error => {
                    dispatch(failure(error.message));
                }
            )
    }

    
}

function setCompleted(id) {

    return dispatch => {

        dispatch(loading());
        adminService.setCompleted(id)
            .then(
                data => {
                    dispatch(success(data.message));
                },
                error => {
                    dispatch(failure(error.message));
                }
            )
    }

    
}

function initateUsers(){
    return dispatch => {
        dispatch(loading());
        adminService.getAllUsers()
            .then(
                data => {
                    dispatch(store(data));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }
    function store(data) { return { type: adminConstants.STORE_USERS, data } }
}

function createOrUpdateUser(model, handleClose, selectedUser) {
    return dispatch => {
        dispatch(loading());
        console.log(model)
        adminService.createOrUpdateUser(model)
            .then(
                data => {
          
                    if(data.success){
                            if (selectedUser !== 0) {
                            handleClose();
                            model.id = data.id
                            dispatch(initateUsers());
                            console.log(model)
                            dispatch(success('Saved!'))
                            dispatch(loading(false))
                            }
                            else {
                            model.id = data.id
                            dispatch(initateUsers());
                            dispatch(initateSelectedUser(data.id))
                            dispatch(success('Saved!'))
                            dispatch(loading(false));
                            }
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false))
                    }

                },
                error => {

                    dispatch(failure(error));

                }
            )
    }

}

function deleteUser(id, handleClose){
    return dispatch => {
        dispatch(loading());
        adminService.deleteUser(id)
            .then(
                data => {
                    if(data.success){
                        dispatch(deleteFromList(id));
                        dispatch(loading(false));
                        dispatch(success(data.message));
                        handleClose()
                    }
                    else{
                        dispatch(loading(false));
                        dispatch(failure(data.message))
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }
    function deleteFromList(data) { return { type: adminConstants.DELETE_SELECTED_USER, data } }
}

function deleteCarrier(id, handleClose){
    return dispatch => {

        dispatch(loading());
        adminService.deleteCarrier(id)
            .then(
                data => {
                    if(data.success){
                        dispatch(deleteFromList(id));
                        dispatch(success(data.message));
                        handleClose()
                    }
                    else{
                        dispatch(loading(false));
                        console.log(data)
                        dispatch(failure(data.message))
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }
    function deleteFromList(data) { return { type: adminConstants.DELETE_SELECTED_CARRIER, data } }
}

function createOrUpdateCarrier(model, handleClose) {
    return dispatch => {

        dispatch(loading());
        adminService.createOrUpdateCarrier(model)
            .then(
                data => {
 
                    if(data.success){
                        handleClose();
                        model.id = data.id
                        dispatch(store(model));
                        console.log(model)
                        dispatch(success('Saved!'))
                        dispatch(loading(false));
                    
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                  
                   
                    }
                    

                },
                error => {

                    dispatch(failure(error));

                }
            )
    }
    function store(data) { return { type: adminConstants.ADD_CARRIER_TO_STORE, data } }

}

function initateCarriers(){
    return dispatch => {
        dispatch(loading());
        adminService.getAllCarriers()
            .then(
                data => {
                    dispatch(store(data));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }
    function store(data) { return { type: adminConstants.STORE_CARRIERS, data } }
}

function initateSelectedCarrier(id) {
    return { type: adminConstants.INITATE_SELECTED_CARRIER, id};

}

function updateCarrierInputValue(name, value) {
    const data = { name, value }
    return { type: adminConstants.UPDATE_CARRIER_INPUT_VALUE, data };
}

function clearSelectedCarrier() {
    return { type: adminConstants.CLEAR_SELECTED_CARRIER };
}

function changeSelectedCarrier(id) {
    return { type: adminConstants.UPDATE_SELECTED_CARRIER, id };
}



//CALCULATOR
function updateCalculatorInputValue(name, value) {
    const data = { name, value }
    return { type: adminConstants.UPDATE_CALCULATOR_INPUT_VALUE, data };
}

function clearSelectedCalculator() {
    return { type: adminConstants.CLEAR_SELECTED_CALCULATOR };
}

function initateSelectedCalculator(id) {

    return dispatch => {

        dispatch(loading());
        adminService.getCalculator(id)
            .then(
                data => {
                    dispatch(store(data));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function store(data) { return { type: adminConstants.STORE_SELECTED_CALCULATOR, data } }
}

function initateCalculators(){
    return dispatch => {
        dispatch(loading());
        adminService.getAllCalculators()
            .then(
                data => {
                    dispatch(store(data));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }
    function store(data) { return { type: adminConstants.STORE_CALCULATORS, data } }
}

function createOrUpdateCalculator(model, handleClose) {
    return dispatch => {
        dispatch(loading());
        console.log(model)
        adminService.createOrUpdateCalculator(model)
            .then(
                data => {
          
                    if(data.success){
                        handleClose();
                        model.id = data.id
                        dispatch(initateCalculators());
                        console.log(model)
                        dispatch(success('Saved!'))
                        dispatch(loading(false));
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                   
                    }
                    

                },
                error => {

                    dispatch(failure(error));

                }
            )
    }

}

function deleteCalculator(id,handleClose){
    return dispatch => {

        dispatch(loading());
        adminService.deleteCalculator(id)
            .then(
                data => {
                    if(data.success){
                        dispatch(deleteFromList(id));
                        handleClose()
                        dispatch(loading(false));
                        dispatch(success(data.message));
                    }
                    else{
                        dispatch(loading(false));
      
                        dispatch(failure(data.message))
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }
    function deleteFromList(data) { return { type: adminConstants.DELETE_SELECTED_CALCULATOR, data } }
}

function storeFile(file) {

    return { type: adminConstants.STORE_CALCULATOR_FILE, file };
  }


  function addCellrow(){
    return { type: adminConstants.ADD_CALCULATOR_CELL_ROW };
  }

  function removeCellrow(index){
    return { type: adminConstants.REMOVE_CALCULATOR_CELL_ROW, index };
}

function updateCalculatorCellRowValue(name, value,index) {
    const data = { name, value,index }
    return { type: adminConstants.UPDATE_CALCULATOR_CELL_ROW_VALUE, data };
}


function refreshCalculatorsInCache(){
    return dispatch => {

        dispatch(loading());
        adminService.refreshCalculatorsInCache()
            .then(
                data => {
                    if(data.success){
                        dispatch(loading(false));
                        dispatch(success(data.message));
                    }
                    else{
                        dispatch(loading(false));
                        dispatch(failure(data.message))
                    }
                },
                error => {
                    dispatch(loading(false));
                    dispatch(failure(error));
                   
                }
            )
    }
 
}

function refreshCache(){
    return dispatch => {

        dispatch(loading());
        adminService.refreshCache()
            .then(
                data => {
                    if(data.success){
                        dispatch(loading(false));
                        dispatch(success(data.message));
                    }
                    else{
                        dispatch(loading(false));
                        dispatch(failure(data.message))
                    }
                },
                error => {
                    dispatch(loading(false));
                    dispatch(failure(error));
                   
                }
            )
    }
 
}



//CHANGING COSTS
function updateChangingCostsInputValue(index, name,value) {
    const data = {index, name, value, }
    return { type: adminConstants.UPDATE_CHANGING_COSTS_INPUT_VALUE, data };
}

function addCostRow(){
    return { type: adminConstants.ADD_CHANGING_COSTS_ROW };
  }

function saveChangingCostsRow(model, index){
    return dispatch => {

        dispatch(loading());
        adminService.CreateOrUpdateChangingCost(model)
            .then(
                data => {
                    if(data.success){
                        console.log(data)
                        const response = {payload: data.payload,index}
                        dispatch(updateStore(response));
                        dispatch(loading(false));
                        dispatch(success(data.message));
                    }
                    else{
                        dispatch(loading(false));
                        dispatch(failure(data.message))
                    }
                },
                error => {
                    dispatch(loading(false));
                    dispatch(failure(error));
                   
                }
            )
    }
    function updateStore(data) { return { type: adminConstants.UPDATE_CHANGING_COST_IN_STORE, data } }
}

function initateChangingCosts(){
    return dispatch => {
        dispatch(loading(true));
        adminService.getChangingCostsForEdit()
            .then(
                data => {
                    dispatch(store(data));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }
    function store(data) { return { type: adminConstants.STORE_CHANGING_COSTS, data } }

}

function deleteChangingCost(id){
    return dispatch => {

        dispatch(loading());
        adminService.deleteChangingCost(id)
            .then(
                data => {
                    if(data.success){
                        dispatch(initateChangingCosts());
                  
                        dispatch(loading(false));
                        dispatch(success(data.message));
                    }
                    else{
                        dispatch(loading(false));
      
                        dispatch(failure(data.message))
                    }
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

}

function generateInvoicePatch(date) {

    return dispatch => {
        dispatch(loading());
        adminService.generateInvoicePatches(date)
            .then(
                data => {
                
                    dispatch(success(data.message));
                    dispatch(getAllInvoicePatches());
                    dispatch(loading(false));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(loading(false));
                }
            )
    }


}

function getAllInvoicePatches() {

    return dispatch => {

        dispatch(loading());
        adminService.getInvoicePatches()
            .then(
                data => {
                    dispatch(store(data));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function store(data) { return { type: adminConstants.STORE_INVOICE_PATCHES, data } }
}

function importCustomers() {

    return dispatch => {
        dispatch(loading());
        adminService.importCustomers()
            .then(
                data => {
                
                    dispatch(success(data.message));
                    dispatch(getAllCustomersToImport());
                    dispatch(loading(false));
                },
                error => {
                    dispatch(failure(error));
                    dispatch(loading(false));
                }
            )
    }


}

function getAllCustomersToImport() {

    return dispatch => {

        dispatch(loading());
        adminService.getAllCustomersToImport()
            .then(
                data => {
                    dispatch(store(data));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function store(data) { return { type: adminConstants.STORE_IMPORTABLE_CUSTOMERS, data } }
}

function getEventLogs() {

    return dispatch => {

        dispatch(loading());
        adminService.getEventLogs()
            .then(
                data => {
                    dispatch(store(data));
                },
                error => {
                    dispatch(failure(error));
                }
            )
    }

    function store(data) { return { type: adminConstants.STORE_EVENT_LOGS, data } }
}


