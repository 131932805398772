import React from 'react';
import {  useSelector } from 'react-redux';

import Grid from "@mui/material/Grid";
import SuiTypography from "_softUI/components/SuiTypography";

import CardMedia from "@mui/material/CardMedia";
import ShipmentInformationPreview from '_components/ShipmentInformationPreview/ShipmentInformationPreview'


export default function Preview({ instant}) {
    const state = useSelector(state => state.ShipmentInformationForm.data);
    return (
   


            <Grid container display="flex" direction="column" alignItems="stretch" alignContent="center" sx={{}}  >
                <Grid item container xs={12} px="2rem"  mb="2rem"  justifyContent="space-between" >
                    <Grid item >


                        <SuiTypography textAlign="left" variant="h4" sx={{ color: "#272828", fontWeight: '800' }} >Preview</SuiTypography>

                        <SuiTypography textAlign="left" sx={{ color: "#272828" }} variant="h5">
                            Please check your {instant ? "booking " : "request "} details
                        </SuiTypography>
                    </Grid>
                    <Grid item >
                        {instant &&
                            <>

                                <Grid item xs={2}>

                                    <CardMedia
                                        src={state.selectedCarrierLogo}
                                        component="img"
                                        title={state.selectedCarrierName}

                                        sx={{
                                            maxWidth: "150px",
                                            maxHeight: "45px",
                                            margin: 0,
                                            // paddingRight:'1rem',    
                                            objectFit: "cover",
                                            objectPosition: "center",
                                            borderRadius: '0.5rem',
                                            width:'unset'



                                        }}
                                    />
                                </Grid>
                            </>
                        }
                    </Grid>
                </Grid>


                <Grid container sx={{ maxWidth: '1600px' }}>

                    <Grid item px="2rem" mb="2rem" xs={12}  >


                        <ShipmentInformationPreview instant={instant} />

                    </Grid>


                </Grid>



            </Grid>


  

    )
}