import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiBox from "_softUI/components/SuiBox";
import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import { adminActions } from '_actions/admin.actions';
import { CarrierInput } from '_components/Admin/CarrierInput';
import DataTable from '_components/Tables/DataTable';
import SuiButton from '_softUI/components/SuiButton';

export default function ManageCarriers() {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const state = useSelector(state => state.admin);

    const data = {
        columns: [
            { Header: "ID", accessor: "id" },
            { Header: "NAME", accessor: "name" }
        ],
        rows: state.carriers.map(x=> (
            {
                'id':x.id,
                name: x.name
        }))
    }


    useEffect(() => {
        dispatch(adminActions.initateUsers());
        dispatch(adminActions.initateCustomers());
        dispatch(adminActions.initateCarriers());

    }, [])

     const save = () => {
        dispatch(adminActions.createOrUpdateCarrier(state.carrierInput, handleModalClose));
    } 

    const handleNewModalOpen = () => {

            setModalTitle('Create a carrier')
            dispatch(adminActions.clearSelectedCarrier(), adminActions.initateRoles());
            setModalOpen(true);
    }

    const handleRowClick = (id) => {
        setModalTitle('Carrier')
        dispatch(adminActions.initateSelectedCarrier(id));
        setModalOpen(true);
    }

    const handleModalClose = () => {
        dispatch(adminActions.clearSelectedCarrier());
        setModalOpen(false);
    }

    const handleDelete = () => {
        dispatch(adminActions.deleteCarrier(state.selectedCarrier));
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start" sx={{ height: '100%' }}>

            <Grid container item xs={12} alignItems="stretch" justifyContent="center">
                <Grid item xs={12} md={12}>
                    <Card sx={{ overflow: "visible", p: 3 }}>
                            <SuiBox>
                                <Grid container alignItems="strecth" justifyContent="center">
                                    <Grid item xs={6}>
                                        <SuiTypography variant="h5">Manage carriers</SuiTypography>
                                    </Grid>
                                    <Grid item xs={6} dir="rtl">
                                        <SuiButton color="secondary" onClick={handleNewModalOpen}>New carrier</SuiButton>
                                    </Grid>
                                </Grid>


                                <SuiBox mt={3}>
                                    <Grid container spacing={3}>
                                        <Grid item xs={12} sm={12}>

                                        {useMemo(()=>       <DataTable handleRowClick={handleRowClick} canSearch table={data} />
                                            ,[state.carriers])}
                                        </Grid>
                                    </Grid>

                                </SuiBox>
                            </SuiBox>
                            <CarrierInput open={modalOpen} title={modalTitle} handleClose={handleModalClose} save={save}></CarrierInput>
                    </Card>
                </Grid>
            </Grid>

        </Grid>
    )

}