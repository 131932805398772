import {nullOrZeroOrEmptyOrError, nullEmptyOrError} from './helpers'

export function validateRegisterForm(input) {

    let vals = []
    let error = input.passwordConfirm !== '' && input.password !== '' && input.password !== input.passwordConfirm 
    let output = {
      ...input,
      
      passwordError: error,
    passwordConfirmError: error && "Passwords don't match.",
      valid: !error && input.terms

    }

    
  
    return output
  }
