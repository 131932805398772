import React from 'react';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputWithLabel from '_components/FormFields/InputWithLabel';
import SuiButton from '_softUI/components/SuiButton';
import { adminActions } from '_actions/admin.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { Loading } from '_components/Shared/LoadingSpinner'
import {countries} from '_helpers/selections'
import SelectWithLabel from '_components/FormFields/SelectWithLabel';

export function CustomerInput({ open, handleClose, title, save, id }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const state = useSelector(state => state.admin);

  function handleCustomerInputChange(event) {
    dispatch(adminActions.updateCustomerInputValue(event.target.name, event.target.value));
  }

  function handleSelectChange(event, name) {

    dispatch(adminActions.updateCustomerInputValue(name, event.value));
}

  const handleDeleteClick = () => {
    confirm({ description: 'Deleting a Customer cannot be undone' })
      .then(() => {

        dispatch(adminActions.deleteCustomer(state.selectedCustomer, handleClose))
      })
      .catch(() => { /* ... */ });
  };


  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll='body'
        sx={{ overflow: 'unset', '.MuiPaper-root': { overflow: 'unset' }, justifyContent: 'center' }}
      >
        {state.loading &&
          <Grid sx={{ marginTop: '32px' }}>
            <Loading />
          </Grid>
        }
        {!state.loading && <>
        </>
        }
        <DialogTitle>
          {title}
        </DialogTitle>

        <DialogContent sx={{overflow: 'unset'}} >

          <Grid container justify="center" direction="column"
            alignItems="center" spacing={2}>
            <Grid item >

            </Grid>
            <Grid item xs={12} >

              <InputWithLabel label="Name" name="name" value={state.customerInput.name ?? ''} onChange={(e) => handleCustomerInputChange(e)} ></InputWithLabel>

            </Grid>
            <Grid item xs={12} >

              <InputWithLabel label="NetvisorId" name="cmrId" value={state.customerInput.cmrId ?? ''} onChange={(e) => handleCustomerInputChange(e)}  ></InputWithLabel>

            </Grid>
            <Grid item xs={12} >

              <InputWithLabel label="BIC" name="bic" value={state.customerInput.bic ?? ''} onChange={(e) => handleCustomerInputChange(e)} ></InputWithLabel>

            </Grid>
            <Grid item xs={12} >

<InputWithLabel label="4pl margin (desimaaleina, esim. 10% = 0.1" name="margin4pl" value={state.customerInput.margin4pl ?? ''} onChange={(e) => handleCustomerInputChange(e)} ></InputWithLabel>

</Grid>
            <Grid item xs={12} >

<InputWithLabel label="Payment term" name="paymentTerm" value={state.customerInput.paymentTerm ?? ''} onChange={(e) => handleCustomerInputChange(e)} ></InputWithLabel>

</Grid>
            {/* <Grid item xs={12} >

<InputWithLabel label="Netvisor id" name="externalCustomerId" value={state.customerInput.externalCustomerId ?? ''} onChange={(e) => handleCustomerInputChange(e)} ></InputWithLabel>

</Grid> */}
            <Grid item xs={12} md={6} >

<SelectWithLabel
    value={countries.find(obj => {
        return obj.value === state.customerInput.countryId
    })}

    onChange={e => handleSelectChange(e, 'countryId')}
    label="Country" options={countries}
    name="countryId" />

</Grid>


          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start">
            <Grid item >
              <SuiButton onClick={handleClose} variant="contained" color="warning">
                Cancel
              </SuiButton>
            </Grid>

            {state.selectedCustomer > 0 &&
              <Grid item >
                <SuiButton onClick={handleDeleteClick} variant="contained" color="error">
                  Delete
                </SuiButton>
              </Grid>}

            <Grid item>

              <SuiButton onClick={save} variant="contained" color="primary">
                Save
              </SuiButton>




            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  )

}