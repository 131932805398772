import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiBox from "_softUI/components/SuiBox";
import DashboardNavbar from '_components/Layout/DashboardNavbar';
import DataTable from '_components/Tables/DataTable';
import Card from "@mui/material/Card";
import DashboardLayout from "_components/Layout/DashboardLayout";
import Grid from '@mui/material/Grid';

import InputWithLabel from '_components/FormFields/InputWithLabel';
import Button from '@mui/material/Button';
import SuiButton from "_softUI/components/SuiButton";
import { adminActions } from '_actions/admin.actions';

export default function ImportCustomers() {
    const dispatch = useDispatch();
    const state = useSelector(state => state.admin);


    const importCustomers = () => {

        dispatch(adminActions.importCustomers())
    }

    useEffect(()=>{
        dispatch(adminActions.getAllCustomersToImport())

    },[])

    const data = {
        columns: [
            { Header: "ID", accessor: "id" },
            { Header: "Customer", accessor: "customer" },
            { Header: "Name", accessor: "name" },
            { Header: "Email", accessor: "email" },
            { Header: "Error", accessor: "error" },
        ],
        rows: state.importableCustomers.map(x => (
            {
                'id': x.id,
                customer: x.customer,
                name: x.name,
                email: x.email,
                error: x.error
            }))
    }



    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start" sx={{ height: '100%' }}>

     

            <Grid container item xs={12} alignItems="strecth" justifyContent="center">
                <Card sx={{ overflow: "visible", p: 3 }}>
                    <SuiBox>
                        <SuiTypography variant="h5">Import customers</SuiTypography>
                        <SuiBox mt={3}>
                            <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>

                                </Grid>
                          
                                <Grid item xs={12} sm={12}>
                                    <SuiButton color="secondary" variant="contained" onClick={() => importCustomers()} >
                                        Import
                                    </SuiButton>
                                </Grid>

                            </Grid>
                        </SuiBox>
                    </SuiBox>
                    <SuiBox mt={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>

                                        <DataTable canSearch table={data} />
                                    </Grid>
                                </Grid>

                            </SuiBox>
                </Card>
            </Grid>
        
        </Grid>
    )

}