import { authHeader } from '../_helpers';
import { handleResponse } from './util'


export const shipmentService = {
    getAllShipmentsForUser,
    getShipmentById,
    getFilesByShipmentById,
    saveFile,
    deleteFile,
    createShipment,
    getAllShipmentsForCarrier,
    updateDeadline,
    getUnCodesForClass,
    GetDescriptionsForUnCode

};

function getAllShipmentsForUser(type) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
 
    return fetch(process.env.REACT_APP_API_URL + 'Shipment/GetShipments?type=' + type, requestOptions).then(handleResponse)

}

function getAllShipmentsForCarrier() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
 
    return fetch(process.env.REACT_APP_API_URL + 'Carrier/GetShipmentsForCarrier', requestOptions).then(handleResponse)

}



function getShipmentById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
   
    return fetch(process.env.REACT_APP_API_URL + 'Shipment/GetShipmentById/' + id , requestOptions).then(handleResponse)

}

function getFilesByShipmentById(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
   
    return fetch(process.env.REACT_APP_API_URL + 'Shipment/GetFilesShipmentById/' + id , requestOptions).then(handleResponse)

}

function saveFile(file) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(file)
    };
   
    return fetch(process.env.REACT_APP_API_URL + 'Shipment/SaveFile' , requestOptions).then(handleResponse)

}

function updateDeadline(model) {


    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
        body: JSON.stringify(model)
    };
   
    return fetch(process.env.REACT_APP_API_URL + 'Shipment/UpdateDeadline' , requestOptions).then(handleResponse)

}



function deleteFile(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader(),
    };
   
    return fetch(process.env.REACT_APP_API_URL + 'Shipment/DeleteFile/' + id , requestOptions).then(handleResponse)

}

function createShipment(data) {
    const requestOptions = {
      method: 'POST',
      headers:authHeader(),
      body: JSON.stringify(data)
    };
  
    return fetch(process.env.REACT_APP_API_URL + 'Shipment/CreateShipment', requestOptions).then(handleResponse)
  
  }

  
function getUnCodesForClass(adrClass) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
   
    return fetch(process.env.REACT_APP_API_URL + 'Shipment/GetUnCodesForClass/' + "?adrClass=" + adrClass, requestOptions).then(handleResponse)

}

function GetDescriptionsForUnCode(unCode) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
   
    return fetch(process.env.REACT_APP_API_URL + 'Shipment/GetDescriptionsForUnCode/' + "?unCode=" + unCode, requestOptions).then(handleResponse)

}
