import { authHeader } from '../_helpers';
import { handleResponse } from './util'

export const spotRequestService = {
    saveQuote,
    getShipmentByIdForCarrier,
    bookSpot,
    cancelSpotRequest
}

function saveQuote(model){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(model)
    };
   
    return fetch(process.env.REACT_APP_API_URL + 'Carrier/SaveQuote' , requestOptions).then(handleResponse)
}

function getShipmentByIdForCarrier(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
   
    return fetch(process.env.REACT_APP_API_URL + 'Carrier/GetShipmentByIdForCarrier/' + id , requestOptions).then(handleResponse)

}

function bookSpot(id){
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    };
   
    return fetch(process.env.REACT_APP_API_URL + 'SpotRequest/BookSpot/' + id , requestOptions).then(handleResponse)
}

function cancelSpotRequest(id){
    
    const requestOptions = {
        method: 'PUT',
        headers: authHeader(),
    };
   
    return fetch(process.env.REACT_APP_API_URL + 'SpotRequest/CancelSpotRequest/' + id , requestOptions).then(handleResponse)
}