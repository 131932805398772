import { FormatColorReset } from '@mui/icons-material';
import { customerSpotRequestTableConstants} from '../_constants';
import { goodsRowModel } from './models';

const initialState = {
    loading: false,
    modalOpen: false,
    modalLoading: false,
    tableUpdateLoading: false,
    data: {
        rows: []
    },
    selectedId: 0,
    selectedShipment: {
        updating: false,
        senderReference: '',
        receiverReference: '',
        pickupStart: '',
        deliveryStart: '',
        messageToCarrier: '',
        selectedCarrierName: '',
        selectedCarrierLogo: '',
        totalPrice: '',
        docFwdCustomerReceiver:'',
        docFwdCustomerLang:'',
        docFwdSenderReceiver:'',
        file: [],
        locations: [
            {
                title: '',
                name: '',
                streetAddress: '',
                addressInfo: '',
                city: '',
                openingHours: '',
                contactName: '',
                contactEmail: '',
                contactPhone: '',
                messageToContact: '',
                zip: '',
                countryAlpha2: '',
            },
            {
                title: '',
                name: '',
                streetAddress: '',
                addressInfo: '',
                city: '',
                openingHours: '',
                contactName: '',
                contactEmail: '',
                contactPhone: '',
                messageToContact: '',
                zip: '',
                countryAlpha2: '',

            }
        ],
        goods: [
            goodsRowModel
        ],
        quotes: [
            
        ]
    }
};

export function customerSpotRequestTable(state = initialState, action) {
    switch (action.type) {
        case customerSpotRequestTableConstants.LOADING:
            switch (action.loader) {
                case 'initial':
                    return {
                        ...state,
                        loading: true

                    };
                case 'update':
                    return {
                        ...state,
                        tableUpdateLoading: true
                    };
            }

        case customerSpotRequestTableConstants.MODAL_LOADING:
            return {
                ...state,
                modalLoading: action.value,
                modalOpen: true,
            };

        case customerSpotRequestTableConstants.ERROR:
            return {
                ...state,
                loading: false
            };
        
            case customerSpotRequestTableConstants.SET_INITIAL_STATE:
                return {
                    ...initialState
                };

        case customerSpotRequestTableConstants.UPDATE_BOOKINGS_TABLE:
            return {
                ...state,
                loading: false,
                tableUpdateLoading: false,
                data: {
                    ...state.data,
                    rows: action.data
                }
            };

            case customerSpotRequestTableConstants.UPDATE_CARRIER_TABLE:
                return {
                    ...state,
                    loading: false,
                    data: {
                        ...state.data,
                        rows: action.data
                    }
                };

        case customerSpotRequestTableConstants.UPDATE_VALUE:

            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    [action.data.name]: action.data.value
                }
            }

        case customerSpotRequestTableConstants.CLEAR:
            return {
                ...state,
                selectedShipment: {
                    ...initialState.selectedShipment
                }
            };

        case customerSpotRequestTableConstants.CLOSE_MODAL:
            return {
                ...state,
                modalOpen: false,
            }



        case customerSpotRequestTableConstants.UPDATE_SELECTED_SHIPMENT:

            return {
                ...state,
                loading: false,
                
                selectedShipment: {
                    ...initialState.selectedShipment,
                    ...action.data
                },
                
              
                modalOpen: true,
                modalLoading: false,

            };

            case customerSpotRequestTableConstants.MODAL_UPDATING:

            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    updating: true
                }
                

            };




        case customerSpotRequestTableConstants.SET_SELECTED_QUOTE:
            return {
                ...state,
                selectedShipment: {
                    ...state.selectedShipment,
                    selectedQuote: action.id
                }


            };

            case customerSpotRequestTableConstants.SET_CANCELLED:

            return {
                ...state,
                loading: false,
                modalLoading: false,
                selectedShipment: {
                    ...state.selectedShipment,
                    cancelled: action.value
                }
            };

            case customerSpotRequestTableConstants.UPDATE_TABLE_VALUE:
                return {
                    ...state,
                    data: {
                      ...state.data,
                      rows: state.data.rows.map(row => {
                        if (row.id === action.data.shipmentId) {
                          return {
                            ...row,
                            status: action.data.value
                          };
                        }
                        return row; 
                      })
                    }
                  };



        default:
            return state
    }
}

