import React, { useEffect, useState, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiBox from "_softUI/components/SuiBox";

import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import { adminActions } from '_actions/admin.actions';

import { Loading } from '_components/Shared/LoadingSpinner'

import SuiInput from "_softUI/components/SuiInput";
import SuiButton from '_softUI/components/SuiButton';
import SuiSelect from "_softUI/components/SuiSelect";
import SuiTagInput from "_softUI/components/SuiTagInput";
import SuiDatePicker from "_softUI/components/SuiDatePicker";

import { useConfirm } from 'material-ui-confirm';
import TableContainer from '@mui/material/TableContainer';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { Box } from '@mui/material';
import { countries } from '_helpers';





export default function ManageChangingCosts() {
  const dispatch = useDispatch();
  const confirm = useConfirm();


  const state = useSelector(state => state.admin);
  let editableKeyToFocus = useRef(null);
  const carriersList = state.carriers && state.carriers.map(x => (
    {
      value: x.id,
      label: x.name
    }))

  

  const typeOptions = [{ value: 1, label: 'Gasoline' }, { value: 2, label: 'BAF' }]
  const amountTypeOptions = [{ value: 1, label: 'Percent' }, { value: 2, label: 'Abs' }]
  // const calculatorTypeOptions = [
  //   { value: 0, label: 'Default' },
  //   { value: 1, label: 'Parcel' },
  //   { value: 2, label: 'Pallet' },
  //   { value: 3, label: 'Groupage' },
  // ]

  const calculatorTypeOptions = [
    { value: 0, label: 'Default' },
    { value: 1, label: 'Parcel' },
    { value: 2, label: 'Pallet' },
    { value: 3, label: 'Groupage' },
    { value: 4, label: 'Fedex Regional Economy' },
    { value: 5, label: 'Fedex International Priority' },
    { value: 6, label: 'Fedex International Economy' },
    { value: 7, label: 'UPS Worldwide Express' },
    { value: 8, label: 'UPS Standard' },
    { value: 9, label: 'UPS Expedited' },
    { value: 10, label: 'UPS Saver' },
    { value: 11, label: 'DHL Sweden parcel' },
    { value: 12, label: 'DHL Sweden pallet' },
    { value: 13, label: 'DHL Sweden kappaletavara' },
    { value: 14, label: 'DHL Sweden osakuorma' },
    { value: 15, label: 'DHL Sweden euroconnect/euroline' },
    { value: 17, label: 'DHL Sweden euroconnect/euroline' },
    { value: 19, label: 'Schenker System Europe' },
    { value: 20, label: 'Schenker System Finland' },
    { value: 21, label: 'Schenker Parcel Finland' },

]

  useEffect(() => {

    dispatch(adminActions.initateChangingCosts());
    dispatch(adminActions.initateCarriers());

  }, [])

  function handleChange(index, name, value) {
    console.log(value)

    if (name === 'startDate') {
      value = value[0]
    }

    dispatch(adminActions.updateChangingCostsInputValue(index, name, value));
  }

  const save = (index) => {
    const itemToBeSaved = state.changingCosts[index]

    dispatch(adminActions.saveChangingCostsRow(itemToBeSaved, index))

  }

  const addCostRow = () => {
    dispatch(adminActions.addCostRow());

  }

  const handleDelete = (row) => {
    confirm({ description: 'Deleting a row cannot be undone' })
      .then(() => { dispatch(adminActions.deleteChangingCost(row.id)) })
      .catch(() => { /* ... */ });

  }

  function amountInput(rowIndex, value) {

    return <SuiInput
      name="amount"
      autoFocus={"amount" + rowIndex=== editableKeyToFocus.current}
      key={"amount" + rowIndex}
      onChange={(e) => { editableKeyToFocus.current = "amount" + rowIndex; handleChange(rowIndex, e.target.name, e.target.value) }} value={value} />;

  }

  function countryInput(rowIndex,row, value) {

   
      return (
      <SuiTagInput
      tags={value}
      name="countries"
      onChange={(e) => row.id === 0 ? handleChange(rowIndex, 'countries', e) : ''}
      />)
  

  }



  const tableData = {
    columns: [
      { Header: "id", accessor: "id", width: '80px', Cell: ({ value }) => <SuiTypography variant="caption">{value}</SuiTypography> },
      { Header: "updatedDate", accessor: "updatedDate", width: '100px', Cell: ({ value }) => <SuiTypography variant="caption">{value.slice(0,10)}</SuiTypography> },
      {
        Header: "carrierId", accessor: "carrierId", width: '130px', Cell: ({ value, row, rowIndex }) => <SuiBox sx={{ background: 'white' }}><SuiSelect
          value={carriersList.find(obj => {
            return obj.value === value
          })}
          onChange={(e) => handleChange(rowIndex, 'carrierId', e.value)}
          options={carriersList} />
          </SuiBox>
      },
      {
        Header: "country", accessor: "countryId", width: '130px', Cell: ({ value, row, rowIndex }) => <SuiBox sx={{ background: 'white' }}><SuiSelect
          value={countries.find(obj => {
            return obj.value === value
          })}
          onChange={(e) => handleChange(rowIndex, 'countryId', e.value)}
          options={countries} />
          </SuiBox>
      },
      {
        Header: "costType", accessor: "costType", width: '130px', Cell: ({ value, row, rowIndex }) => <SuiBox sx={{ background: 'white' }}><SuiSelect
          value={typeOptions.find(obj => {
            return obj.value === value
          })}
          onChange={(e) => handleChange(rowIndex, 'costType', e.value)}
          options={typeOptions} /></SuiBox>
      },
      {
        Header: "amountType", accessor: "amountType", width: '130px', Cell: ({ value, row, rowIndex }) => <SuiBox sx={{ background: 'white' }}><SuiSelect
          value={amountTypeOptions.find(obj => {
            return obj.value === value
          })}
          onChange={(e) => handleChange(rowIndex, 'amountType', e.value)}
          options={amountTypeOptions} /></SuiBox>
      },
      {
        Header: "calculatorType", accessor: "calculatorType", width: '130px', Cell: ({ value, row, rowIndex }) => <SuiBox sx={{ background: 'white' }}><SuiSelect
          value={calculatorTypeOptions.find(obj => {
            return obj.value === value
          })}
          onChange={(e) => handleChange(rowIndex, 'calculatorType', e.value)}
          options={calculatorTypeOptions} /></SuiBox>
      },

      {
        Header: "countries", accessor: "countries", Cell: ({ value, row, rowIndex }) => countryInput(rowIndex,row,value)
       
      },
      { Header: "startDate", accessor: "startDate", Cell: ({ value, row, rowIndex }) => <SuiDatePicker onChange={(e) => handleChange(rowIndex, 'startDate', e)} value={value} input={{ placeholder: "Select a date" }} /> },

      { 
        Header: "amount", accessor: "amount", Cell: ({ value, row, rowIndex }) => amountInput(rowIndex, value) },
      {
        Header: "actions", Cell: ({ row, rowIndex }) =>
          <SuiBox sx={{paddingTop:'0.5rem', paddingBottom:'0.5rem'}}><Grid container spacing={0}><Grid item xs={12}>
            <SuiButton size="small" color="secondary" fullWidth onClick={() => save(rowIndex)}>Save</SuiButton>
          </Grid><Grid xs={12} item>
              <SuiButton color="error" onClick={() => handleDelete(row)} fullWidth size="small">Delete</SuiButton></Grid></Grid></SuiBox>
      },
    ],

    rows: state.changingCosts.map(x => (
      {
        id: x.id,
        carrierId: x.carrierId,
        countryId: x.countryId,
        costType: x.costType,
        amountType: x.amountType,
        countries: x.countries,
        startDate: x.startDate,
        calculatorType: x.calculatorType,
        updatedDate: x.updatedDate,
        amount: x.amount

      }))
  };






  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start" sx={{ height: '100%', position: 'absolute', width: '98vw', left: 'calc((98vw - 100%) / -2)'}}>



      <Grid container item xs={12} alignItems="strecth" justifyContent="center">
        <Grid item xs={12} md={12}>
          <Card sx={{ overflow: "visible", p: 3 }}>
         
         
       
            {useMemo(()=> 
            <>
              <SuiBox>
                <Grid container alignItems="strecth" justifyContent="center">
                  <Grid item xs={6}>
                    <SuiTypography variant="h5">Manage changing costs</SuiTypography>
                  </Grid>
                  <Grid item xs={6} dir="rtl">
                    <SuiButton color="secondary" onClick={addCostRow} >New row</SuiButton>
                  </Grid>
                </Grid>
                {state.loading && <Loading />}

                <SuiBox mt={3}>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={12} sx={{ '.MuiTableContainer-root': { overflowX: 'hidden' } }}>
                 
                      <TableContainer component={Box} sx={{ maxHeight: '80vh' }}>
                        <Table >
                          <TableHead >
                            <TableRow >
                              {tableData.columns.map((column, index) => (
                                <TableCell  key={index}>{column.Header}</TableCell>
                              ))}
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {tableData.rows.map((row, rowIndex) => (
                              <TableRow  key={rowIndex}>
                                {tableData.columns.map((column, colIndex) => (
                                  <TableCell sx={{padding:0,paddingLeft:'0.15rem'}} key={colIndex}>
                                    {column.Cell({ value: row[column.accessor], row, rowIndex })}
                                  </TableCell>
                                ))}
                              </TableRow>
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                  
                    </Grid>
                  </Grid>

                </SuiBox>
              </SuiBox>
              </>
             
              ,[state.changingCosts, state.loading])}

          
          
          </Card>
        </Grid>
      </Grid>

    </Grid>
  )

}



