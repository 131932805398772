import React, { useEffect, useMemo } from 'react';
import { carrierSpotRequestTableActions } from '../_actions';
import { useSelector, useDispatch } from 'react-redux';

import DataTable from '_components/Tables/DataTable';

import Grid from '@mui/material/Grid';

import { CarrierSpotDisplayModal } from '_components/CarrierSpotDisplayModal/CarrierSpotDisplayModal';
import { Paper } from '@mui/material';
import {Id,  Location,  SpotStatus, SpotType, Deadline} from '_components/BookingsTable/CellFunctions'

export function SpotRequestsCarrier() {

  const state = useSelector(state => state.carrierSpotRequestTable)
  const dispatch = useDispatch()
  useEffect(() => {
    const loadtype = state.data.rows.length === 0 ? 'initial': 'update'
    dispatch(carrierSpotRequestTableActions.initateCarrier(loadtype)); 
  }, [])

  const handleRowClick = (id) => {

    dispatch(carrierSpotRequestTableActions.getShipmentByIdForCarrier(id));
  }

  const tableData = {
 
    columns: [
        { Header: "id",width:'58px', accessor: "id", Cell: (props) => {return (<Id id={props.row.original.id} date={props.row.original.bookingDate} />) } },
        { Header: "type", width: '80px', accessor: "type", Cell: (props) => {return (<SpotType justifyContent="center" alignItems="center" type={props.row.original.type} />) }},
        { Header: "company", accessor: "customer"}, 
        { Header: "pickup", accessor: "pickupSearch", Cell: (props) => {return (<Location city={props.row.original.pickupCity} country={props.row.original.pickupCountry} name={props.row.original.pickupCompany}/>) }},
        { Header: "delivery", accessor: "deliverySearch", Cell: (props) => {return (<Location city={props.row.original.deliveryCity} country={props.row.original.deliveryCountry} name={props.row.original.deliveryCompany}/>) }},
        { Header: "deadline", accessor: "deadlineForQuotes" ,Cell: (props) => {return (<Deadline deadline={props.row.original.deadlineForQuotes} />) }},
        { Header: "Quoted price", accessor: "quotedPrice"},
        { Header: "Status", accessor: "statusSearch", Cell: (props) => {return (<SpotStatus status={props.row.original.status} />) }},
    ],
    rows: state.data.rows
}

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="stretch"
      
      sx={{ height: 'calc(100% - 75px)'  }}>
   
   <Grid container item xs={12}  sx={{height:'100%'}} >
        <Paper sx={{width:'100%'}}>
        {useMemo(()=> <DataTable canSearch loading={state.loading} tableUpdateLoading={state.tableUpdateLoading} handleRowClick={handleRowClick} table={tableData} title={'Spot requests'} subtitle={'Click a row to open detailed information about the request'} />
        ,[state.data,state.loading,state.tableUpdateLoading])}
         
        </Paper>
        {/* <Card sx={{ width: '100%',background:'rgba(255,255,255,0.9)', height: 'calc(100%)', boxShadow:'rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px',   border: '1px solid rgb(233,233,233)',backdropFilter:'saturate(200%) blur(1.875rem)' }}>
       */}
      
        {/* </Card> */}
      </Grid>
      <CarrierSpotDisplayModal state={state}/>
    </Grid>

  )

}

