import React, { useCallback, useMemo } from 'react'
import { useDropzone } from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux';
import { shipmentInformationInputModalActions } from '../../_actions';
import Grid from '@mui/material/Grid';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import icon from '../../_assets/images/pdfIcon.png'
import SuiTypography from "_softUI/components/SuiTypography";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SuiButton from "_softUI/components/SuiButton";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';
import ClearIcon from '@mui/icons-material/Clear';
import { AiOutlineCloudUpload } from 'react-icons/ai';

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '15px',
  background: 'rgba(168, 149, 108, 0.1)',
  borderRadius: 1,
  height: '100px',
  borderRadius: '5px',
  color: '#000',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#397087'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};


export default function Dropzone({
  action,
  files,
  handleDelete = null,
  acceptedFileFormats,
  singleFile = false,
  title = "Attachments",
  hintText = "To speed things up, please upload required documets here",
  showIcon = true }) {

  const onDrop = action
  const { acceptedFiles, getRootProps, getInputProps, isFocused,
    isDragAccept,
    isDragReject } = useDropzone({ onDrop, accept: acceptedFileFormats, maxFiles: singleFile ? 1 : null })


  const items = files && files.map((x, i) => (
    <Grid item xs={12} container justifyContent="center" alignItems="center" key={i} sx={{ width: '100%' }}>
      <Grid item xs={2}>
        {showIcon &&
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <img style={{ width: 'auto', height: '20px' }} alt={x.name} src={x.type !== 'application/pdf' ? null : icon} />
          </Grid>}
      </Grid>
      <Grid item xs={8}>
        <SuiTypography sx={{color:'#4F4F4F'}} variant="caption" fontWeight="light" >{x.name?.length > 25 ? x.name.substring(0, 25) + '...' : x.name}</SuiTypography>
      </Grid>
      <Grid item xs={2}>
        {handleDelete &&
          <Grid item xs={12} container justifyContent="center" alignItems="center">
            <IconButton sx={{ color: 'red'}} onClick={() => handleDelete(i)}  ><ClearIcon /></IconButton>
          </Grid>}
      </Grid>
    </Grid>
  ))

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);


  return (
    <Grid container  >
      <Grid item xs={7}
        onMouseEnter={(e) => { e.target.style.borderColor = '#2196f3'; e.target.style.cursor = 'pointer' }}
        onMouseLeave={(e) => { e.target.style.borderColor = 'transparent' }}
        container sx={{ mt: '0.2rem', "maxWidth": '450px' }} {...getRootProps({ style })}>

        <Grid item xs={12}>
          <div >
            {/* <div {...getRootProps({})}> */}
              <input {...getInputProps()} />
              <Grid container item xs={12}>
                <Grid item xs={4}>
                  <AiOutlineCloudUpload size="4rem" />
                </Grid>
                <Grid container item xs={8}>
                  <Grid item xs={12}>
                    <SuiTypography textAlign="center" sx={{ color: "#000" }} variant="button" mb={1}>
                      Upload files here
                    </SuiTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <SuiTypography textAlign="center" sx={{ color: "#000" }} variant="caption" mb={1}>
                      {hintText}
                    </SuiTypography>
                  </Grid>


                </Grid>
              </Grid>


            </div>
          {/* </div> */}

        </Grid>

      </Grid>
      <Grid item xs={5} container sx={{ mt: '0.2rem'}} justifyContent="center" alignItems="flex-start">
        
        <Grid sx={{ mt: '0.5rem' }} item container justifyContent="center" alignItems="flex-start">
          {items && items.length === 0 ? <>

          </> : items}
        </Grid>
      </Grid>


    </Grid>
  )
}

