import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import { useDispatch, useSelector } from 'react-redux';
import ReactCountryFlag from "react-country-flag"
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';
import { customerSpotRequestTableActions } from '../_actions';
import { useEffect } from 'react';
import { dashboardActions, shipmentActions } from '_actions';
import { Grid } from '@mui/material';
import Skeleton from '@mui/material/Skeleton';
import { ShipmentDisplayModal } from '_components/ShipmentDisplayModal/ShipmentDisplayModal';
import { useHistory } from 'react-router-dom';

export function Dashboard() {
    const history = useHistory();
    const dispatch = useDispatch();
    const state = useSelector(state => state.dashboard)
    const goods = useSelector(state => state.instantPricing.goods)
    const shipmentState = useSelector(state => state.shipment)
    const SpotState = useSelector(state => state.customerSpotRequestTable)


    useEffect(() => {
        dispatch(dashboardActions.initate())
    }, [])

    const handleRowClick = (index) => {
        dispatch(dashboardActions.setSelectedSearchHistory(index, state.model.searchHistories[index]))
        history.push('/InstantPricing')
    }

    const handleProgressRowClick = (id, type) => {

        if(type === 1 || type === 3) {
            dispatch(customerSpotRequestTableActions.getShipmentById(id));
        } else if (type === 2 ) {
            dispatch(shipmentActions.getShipmentById(id));
        }
      
    }

    const mapGoodsRows = (row) => {
        return (
            <table>
              <thead>
                <tr >
                  <th>Qty</th>
                  <th>Type</th>
                  <th>Length</th>
                  <th>Width</th>
                  <th>Height</th>
                  <th>Weight</th>
                  <th>Stackable</th>
                </tr>
              </thead>
              <tbody>
                {row.searchHistoryGoodsRows.map((item, index) => (
                  <tr key={index}>
                    <td>{item.quantity}</td>
                    <td>{item.typeCode === 2 ? "EUR" : item.typeCode === 3 ? "FIN" : item.typeCode === 4 ? "Half" : item.typeCode === 7 ? "Parcel" : "Colli"}</td>
                    <td>{item.itemWidth}</td>
                    <td>{item.itemLength}</td>
                    <td>{item.itemHeight}</td>
                    <td>{item.itemWeight}</td>
                    <td>{item.stackable ? "yes" : "no"}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          );
        };

        const NoMaxWidthTooltip = styled(({ className, ...props }) => (
            <Tooltip {...props} classes={{ popper: className }} arrow={true} TransitionComponent={Zoom}/>
          ))({
            '& th': {
                padding: '10px', 
                paddingBottom: '5px',
                marginRight: '10px',
                fontWeight: 'bold', 
                textAlign: 'left'
              },
            '& tr': {
                textAlign: 'left',
              },
            '& td': {
                paddingLeft: '10px',
                paddingRight: '10px',
                textTransform: 'capitalize',
              },
              '& .MuiTooltip-arrow': {
                color: 'rgba(255,255,255,1)', 
              },
              '& .MuiTooltip-tooltip': {
                opacity: 1,
                maxWidth: 'none',
                backgroundColor: 'rgba(255,255,255,1)',
                boxShadow: 'rgba(0, 0, 0, 0.3) 0 2px 10px',
                color: '#2B2B2B',
              }
          });

    return (
        <>
            <Grid container spacing={2} sx={{height: '100%'}}>
                <Grid item container xs={12}>
                    <h1>Hi {state.model.name}</h1>
                </Grid>
                <Grid item container xs={12} md={4} sx={{height: '100%'}}>
                    <Card sx={{ width: '100%', height: '100%' }}>
                        <CardContent>
                            <Typography sx={{ fontSize: 14, px: 2, py: 1 }} color="text.secondary" gutterBottom>
                                Last five searches
                            </Typography>
                            <Typography sx={{ fontSize: 11, px: 2, py: 1 }} color="text.secondary" gutterBottom>
                                Click on a row to reopen search
                            </Typography>
                            <TableContainer >
                                
                                <Table size="small" >
                                    <TableHead >
                                        <TableRow>
                                            <TableCell align="left">From</TableCell>
                                            <TableCell align="left">To</TableCell>
                                            <TableCell align="right">Items</TableCell>
                                        </TableRow>
                                        
                                    </TableHead>
                                    
                                    {state.loading ? (
                                    <TableBody >
                                    {[...Array(5)].map((_, index) => (
                                        <TableRow key={index}>
                                        <TableCell>
                                            <Skeleton animation="wave" height={20} width="70%" />
                                        </TableCell>
                                        <TableCell>
                                            <Grid sx={{display: 'flex', justifyContent: 'center'}}>
                                            <Skeleton animation="wave" height={20} width="70%" />
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                            <Skeleton animation="wave" height={20} width="30%" />
                                            </Grid>
                                        </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody> ) : (


                                    <TableBody>
                                        
                                        {state.model.searchHistories && state.model.searchHistories.map((row, index) => (
                                         
                                            <NoMaxWidthTooltip title={mapGoodsRows(row)} placement="right"  key={index} >
                                            <TableRow
                                            hover
                                               
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                                                '&:hover': { cursor: 'pointer' }
                                             }}
                                                onClick={() => handleRowClick(index)}
                                            >
                                                
                                            
                                                <TableCell align="left">{<> <ReactCountryFlag svg   countryCode={row.pickupCountry} width="20" style={{ marginRight: '0.5rem' }} />{row.pickupZip + ", " + row.pickupCountry}</>}</TableCell>
                                                <TableCell align="left">{<> <ReactCountryFlag svg   countryCode={row.deliveryCountry} width="20" style={{ marginRight: '0.5rem' }} />{row.deliveryZip + ", " + row.deliveryCountry}</>}</TableCell>
                                                <TableCell align="right"> {row.searchHistoryGoodsRows.reduce((total, item) => total + item.quantity, 0)}</TableCell>
                                                
                                            </TableRow>
                                            </NoMaxWidthTooltip>
                                        ))}
                                    </TableBody> )}
                                </Table>
                            </TableContainer>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid item container xs={12} md={8} sx={{height: '100%'}}>
                    <Card sx={{ width: '100%', height: '100%'}}>
                        <CardContent sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
                            <Typography sx={{ fontSize: 14, px: 2, py: 1 }} color="text.secondary" gutterBottom>
                                In Progress
                            </Typography>
                            <Typography sx={{ fontSize: 11, px: 2, py: 1 }} color="text.secondary" gutterBottom>
                                Click on a row to open booking / request
                            </Typography>
                            <Grid sx={{ flex: 1, overflowY: 'auto' }}>
                            <TableContainer sx={{ height: '100%'}}>

                                <Table size="small" stickyHeader>
                                    <TableHead >
                                        <TableRow>
                                            <TableCell align="left">Id</TableCell>
                                            <TableCell align="left">From</TableCell>
                                            <TableCell align="left">To</TableCell>
                                            <TableCell align="right">Status</TableCell>
                                            <TableCell align="right">Latest change</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    {state.loading ? (
                                    <TableBody >
                                    {[...Array(19)].map((_, index) => (
                                        <TableRow key={index}>
                                                                                    <TableCell>
                                            <Skeleton animation="wave" height={20} width="70%" />
                                        </TableCell>
                                        <TableCell>
                                            <Skeleton animation="wave" height={20} width="70%" />
                                        </TableCell>
                                        <TableCell>
                                            <Grid sx={{display: 'flex', justifyContent: 'center'}}>
                                            <Skeleton animation="wave" height={20} width="70%" />
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                            <Skeleton animation="wave" height={20} width="30%" />
                                            </Grid>
                                        </TableCell>
                                        <TableCell>
                                            <Grid sx={{display: 'flex', justifyContent: 'flex-end'}}>
                                            <Skeleton animation="wave" height={20} width="30%" />
                                            </Grid>
                                        </TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody> ) : (                                   
                                    <TableBody>
                                        {state.model.inProgress && state.model.inProgress.map((row, index) => (                                            
                                            <TableRow
                                            hover
                                                key={index}
                                                sx={{ '&:last-child td, &:last-child th': { border: 0 },
                                                '&:hover': { cursor: 'pointer' }
                                            }}
                                            onClick={() => handleProgressRowClick(row.bookingId, row.shipmentType)}
                                            >

                                                <TableCell align="left">{row.bookingId}</TableCell>
                                                <TableCell align="left">{<> <ReactCountryFlag svg   countryCode={row.pickupCountry} width="20" style={{ marginRight: '0.5rem' }} />{row.pickupZip }</>}</TableCell>
                                                <TableCell align="left">{<> <ReactCountryFlag svg   countryCode={row.deliveryCountry} width="20" style={{ marginRight: '0.5rem' }} />{row.deliveryZip }</>}</TableCell>
                                                <TableCell align="right">{row.status}</TableCell>
                                                <TableCell align="right">{row.latestChange}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody> )}                                   
                                </Table>
                            </TableContainer>
                            </Grid>
                        </CardContent>
                    </Card>
                </Grid>

                <ShipmentDisplayModal state={shipmentState}/>
                <ShipmentDisplayModal state={SpotState} shipmentFamily="spot"/>
            </Grid>

        </>

    );
}


