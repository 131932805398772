import DashboardLayout from "_components/Layout/DashboardLayout";

import SuiBox from "_softUI/components/SuiBox";

import brand from "_assets/images/logo.png";
import Grid from "@mui/material/Grid"
import { Banner } from './Banner'

import bgBlue from "_assets/images/bgFlat2.png";

import Star from '_assets/landingPage/images/shape-2.svg'
import {TopNavigation} from './TopNavigation'

export function Main(props) {

  let user = JSON.parse(localStorage.getItem('user'));
  let showNotification = user?.notificationText !== null


  return (
    <Grid container sx={{height:'100%'}}>
      
      <TopNavigation   brand={brand}  routes={props.routes.filter(val => !val.hidden)} />
    <Grid container justifyContent="center" sx={{height:'calc(100% - 65px)'}}>
            {/* <Grid item xs={12} pt={0} sx={{ backgroundColor:'#fff',position:'relative'  }}> */}
            <Grid item xs={12} pt={0} sx={{ backgroundColor:'#f9f9f9',position:'relative',   boxShadow: 'rgba(0, 0, 0, 0.06) 0px 2px 4px 0px inset', backgroundImage: `url(${bgBlue})`, backgroundRepeat: 'no-repeat',  backgroundPosition: 'left bottom', height:'100%' }}>
      {/* <SuiBox sx={{ marginTop: '1rem', position:'relative', left:0 }} component="img" src={bgBlue} alt="FreightOpt" width="459px" height="611px" /> */}

      <div style={{position:'absolute', right:0, top:'10%'}}>
      <img src={Star} alt="" />

        </div>
      
        {/* <Sidenav
          //tähän max leveys 1440px ja keskelle näyttöä
          brand={brand}
          routes={props.routes.filter(val => !val.hidden)} 
          sx={{ float:'left'}} /> */}


          <DashboardLayout >

            <SuiBox sx={{ height: '100%' }} pl={6} pr={6} pt={2} pb={2} mb={1}>
            {showNotification && <Banner notificationText={user.notificationText}/>}
              {/* <DashboardNavbar/> */}
              {props.children}
            </SuiBox>
          </DashboardLayout>
        </Grid>
      </Grid>
      {/* <Footer/> */}
    </Grid>


  )


}