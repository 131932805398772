import SuiTypography from "_softUI/components/SuiTypography";
import Zoom from '@mui/material/Zoom';
import SuiButton from "_softUI/components/SuiButton";
import Checkbox from '@mui/material/Checkbox';
import Grid from "@mui/material/Grid";
import { Paper } from "@mui/material";
import React, { useState, useEffect } from 'react';
import Alert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';


export const Banner = ({notificationText}) => {
    const [banner, setBanner] = useState(false);
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        if (!clicked) {
            const timeoutId = setTimeout(() => {
                setBanner(true)
            }, 2500)
            return () => clearTimeout(timeoutId);
        }
    });

    const handleChange = () => {
        setTimeout(() => {
            setBanner(false)
            setClicked(true)
        }, 500)
    };



    return (
        <>
            {banner &&
                <Zoom in={banner}>
                    <Paper mb={1}>
                        <Grid container mb={1} >
                            <Alert severity="warning" sx={{ width: '100%', '& .MuiAlert-message': { width: '100%' }, padding: '10px' }} >
                                <Grid container>
                                    <Grid item xs={10}>
                                        {notificationText}
                                    </Grid>
                                    <Grid item xs={2} >
                                        <Grid container sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                            <Grid item md={2} xs={12} sx={{ display: 'flex', justifyContent: 'center' }} >
                                                <Checkbox sx={{ padding: '0px !important' }} onChange={handleChange} inputProps={{ 'aria-label': 'controlled' }} />
                                            </Grid>
                                            <Grid item md={5} xs={12} mt={0.4}>
                                                Noted
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Alert>
                        </Grid>
                    </Paper>
                </Zoom>
            }
        </>
    )
}