export function validateState(state){
    let checks = []
    console.log(state);
    checks.push(state.originCountry !== '')
    checks.push(state.originZip !== '')
    checks.push(state.destinationCountry !== '')
    checks.push(state.destinationZip !== '')
    checks.push(state.goods.every(x => x.valid === true))


    var originRegex = new RegExp(state.originZipRegex)
    checks.push(originRegex.test(state.originZip?.value))

    var destinationRegex = new RegExp(state.destinationZipRegex)
    checks.push(destinationRegex.test(state.destinationZip?.value))

    // if (state.adr)
    // {
    //   checks.push(state.adrClass !== '')
    // }


    return checks.every(x => x === true)
  
  }

  
export function mapInstantPricingRequestDTO(state, getPricesType){
    console.log(state)
    const adrRowClasses = state.goods.flatMap(goodsRow => goodsRow.adrRow.map(x => x.class))

    let DTO = {
      originCountry: state.originCountry.value,
      originCity: state.originZip.city,
      originState: state.originZip.state,
      originZip: state.originZip.value,
      destinationCountry: state.destinationCountry.value,
      destinationCity: state.destinationZip.city,
      destinationState: state.destinationZip.state,
      destinationZip: state.destinationZip.value,
      totalWeight: state.weight,
      totalVolume: state.volume,
      totalLdm: state.ldm,
      rows: state.goods.map(x=>  ({
        quantity: x.quantity,
        weight: x.weight,
        width: x.width,
        length: x.length,
        height: x.height,
        stackable: x.stackable,
        itemType: x.itemType,
        typeCode: x.typeCode,
        adr: x.adr
      })),
      additionalServices: {
        tailLift: state.tailLift,
        insurance: state.insurance,
        adr: state.adr,
        importDeclaration: state.importDeclaration,
        exportDeclaration: state.exportDeclaration,
        adrClass: adrRowClasses.join(','),
        // adrClass: state.adrClass.map(x=> x.value).join(','),
        goodsValue: state.goodsValue,
        callOnPickup: state.callOnPickup,
        callOnDelivery: state.callOnDelivery,
        consumerDelivery: state.consumerDelivery,
        heatedTransport: state.heatedTransport,
      },
      getPricesType: getPricesType
  
    }
    console.log(DTO)
  
    return DTO
  }

export function mapShipmentInformationModalToInstantPricingRequestDTO(state){
  const adrRowClasses = state.goods.flatMap(goodsRow => goodsRow.adrRow.map(x => x.class))
  let DTO = {
    originCountry: state.locations[0].countryAlpha2,
    originZip: state.locations[0].zip,
    originCity: state.locations[0].city,
    originState: state.locations[0].state,
    destinationCountry: state.locations[1].countryAlpha2,
    destinationZip: state.locations[1].zip,
    destinationState: state.locations[1].state,
    destinationCity: state.locations[1].city,
    selectedCarrierId: state.selectedCarrierId,
    selectedCalculatorId: state.calculatorId,
    rows: state.goods.map(x=>  ({
      quantity: x.quantity,
      weight: x.weight,
      width: x.width,
      length: x.length,
      height: x.height,
      stackable: x.stackable,
      itemType: x.itemType,
      typeCode: x.typeCode
    }))
    ,
      additionalServices: {
        tailLift: state.tailLift,
        insurance: state.insurance,
        adr: state.adr,
        importDeclaration: state.importDeclaration,
        exportDeclaration: state.exportDeclaration,
        adrClass: adrRowClasses.join(','),
        goodsValue: state.goodsValue,
        callOnPickup: state.callOnPickup,
        callOnDelivery: state.callOnDelivery,
        consumerDelivery: state.consumerDelivery,
        heatedTransport: state.heatedTransport,
      }

  }
  console.log(DTO)

  return DTO
}
