import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiBox from "_softUI/components/SuiBox";
import DashboardNavbar from '_components/Layout/DashboardNavbar';
import DataTable from '_components/Tables/DataTable';
import Card from "@mui/material/Card";
import DashboardLayout from "_components/Layout/DashboardLayout";
import Grid from '@mui/material/Grid';

import InputWithLabel from '_components/FormFields/InputWithLabel';
import Button from '@mui/material/Button';
import SuiButton from "_softUI/components/SuiButton";
import { adminActions } from '_actions/admin.actions';
import { Paper } from '@mui/material';

export default function ManageInvoicePatches() {
    const dispatch = useDispatch();
    const state = useSelector(state => state.admin);
    const [from,setDate] = useState();

    const generatePatch = (fromDate) => {
        console.log(fromDate)
        dispatch(adminActions.generateInvoicePatch(fromDate))
    }

    useEffect(()=>{
        dispatch(adminActions.getAllInvoicePatches())

    },[])

    const data = {
        columns: [
            { Header: "ID", accessor: "id" },
            { Header: "Rows", accessor: "name" },
            { Header: "BIC", accessor: "bic" },
            { Header: "CMR ID", accessor: "cmrId" },
            { Header: "Country", accessor: "country" },
        ],
        rows: state.customers.map(x => (
            {
                'id': x.id,
                name: x.name,
                bic: x.bic,
                cmrId: x.cmrId,
                country: x.countryId
            }))
    }



    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start" sx={{ height: '100%', width:'100%' }} item xs={12}>
                
     

            <Grid container item xs={12} alignItems="strecth" justifyContent="center">
                <Card sx={{ overflow: "visible", p: 3 }}>
                    <SuiBox>
                        <SuiTypography variant="h5">Create invoice patch</SuiTypography>
                        <SuiBox mt={3}>
                            <Grid container spacing={3}>
                            <Grid item xs={12} sm={12}>
                            <InputWithLabel
                            label="BeforeDate"
                            name="beforeDate"
                            onChange={e => setDate(e.target.value)}
                            fullWidth
                            value={from}
                            type="date"
                            mandatory={true}
                            
                          />
                                </Grid>
                          
                                <Grid item xs={12} sm={12}>
                                    <SuiButton color="secondary" variant="contained" onClick={() => generatePatch(from)} >
                                        Generate
                                    </SuiButton>
                                </Grid>

                            </Grid>
                        </SuiBox>
                    </SuiBox>
                </Card>
            </Grid>
            {state.invoicePatches.map(x => {
                return (
                 
                    <Grid container item xs={12} sx={{m:0.2}} >
                        <Paper sx={{width:'100%'}}>
                              <Grid container item xs={12} sx={{p:0.2}} >
                            <Grid item xs={4} >
                                ID: {x.id}

                            </Grid>
                            <Grid item xs={4} >
                                Created: {x.created}

                            </Grid>
                            <Grid item xs={4} >

                                <a href={x.url}>Lataa</a>
                            </Grid>
                            </Grid>
                            </Paper>
                            </Grid>
                   
                 
                )
            })}
        </Grid>
    )

}