import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { userActions } from '../_actions';
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import logo from "_assets/images/logo3.png";

import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";
import SuiInput from "_softUI/components/SuiInput";
import SuiButton from "_softUI/components/SuiButton";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";


export default function Login({ show, hide }) {
    const dispatch = useDispatch();
    const state = useSelector(state => state.authentication);
    const [submitted, setSubmitted] = useState(false)
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [reset, setReset] = useState(false)



    function handleLogin() {


        const query = new URLSearchParams(window.location.search );
 
        
        if (username && password) {
            dispatch(userActions.login(username, password, query.get('url')?? '/'));
        }
    }

    function handleReset() {

        hide(false)
        setSubmitted(true);

        if (username) {

            dispatch(userActions.forgotPassword({email:username}));
        }
    }

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            event.preventDefault();
            handleLogin();
        }
    };

    useEffect(() => {
        setReset(false)

    }, [show])

    return (
        <>
            <Dialog open={show} maxWidth='xs' fullWidth>
                <DialogTitle>
                    <Grid container justifyContent="space-between" alignItems="flex-start">

                        <Grid item textAlign="center" xs={12}>
                            <SuiTypography variant="h4" fontWeight="bold">
                                {reset && "Reset password"}
                                {!reset && "Login"}
                            </SuiTypography>
                        </Grid>
                        <Grid sx={{ position: 'absolute', right: 0, top: 0 }}>
                            <IconButton onClick={() => hide(false)}>
                                <CloseIcon style={{ fontSize: '1.2rem' }} />
                            </IconButton>
                        </Grid>
                    </Grid>

                </DialogTitle>
                <DialogContent>



                    <Card elevation={0} onKeyDown={handleKeyPress}>
                        {!reset &&
                            <SuiBox pt={2} pb={3} px={3}>
                                <SuiBox >

                                    <SuiBox mb={2}>
                                        <SuiInput type="email" placeholder="Email" value={username} onChange={(e) => setUsername(e.target.value)} />
                                    </SuiBox>

                                    <SuiBox mb={2}>
                                        <SuiInput type="password" placeholder="Password" value={password} onChange={(e) => setPassword(e.target.value)} />
                                    </SuiBox>


                                    <SuiBox mt={4} mb={1}>
                                        <SuiButton variant="gradient" color="dark" sx={{ color: 'white' }} fullWidth onClick={() => handleLogin()}>
                                            Login
                                        </SuiButton>
                                        {state.loggingIn &&
                                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        }
                                    </SuiBox>

                                </SuiBox>
                                <Grid container justifyContent="center">
                                    <Grid item >
                                        <SuiButton variant="text" color="dark" onClick={() => setReset(true)}>
                                            Forgot password?
                                        </SuiButton>

                                    </Grid>
                                </Grid>

                            </SuiBox>
                        }

                        {reset &&
                            <SuiBox pt={2} pb={3} px={3}>
                                <SuiBox >

                                    <SuiBox mb={2}>
                                        <SuiInput type="email" placeholder="Email" value={username} onChange={(e) => setUsername(e.target.value)} />
                                    </SuiBox>

                                    <SuiBox mt={4} mb={1}>
                                        <SuiButton variant="gradient" color="dark" sx={{ color: 'white' }} fullWidth onClick={() => handleReset()}>
                                            Reset
                                        </SuiButton>
                                        {state.loggingIn &&
                                            <img src="data:image/gif;base64,R0lGODlhEAAQAPIAAP///wAAAMLCwkJCQgAAAGJiYoKCgpKSkiH/C05FVFNDQVBFMi4wAwEAAAAh/hpDcmVhdGVkIHdpdGggYWpheGxvYWQuaW5mbwAh+QQJCgAAACwAAAAAEAAQAAADMwi63P4wyklrE2MIOggZnAdOmGYJRbExwroUmcG2LmDEwnHQLVsYOd2mBzkYDAdKa+dIAAAh+QQJCgAAACwAAAAAEAAQAAADNAi63P5OjCEgG4QMu7DmikRxQlFUYDEZIGBMRVsaqHwctXXf7WEYB4Ag1xjihkMZsiUkKhIAIfkECQoAAAAsAAAAABAAEAAAAzYIujIjK8pByJDMlFYvBoVjHA70GU7xSUJhmKtwHPAKzLO9HMaoKwJZ7Rf8AYPDDzKpZBqfvwQAIfkECQoAAAAsAAAAABAAEAAAAzMIumIlK8oyhpHsnFZfhYumCYUhDAQxRIdhHBGqRoKw0R8DYlJd8z0fMDgsGo/IpHI5TAAAIfkECQoAAAAsAAAAABAAEAAAAzIIunInK0rnZBTwGPNMgQwmdsNgXGJUlIWEuR5oWUIpz8pAEAMe6TwfwyYsGo/IpFKSAAAh+QQJCgAAACwAAAAAEAAQAAADMwi6IMKQORfjdOe82p4wGccc4CEuQradylesojEMBgsUc2G7sDX3lQGBMLAJibufbSlKAAAh+QQJCgAAACwAAAAAEAAQAAADMgi63P7wCRHZnFVdmgHu2nFwlWCI3WGc3TSWhUFGxTAUkGCbtgENBMJAEJsxgMLWzpEAACH5BAkKAAAALAAAAAAQABAAAAMyCLrc/jDKSatlQtScKdceCAjDII7HcQ4EMTCpyrCuUBjCYRgHVtqlAiB1YhiCnlsRkAAAOwAAAAAAAAAAAA==" />
                                        }
                                    </SuiBox>

                                </SuiBox>
                                {/* <Grid container justifyContent="center">
                                    <Grid item >
                                        <SuiButton variant="text" color="dark" onClick={() => setReset(false)}>
                                            Login
                                        </SuiButton>

                                    </Grid>
                                </Grid> */}
                            </SuiBox>
                        }

                    </Card>




                </DialogContent>


            </Dialog>




        </>
    );

}


