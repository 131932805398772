import React from 'react';
import PropTypes from "prop-types";
import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";
import SuiInput from "_softUI/components/SuiInput";
import InputAdornment from '@mui/material/InputAdornment';

import Tooltip, { tooltipClasses }  from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';

import { useRef } from 'react'

const LightTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: 'red',
    color: 'black',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}));

function InputWithLabel({ label,adornment,adornmentPosition,fontWeight,mandatory,setDefault, adornmentSize, ...rest }) {


  
  const inputRef = useRef()
  const direction = adornmentPosition === "end" ? "right" : "left"
  return (
    <>
      {label && 
      <SuiBox mb={0.25} ml={0} lineHeight={0}  sx={{marginBottom: '0rem'}}  display="inline-block">






        <SuiTypography
          component="label"
          variant="caption"
          fontWeight='light'
          textTransform="capitalize"
         
        >
          {label} {mandatory&&  <SuiTypography
          component="label"
          variant="caption"
          fontWeight='light'
          textTransform="capitalize"
          sx={{color:'#fd5c70'}}
         
        >
           *
        </SuiTypography>}
        </SuiTypography>
      </SuiBox>
 
      
}
    
   
      <SuiInput 
      {...rest} 

      sx={{height:'1.2em', fontSize:'1.2rem',color:'#183B59',ml:0,'.MuiTypography-root':{fontSize:'1.2rem', color:'#183B59'}}}

          icon={ adornment && {component: <InputAdornment sx={{height:'1.2em', fontSize:'1.2rem',color:'#183B59',ml:0, '.MuiTypography-root':{fontSize: adornmentSize ? adornmentSize : '1.1rem', color:'#183B59'}}} position={adornmentPosition}>{adornment}</InputAdornment>, direction: direction}}
          inputRef={inputRef}
          onClick={rest.type === 'date' || rest.type === 'datetime-local' ? () => { 
           
            if(setDefault){
              setDefault().then(() => {
                inputRef.current.showPicker()
              });
            }
            else {
              inputRef.current.showPicker()
            }
            
          
          } : undefined}
        
      />


    </>
  );
}

InputWithLabel.propTypes = {
  label: PropTypes.string.isRequired,
};

export default InputWithLabel;
