import {nullOrZeroOrEmptyOrError, nullEmptyOrError} from './helpers'


export function validateAdrRow(input) {

  let vals = []

  let newOutput = {
    ...input,
    // unCodeError: nullOrZeroOrEmptyOrError(input.class, input.unCodeError,vals),
    adrClassError: nullEmptyOrError(input.class, vals),
    unCodeError: nullEmptyOrError(input.unCode, vals),
    adrDescriptionError: nullEmptyOrError(input.description, vals),
    adrQuantityError: nullEmptyOrError(input.quantity, vals),
    adrPkgTypeError: nullEmptyOrError(input.packageType, vals),
    adrNetWeightError: nullEmptyOrError(input.netWeight, vals),
    adrGrossWeightError: nullEmptyOrError(input.grossWeight, vals),
    adrUnitError: nullEmptyOrError(input.measurementUnit, vals),
    adrNetVolumeError: nullEmptyOrError(input.netVolume, vals)
  }

  if(input.technicalNameRequired){
    newOutput = {
      ...newOutput,
      technicalNameError: nullEmptyOrError(input.technicalDescription, vals)
    }
  }
 
  console.log(vals)
  let valid = !vals.includes(false)


  const output = {
    ...newOutput,
    valid: valid
  }

  return output
}

