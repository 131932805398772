import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";
import Grid from '@mui/material/Grid';

function DisplayWithLabel({ label, fontWeight, value, icon }) {
    return (
        <>
            <SuiBox mb={0.5} sx={{ borderRadius: '0rem', }}>

                <SuiBox mb={0}  display="inline-block">
                    <Grid container alignItems="flex-start" justifyContent="flex-start">

                        <Grid item xs={12} >
                        <SuiTypography variant='subtitle2' sx={{ pr: 1, color: 'text.secondary' }} >
                            {/* <SuiTypography
                                sx={{fontFamily:"Montserrat", fontWeight:'400', fontSize:'1rem', color: '##2b2b2b'}}
                                variant="caption" 
                            > */}
                                {label}


                            </SuiTypography>
                        </Grid>
                        <Grid item xs={12} sx={{ pt: 0.2, mt: '-0.25rem' }}>
                        <SuiTypography variant='subtitle1' sx={{ pr: 1, color: 'text.primary' }} >
                            {/* <SuiTypography

                                variant="button"
                                sx={{fontFamily:"Montserrat", fontWeight:'500', fontSize:'1.2rem', color: '#2B2B2B'}}
                            > */}
                                {value === '' || value === null || value === undefined ? "n/a" : value}
                            </SuiTypography>
                        </Grid>
                    </Grid>



                </SuiBox>


            </SuiBox>
        </>
    );
}



export default DisplayWithLabel;