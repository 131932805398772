import { history } from '../_helpers';


function handleResponse(response) {

  const redirectUrl = '/login' + (window.location.search !=="" ? '?url='+window.location.pathname+window.location.search :'')

  return response.text().then(text => {
    const data = text && JSON.parse(text);
    if (!response.ok) {
      if (response.status === 401) {
        // auto logout if 401 response returned from api
        localStorage.removeItem('user');
        history.push(redirectUrl,);
      }

      const error = (data && data.errors) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

export { handleResponse }