import { alertConstants } from '../_constants';

const initialState = {
  type:'',
  open: false,
  message:''
}


export function alert(state = initialState, action) {
  switch (action.type) {
    case alertConstants.SUCCESS:
      return {
        type: 'success',
        message: action.message,
        open:true
      };
    case alertConstants.ERROR:
      return {
        type: 'danger',
        message: action.message,
        open:true
      };
    case alertConstants.CLEAR:
      return initialState;
    default:
      return state
  }
}