export const instantPricingConstants = {
  LOADING: 'INSTANT_PRICING_LOADING',
  CALCULATE: 'INSTANT_PRICING_CALCULATE',
  SET_INITIAL_STATE: 'INSTANT_PRICING_SET_INITIAL_STATE',
  UPDATE_COUNTRIES: 'INSTANT_PRICING_UPDATE_COUNTRIES',
  INITATE_OPTIONS: 'INSTANT_PRICING_INITATE_OPTIONS',
  INITATE_ORIGIN_PLACES: 'INSTANT_PRICING_INITATE_ORIGIN_PLACES',
  UPDATE_ORIGIN_PLACES: 'INSTANT_PRICING_UPDATE_ORIGIN_PLACES',
  INITATE_DESTINATION_PLACES: 'INSTANT_PRICING_INITATE_DESTINATION_PLACES',
  UPDATE_DESTINATION_PLACES: 'INSTANT_PRICING_UPDATE_DESTINATION_PLACES',
  UPDATE_ORIGIN_LOCATION: 'INSTANT_PRICING_UPDATE_ORIGIN_LOCATION',
  UPDATE_DESTINATION_LOCATION: 'INSTANT_PRICING_UPDATE_DESTINATION_LOCATION',
  ERROR: 'INSTANT_PRICING_ERROR',
  UPDATE_DETAIL_ROW: 'INSTANT_PRICING_UPDATE_DETAIL_ROW',
  REMOVE_DETAIL_ROW: 'INSTANT_PRICING_REMOVE_DETAIL_ROW',
  ADD_DETAIL_ROW: 'INSTANT_PRICING_ADD_DETAIL_ROW',
  UPDATE_TOTAL_VALUES: 'INSTANT_PRICING_UPDATE_TOTAL_VALUES',
  GET_PRICES: 'INSTANT_PRICING_GET_PRICES',
  SHOW_HINTS: 'INSTANT_PRICING_SHOW_HINTS',
  UPDATE_VALUE: 'INSTANT_PRICING_UPDATE_VALUE',
  SET_SEARCH_FROM_HISTORY: 'INSTANT_PRICING_SET_SEARCH_FROM_HISTORY',
  INITATE_GOODS_ROWS: 'INSTANT_PRICING_INITATE_GOODS_ROWS',
  SHOW_ADDITIONAL_SERVICES: 'INSTANT_PRICING_SHOW_ADDITIONAL_SERVICES',
  CLEAR_ADDITIONAL_SERVICES: 'INSTANT_PRICING_CLEAR_ADDITIONAL_SERVICES',
  UPDATE_ADR_VALUE: 'INSTANT_PRICING_UPDATE_ADR_VALUE',
}