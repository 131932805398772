import { Dashboard } from '../Dashboard';
import { InstantPricing } from '../InstantPricing';
import { Bookings } from '../Bookings';
import { AddressBook } from '../AddressBook';
import Ready from '../_components/ShipmentInformationForm/Ready';
import Document from "_softUI/icons/Document";
import { LoginPage } from 'LoginPage/LoginPage';
import AdminMenu from 'Admin/AdminMenu';
import ManageRoles from 'Admin/ManageRoles';
import ManageCustomers from 'Admin/ManageCustomers';
import ManageUsers from 'Admin/ManageUsers';
import ManageCarriers from 'Admin/ManageCarriers';
import ManageCalculators from 'Admin/ManageCalculators';
import ManageChangingCosts from 'Admin/ManageChangingCosts';
import ManageInvoicePatches from 'Admin/ManageInvoicePatches';
import ImportCustomers from 'Admin/ImportCustomers';
import {ConfirmEmail} from '../ConfirmEmail'
import SuiButton from '_softUI/components/SuiButton';
import {InstantBookingProcess} from 'InstantBookingProcess';
import { ShipmentInformationFormActions } from '_actions';
import {SpotRequests} from '../SpotRequests';
import { SpotRequestsCarrier } from 'SpotRequestsCarrier/SpotRequestsCarrier';
import { ConfirmBooking } from '../ConfirmBooking';
import { UserProfile } from 'UserProfile';
import { SpotQuoteProcess } from 'SpotQuoteProcess';
import { HowItWorks } from 'LoginPage/HowItWorks';
import { AboutUs } from 'LoginPage/AboutUs';
import { FAQ } from 'LoginPage/FAQ';
import EventLogs from 'Admin/EventLogs';
import { FI } from 'LoginPage/FI';
import { SE } from 'LoginPage/SE';



const routes = [

 
    { type: "title", title: "Instant Pricing", key: "instant-title", route:"",requiredRoles:[] },

    {
        type: "collapse",
        name: "Instant Pricing",
        routeKey: "",
        route: "/",
        icon: <Document size="12px" />,
        private: true,
        component: InstantPricing,
        noCollapse: true,
        requiredRoles: ["Customer"],
        
    },
    {
        type:"collapse",
        name: "Dashboard",
        routeKey: "Dashboard",
        icon: <Document size="12px" />,
        route: "/Dashboard",
        private: true,
        component: Dashboard,
        noCollapse: true,
        requiredRoles: ["Customer"],
        

    },
    {
        type: "collapse",
        name: "Bookings",
        routeKey: "Bookings",
        route: "/Bookings",
        icon: <Document size="12px" />,
        private: true,
        component: Bookings,
        noCollapse: true,
        requiredRoles: ["Customer"],
        
    },
    { type: "title", title: "Spot requests", key: "spot-title", route:"",requiredRoles:['SpotCustomer'] },
    {
        type: "collapse",
        name: "New Spot Request",
        routeKey: "SpotQuoteProcess",
        route: "/SpotQuoteProcess",
        icon: <Document size="12px" />,
        component: SpotQuoteProcess,
        private: true,
        noCollapse: true,
        requiredRoles: ['SpotCustomer'],
    },
    {
        type: "collapse",
        name: "Spot requests",
        routeKey: "SpotRequests",
        route: "/SpotRequests",
        icon: <Document size="12px" />,
        component: SpotRequests,
        private: true,
        noCollapse: true,
        requiredRoles: ['SpotCustomer'],
    },
    { type: "title", title: "Carrier", key: "carrier-title", route:"",requiredRoles:['Carrier'] },
    {
        type: "collapse",
        name: "Spot requests",
        routeKey: "SpotRequestsCarrier",
        route: "/SpotRequestsCarrier",
        icon: <Document size="12px" />,
        component: SpotRequestsCarrier,
        private: true,
        noCollapse: true,
        requiredRoles: ['Carrier'],
    },
    { type: "title", title: "Admin", key: "admin-title", route:"",requiredRoles:['Admin'] },
    {
        type: "collapse",
        name: "Admin",
        routeKey: "Admin",
        route: "/Admin",
        icon: <Document size="12px" />,
        private: true,
        component: AdminMenu,
        noCollapse: true,
        requiredRoles: ['Admin'],
        
    },
    {
        type: "collapse",
        name: "Admin/ManageRoles",
        routeKey: "Admin/ManageRoles",
        route: "/Admin/ManageRoles",
        icon: <Document size="12px" />,
        private: true,
        component: ManageRoles,
        noCollapse: true,
        hidden:true,
        requiredRoles: ['Admin'],
    },
    {
        type: "collapse",
        name: "Admin/ManageCustomers",
        routeKey: "Admin/ManageCustomers",
        route: "/Admin/ManageCustomers",
        icon: <Document size="12px" />,
        private: true,
        component: ManageCustomers,
        noCollapse: true,
        hidden:true,
        requiredRoles: ['Admin'],
    },
    {
        type: "collapse",
        name: "Admin/ManageUsers",
        routeKey: "Admin/ManageUsers",
        route: "/Admin/ManageUsers",
        icon: <Document size="12px" />,
        private: true,
        component: ManageUsers,
        noCollapse: true,
        hidden:true,
        requiredRoles: ['Admin'],
    },
    {
        type: "collapse",
        name: "Admin/ManageCarriers",
        routeKey: "Admin/ManageCarriers",
        route: "/Admin/ManageCarriers",
        icon: <Document size="12px" />,
        private: true,
        component: ManageCarriers,
        noCollapse: true,
        hidden:true,
        requiredRoles: ['Admin'],
    },
    {
        type: "collapse",
        name: "Admin/ManageInvoicePatches",
        routeKey: "Admin/ManageInvoicePatches",
        route: "/Admin/ManageInvoicePatches",
        icon: <Document size="12px" />,
        private: true,
        component: ManageInvoicePatches,
        noCollapse: true,
        hidden:true,
        requiredRoles: ['Admin'],
    },
    {
        type: "collapse",
        name: "Admin/ImportCustomers",
        routeKey: "Admin/ImportCustomers",
        route: "/Admin/ImportCustomers",
        icon: <Document size="12px" />,
        private: true,
        component: ImportCustomers,
        noCollapse: true,
        hidden:true,
        requiredRoles: ['Admin'],
    },
    {
        type: "collapse",
        name: "Admin/ManageCalculators",
        routeKey: "Admin/ManageCalculators",
        route: "/Admin/ManageCalculators",
        icon: <Document size="12px" />,
        private: true,
        component: ManageCalculators,
        noCollapse: true,
        hidden:true,
        requiredRoles: ['Admin'],
    },
   
    {
        type: "collapse",
        name: "Admin/ManageChangingCosts",
        routeKey: "Admin/ManageChangingCosts",
        route: "/Admin/ManageChangingCosts",
        icon: <Document size="12px" />,
        private: true,
        component: ManageChangingCosts,
        noCollapse: true,
        hidden:true,
        requiredRoles: ['Admin'],
    },
    {
        type: "collapse",
        name: "Admin/EventLogs",
        routeKey: "Admin/EventLogs",
        route: "/Admin/EventLogs",
        icon: <Document size="12px" />,
        private: true,
        component: EventLogs,
        noCollapse: true,
        hidden:true,
        requiredRoles: ['Admin'],
    },
    {
        type: "collapse",
        name: "InstantBookingProcess",
        routeKey: "InstantBookingProcess",
        route: "/InstantBookingProcess",
        icon: <Document size="12px" />,
        private: true,
        component: InstantBookingProcess,
        noCollapse: true,
        hidden:true,
        requiredRoles: ['Customer'],
    },

    {
        name: "Login",
        routeKey: "login",
        route: "/login",
        private: false,
        component: LoginPage,
        requiredRoles: [],
    }
    ,

    {
        name: "How it workd",
        routeKey: "howItWorks",
        route: "/howItWorks",
        private: false,
        component: HowItWorks,
        requiredRoles: [],
    }
    ,
    {
        name: "About us",
        routeKey: "aboutUs",
        route: "/aboutUs",
        private: false,
        component: AboutUs,
        requiredRoles: [],
    }
    ,
    {
        name: "FAQ",
        routeKey: "faq",
        route: "/faq",
        private: false,
        component: FAQ,
        requiredRoles: [],
    }
    ,
    {
        name: "FI",
        routeKey: "fi",
        route: "/fi",
        private: false,
        component: FI,
        requiredRoles: [],
    }
    ,
    {
        name: "SE",
        routeKey: "se",
        route: "/se",
        private: false,
        component: SE,
        requiredRoles: [],
    }
    ,
  

    {
        name: "ConfirmEmail",
        routeKey: "confirmEmail",
        route: "/confirmEmail",
        private: false,
        component: ConfirmEmail,
        requiredRoles: [],
    },

    {
        name: "ConfirmBooking",
        routeKey: "confirmBooking",
        route: "/confirmBooking",
        private: false,
        component: ConfirmBooking,
        requiredRoles: [],
    }
    ,

    {
        name: "UserProfile",
        routeKey: "userProfile",
        route: "/userProfile",
        private: true,
        component: UserProfile,
        requiredRoles: [],
        hidden:true,
    },
    {
        type: "collapse",
        name: "Address book",
        routeKey: "AddressBook",
        route: "/AddressBook",
        icon: <Document size="12px" />,
        private: true,
        component: AddressBook,
        noCollapse: true,
        requiredRoles: ["Customer"],
        
    },
    {
        name: "Ready",
        routeKey: "Ready",
        route: "/Ready",
        private: true,
        component: Ready,
        hidden: true,
        requiredRoles: ["Customer"],
    },


]


export default routes;