import React from 'react';

import Grid from '@mui/material/Grid';
import SuiTypography from "_softUI/components/SuiTypography";

export default function LegacyContainerBox(props) {

  return (




    <Grid
      display="flex"
      alignItems="center"
      position="relative"
      xs={12}
      container
      item
      mb={2}
      sx={{
        background: '#fff',
        width: '100%',
        ...props.sx
      }}
    >
      <Grid item container xs={12} spacing={2}  >
        <Grid item container xs={2}>
          <SuiTypography variant="h6" color="secondary" fontWeight="bold" textAlign="left" >
            {props.title}
          </SuiTypography>
        </Grid>
        <Grid container item xs={10} spacing={2}>
          {props.children}
        </Grid>

       
      </Grid>
   
    </Grid>

 
  )
}