import React, { useRef, useState  }  from 'react';
import Grid from '@mui/material/Grid';
import FormControl from '@mui/material/FormControl';
import Select, {createFilter} from 'react-select';
import WindowedSelect from "react-windowed-select";
import FormHelperText from '@mui/material/FormHelperText';
import { useDispatch, useSelector } from 'react-redux';
import { instantPricingActions } from '../../_actions';
import ReactCountryFlag from "react-country-flag"
import SuiTypography from "_softUI/components/SuiTypography";
import InputWithLabel from "_components/FormFields/InputWithLabel";
import SuiInput from "_softUI/components/SuiInput";
import Tooltip from '@mui/material/Tooltip';
import GoogleAutoComplate from '_components/FormFields/GoogleAutoComplate'
import { GooglePlaces } from '_helpers/google';
import { GoogleGetDetails } from '_helpers/google';
import { GetStateFromPlace } from '_helpers/google';







const locationBorder = (state,hint) => ({
 
  border: state.isFocused ? '1px solid #d1d5ce' : hint ? '1px solid #fd5c70 ' :'1px solid #d1d5ce',

  borderRadius: '4px !important',
  boxShadow: 'none', 
  height:'1.2em !important',
  fontWeight: '400',
  fontSize: '0.95em !important',

  borderColor: "#c4c4c4",
  "&:hover": {
    borderColor: state.isFocused ? "#d1d5ce" : "#c4c4c4",
    boxShadow: 'inset 0 0 0 1px #D1D5CE',
    border: '1px solid #D1D5CE'
  },
});




const stylesCountry = (hint) => ({

  control: (styles,state) => ({ ...styles, ...locationBorder(state,hint)}),
  input: (styles) => ({
    ...styles,
    textAlign: 'center', // Center the text horizontally
    padding: '0 !important',
  }),
  option: (styles, state) => ({
    backgroundColor: state.isFocused ? '#d1d5ce' : 'transparent',
    borderRadius: '5px',
    padding: '5px 0px 5px 8px',
    fontWeight: 400
  }),
});





export function SearchLocation({ title, selectedCountry, selectedLocation, locationOptions, hint, regex, zipExample}) {

  const dispatch = useDispatch()
  const state = useSelector(state => state.instantPricing)
  const countryElement = useRef();
  const locationElement = useRef();

  const [opt1,setOpt1] = useState([])

  const optionsCountry = state.countries.map(x => (
    {
      value: x.alpha2,
      label: <React.Fragment key={x.name}><span><ReactCountryFlag svg  countryCode={x.alpha2} width="20" /></span><span style={{marginLeft:'10px'}}>{x.name}</span></React.Fragment>
     
    }
  ))

  const optionsLocation = locationOptions.map(x => (
    {
      value: x.code,
      label: x.code
    }))

  const locationsEmpty = optionsLocation.length === 0

  const filterOptions = (candidate, input) => {

    if (input) {
      console.log(candidate)
      console.log(input)
      return candidate.value.toLowerCase().includes(input.toLowerCase()) || candidate.label.key.toLowerCase().includes(input.toLowerCase());
    }
    return true;
  };

  function handleCountryChange(event){

    dispatch(instantPricingActions.changeCountry(event,title))

    setOpt1([])
    
    }



    const onCountryInputChange = (
      inputValue,
      { action, prevInputValue }
    ) => {
  
      let aa = countryElement.current.state.focusedOption
    
      if (action === 'input-change') return inputValue;

      if(action === 'set-value') {

        dispatch(instantPricingActions.changeCountry(aa, title))
        return inputValue
      }

      if (action === 'menu-close') {

        dispatch(instantPricingActions.changeCountry(aa, title))
        return inputValue
     
      }
      return inputValue;
    };

    const onLocationInputChange = (
      inputValue,
      { action, prevInputValue }
    ) => {

      let aa = locationElement.current.state.focusedOption
      
      console.log(aa)
      if (action === 'input-change') return inputValue;

      if(action === 'set-value') {

        dispatch(instantPricingActions.changeLocation(aa, title))
        return inputValue
      }
      if (action === 'menu-close') {

        dispatch(instantPricingActions.changeLocation(aa, title))
        return inputValue
 
      }
      return inputValue;
    };







    function handleChange(event){


      const displaySuggestions = function (predictions, status) {

        console.log(predictions);
        if(predictions !== null) {
          setOpt1(predictions.map(x=> {

            const secondary = x.structured_formatting.secondary_text.split(',')
            console.log(x)
  
            return {'label':x.structured_formatting.main_text + " " + secondary[0], 'value':x.structured_formatting.main_text , 'city':secondary[0], google_id: x.place_id
          }
          }));
        }
        else{
          setOpt1([])
        }
       

    
      };

      if(event.target.value !== ''){
        GooglePlaces(event.target.value,selectedCountry.value,displaySuggestions)
      }
      else{
        setOpt1([])
      }
      
     

      }
  


function onGoogleSelect(event){

  dispatch(instantPricingActions.changeLocation(event, title))
  const callback =  function (place, status) {
    if (status == window.google.maps.places.PlacesServiceStatus.OK) {
      console.log(place)

      const province = place.address_components.find(GetStateFromPlace)

      const modifiedEvent = {
        ...event,
        state: province?.short_name
      }
      dispatch(instantPricingActions.changeLocation(modifiedEvent, title))
    }
    else{
      dispatch(instantPricingActions.changeLocation(event, title))
    }
  }
  console.log('test',event)
  if(event  !== null && event.google_id !== null){
    GoogleGetDetails(event.google_id,callback)
  }


}

  function handleLocationChange(event) {

    // const aa = {value:event.label,label:event.label}
    console.log(event)
    dispatch(instantPricingActions.changeLocation({'value':event,'label':event}, title))
  }

  return (
  
    <Grid container justifyItems="stretch" spacing={1} sx={{mt:'1.5rem'}}>
      <Grid item xs={12} md={6}>
 



        <FormControl  sx={{
          width:'100%'
          
          }}>
          <FormHelperText sx={{
            mb:1,
            ml: 0,
            borderTopLeftRadius:5,
            textAlign:'left',
            
            }}> 
          <SuiTypography  component="label"
          variant="caption"
          fontWeight='light'

          textTransform="capitalize">Country of {title}</SuiTypography>
          </FormHelperText>
          <Select 
    ref={countryElement}
          options={optionsCountry} 
          menuPortalTarget={document.body} 
          components={{IndicatorSeparator:() => null }}
          filterOption={filterOptions}
          value={selectedCountry} 
          onChange={e => handleCountryChange(e)} 
          // styles={stylesCountry} 
          isLoading={state.loading}
          onInputChange={onCountryInputChange}
          // sx={{height:'40px',}}
      tabSelectsValue={true}
      blurInputOnSelect={true}
      styles={stylesCountry(false)} 
          />

         
       
        </FormControl>
      </Grid>
      <Grid item xs={12} md={6}>
        <FormControl sx={{
          width:'100%',

          }}>
    
             <FormHelperText sx={{

              mb:1,
              ml: 0,
              textAlign:'left',
              }}>  <SuiTypography component="label"
          variant="caption"
          fontWeight='light'
          
              
          // sx={{paddingLeft:"0.5rem"}}
          textTransform="capitalize">Postal code of  {title} </SuiTypography></FormHelperText>
          {!locationsEmpty
          &&
          <WindowedSelect 
           ref={locationElement}
           components={{IndicatorSeparator:() => null }}
          isClearable={false} 
          filterOption={createFilter({ignoreAccents: false})}
          options={optionsLocation} 
          menuPortalTarget={document.body} 
          value={selectedLocation} 
          onChange={e => handleLocationChange(e)} 
          styles={stylesCountry(hint)} 
          onInputChange={onLocationInputChange}
          
          isLoading={state.loading}
          // sx={{height:'140px',borderColor:'red'}}
          tabSelectsValue={true}
          blurInputOnSelect={true}
       
          />}
         



          {locationsEmpty
            &&
            <GoogleAutoComplate lable="" value={selectedLocation?.value} onChange={e => handleChange(e)} onSelect={e => (onGoogleSelect(e))} options={opt1} ></GoogleAutoComplate>

            // <Tooltip sx={{backgroundColor:'red'}} title={"Please type zip code in correct official format! " +(zipExample !== '' ? "E.g. "+ zipExample : "") }>
            // <SuiInput
            //   sx={{ height: '38px!important', fontSize: '1.2rem', color: '#183B59', ml: 0, '.MuiTypography-root': { fontSize: '1.2rem', color: '#183B59' } }}
            //   onChange={e => handleLocationChange(e.target.value)}
            //   placeholder="Type zip code"
            //   value={selectedLocation.label ?? ''}
            //   error={hint}
            // />
            // </Tooltip>
          }



        </FormControl>
      </Grid>
    </Grid>

  )
}