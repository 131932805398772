import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Register from '_components/Register';
// import '_assets/landingPage/css/bootstrap.css'
// import '_assets/landingPage/css/main.css'
import Navigation from './Navigation'
import { Footer } from './Footer'
import LeftCircle from '_assets/landingPage/images/left-circle.svg'
import Star from '_assets/landingPage/images/star.svg'
import Stock3 from '_assets/landingPage/images/iStock3.jpg'
import Stock4 from '_assets/landingPage/images/iStock4.jpg'
import Stock2 from '_assets/landingPage/images/iStock4.jpg'
import Img from '_assets/landingPage/images/img.jpg'
import Shape4 from '_assets/landingPage/images/shape-4.svg'
import Logo from '_assets/landingPage/images/logo.png'

import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import Login from '_components/Login';

export function SE() {
  const dispatch = useDispatch();
  const [showRegister, setShowRegister] = useState(false)
  const [showLogin, setShowLogin] = useState(false)



  return (
    <div style={{ backgroundColor: '#fff', fontWeight:400, fontSize:'0.875rem', lineHeight:'1.8571' }}>
      <link href="_assets/landingPage/css/main.css" rel="stylesheet" />
      <link href="'_assets/landingPage/css/bootstrap.css" rel="stylesheet" />
      <Navigation showLogin={setShowLogin} showRegister={setShowRegister} active="" />


      <section class="landingBanner">
        <div class="container ">
          <div class="row d-flex align-items-center">
            <div class="col-12 ">
              <div class="text bannerText">
                <div class="text-holder">

                  <h1>Det enklaste sättet att jämföra, boka och spåra landsvägsfrakter i Europa. Gratis.</h1>
                  <p class="subText">FreightOpt är en gratis fraktbokningskanal som är baserad i Finland och expanderar till Sverige våren 2023. Sök och beställ från de mest lojala transportföretagen. Vi betjänar även på svenska.</p>
                </div>
              </div>
              <div class="col-12 ">
                <div class="cta-holder align-center">
                       
              <button  type="button" onClick={() => setShowRegister(true)} className="btn btn-secondary d-inline-flex" >    Registrera dig gratis
              <img src={LeftCircle} alt="" /></button>

                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section class="videoBanner">
        <div class="container">


          <div class="row" style={{"height": "100%;"}}>
            <div class="col-12 col-lg-6">
              <div class="video-container section-class" style={{"height": "100%;"}}>
                <div class="video-wrapper">
                  <iframe src="https://player.vimeo.com/video/820205712?h=7d0b25189e" width="640" height="564" frameborder="0" allow="autoplay; fullscreen" allowfullscreen></iframe>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-6 videoList-wrapper">
              <div class="videoList-container margin-left-md ">
                <ul class="list" style={{"display": "grid;"}}>
                  <li class="videoListItem"><span style={{"fontWeight": "700"}}>Konkurrenskraftiga priser</span><br />Inom Europa från pålitliga transportföretag.</li>
                  <li class="videoListItem"><span style={{"fontWeight": "700"}}>En plattform</span><br />Lättanvänd. Sök, boka och spåra frakter.</li>
                  <li class="videoListItem"><span style={{"fontWeight": "700"}}>Enkel beställning</span><br/>Flera alternativ, beställ direkt från systemet.</li>
                </ul>
                <div class="col-12 row videoList-btn-row" style={{"height": "100%;"}}>
                  <div class="col-md-12 col-lg-6 videoList-btn">
                    <div>


<button  type="button" onClick={() => setShowRegister(true)} className="btn btn-secondary d-inline-flex" >    Prova gratis
              <img src={LeftCircle} alt="" /></button>

                    </div>
                  </div>
                  <div class="col-md-12 col-lg-6 text-center-sm video-cta-col">
                    <span class="video-cta">Anslut dig nu till mer än 3000+ användare!</span>
                  </div>

                </div>
              </div>
            </div>
          </div>

          </div>


      </section>


      <section class="appointment-holder" style={{"padding": "50px"}}>
        <div class="container">
          <div class="align-center head">
            <h1>Se priset på transporten direkt gratis</h1>
          </div>

        </div>
      </section>

      <div class="details-holder process">
        <div class="container">
          <div class="row d-flex align-items-center">

            <div class="col-12 col-lg-6 order-lg-2 details-order-2">
              <div class="text">
                <div class="text-block margin-left-md" style={{"margin-right": "0px !important;"}} >
                  <h1>Perfekt verktyg för små och medelstora företag</h1>
                  <p>Vår plattform betjänar bäst små och medelstora företag, där internationella frakter kanske inte rör sig helt varje dag. Vi erbjuder en konkurrenskraftig prisnivå oavsett storleken på ditt företag. Vi erbjuder även en mycket lättanvänd plattform för Europas landsvägstransporter. Priserna är färdigt jämförda, utan dolda kostnader.</p>
                </div>



<button  type="button" onClick={() => setShowRegister(true)} className="btn btn-primary btn-dark d-inline-flex margin-left-md" >    Registrera dig nu
              <img src={LeftCircle} alt="" /></button>

                

              </div>
            </div>
            <div class="col-12 col-lg-6 order-lg-1 details-order-1">
              <div class="img-holder">
                <img src={Stock2} alt=""/>
              </div>
            </div>
          </div>
          <div class="row d-flex align-items-center">
            <div class="col-12 col-lg-6 order-lg-2 details-order-3">
              <div class="img-holder">
                <img src={Img} alt=""/>
              </div>
            </div>
            <div class="col-12 col-lg-6 order-lg-1 details-order-4">
              <div class="text">
                <div class="text-block">
                  <h1>Användningen är gratis, du betalar endast för transporten</h1>
                  <p>Det är kostnadsfritt att använda sig av FreightOpt och du betalar endast för de slutförda transportordnarna. Vi jämförpriserna för dig färdigt, så att du lätt kan välja det bästa alternativet. Sök, jämför, boka och spåra transporten, allt på ett och samma ställe.</p>
                </div>


<button  type="button" onClick={() => setShowRegister(true)} className="btn btn-primary btn-dark d-inline-flex" >    Registrera dig nu
              <img src={LeftCircle} alt="" /></button>

              </div>
            </div>
          </div>
        </div>
      </div>
      <section class="reference-banner">
        <div class="container">
          <div class="head" style={{"margin-bottom":"50px;"}}>
            <h2 style={{"color": "#D1D5CE"}}>"FreightOpt är ett bra alternativ för företag med mycket export och import.
              <br/>
              Systemet är lätt och snabbt att använda och alla offerter finns på ett och samma ställe.
                <br/>
                Ett bra sätt att spara tid, pengar och nerver."</h2>
                <p style={{"color": "white"}}>- Amanda Nygård, Finn-Korkki Oy</p>
              </div>
              <div class="stars-wrapper">
              <img src={Star} alt="" />
              <img src={Star} alt="" />
              <img src={Star} alt="" />
              <img src={Star} alt="" />
              <img src={Star} alt="" />
     
                        </div>
                      </div>

                    </section>
                    {/* <section>
                      <div class="callToAction">
                        <div>
                          <a href="/contact.aspx" class="btn btn-dark cta-btn">Haluatko kuulla lisää? Jätä yhteystietosi tästä</a>
                        </div>
                      </div>
                    </section> */}
                    <section class="offer-holder">
                      <div class="container">
                        <div class="row d-flex align-items-center">
                          <div class="col-12 col-lg-6">
                            <div class="signup-holder">
                              <h1>Börja gratis nu</h1>
                              <ul class="list">
                                <li>Lätt och snabb registrering. Du ser priserna och kan beställa första transporten på bara några minuter.</li>
                                <li>Få tillgång till alla avtalspriser från transportföretag som FreightOpt har att erbjuda.</li>
                                <li>Du betalar endast för frakten, användning av tjänsten är helt gratis.</li>
                                <li>Hantera hela processen från fraktdokument till uppföljning på samma plattform.</li>

                              </ul>
                              <div class="logo">
                                <a href="#">
                                  <img src={Logo} alt=""/>
                                </a>
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-lg-6">
                            <h1>Hantera hela processen lätt</h1>
                            <p>Med hjälp av FreightOpt kan du dela alla nödvändiga fraktdokument och motsvarande information lätt via systemet. Hantera hela transporten från erbjudande till leverans enkelt.</p>

                            <p>Du kan kontrollera din sändningshistorik och prisernas utveckling genom fliken bookings.</p>
                            {/* <asp:LinkButton ID="LinkButton5" runat="server" class="btn btn-primary d-inline-flex" data-toggle="modal" data-target="#registerModal" Text="Rekisteröidy nyt" OnClitClick="return false" ClientIDMode="static" OnClick="LinkButton1_Click">
                              <asp:Image runat="server" ImageUrl="../../Content/LandingPage/images/left-circle.svg" />

                            </asp:LinkButton> */}
<button  type="button" onClick={() => setShowRegister(true)} className="btn btn-primary btn-dark d-inline-flex" >    Registrera dig nu
              <img src={LeftCircle} alt="" /></button>
                          </div>
                        </div>
                      </div>
                    </section>


                    <section class="price-holder">
                      <div class="container">
                        <div class="text">
                          <div class="head text-center">
                            <h1>Förbättra din konkurrenskraft</h1>
                          </div>
                          <p>Fyll i transportinformationen i systemet så erbjuder vi omedelbart de bästa alternativen för din transport. Du kan beställa frakt direkt, enkelt och snabbt.</p>

                          <p>Ett flertal alternativ säkerställer, att priserna är konkurrenskraftiga på så många områden som möjligt.</p>
                          <p>Budning på sändningar ger tydliga besparingar och effektiviserar din företagsverksamhet.</p>
                          <ul class="timeline">
                            <li>
                              <span class="count">1</span>
                              <strong style={{"fontSize": "1.2em"}}>Fraktinformation</strong>
                            </li>
                            <li>
                              <span class="count">2</span>
                              <strong style={{"fontSize": "1.2em"}}>Välj en tjänsteleverantör</strong>
                            </li>
                            <li>
                              <span class="count">3</span>
                              <strong style={{"fontSize": "1.2em"}}>Försändelse-<br/>information</strong>
                            </li>
                            <li>
                              <span class="count">4</span>
                              <strong style={{"fontSize": "1.2em"}}>Bekräfta</strong>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </section>




                    <Footer />

                    <Register show={showRegister} hide={setShowRegister} />
                    <Login show={showLogin} hide={setShowLogin} />





                  </div>
                  );

}


