import React from 'react';
import Grid from '@mui/material/Grid';
import { ShipmentInformationFormActions } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';

import InputWithLabel from "_components/FormFields/InputWithLabel";




export function AdditionalInformation({ instant }) {

    const stateParent = useSelector(state => state.ShipmentInformationForm)
    const state = stateParent.data

    const dispatch = useDispatch()
    function handleChange(event) {
        dispatch(ShipmentInformationFormActions.updateBasicValue(event.target.name, event.target.value));
    }




    return (




        <Grid sx={{}} item xs={12} container spacing={2} justifyContent="space-evenly" alignItems="stretch" >


            <Grid item container xs={12}>
            <Grid item xs={12} >
                    <InputWithLabel
                      id={"additionalInformation"}
                    //   label={"Additional information"}
                      multiline
                      rows={4}
                      label=""
                      value={state.additionalInformation}
                      placeholder={"Additional information that carriers should consider when quoting the request"}
                      name={"additionalInformation"} onChange={e => handleChange(e)}

                    />
                  </Grid>

            </Grid>
        </Grid>







    )
}