import React from 'react';
import PropTypes from "prop-types";
import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";
import SuiInput from "_softUI/components/SuiInput";
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';

import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import AccessTimeIcon from '@mui/icons-material/AccessTime';

import { useRef } from 'react'

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: 'red',
        color: 'black',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));

function TimeFieldWithLabel({ label, adornment, adornmentPosition, startAdornment, fontWeight, mandatory, setDefault, adornmentSize, ...rest }) {

    const inputRef = useRef()

    const direction = adornmentPosition === "end" ? "right" : "left"
    return (
        <>
            {label &&
                <SuiBox mb={0.25} ml={0} lineHeight={0} sx={{ marginBottom: '0rem' }} display="inline-block">






                    <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight='light'
                        textTransform="capitalize"

                    >
                        {label} {mandatory && <SuiTypography
                            component="label"
                            variant="caption"
                            fontWeight='light'
                            textTransform="capitalize"
                            sx={{ color: '#fd5c70' }}

                        >
                            *
                        </SuiTypography>}
                    </SuiTypography>
                </SuiBox>


            }

            <LocalizationProvider dateAdapter={AdapterDayjs} >
            {/* <TimeField defaultValue={moment(null, 'HH:mm')} format={'HH:mm'} /> */}
            <TimeField
                {...rest}
                ampm={false}
                sx={{ height: '1.2em', fontSize: '1.2rem', color: '#183B59', ml: 0, '.MuiTypography-root': { fontSize: '1.2rem', color: '#183B59' }, paddingRight: '10px !important' }}
                slotProps={{
                    clearButton: {
                        
                    },
                    textField: {
                      InputProps: {
                        [`${adornmentPosition}Adornment`]: adornment ? (
                          <InputAdornment
                            position={adornmentPosition}
                            sx={{
                              height: '1.1em',
                              fontSize: '1.2rem',
                              color: '#183B59',
                              marginLeft: '-15px !important',
                              '.MuiTypography-root': {
                                fontSize: adornmentSize ? adornmentSize : '1.1rem',
                                color: '#183B59',
                              },
                              // '.MuiInputBase-input-MuiOutlinedInput-input': {minWidth: '70%'}
                            }}
                          >
                            {adornment}
                          </InputAdornment>
                        ) : null
                      },
                      sx: {
                        '.MuiInputBase-root.MuiOutlinedInput-root': {
                          paddingRight: '10px !important',
                          paddingTop:'0.4rem !important',
                          paddingBottom: '0.4rem !important'
                        },
                        '.MuiButtonBase-root.MuiIconButton-root': {
                            marginLeft: '-10px', 
                          },
                    },
                    },
                  }}
                inputRef={inputRef}
            />
            </LocalizationProvider>


        </>
    );
}

TimeFieldWithLabel.propTypes = {
    label: PropTypes.string.isRequired,
    inputRef: PropTypes.any,
};

export default TimeFieldWithLabel;