import React, { useState } from 'react';

import { useDispatch} from 'react-redux';
import { userConstants } from '../_constants';

import Register from '_components/Register';
import LeftCircle from '_assets/landingPage/images/left-circle.svg'

import Stock3 from '_assets/landingPage/images/iStock3.jpg'

import Shape4 from '_assets/landingPage/images/shape-4.svg'
import Logo from '_assets/landingPage/images/logo.png'
import '_assets/landingPage/css/bootstrap.css'
import '_assets/landingPage/css/main.css'




import Navigation from './Navigation';
import {Footer} from './Footer.jsx'


import Login from '_components/Login';

export function LoginPage() {
    const dispatch = useDispatch();
    const [showRegister, setShowRegister] = useState(false)
    const [showLogin, setShowLogin] = useState(false)

    function handleRegisterClick(value){
  
        setShowRegister(value)
        dispatch({type: userConstants.CLEAR} )
    }




    return (
        <div style={{backgroundColor: '#fff'}}>
        <Navigation showLogin={setShowLogin} showRegister={setShowRegister}/>
            <section className="banner">
      <div className="container">
        <div className="text">
          <div className="text-holder">
            <div className="brand">
              <img src="images/brand.svg" alt="" />
            </div>
            <p>The easiest way to book freights with competitive prices.</p>
          </div>
          <div className="cta-holder align-right">
          <button  type="button" onClick={() => setShowRegister(true)} className="btn btn-secondary d-inline-flex" >   Book now for free
              <img src={LeftCircle} alt="" /></button>
            
          </div>
        </div>
      </div>
    </section>
    {/* appointment area of the page */}
    <section className="appointment-holder price">
      <div className="container">
        <div className="align-center head">
          <h1>Check your price for free </h1>
        </div>
        
      </div>
    </section>
    {/* clients area of the page */}
    <section className="clients-holder">
      <div className="container">
        <div className="details-holder">
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-6">
              <div className="img-holder">
                <img src={Stock3} alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="text center">
                <div className="text-block">
                  <h1>Get instant freight rates</h1>
                  <p>
                    Completely free of charge search engine and a booking portal
                    for European road freights. Get prices instantly and book
                    your freights. Make full use of FreightOpt contract prices!
                  </p>
                </div>
                <button  type="button" onClick={() => setShowRegister(true)} className="btn btn-secondary d-inline-flex" >   Book now for free
              <img src={LeftCircle} alt="" /></button>
              </div>
            </div>
          </div>
          <div className="row d-flex align-items-center">
            <div className="col-12 col-lg-6 order-lg-2">
              <div className="img-holder">
                <img src="images/img.jpg" alt="" />
              </div>
            </div>
            <div className="col-12 col-lg-6 order-lg-1">
              <div className="text">
                <div className="text-block">
                  <h1>One Platform</h1>
                  <p>
                    Comprehensive freight management with one system –
                    FreightOpt is a system for shippers to search, book and
                    track shipments. The system is browser-based and requires
                    zero installation. Savings achieved with multiple
                    transparent options, higher volumes and automation which
                    enables international competitiveness.
                  </p>
                </div>
                <button  type="button" onClick={() => setShowRegister(true)} className="btn btn-primary d-inline-flex" >       Register now
              <img src={LeftCircle} alt="" /></button>
           
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* price area of the page */}
    <section className="price-holder">
      <div className="container">
        <div className="text">
          <div className="head text-center">
            <h1>Get prices immediately</h1>
          </div>
          <p>
            Enter the shipping information in the system and FreightOpt provides
            you with the best shipping options available. You can book the
            shipment directly.
          </p>
          <ul className="timeline">
            <li>
              <span className="count">1</span>
              <strong>Cargo info</strong>
            </li>
            <li>
              <span className="count">2</span>
              <strong>Choose forwarder</strong>
            </li>
            <li>
              <span className="count">3</span>
              <strong>Details</strong>
            </li>
            <li>
              <span className="count">4</span>
              <strong>Confirm</strong>
            </li>
          </ul>
        </div>
      </div>
    </section>
    {/* features area of the page */}
    <section className="features-area">
      <div className="container">
        <div className="row d-flex justify-content-center">
          <div className="col-12 col-md-6 col-lg-4">
            <div className="box">
              <h1>Instant bookings</h1>
              <p>From multiple carriers in seconds</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="box prices">
              <h1>Competitive prices</h1>
              <p>Across Europe for trusted carriers</p>
            </div>
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <div className="box platform">
              <h1>One platform</h1>
              <p>Easy to use, for booking and tracking</p>
            </div>
          </div>
        </div>
      </div>
    </section>
    {/* booking area of the page */}
    <section className="booking-banner">
      <div className="container">
        <div className="head">
          <h1>Free online freight booking platform</h1>
        </div>
        <a href="#" className="btn btn-dark">
          Get a demo - we will tell you more{" "}
        </a>
      </div>
    </section>
    {/* competitiveness area of the page */}
    <section className="competitiveness-holder">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-8">
            <h1>Improved competitiveness</h1>
            <p>
              Having several options increases the security of your supply chain
              and ensures you can meet your customers’ needs.
            </p>
            <p>
              More efficient and very simple to use booking platform for your
              company’s needs.{" "}
            </p>
            <button  type="button" onClick={() => setShowRegister(true)} className="btn btn-primary d-inline-flex" >         Start now
              <img src={LeftCircle} alt="" /></button>
       
          </div>
          <div className="col-12 col-lg-4 col-sm-5">
            {/*<div class="col-12 col-lg-6 order-lg-2" class="mx-auto">*/}
            <div className="shape-holder">
              <img src={Shape4} alt="" />
            </div>
            {/*</div>*/}
          </div>
        </div>
      </div>
    </section>
    {/* offer area of the page */}
    <section className="offer-holder">
      <div className="container">
        <div className="row d-flex align-items-center">
          <div className="col-12 col-lg-6">
            <div className="signup-holder">
              <h1>Start now for free</h1>
              <ul className="list">
                <li>
                  Easy registration process. Gain access to prices in few
                  minutes.
                </li>
                <li>
                  Get access to all FreightOpt contract prices from multiple
                  carriers.{" "}
                </li>
                <li>Service is free to use, you only pay for your freights.</li>
                <li>
                  Manage the entire process. Shipping documents and tracking in
                  the portal.
                </li>
                <li>
                  Analyse your data easily. Reports available with an easy
                  export to excel.{" "}
                </li>
              </ul>
              <div className="logo">
                <a href="/">
                  <img src={Logo} alt="" />
                </a>
              </div>
            </div>
          </div>
          <div className="col-12 col-lg-6">
            <h1>Manage the entire process</h1>
            <p>
              FreightOpt allows you to share all necessary shipping documents
              and other information directly within the system.{" "}
            </p>
            <p>
              You can always view your booking history and compare shipping
              options. FreightOpt also produces analytics on your past shipping
              choices.
            </p>
     
            <button  type="button" onClick={() => setShowRegister(true)} className="btn btn-secondary d-inline-flex" >Register now
              <img src={LeftCircle} alt="" /></button>
          </div>
        </div>
      </div>
    </section>


    <Footer/>
            <Register show={showRegister} hide={setShowRegister} />
            <Login show={showLogin} hide={setShowLogin} />





        </div>
    );

}


