

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Collapse from "@mui/material/Collapse";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Icon from "@mui/material/Icon";
import Button from '@mui/material/Button';
// Soft UI Dashboard PRO React components
import SuiBox from "_softUI/components/SuiBox";
import Grid from '@mui/material/Grid';
import SuiTypography from "_softUI/components/SuiTypography";
// Custom styles for the SidenavCollapse
import {
    collapseItem,
    collapseIconBox,
    collapseIcon,
    collapseText,
    collapseArrow,
} from "_components/Layout/Sidenav/styles/sidenavCollapse";

// Soft UI Dashboard PRO React context
import { useSoftUIController } from "_softUI/context";

function TopnavItem({ color, icon, name, children, active, noCollapse, open, ...rest }) {
    const [controller] = useSoftUIController();
    const { miniSidenav, transparentSidenav } = controller;

    return (
        <>
            <ListItem component="li" sx={{marginLeft:'1rem', marginRight:'1rem'}}>

                <Grid container>
                    <Grid item xs={12}>
                        <SuiBox {...rest} sx={{ height: '100%', color: 'white', display: 'block' }}>


                            <ListItemText
                                primary={
                                    <>
                                      <SuiTypography
                                        sx={{ display: 'inline' }}
                                        component="span"
                                        variant="body2"
                                        color="dark"
                                      >
                                        {name}
                                      </SuiTypography>
                                   
                                    </>}
                                sx={{textAlign:'center',height:'60px', display:'flex', justifyContent:'center', alignItems:'center', fontSize:'1rem' }}
                            />
                        </SuiBox>
                        {active ? <div style={{ width: "100%", height:'2px', backgroundColor: "#183b59"}}>

</div> :
    <div style={{ width: "100%", height:'2px' }}>

    </div>


}
                    </Grid>
                    {/* <Grid item xs={12}>

                       

                    </Grid> */}

                </Grid>


            </ListItem>
            {children && (
                <Collapse in={open} unmountOnExit>
                    {children}
                </Collapse>
            )}
        </>
    );
}

// Setting default values for the props of SidenavCollapse
TopnavItem.defaultProps = {
    color: "info",
    active: false,
    noCollapse: false,
    children: false,
    open: false,
};

// Typechecking props for the SidenavCollapse
TopnavItem.propTypes = {
    color: PropTypes.oneOf(["primary", "secondary", "info", "success", "warning", "error", "dark"]),
    icon: PropTypes.node.isRequired,
    name: PropTypes.string.isRequired,
    children: PropTypes.node,
    active: PropTypes.bool,
    noCollapse: PropTypes.bool,
    open: PropTypes.bool,
};

export default TopnavItem;

