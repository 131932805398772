import { locationConstants } from '../_constants';
import { locationService } from '../_services';
import { userService } from '_services/userService';
import { alertConstants } from "_constants/alert.constants";

export const locationActions = {
    loadSavedLocations,
    create,
    initateSelectedLocation,
    updateLocationInputValue,
    initate,
    clearSelectedLocation,
    deleteSavedLocation,
    updateSavedLocation,
    showHints
};

function failurealert(message) { return { type: alertConstants.ERROR, message } }
function successalert(message) { return { type: alertConstants.SUCCESS, message } }

function loadSavedLocations() {
    return dispatch => {

        dispatch(loading());
        locationService.getAllSavedLocationsForUser()
            .then(
                data => {
                    dispatch(store(data));

                },
                error => {
                    console.log(error)
                    dispatch(failure(error));

                }
            )
    }

    function loading() { return { type: locationConstants.LOADING } }
    function store(data) { return { type: locationConstants.STORE, data } }
    function failure(error) { return { type: locationConstants.ERROR, error } }

}

function create(location, handleClose) {
    return dispatch => {
        dispatch(loading())

        locationService.createSavedLocation(location)
            .then(
                data => {

                    if (data.success) {
                        const loc = {
                            ...location,
                            id: data.id
                        }
                        dispatch(add(loc))
                        if (handleClose) {
                            handleClose()
                        }
                        dispatch(successalert('Saved!'))

                    } else {
                        dispatch(failure(data.message))
                    }

                },
                error => {
                    dispatch(failure(error))

                }
            )
    }

    function loading() { return { type: locationConstants.LOADING } }
    function add(data) { return { type: locationConstants.ADD, data } }
    function failure(error) { return { type: locationConstants.ERROR, error } }
}

function showHints() {
    return { type: locationConstants.SHOW_HINTS };
}

function initateSelectedLocation(id) {
    return { type: locationConstants.INITATE_SELECTED_LOCATION, id };
}

function updateLocationInputValue(name, value) {
    const data = { name, value }
    return { type: locationConstants.UPDATE_LOCATION_INPUT_VALUE, data };
}

function initate() {
    return dispatch => {
        /*         dispatch(request()); */

        userService.getShipmentFormDataForUser()
            .then(
                data => {
                    dispatch(success(data.allCountries));

                    /*               dispatch(changeCountry({ value: 'FI', label: <React.Fragment><span><Flag code={'FI'} width="20" /></span><span style={{ marginLeft: '10px' }}>Finland</span></React.Fragment>},'origin'));
                                  dispatch(changeCountry({ value: 'AT', label: <React.Fragment><span><Flag code={'AT'} width="20" /></span><span style={{ marginLeft: '10px' }}>Austria</span></React.Fragment> }, 'destination')); */
                },
                error => {
                    console.log('error')
                    /*               dispatch(failure(error));
                                  dispatch(alertActions.error(error)); */
                }
            )

        /*         function request() { return { type: instantPricingConstants.LOADING } } */
        function success(data) { return { type: locationConstants.UPDATE_COUNTRIES, data } }
        /*         function failure(error) { return { type: instantPricingConstants.ERROR, error } } */
    }
}

function clearSelectedLocation() {
    return { type: locationConstants.CLEAR_SELECTED_LOCATION };
}

function deleteSavedLocation(id, handleClose) {
    return dispatch => {

        dispatch(loading())
        locationService.deleteSavedLocation(id)
            .then(
                data => {
                    if (data.success) {
                        dispatch(deleteFromList(id));
                        dispatch(successalert('Deleted!'))
                        handleClose()
                    }
                    else {
                        dispatch(failurealert('Error, location was not saved'))
                    }
                },
                error => {
                    /*         dispatch(failure(error)); */
                }
            )
    }
    function loading() { return { type: locationConstants.LOADING } }
    function deleteFromList(data) { return { type: locationConstants.DELETE_SELECTED_LOCATION, data } }
}

function updateSavedLocation(model, handleClose) {
    return dispatch => {

        dispatch(loading());
        locationService.updateSavedLocation(model)
            .then(
                data => {
                    if (data.success) {
                        dispatch(updateList(model))
                        handleClose();
                        dispatch(successalert('Saved!'))


                    }
                    else {
                        console.log('failure!')
                        dispatch(failurealert('Error, information was not saved'))
                        /*                 dispatch(failure(data.message)) */
                    }
                },
                error => {
                    console.log('error!')
                    /*            dispatch(failure(error)); */

                }
            )
    }
    function loading() { return { type: locationConstants.LOADING } }
    function updateList(data) { return { type: locationConstants.UPDATE_LOCATION_LIST, data } }
}
