import {nullOrZeroOrEmptyOrError, nullEmptyOrError} from './helpers'

export function validateBasicInfo(input, isSpot = true) {

    console.log(isSpot)

    let vals = []
    let spotVals = []
    let instaVals = []
    let spotValues = {
      deadlineForQuotesError: nullEmptyOrError(input.deadlineForQuotes,spotVals),
      transportationTypeError: nullEmptyOrError(input.transportationType,spotVals),
      incotermError: nullEmptyOrError(input.incoterm,spotVals),
    }

    let instaIncoterm = {
      incotermError: nullEmptyOrError(input.incoterm,instaVals),
    }


    let newOutput = {
      ...input,
      pickupStartError: nullEmptyOrError(input.pickupStart,vals),

    }

    if(input.customsNeeded){
      newOutput = {
        ...newOutput,
        ...instaIncoterm
      }
      vals = vals.concat(instaVals)
    }

    if(isSpot){
      newOutput = {
        ...newOutput,
        ...spotValues
      }
      vals = vals.concat(spotVals)
    }

  

  
    let valid = !vals.includes(false)

    newOutput = {
      ...newOutput,
      valid: valid
    }
  
    console.log(vals)
    const output = {
      ...newOutput,
      valid: valid
    }
  
  
    return output
  }
