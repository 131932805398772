import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Loader from 'react-dots-loader'
import 'react-dots-loader/index.css'



export function Progress({text,size}) {
 
  return (
    <React.Fragment>
      <Grid container justifyContent="center" direction="column"
        alignItems="center">
  
        <Grid item xs textAlign="center">
          <Typography variant={size} >
            {text}
          </Typography>
          <Loader size={5}/>
          {/* <LinearProgress sx={{width:'100px'}}/> */}
     

        </Grid>
      </Grid>

    </React.Fragment>
  )

}