import * as React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { useSelector, useDispatch } from 'react-redux';
import { alertConstants } from '_constants';
import { alertActions } from '_actions';
import Fade from '@mui/material/Fade';
import { render } from '@testing-library/react';

// const Alert = React.forwardRef(function Alert(props, ref) {
//   return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
// });

export default function CustomAlert() {

  const state = useSelector(state => state.alert);
  const dispatch = useDispatch();
  const handleClose = () => {
    dispatch(alertActions.clear())
  }






  function alertHtml(type) {

    switch (type) {
      case 'success':

        return <MuiAlert severity="success" elevation={6} variant="filled">{state.message}</MuiAlert>
      case 'danger':
    
        return (<MuiAlert severity="error" elevation={6} variant="filled">{state.message}</MuiAlert>)
    
      default:
        break;

    }
  }


  return (
    <>
      {/* <Fade in={state.open}> */}
      <Snackbar open={state.open} autoHideDuration={3000} onClose={handleClose}>
       
          {alertHtml(state.type)}
     
      </Snackbar>
   
    </>
  )




}