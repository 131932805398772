import { customerSpotRequestTableConstants, spotRequestConstants, miscConstants } from '../_constants';
import { shipmentService, spotRequestService } from '../_services';
import { adminService } from '../_services';
import { alertConstants } from "_constants/alert.constants";
import { spotRequest } from '_reducers/spotRequest.reducer';

export const customerSpotRequestTableActions = {
    initate, 
    getShipmentById, 
    closeModal, 
    cancelSpot,
    setSelectedQuote,
    bookSpot,
    updateValue,
    saveFile,
    deleteFile,
    updateDeadline
};


function closeModal() {


    return dispatch => {
        dispatch(initate(1, 'update'));
        dispatch(close());


        function close() { return { type: customerSpotRequestTableConstants.CLOSE_MODAL } }

    }



}


function initate(type, loader) {
    return dispatch => {
        dispatch(request());

        shipmentService.getAllShipmentsForUser(type)
            .then(
                data => {
                    dispatch(success(data));

                },
                error => {
                    dispatch(failure(error));
                 
                }
            )

        function request() { return { type: customerSpotRequestTableConstants.LOADING, loader } }
        function success(data) { return { type: customerSpotRequestTableConstants.UPDATE_BOOKINGS_TABLE, data } }
        function failure(error) { return { type: customerSpotRequestTableConstants.ERROR, error } }

    }
}


function getShipmentById(id) {
    return dispatch => {
        dispatch(request(true));

        shipmentService.getShipmentById(id)
            .then(
                data => {
                    clear();
                    dispatch(success(data));

                },
                error => {
                    dispatch(failure(error));
                 
                }
            )

        function request(value) { return { type: customerSpotRequestTableConstants.MODAL_LOADING, value} }
        function clear() { return { type: customerSpotRequestTableConstants.CLEAR } }
        function success(data) { return { type: customerSpotRequestTableConstants.UPDATE_SELECTED_SHIPMENT, data } }
        function failure(error) { return { type: customerSpotRequestTableConstants.ERROR, error } }

    }
}


function updateDeadline(model) {
    return dispatch => {
        dispatch(request(true));

        shipmentService.updateDeadline(model)
        .then(
            data => {
                shipmentService.getShipmentById(model.id)
                .then(
                    data => {
                        clear();
                        dispatch(success(data));
    
                    },
                    error => {
                        dispatch(failure(error));
                     
                    }
                )
    

            },
            error => {
                dispatch(failure(error));
             
            }
        )

 
        function request(value) { return { type: customerSpotRequestTableConstants.MODAL_UPDATING, value} }
        function clear() { return { type: customerSpotRequestTableConstants.CLEAR } }
        function success(data) { return { type: customerSpotRequestTableConstants.UPDATE_SELECTED_SHIPMENT, data } }
        function failure(error) { return { type: customerSpotRequestTableConstants.ERROR, error } }

    }
}



function cancelSpot(id, shipmentId, value){
    return dispatch => {
        dispatch(loading(true));

        spotRequestService.cancelSpotRequest(id)
            .then(
                data => {


                    if(data.success){
                        dispatch(setCancelled(true));
                        dispatch(success('Cancelled!'));
                        dispatch(closeModal())
                        const data = { shipmentId, value }
                        dispatch(update(data));
                       
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                    }
                  

                },
                error => {
                    dispatch(failure(error));
                    dispatch(loading(false));
                 
                }
            )

        function failure(message) { return { type: alertConstants.ERROR, message } }
        function success(message) { return { type: alertConstants.SUCCESS, message } }
        function loading(value = true) { return { type: customerSpotRequestTableConstants.MODAL_LOADING, value } }
        function setCancelled(value = true) { return { type: customerSpotRequestTableConstants.SET_CANCELLED, value } }
        function update(data) { return { type: customerSpotRequestTableConstants.UPDATE_TABLE_VALUE, data }}
    }

}

function setSelectedQuote(id){
    return {type: customerSpotRequestTableConstants.SET_SELECTED_QUOTE, id}
}

function bookSpot(id, shipmentId, value){
    return dispatch => {
        dispatch(loading(true));

        spotRequestService.bookSpot(id)
            .then(
                data => {


                    if(data.success){
                        
                        dispatch(success('Booked!'));
                        dispatch(loading(false));
                        dispatch(closeModal())
                        const data = { shipmentId, value }
                        dispatch(update(data));
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                    }
                  

                },
                error => {
                    dispatch(failure(error));
                    dispatch(loading(false));
                 
                }
                
            )

        function failure(message) { return { type: alertConstants.ERROR, message } }
        function success(message) { return { type: alertConstants.SUCCESS, message } }
        function loading(value = true) { return { type: customerSpotRequestTableConstants.MODAL_LOADING, value } }
        function update(data) { return { type: customerSpotRequestTableConstants.UPDATE_TABLE_VALUE, data }}
    }
}




function updateValue(name, value) {


    return dispatch => {

      const data = { name, value }
      dispatch(update(data));
  
    }
  
    function update(data) { return { type: customerSpotRequestTableConstants.UPDATE_VALUE, data }}
   
  
  }

  function saveFile(file) {
    return dispatch => {
        dispatch(loading(true));

        shipmentService.saveFile(file)
            .then(
                data => {
                    if(data.success){
                        dispatch(getShipmentById(file.bookingId))
                        dispatch(success('Saved!'));
                        dispatch(loading(false));
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                    }
                  

                },
                error => {
                    dispatch(failure(error));
                    dispatch(loading(false));
                 
                }
            )

        function failure(message) { return { type: alertConstants.ERROR, message } }
        function success(message) { return { type: alertConstants.SUCCESS, message } }
        function loading(value = true) { return { type: customerSpotRequestTableConstants.MODAL_LOADING, value } }

    }
}




function deleteFile(id,bookingId) {
    return dispatch => {
        dispatch(loading(true));
        shipmentService.deleteFile(id)
            .then(
                data => {
                    if(data.success){
                        dispatch(getShipmentById(bookingId))
                        dispatch(success('Deleted!'));
                        dispatch(loading(false));
                    }
                    else{
                        dispatch(failure(data.message))
                        dispatch(loading(false));
                    }
                  

                },
                error => {
                    dispatch(failure(error));
                    dispatch(loading(false));
                 
                }
            )

        function failure(message) { return { type: alertConstants.ERROR, message } }
        function success(message) { return { type: alertConstants.SUCCESS, message } }
        function loading(value = true) { return { type: customerSpotRequestTableConstants.MODAL_LOADING, value } }

    }
}
