import React, { useEffect, useState } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiBox from "_softUI/components/SuiBox";
import DashboardNavbar from '_components/Layout/DashboardNavbar';
import DataTable from '_components/Tables/DataTable';
import Card from "@mui/material/Card";
import DashboardLayout from "_components/Layout/DashboardLayout";
import Grid from '@mui/material/Grid';

import InputWithLabel from '_components/FormFields/InputWithLabel';
import Button from '@mui/material/Button';
import SuiButton from "_softUI/components/SuiButton";
import { adminActions } from '_actions/admin.actions';

export default function EventLogs() {
    const dispatch = useDispatch();
    const state = useSelector(state => state.admin);




    useEffect(()=>{
        dispatch(adminActions.getEventLogs())

    },[])

    const data = {
        columns: [
            { Header: "Created", accessor: "created" },
            { Header: "Type", accessor: "type" },
            { Header: "Event", accessor: "event" },
            { Header: "Details", accessor: "details" },
        ],
        rows: state.importableCustomers.map(x => (
            {

                created: x.created,
                type: x.type,
                event: x.event,
                details: x.details
            }))
    }



    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start" sx={{ height: '100%' }}>

     

            <Grid container item xs={12} alignItems="strecth" justifyContent="center">
                <Card sx={{ overflow: "visible", p: 3 }}>
                    <SuiBox>
                        <SuiTypography variant="h5">Event Logs</SuiTypography>
                      
                    </SuiBox>
                    <SuiBox mt={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>

                                        <DataTable canSearch table={data} />
                                    </Grid>
                                </Grid>

                            </SuiBox>
                </Card>
            </Grid>
        
        </Grid>
    )

}