import React, { useEffect, useMemo } from 'react';
import { customerSpotRequestTableActions } from '../_actions';
import { useSelector, useDispatch } from 'react-redux';

import DataTable from '_components/Tables/DataTable';

import Grid from '@mui/material/Grid';
import { Paper } from '@mui/material';
import { ShipmentDisplayModal } from '_components/ShipmentDisplayModal/ShipmentDisplayModal';
import {Id,  Location,  BookedPrice, SpotStatus, SpotType,Deadline} from '_components/BookingsTable/CellFunctions'


export function SpotRequests() {

  const state = useSelector(state => state.customerSpotRequestTable)
  console.log('state', state)
  const dispatch = useDispatch()
  useEffect(() => {

    const loadtype = state.data.rows.length === 0 ? 'initial': 'update'
    dispatch(customerSpotRequestTableActions.initate(1, loadtype)); // ShipmentTypeEnum.SpotRequest

  }, [])

 

  const handleRowClick = (id) => {

    dispatch(customerSpotRequestTableActions.getShipmentById(id));
  }

  const tableData = {
 
    columns: [
        { Header: "id",width: '58px',  accessor: "id", Cell: (props) => {return (<Id id={props.row.original.id} date={props.row.original.bookingDate} />) } },
        { Header: "type", width: '80px', accessor: "type", Cell: (props) => {return (<SpotType justifyContent="center" alignItems="center" type={props.row.original.type} />) }},
        { Header: "reference", accessor: "reference" },
        { Header: "pickup", accessor: (x) => {return (x.pickupCity + " " + x.pickupCountry + " " + x.pickupCompany)}, Cell: (props) => {return (<Location city={props.row.original.pickupCity} country={props.row.original.pickupCountry} name={props.row.original.pickupCompany}/>) }},
        { Header: "delivery", accessor:  (x) => {return (x.deliveryCity + " " + x.deliveryCountry + " " + x.deliveryCompany)}, Cell: (props) => {return (<Location city={props.row.original.deliveryCity} country={props.row.original.deliveryCountry} name={props.row.original.deliveryCompany}/>) }},
        { Header: "Quotes",width: '100px',  accessor: "numberOfQuotes" },
        { Header: "best Quote", accessor: "bestQuote", Cell: (props) => {return (<BookedPrice price={props.row.original.bestQuote} />) }},
        { Header: "Deadline", accessor: "deadlineForQuotes",Cell: (props) => {return (<Deadline deadline={props.row.original.deadlineForQuotes} />) }},
        { Header: "status", accessor: "statusSearch", Cell: (props) => {return (<SpotStatus status={props.row.original.status} />) }},
    ],
    rows: state.data.rows
}

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      
      sx={{ height: 'calc(100%)'}}>

      <Grid container item xs={12}  sx={{height:'100%'}} >
        <Paper sx={{width:'100%'}}>
        {useMemo(()=> <DataTable canSearch loading={state.loading} tableUpdateLoading={state.tableUpdateLoading} handleRowClick={handleRowClick} table={tableData} title={'Spot requests'} subtitle={'Click a row to open detailed information about the request'} />
        ,[state.data,state.loading,state.tableUpdateLoading])}
         
        </Paper>

      </Grid>
      <ShipmentDisplayModal state={state} shipmentFamily = {'spot'}/>
    </Grid>
  )

}   

