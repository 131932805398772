
import React, { useRef  }  from 'react';
import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";
import SuiInput from "_softUI/components/SuiInput";
import InputAdornment from '@mui/material/InputAdornment';
import Autocomplete, { createFilterOptions } from '@mui/material/Autocomplete';
import SearchIcon from '@mui/icons-material/Search';


function GoogleAutocomplate({ label, value, options, onSelect, onChange}) {


  const filter = createFilterOptions();
  console.log(options)

  return (
    <>

      <Autocomplete
        name="name"
        value={value ?? ""}
        onChange={(event, newValue) => {
          console.log(event)
     
          onSelect(newValue);
       }}
       filterOptions={(options, params) => {
        // const filtered = filter(options, params);
        // const { inputValue } = params;
        return options;
      }}
        selectOnFocus
        includeInputInList
        handleHomeEndKeys
    
        options={options}
        icon={{component: <SearchIcon ref={null}/>, direction: 'right'}}
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
        sx={{ width: '100%', mt:'0.25rem'}}
        freeSolo
        renderInput={(params) => (
         
          <SuiInput 
          {...params} 
            value={value}
          fullWidth 
          onChange={onChange}
          ref={params.InputProps.ref}
          placeholder="Search"
    
          />

        )}
      />

    </>
  );
}



export default GoogleAutocomplate;

