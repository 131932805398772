export const customerSpotRequestTableConstants = {
    LOADING: 'SPOTSHIPMENTS_LOADING',
    MODAL_LOADING: 'SPOTSHIPMENTS_MODAL_LOADING',
    UPDATE_BOOKINGS_TABLE: 'SPOTSHIPMENTS_UPDATE_BOOKINGS_TABLE',
    ERROR: 'SPOTSHIPMENTS_ERROR',
    CLEAR: 'SPOTSHIPMENTS_CLEAR',
    CLOSE_MODAL: 'SPOTSHIPMENTS_CLOSE_MODAL',
    UPDATE_SELECTED_SHIPMENT: 'SPOTSHIPMENTS_UPDATE_SELECTED_BOOKING',
    UPDATE_VALUE: 'SPOTSHIPMENTS_UPDATE_VALUE',
    SET_SELECTED_QUOTE: 'SPOTSHIPMENT_SET_SELECTED_QUOTE',
    SET_CANCELLED: 'SPOTSHIPMENT_SET_CANCELLED',
    SET_INITIAL_STATE: 'SPOTSHIPMENT_ SET_INITIAL_STATE',
    MODAL_UPDATING: 'SPOTSHIPMENTS_MODAL_UPDATING',
    UPDATE_TABLE_VALUE: 'SPOTSHIPMENTS_UPDATE_TABLE_VALUE'
};