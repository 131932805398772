import React, { useEffect } from 'react';

import { useSelector, useDispatch } from 'react-redux';

import DashboardNavbar from '_components/Layout/DashboardNavbar';

import Grid from '@mui/material/Grid';
import { useLocation, NavLink } from "react-router-dom";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import { adminActions } from '_actions/admin.actions';
import SuiButton from '_softUI/components/SuiButton';
import { Loading } from '_components/Shared/LoadingSpinner'
import InputWithLabel from "_components/FormFields/InputWithLabel";


import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import 'dayjs/locale/de';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';


import LinearProgress from '@mui/material/LinearProgress';

export default function AdminMenu() {

  const state = useSelector(state => state.admin)
  const dispatch = useDispatch();

  function refreshCalculatorsInCache() {

    dispatch(adminActions.refreshCalculatorsInCache());
  }

  function refreshCache() {

    dispatch(adminActions.refreshCache());
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="space-between"
      alignItems="flex-start" sx={{ height: '100%' }}>


      {state.loading && <Loading />}
      {!state.loading && <>
        <Grid container item xs={12} alignItems="strecth" justifyContent="center">
          <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <nav aria-label="main mailbox folders">
              <List>

                <ListItem>   <NavLink to={"Admin/ManageRoles"} key={"Admin/ManageRoles"}>
                  Manage Roles
                </NavLink>
                </ListItem>
                <Divider />
                <ListItem>
                  <NavLink to={"Admin/ManageCustomers"} key={"Admin/ManageCustomers"}>
                    Manage Customers
                  </NavLink>
                </ListItem>
                <Divider />
                <ListItem>
                  <NavLink to={"Admin/ManageUsers"} key={"Admin/ManageUsers"}>
                    Manage Users
                  </NavLink>
                </ListItem>
                <Divider />
                <ListItem>
                  <NavLink to={"Admin/ManageCarriers"} key={"Admin/ManageCarriers"}>
                    Manage Carriers
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to={"Admin/ManageInvoicePatches"} key={"Admin/ManageInvoicePatches"}>
                    Manage Invoice Patches
                  </NavLink>
                </ListItem>
                <Divider />
                <ListItem>
                  <NavLink to={"Admin/ManageCalculators"} key={"Admin/ManageCalculators"}>
                    Manage Calculators
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to={"Admin/ManageChangingCosts"} key={"Admin/ManageChangingCosts"}>
                    Manage Changing Costs
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to={"Admin/ImportCustomers"} key={"Admin/ImportCustomers"}>
                    ImportCustomers
                  </NavLink>
                </ListItem>
                <ListItem>
                  <NavLink to={"Admin/EventLogs"} key={"Admin/EventLogs"}>
                    Logs
                  </NavLink>
                </ListItem>

              </List>


            </nav>
          </Box>
        </Grid>

        <Grid container item xs={12} alignItems="strecth" justifyContent="center">
          <Box sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
            <nav aria-label="main mailbox folders">
              <List>
                <ListItem>
                  <SuiButton onClick={refreshCalculatorsInCache} variant="contained" color="secondary">
                    Refresh calculators in cache
                  </SuiButton>
                </ListItem>
                <ListItem>
                  <SuiButton onClick={refreshCache} variant="contained" color="secondary">
                    Refresh  cache
                  </SuiButton>
                </ListItem>
                <Divider />


              </List>


            </nav>
          </Box>
        </Grid>
      </>}

    </Grid>
  )

}