import React, { useEffect } from 'react';

import { SearchContainer, ResultsContainer } from '../_components'
import { instantPricingActions } from '../_actions';
import { useSelector, useDispatch } from 'react-redux';

import { InstantPricingContainer } from "_components"
import Grid from '@mui/material/Grid';

import SuiTypography from '_softUI/components/SuiTypography';
import { AdditionalServiceContainer } from '_components/InstantPricing/AdditionalServicesContainer';

export function InstantPricing() {

  const state = useSelector(state => state.instantPricing)
  const history = useSelector(state => state.dashboard)
  const dispatch = useDispatch()


  let user = JSON.parse(localStorage.getItem('user'));
  
  useEffect(() => {
    const historyData = history.searchHistorySelected ? history.selectedSearch : ''
    const pickupCountry = history.searchHistorySelected ? history.selectedSearch.originCountry.value : user.country
    const deliveryCountry = history.searchHistorySelected ? history.selectedSearch.deliveryCountry.value : 'AT'
    dispatch(instantPricingActions.initate(historyData, pickupCountry, deliveryCountry, state.selections))


  }, [])



  return (
    <>


      <InstantPricingContainer>
        <SearchContainer />
      </InstantPricingContainer>

      <AdditionalServiceContainer /> 
      <ResultsContainer></ResultsContainer>



    </>
  )

}

