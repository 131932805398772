import { Box, Grid, Paper,  Typography } from '@mui/material';
import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { userActions } from '_actions';
import { Loading } from '_components';
import DisplayWithLabel from '_components/FormFields/BigDisplayWithLabel';
import InputWithLabel from '_components/FormFields/InputWithLabel';

import SuiButton from "_softUI/components/SuiButton";

import EmailNotificationSubscription from '_components/EmailNotificationSubscription';

export function UserProfile() {

    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(userActions.getUserInformation())
    }, [])

    const state = useSelector(state => state.user)

    function handleRowChange(event) {

        dispatch(userActions.updateUserInformation(event.target.name, event.target.value));
    }

    function handleLegacyChange(event) {

        dispatch(userActions.updateUserInformation(event.target.name, event.target.checked));
    }

    function handleEmailNotificationChange(event) {


        dispatch(userActions.updateUserEmailNotification(event.target.name, event.target.checked));
    }

    function saveUser() {
        dispatch(userActions.saveUserInformation(state.user))
    }

    function savePassword() {
        dispatch(userActions.saveUserPassword(state.user))
    }

    function getSubscriptionStatus(type){

        return !state.user.emailUnsubscriptions?.find(x => x.emailType === type)?.unsubscribed ?? true
    }

    function getVisibility(name){

        return state.user.emailNotificationDisplayList?.find(x => x === name) ?? false
    }

    const loading = state.loading;

    const isEnabled = state.user.password === state.user.passwordConfirm && state.user.oldPassword && state.user.password;
    const passwordError = state.user.password !== state.user.passwordConfirm && state.user.password !== ''

    return (
        <Grid spacing={3} container sx={{ my: 4 }}  >
            <Grid item container lg={8} xs={12}>


                <Paper elevation={1} sx={{ px: 5, py: 3 }}>
                    <Typography variant="h5" sx={{ mb: 3 }}>
                        User Information
                    </Typography>
                    {loading &&
                        <Grid container item xs={12} sx={{ p: 5 }}>
                            <Loading />

                        </Grid>

                    }
                    {!loading &&

                        <Grid spacing={2} container item xs={12} justifyContent="center" alignItems="stretch">
                            <Grid item container xs={12}>
                                <Box sx={{ backgroundColor: '#f5f5f5', px: 3, borderRadius: '0.25rem', width: '100%' }}>
                                    <Grid item container xs={12}>


                                        <Grid item container md={6} xs={12}>
                                            <DisplayWithLabel label="Username / email" value={state.user.username} />
                                        </Grid>
                                        <Grid item container md={6} xs={12}>
                                            <DisplayWithLabel label="Company" value={state.user.customer} />
                                        </Grid>


                                    </Grid>
                                </Box>
                            </Grid>
                            <Grid item container spacing={2} xs={12}>
                                <Grid item container lg={6} xs={12}>
                                    <InputWithLabel value={state.user.firstName} name="firstName" label="First name" onChange={e => handleRowChange(e)} />
                                </Grid>
                                <Grid item container lg={6} xs={12}>
                                    <InputWithLabel value={state.user.lastName} name="lastName" label="Last name" onChange={e => handleRowChange(e)} />
                                </Grid>

                                <Grid item container lg={6} xs={12}>
                                    <InputWithLabel value={state.user.phone} name="phone" label="Phone" onChange={e => handleRowChange(e)} />
                                </Grid>
                            </Grid>
                            <Grid item container spacing={2} xs={12}>
                                <Grid item container xs={12}>
                                    <Typography variant="h6" sx={{ mb: 2, mt: 2, fontWeight: 'bold' }} >
                                        Email notifications
                                    </Typography>
                                </Grid>
                                {/* <Grid item container xs={12}>
                                    <FormControl component="fieldset" variant="standard" >
            
                                        <Typography variant="subtitle1" sx={{ fontSize: '0.65em', fontWeight: 'bold' }}>Legacy mode</Typography>
                                        <FormGroup>
                                            <Stack direction="row" spacing={1} alignItems="center">
                                                <Typography sx={{ fontSize: '0.6em' }}>Off</Typography>
                                                <FormControlLabel
                                                    control={
                                                        <Switch checked={state.user.legacyMode} onChange={e => handleLegacyChange(e)} name="legacyMode" />
                                                    }


                                                />
                                                <Typography sx={{ fontSize: '0.6em' }}>On</Typography>
                                            </Stack>

                                            <FormHelperText>Shipment ordering form is displayed in a single view.</FormHelperText>
                                        </FormGroup>

                                    </FormControl>
                                </Grid> */}
                                <EmailNotificationSubscription title="New quote" name="NewQuote" value={getSubscriptionStatus('NewQuote')} changeHandler={handleEmailNotificationChange} helperText="Get notified when you receive a new quote for your spot request" visible={getVisibility('NewQuote')} />
                 
                                <EmailNotificationSubscription title="Spot request deadline reached" name="DeadlineReached" value={getSubscriptionStatus('DeadlineReached')} changeHandler={handleEmailNotificationChange} helperText="Get notified when deadline for quotes has been reached" visible={getVisibility('DeadlineReached')} />
                                <EmailNotificationSubscription title="New spot request" name="NewSpotRequest" value={getSubscriptionStatus('NewSpotRequest')} changeHandler={handleEmailNotificationChange} helperText="Get notified when a customer submits new spote request for quatation" visible={getVisibility('NewSpotRequest')} />
                                <EmailNotificationSubscription title="Spot request cancelled" name="SpotRequestCancelled" value={getSubscriptionStatus('SpotRequestCancelled')} changeHandler={handleEmailNotificationChange} helperText="Get notified when you the customer cancels the rfq for the spot request" visible={getVisibility('SpotRequestCancelled')} />
                                <EmailNotificationSubscription title="Spot request ended " name="SpotRequestEnded" value={getSubscriptionStatus('SpotRequestEnded')} changeHandler={handleEmailNotificationChange} helperText="Get notified when the customer has booked the freight from another carrier than you" visible={getVisibility('SpotRequestEnded')} />

                            </Grid>
                            <Grid item container xs={12} justifyContent="flex-end">
                                <SuiButton color="secondary" size="small" variant="contained" onClick={saveUser}>
                                    Save
                                </SuiButton>
                            </Grid>
                        </Grid>

                    }
                </Paper>
            </Grid>

            <Grid item container lg={4} xs={12}>
                <Paper sx={{ px: 5, py: 3 }}>
                    <Typography variant="h5" sx={{ mb: 3 }}>
                        Change password
                    </Typography>

                    {loading &&
                        <Grid container item xs={12} sx={{ p: 5 }}>
                            <Loading />

                        </Grid>

                    }
                    {!loading &&

                        <Grid spacing={2} container item xs={12} direction="column" justifyContent="flex-end" alignItems="flex-end">
                            <Grid item container xs={12}>
                                <InputWithLabel name="oldPassword" label="Old password" onChange={e => handleRowChange(e)} />
                            </Grid>
                            <Grid item container xs={12}>
                                <InputWithLabel name="password" label="New password" onChange={e => handleRowChange(e)} />
                            </Grid>


                            <Grid item container xs={12} >
                                <InputWithLabel error={passwordError} name="passwordConfirm" label="Confirm new password" onChange={e => handleRowChange(e)} />
                            </Grid>

                            <Grid item container xs={12} justifyContent="flex-end">
                                <SuiButton disabled={!isEnabled} color="secondary" fullWidth variant="contained" size="small" onClick={savePassword}>
                                    Update password
                                </SuiButton>
                            </Grid>


                        </Grid>

                    }
                </Paper>
            </Grid>
        </Grid>
    )
}
