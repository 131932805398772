import {nullOrZeroOrEmptyOrError, nullEmptyOrError, minLengthError} from './helpers'
import dayjs from 'dayjs';

export function validateLocations(input) {

    let vals = []

    const parsedOpeningHoursFrom = new Date(input.openingHoursFrom);
    const parsedOpeningHoursTo = new Date(input.openingHoursTo);

    let newOutput = {
      ...input,
      openingHoursFrom: !dayjs(input.openingHoursFrom, 'HH:mm', true).isValid()  ? null : input.openingHoursFrom,
      openingHoursTo: !dayjs(input.openingHoursTo, 'HH:mm', true).isValid()  ? null : input.openingHoursTo,
      nameError: nullEmptyOrError(input.name,vals),
      streetAddressError: nullEmptyOrError(input.streetAddress,vals),
      cityError: nullEmptyOrError(input.city,vals),
      zipError: nullEmptyOrError(input.zip,vals),
      countryAlpha2Error: nullEmptyOrError(input.countryAlpha2,vals),
      contactNameError: nullEmptyOrError(input.contactName,vals),
      contactEmailError: nullEmptyOrError(input.contactEmail,vals),
      contactPhoneError: minLengthError(input.contactPhone,vals, 5),
    }



  
    let valid = !vals.includes(false)

    newOutput = {
      ...newOutput,
      valid: valid
    }
  
    console.log(vals)
    const output = {
      ...newOutput,
      valid: valid
    }


    return output
  }

