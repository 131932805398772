import { authHeader } from '../_helpers';
import { handleResponse } from './util'


export const locationService = {
    getAllSavedLocationsForUser,
    createSavedLocation,
    getPostalCodesByCountry,
    getAllCountries,
    getCountriesForInstantPricing,
    deleteSavedLocation,
    updateSavedLocation,
};


function getAllSavedLocationsForUser() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };
    console.log('fetch')
    return fetch(process.env.REACT_APP_API_URL + 'SavedLocation/GetSavedLocations', requestOptions).then(handleResponse)

}

function createSavedLocation(data){
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data),
    };

    return fetch(process.env.REACT_APP_API_URL + 'SavedLocation/CreateSavedLocation', requestOptions).then(handleResponse)
}

function getPostalCodesByCountry(country) {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };
  
    return fetch(process.env.REACT_APP_API_URL + 'Country/GetPostalCodes/' + country, requestOptions).then(handleResponse)
  
  }
  
  function getAllCountries() {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };
  
    return fetch(process.env.REACT_APP_API_URL + 'Country/GetCountries', requestOptions).then(handleResponse)
  
  }

  function getCountriesForInstantPricing() {
    const requestOptions = {
      method: 'GET',
      headers: authHeader()
    };
  
    return fetch(process.env.REACT_APP_API_URL + 'Country/GetCountriesForInstantPricing', requestOptions).then(handleResponse)
  
  }

  function deleteSavedLocation(id) { 
    const requestOptions = {
      method: 'DELETE',
      headers: authHeader()
    };
    return fetch(process.env.REACT_APP_API_URL + 'SavedLocation/DeleteSavedLocation/' + id, requestOptions).then(handleResponse)
  }

  function updateSavedLocation(data) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(data)
    };
 
    return fetch(process.env.REACT_APP_API_URL + 'SavedLocation/UpdateSavedLocation' , requestOptions).then(handleResponse)

}