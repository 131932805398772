import React from 'react';
import { Box, Grid, Paper, Stack, Typography } from '@mui/material';
import Switch from '@mui/material/Switch';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormHelperText from '@mui/material/FormHelperText';

export default function EmailNotificationSubscription({title,name, value,helperText, changeHandler, visible}){

    return(
        visible && 
        <Grid item container xs={12} sx={{pb:'1rem'}}>
        <FormControl component="fieldset" variant="standard" >

            <Typography variant="subtitle1" sx={{ fontSize: '0.65em', fontWeight: 'bold' }}>{title}</Typography>
            <FormGroup>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography sx={{ fontSize: '0.6em' }}>No notificaton</Typography>
                    <FormControlLabel
                        control={
                            <Switch checked={value} onChange={e => changeHandler(e)} name={name} />
                        }


                    />
                    <Typography sx={{ fontSize: '0.6em' }}>Get notified</Typography>
                </Stack>

                <FormHelperText>{helperText}</FormHelperText>
            </FormGroup>

        </FormControl>
    </Grid>

    )
}