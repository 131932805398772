import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

import lottie from "lottie-web";
import success from "../../_assets/success.json";




export function Success({title = 'Saved!',subTitle= ''}) {

  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#success"),
      animationData: success,
     loop: false
    });
  }, []);

  return (
    <React.Fragment>
      <Grid container justify="center" direction="column"
        alignItems="center">
        <Grid item >
          <div id="success" style={{color:'red'}} />
        </Grid>
        <Grid item >
          <Typography variant="h5" gutterBottom>
            {title}
          </Typography>



        </Grid>
      </Grid>

    </React.Fragment>
  )

}