import { spotRequestConstants } from '../_constants';

const initialState = {
    loading: false,
    quoteCurrent: {
        totalPrice: '',
        quoteValid: '',
        transitTime: '',
        comments: '',
    },
    quote: {
        totalPrice: '',
        quoteValid: '',
        transitTime: '',
        additionalInformation: '',
        file: [],
    }
};

export function spotRequest(state = initialState, action) {
    switch (action.type) {
        case spotRequestConstants.LOADING:
            return {
                ...state,
                loading: action.value
            };

        case spotRequestConstants.UPDATE_VALUE:
            let newValue = ""
            if (action.data.name === 'totalPrice') {
                newValue = action.data.value.replace(/,/g, '.')
            }
            else {
                newValue = action.data.value;
            }

            return {
                ...state,
                quote: {
                    ...state.quote,
                    [action.data.name]: newValue
                }
            }
        case spotRequestConstants.STORE_FILE:


            return {
                ...state,
                quote: {
                    ...state.quote,
                    file: state.quote.file.concat(action.file)
                }
            };

        case spotRequestConstants.DELETE_FILE:


            return {
                ...state,
                quote: {
                    ...state.quote,
                    file: state.quote.file.filter((row, i) => i !== action.index)
                }
            };

        case spotRequestConstants.INITATE:
            return {
                ...state,
                shipmentId: action.model.shipmentId,
                quote: {
                    ...initialState.quote,
                    ...action.model.currentQuote
                },
                quoteCurrent: action.model.currentQuote
            }

        default:
            return state
    }
}