export const shipmentConstants = {
    LOADING: 'SHIPMENTS_LOADING',
    MODAL_LOADING: 'SHIPMENTS_MODAL_LOADING',
    UPDATE_BOOKINGS_TABLE: 'SHIPMENTS_UPDATE_BOOKINGS_TABLE',
    UPDATE_CARRIER_TABLE: 'SHIPMENTS_UPDATE_CARRIER_TABLE',
    ERROR: 'SHIPMENTS_ERROR',
    CLEAR: 'SHIPMENTS_CLEAR',
    CLOSE_MODAL: 'SHIPMENTS_CLOSE_MODAL',
    UPDATE_SELECTED_SHIPMENT: 'SHIPMENTS_UPDATE_SELECTED_BOOKING',
    UPDATE_VALUE: 'SHIPMENTS_UPDATE_VALUE',
    SET_SELECTED_QUOTE: 'SHIPMENT_SET_SELECTED_QUOTE',
    SET_CANCELLED: 'SHIPMENT_SET_CANCELLED',
    SET_INITIAL_STATE: 'SHIPMENT_SET_INITIAL_STATE',

};
