import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import AutocomplateWithLabel from "_components/FormFields/AutocomplateWithLabel";
import Grid from '@mui/material/Grid';
import SelectWithLabel from "_components/FormFields/SelectWithLabel";
import InputWithLabel from "_components/FormFields/InputWithLabel";
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import MapOutlinedIcon from '@mui/icons-material/MapOutlined';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import EmailOutlinedIcon from '@mui/icons-material/EmailOutlined';
import CallOutlinedIcon from '@mui/icons-material/CallOutlined';
import TimeFieldWithLabel from "_components/FormFields/TimeFieldWithLabel";
import dayjs from 'dayjs';
import { MuiTelInput } from "mui-tel-input";
import Label from '../FormFields/Label'
import InputAdornment from '@mui/material/InputAdornment';
import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";
import BusinessRoundedIcon from '@mui/icons-material/BusinessRounded';
import AddLocationAltOutlinedIcon from '@mui/icons-material/AddLocationAltOutlined';
import ForkLeftOutlinedIcon from '@mui/icons-material/ForkLeftOutlined';
import MapsHomeWorkOutlinedIcon from '@mui/icons-material/MapsHomeWorkOutlined';
import FormHelperText from '@mui/material/FormHelperText';


export default function LocationInputForm({ index, instant, handleInputChange, handleCountryChange, loadSavedLocation, location, addressbook, countryOptions, title, handleLocationChangeWithMaxLenght, locationOptions }) {

  const dispatch = useDispatch();
  const state = useSelector(state => state.location);
  const [touched, setTouched] = React.useState(false);
  const dayjs = require("dayjs");
  var customParseFormat = require("dayjs/plugin/customParseFormat");
  dayjs.extend(customParseFormat);  

  const savedLocationOptions = locationOptions && locationOptions.map((x, index) => ({ value: x.id, label: x.name }))
  console.log(savedLocationOptions)
  const selectedCountry = countryOptions.find(obj => {
    return obj.value === location.countryAlpha2
  })

  const filterOptions = (candidate, input) => {

    if (input) {
      return candidate.value.toLowerCase().includes(input.toLowerCase()) || candidate.label.key.toLowerCase().includes(input.toLowerCase());
    }
    return true;
  };

 
  return (
    <>
      <Grid item xs={12}  >

        <Grid container spacing={3}>
          <Grid item xs={8.5}>

            <Grid container spacing={0}>
              <Grid container spacing={1} item xs={12} >
                <Grid item xs={5}>
                  {!addressbook && <AutocomplateWithLabel
                    adornment={<BusinessRoundedIcon />}
                    adornmentPosition="end"
                    placeholder="Company name"
                    value={location.name}
                    label="Company name"
                    fullWidth
                    name="name"
                    index={index}
                    error={location.nameError}
                    onSelect={(e) => loadSavedLocation(e, index)}
                    locations={savedLocationOptions}
                    onChange={handleInputChange}
                    mandatory={true}
                  />}

                  {addressbook && <InputWithLabel
                    adornment={<BusinessRoundedIcon />}
                    adornmentPosition="end"
                    placeholder="Company name"
                    value={location.name}
                    label="Company name"
                    variant="outlined"
                    fullWidth
                    name="name"
                    mandatory={true}
                    error={location.nameError}
                    onChange={handleInputChange}
                  />}


                </Grid>
                <Grid item xs={7}>
                  <InputWithLabel
                    adornment={<LocationOnOutlinedIcon />}
                    adornmentPosition="end"
                    placeholder="Street address"
                    value={location.streetAddress}
                    label="Street address / Location"
                    variant="outlined"
                    fullWidth
                    mandatory={true}
                    name="streetAddress"
                    error={location.streetAddressError}
                    onChange={handleInputChange} />
                </Grid>

              </Grid>
              <Grid item xs={12} >

              </Grid>
              <Grid item xs={12}>
                <Grid container spacing={1}>
                  <Grid item xs={3}>
                    <InputWithLabel
                      adornment={<ForkLeftOutlinedIcon />}
                      adornmentPosition="end"
                      placeholder="Floor, gate, etc..."
                      value={location.addressInfo}
                      label="Address info"
                      variant="outlined"
                      fullWidth

                      name="addressInfo"
                      onChange={handleInputChange} />
                  </Grid>
                  {<Grid item xs={3.5}>
                    <InputWithLabel
                      adornment={<MapsHomeWorkOutlinedIcon />}
                      adornmentPosition="end"
                      value={location.city}
                      placeholder="City"
                      label="City"
                      mandatory={true}
                      name="city"
                      error={location.cityError}
                      onChange={handleInputChange} />


                  </Grid>}
                  <Grid item xs={2}>
                    {instant && <SelectWithLabel
                      value={{ value: location.zip, label: location.zip }}

                      label="ZIP"
                      mandatory={true}
                      fullWidth

                      name="zip"
                      isDisabled={true}
                      error={location.zipError}
                      onChange={handleInputChange}
                    />}
                    {!instant &&
                      <InputWithLabel
                        adornment={<MapOutlinedIcon />}
                        adornmentPosition="end"
                        value={location.zip}
                        placeholder="Zip"
                        label="Zip"
                        mandatory={true}
                        name="zip"
                        error={location.zipError}
                        onChange={handleInputChange} />

                    }

                  </Grid>
                  <Grid item lg={3.5} xs={3.5} >


                    <SelectWithLabel
                      id={"outlined-select-country"}
                      label="Country"
                      name="countryAlpha2"
                      onChange={handleCountryChange}
                      mandatory={true}
                      error={location.countryAlpha2Error}
                      value={selectedCountry}
                      options={countryOptions}
                      isDisabled={instant}
                      filterOption={filterOptions}
                      components={{ IndicatorSeparator: () => null }}
                      blurInputOnSelect={true}
                    >

                    </SelectWithLabel>




                  </Grid>


                </Grid>
              </Grid>
              <Grid item xs={12} >
                <Grid container spacing={1}>


                  <Grid item xs={2} >
                    {/* <InputWithLabel
                            id="outlined-basic"
                            type="time"
                            // adornment={<AccessTimeIcon />}
                            // adornmentPosition="end"
                            placeholder="Opening hours"
                            value={location.openingHours}
                            label="Opening hours"
                            variant="outlined"
                            fullWidth
                            name="openingHours"
                            onChange={handleInputChange}

                          /> */}
                    <TimeFieldWithLabel
                      id="outlined-basic"
                      // adornment={<AccessTimeIcon />}
                      adornmentPosition="end"
                      placeholder="From"
                      // value={dayjs(location.openingHoursFrom, "HH:mm")}
                      value={location.openingHoursFrom !== '' ? dayjs(location.openingHoursFrom, "HH:mm") : null}
                      label="Opening"
                      variant="outlined"
                      fullWidth
                      name="openingHoursFrom"
                      onChange={e => handleInputChange(({ target: { name: 'openingHoursFrom', value: e.format("HH:mm") } }))}
                      onBlur={() => setTouched(true)}
                      onClick={() => setTouched(false)}
                    />
                  </Grid>
                  <Grid item xs={2} >
                    <TimeFieldWithLabel
                      id="outlined-basic"
                      adornment={<AccessTimeIcon />}
                      adornmentPosition="end"
                      placeholder="To"
                      value={location.openingHoursTo !== '' ? dayjs(location.openingHoursTo, "HH:mm") : null}
                      label={"\u00A0"}
                      variant="outlined"
                      fullWidth
                      name="openingHoursTo"
                      onChange={e => handleInputChange(({ target: { name: 'openingHoursTo', value: e.format("HH:mm") } }))}
                      onBlur={() => setTouched(true)}
                      onClick={() => setTouched(false)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <InputWithLabel
                      id={"instructions"}
                      label={title + " instructions"}
                      // multiline
                      // rows={4}

                      value={location.instructions}
                      placeholder={title + " instructions"}
                      name={"instructions"} onChange={handleLocationChangeWithMaxLenght}

                    />
                  </Grid>
                </Grid>
                {touched && (!dayjs(location.openingHoursFrom, 'HH:mm', true).isValid() || !dayjs(location.openingHoursTo, 'HH:mm', true).isValid()) && (
                <FormHelperText sx={{color: '#C83E4D', fontSize: '13px', marginLeft: 0.5}} id="my-helper-text">The given time is invalid. To include opening hours, please use format hh:mm (example 08:00) </FormHelperText>
                )}
              </Grid>

            </Grid>
          </Grid>

          <Grid item xs={3.5}>

            <Grid item xs={12}>

              <InputWithLabel

                adornment={<PersonOutlineOutlinedIcon />}
                adornmentPosition="end"
                placeholder="Contact name"
                value={location.contactName}
                label="Contact name"
                variant="outlined"
                fullWidth
                size="small"
                error={location.contactNameError}
                name="contactName"
                mandatory={true}
                onChange={handleInputChange} />


            </Grid>

              <Grid item xs={12}>
                <InputWithLabel
                  adornment={<EmailOutlinedIcon />}
                  adornmentPosition="end"
                  placeholder="Contact email"
                  value={location.contactEmail}
                  label="Contact email"
                  variant="outlined"
                  mandatory={true}
                  fullWidth
                  size="small"
                  name="contactEmail"
                  error={location.contactEmailError}
                  onChange={handleInputChange} />

              </Grid>



              <Grid item xs={12} >
                <Label>Contact Phone&nbsp;
                    <SuiTypography
                      component="label"
                      variant="caption"
                      fontWeight='light'
                      textTransform="capitalize"
                      sx={{ color: '#fd5c70' }}

                    >
                      *
                    </SuiTypography>
                    </Label>
                <MuiTelInput variant="outlined" fullWidth defaultCountry={location.countryAlpha2 ? location.countryAlpha2 : 'SE'} name="contactPhone" value={location.contactPhone} 
                  onChange={e => handleInputChange(({ target: { name: 'contactPhone', value: e.replace(/\s/g, '') } }))}
                  sx={{
                    '.MuiInputBase-root.MuiOutlinedInput-root': {
                      paddingRight: '10px !important', paddingTop: '0.4rem !important', paddingBottom: '0.4rem !important', paddingLeft: '0.2rem !important',
                      '& .MuiTelInput-FlagImg': {
                        width: '15px',
                      },
                      '& .MuiInputAdornment-root.MuiInputAdornment-positionStart': {
                        width: '25px',
                      },
                      '.MuiInputBase-input.MuiOutlinedInput-input': {
                        width: '100% !important',
                      },
                      borderColor: location.contactPhoneError ? '#fd5c70' : 'default',
                    }
                  }}
                  InputProps={{
                    endAdornment: (
                      location.contactPhoneError ? (
                        <img
                        src="data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23fd5c70' viewBox='0 0 12 12'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23fd5c70' stroke='none'/%3E%3C/svg%3E"
                        style={{ height: '1.12em', paddingRight: '2px' }}
                    />  
                    ) : (
                      <InputAdornment
                        position='end'
                        sx={{
                          height: '1.1em',
                          fontSize: '1.2rem',
                          color: '#183B59',
                          //marginLeft: '-15px !important',
                          '.MuiTypography-root': {
                            fontSize: '1.1rem',
                            color: '#183B59',
                          },
                          // '.MuiInputBase-input-MuiOutlinedInput-input': {minWidth: '70%'}
                        }}
                      >
                        <CallOutlinedIcon />
                      </InputAdornment> )
                    )
                  }}
                >
                </MuiTelInput>
                {/* <InputWithLabel

                        adornment={<CallOutlinedIcon />}
                        adornmentPosition="end"
                        placeholder="Contact phone"
                        value={location.contactPhone}
                        label="Contact phone"
                        variant="outlined"
                        fullWidth
                        mandatory={true}
                        size="small"
                        name="contactPhone"
                        error={location.contactPhoneError}
                        onChange={handleInputChange} /> */}



              </Grid>



          </Grid>




        </Grid>

      </Grid>
    </>
  )
}