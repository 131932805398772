import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableBody from "@mui/material/TableBody";
import TableCell from '@mui/material/TableCell';
import HeaderCell from '_components/Tables/HeaderCell'
import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";


import DropZone from "_components/Common/Dropzone"
import { arrayBufferToBase64 } from '_helpers/file'
import Link from '@mui/material/Link';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import IconButton from '@mui/material/IconButton';

import ReactMimeIcons from 'react-mime-icons';

import { shipmentActions, customerSpotRequestTableActions } from '_actions';

export default function DocumentsAndLabels({ state, id, isSpot }) {
    const dispatch = useDispatch()

    const actions = isSpot ? customerSpotRequestTableActions : shipmentActions

    const handleFile = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {

                const entry = {
                    name: file.path,
                    data: arrayBufferToBase64(reader.result),
                    type: file.type,
                    bookingId: id,
                    fileType: 3
                }

                dispatch(actions.saveFile(entry))
            }
            reader.readAsArrayBuffer(file)
        })

    }, [])

    function handleDeleteClick(fileId) {
        dispatch(actions.deleteFile(fileId, id))
    }

    const labelAndDocumentRows = state && state.filter(x => (x.fileType === 1 || x.fileType === 2))
    const otherRows = state && state.filter(x => (x.fileType > 2 || x.fileType === 0))


    return (
        <>
            <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <SuiTypography variant="h6">Labels and waybill</SuiTypography>


            </SuiBox>
            <SuiBox py={1} px={2}>
                <TableContainer sx={{ boxShadow: "none" }}>
                    <Table>
                        <SuiBox component="thead">
                            <TableRow>
                                <HeaderCell>filename</HeaderCell>
                                <HeaderCell>download</HeaderCell>
                                <HeaderCell>delete</HeaderCell>
                            </TableRow>
                        </SuiBox>
                        <TableBody>{labelAndDocumentRows.map((x, i) =>

                            <TableRow key={i}>
                                <TableCell align="left">{x.name}</TableCell>
                                <TableCell align="center"> <Link target="_blank" href={x.path} ><FileDownloadIcon /></Link></TableCell>
                                <TableCell align="center">{(x.canDelete ? <IconButton onClick={() => handleDeleteClick(x.id)}><DeleteForeverIcon /></IconButton> : '')}</TableCell>
                            </TableRow>
                        )}
                            {labelAndDocumentRows.length === 0 &&

                                <TableRow key={'nofilesother'}>
                                    <TableCell align="left">No Files</TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>



                            }

                        </TableBody>
                    </Table>
                </TableContainer>
                {/* <LabelDisplay name="Lable.pdf" /> */}
            </SuiBox>

            <SuiBox display="flex" justifyContent="space-between" alignItems="center" pt={2} px={2}>
                <SuiTypography variant="h6">Other documents</SuiTypography>

            </SuiBox>
            <SuiBox py={1} px={2}>
                <TableContainer sx={{ boxShadow: "none" }}>
                    <Table>
                        <SuiBox component="thead">
                            <TableRow>
                                <HeaderCell>filename</HeaderCell>
                                <HeaderCell>type</HeaderCell>
                                <HeaderCell>uploader</HeaderCell>
                                <HeaderCell>download</HeaderCell>
                                <HeaderCell>delete</HeaderCell>
                            </TableRow>
                        </SuiBox>
                        <TableBody>
                            {otherRows.map((x, i) =>
                                <TableRow key={i}>
                                    <TableCell align="left">{x.name}</TableCell>
                                    <TableCell align="center"> <ReactMimeIcons mimetype={x.type} /></TableCell>
                                    <TableCell align="center">{x.uploader ?? ''}</TableCell>
                                    <TableCell align="center"> <Link href={x.path} ><FileDownloadIcon /></Link></TableCell>
                                    <TableCell align="center">{(x.canDelete ? <IconButton onClick={() => handleDeleteClick(x.id)}><DeleteForeverIcon /></IconButton> : '')}</TableCell>
                                </TableRow>
                            )}



                            {otherRows.length === 0 &&

                                <TableRow key={'nofilesother'}>
                                    <TableCell align="left">No Files</TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                </TableRow>



                            }

                        </TableBody>
                    </Table>
                </TableContainer>
            </SuiBox>
            <SuiBox p="1rem">
                <DropZone title="" hint="" action={handleFile} handleDelete={undefined} files={[]} acceptedFileFormats='application/pdf' />
            </SuiBox>


        </>
    )
}