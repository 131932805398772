/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React Base Styles
import borders from "_softUI/assets/theme/base/borders";

// Soft UI Dashboard PRO React Helper Functions
import pxToRem from "_softUI/assets/theme/functions/pxToRem";

const { borderRadius } = borders;

export default {
  styleOverrides: {
    // root: {
    //   borderRadius: borderRadius.xl,
    //   margin: `${pxToRem(16)} ${pxToRem(16)} 0`,
    // },

    // media: {
    //   width: "auto",
    // },
  },
};
