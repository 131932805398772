import { authHeader } from '../_helpers';
import {handleResponse} from './util'

export const authService = {
    login,
    logout,
    register,
    confirmEmail,
    forgotPassword
};

function login(username, password) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username, password })
    };

  return fetch(process.env.REACT_APP_API_URL + 'auth/login', requestOptions)
        .then(handleResponse)
        .then(user => {
            // store user details and jwt token in local storage to keep user logged in between page refreshes
            localStorage.setItem('user', JSON.stringify(user));

            return user;
        });
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function register(model) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(model)
    };

  return fetch(process.env.REACT_APP_API_URL + 'auth/register', requestOptions)
        .then(handleResponse);
}

function forgotPassword(model) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(model)
    };

  return fetch(process.env.REACT_APP_API_URL + 'auth/forgotPassword', requestOptions)
        .then(handleResponse);
}

function confirmEmail(model) {
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(model)
    };

  return fetch(process.env.REACT_APP_API_URL + 'auth/confirmEmail', requestOptions)
        .then(handleResponse);
}






