export function nullOrZeroOrEmptyOrError(value,error,vals) {
    if (!value || value === '' || value === 0 || value === '0' || error) {
        vals.push(false)
      return true
    } else {
      return false
    }
  }
  
  
  export function nullEmptyOrError(value,vals) {
    if (!value || value === '') {
      vals.push(false)
      return true
    } else {
      return false
    }
  }
  
  export function minLengthError(value, vals, minLength) {
    if (!value || value.length < minLength) {
      vals.push(false);
      return true;
    } else {
      return false;
    }
  }