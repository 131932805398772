import React from 'react';
import freightoptLogoSmall from '../../_assets/images/parcel.png'
import Grid from '@mui/material/Grid';
import DisplayWithLabel from '_components/FormFields/DisplayWithLabel'
import { useDispatch, useSelector } from 'react-redux';
import SuiBox from "_softUI/components/SuiBox";
import CardMedia from "@mui/material/CardMedia";
import SuiTypography from "_softUI/components/SuiTypography";
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import IconButton from '@mui/material/IconButton';
import SuiInput from "_softUI/components/SuiInput";
import SuiSelect from "_softUI/components/SuiSelect";

export default function ExcelCellInfoRow({ index, state, groupOptions, typeOptions, valueTypeOptions, last, addRow, removeRow, handleChange }) {


    return (
        <React.Fragment >
            <Grid container sx={{ mb: '1rem' }}>

                <Grid item container spacing={1} xs={12} sx={{ position: 'relative' }}>
                    <Grid item xs={2}>
                        <SuiSelect
                            name="group"

                            onChange={(e) => handleChange('group', e.value, index)}
                            value={groupOptions.find(obj => {
                                return obj.value === state.group
                            })}
                            options={groupOptions}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <SuiInput name="range" value={state.range} onChange={(e) => handleChange(e.target.name, e.target.value, index)} />
                    </Grid>
                    <Grid item xs={2}>
                        <SuiInput name="name" value={state.name} onChange={(e) => handleChange(e.target.name, e.target.value, index)} />
                    </Grid>
                    <Grid item xs={2}>
                        <SuiSelect
                            name="type"

                            onChange={(e) => handleChange('type', e.value, index)}
                            value={typeOptions.find(obj => {
                                return obj.value === state.type
                            })}

                            options={typeOptions}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <SuiSelect
                            name="valueType"

                            onChange={(e) => handleChange('valueType', e.value, index)}
                            value={valueTypeOptions.find(obj => {
                                return obj.value === state.valueType
                            })}

                            options={valueTypeOptions}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        {index > 0 &&
                            <IconButton color="primary" onClick={() => removeRow(index)} >
                                <RemoveIcon />
                            </IconButton>
                        }
                        {last &&
                            <IconButton color="secondary" onClick={addRow} >
                                <AddIcon />
                            </IconButton>
                        }
                    </Grid>

                </Grid>

            </Grid>

        </React.Fragment>
    );

}