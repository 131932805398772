import { Box, Grid } from '@mui/material';
import React from 'react';
import { useDispatch } from 'react-redux';
import { identifier } from 'stylis';
import { customerSpotRequestTableActions } from '_actions';
import DisplayWithLabel from '_components/FormFields/DisplayWithLabel';
import DataTable from '_components/Tables/DataTable'
import { Logo, BookedPrice, Deadline } from '_components/BookingsTable/CellFunctions'
import CardMedia from "@mui/material/CardMedia";
import x from "_assets/images/x.png";
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import SuiButton from "_softUI/components/SuiButton";
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import { useEffect } from 'react';
import {convertUTCDateToLocalDate} from '_helpers'
import Chip from '@mui/material/Chip';
import CheckOutlinedIcon from '@mui/icons-material/CheckOutlined';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: theme.palette.common.white,
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[1],
      fontSize: 11,
    },
  }));
  

export function FreightopPrice({ value }) {

    if (value) {
        return (
<LightTooltip title="Automatically generated based on Freightopt contract prices">
            <Box component="img" src={x} alt="FreightOpt" width="15px" />

            </LightTooltip>

        )
    }
    else { return <Box component="img" width="15px" /> }

}

export  function QuoteExpiration({ deadline }) {
    const deadlineTime = new Date(deadline)
    return (
      <Grid container item xs={12}>
  
        <Grid item container xs={12}>
          <Grid item >
            <Typography variant="caption" sx={{ color: 'rgb(104, 104, 104)' }}>
       
              {  deadline.slice(8, 10) + '.'+deadline.slice(5, 7)+ '.'+deadline.slice(0, 4) ?? 'n/a'}
            </Typography>
          </Grid>
        </Grid>
        {/* <Grid item container xs={12}>
          <Grid item >
            <Typography variant="caption" sx={{ color: 'rgb(104, 104, 104)' }}>
              {converted.toLocaleTimeString()}
            </Typography>
          </Grid>
        </Grid> */}
      </Grid>
    )
  }

export default function Quotes({ state }) {

    const dispatch = useDispatch();
    const handleRowClick = (id) => {
        dispatch(customerSpotRequestTableActions.setSelectedQuote(id))
        setTimeout(function() {
        document.getElementById("selectedquote").scrollIntoView({block: "center", behavior: 'smooth'})
        }, 300)
    }

    // function book(id) {
    //     dispatch(customerSpotRequestTableActions.bookSpot(id))
    // }

    console.log('bookedstate', state)

    const columns = [
        { Header: "", width: '15px', accessor: "isFreightopt", Cell: (props) => { return (<FreightopPrice value={props.row.original.isFreightopt} />) } },
        { Header: "Id", accessor: "id" },
        { Header: "Carrier", accessor: "carrierLogoUrl", Cell: (props) => { return (<Logo url={props.row.original.carrierLogoUrl} name={props.row.original.carrierName} />) } },
        { Header: "Price", accessor: "totalPrice", Cell: (props) => { return ( <BookedPrice price={props.row.original.totalPrice.toFixed(2) + " " + state.currency} />) } },
        { Header: "Quote expiration date", accessor: "quoteValid", Cell: (props) => { return (<QuoteExpiration deadline={props.row.original.quoteValid} />) } },
    ]
    const rows = state && state.quotes
    const table = {
        columns: columns,
        rows: rows
    }

    if (state.booked) {
        columns.push({Header: "Booked",accessor: "newData", Cell: (props) => {
            const isBooked = props.row.original.id === state.bookedQuoteId;
            return ( 
                <>
                {isBooked &&
                <Chip   variant="outlined" size="medium" sx={{ width: '120px'}} icon={<CheckOutlinedIcon sx={{color:'#A7936A !important',fontSize:'1.2rem !important' }}/>} label="Booked"/>
                }
                </>
        )},});
      }

    const selectedQuote = state.selectedQuote && state.quotes.find(x => x.id === state.selectedQuote)


    return (
        <>
            <Grid container>
                <Typography variant="h5" component="h5">
                    Quotes
                </Typography>

                <Grid item xs={12}  >
                    <DataTable onClick table={table} handleRowClick={handleRowClick} showTotalEntries={false} selected={state.selectedQuote} />
                </Grid>
                <Grid item container xs={12} sx={{ paddingTop: '3rem' }} id="selectedquote">
                    <Typography variant="h5" component="h5">
                        Selected quote
                    </Typography>

                    {selectedQuote &&
                        <Grid item container xs={12} sx={{ padding: '1rem' }} >
                            <Grid item container md={8}>
                                <Grid item container xs={12} justifyContent="space-between">
                                    <DisplayWithLabel label="Id" value={selectedQuote.id} />
                           
                                    <DisplayWithLabel label="Quote valid" value={         selectedQuote.quoteValid.slice(8, 10) + '.'+selectedQuote.quoteValid.slice(5, 7)+ '.'+selectedQuote.quoteValid.slice(0, 4) ?? 'n/a'} />
                                    <DisplayWithLabel label="Total Price" value={selectedQuote.totalPrice.toFixed(2) + " " + state.currency} />
                                    <DisplayWithLabel label="Estimated Transit Time" value={selectedQuote.estimatedTransitTime === 0 ? "n/a" : selectedQuote.estimatedTransitTime + " days" } />
                                </Grid>
                            </Grid>
                            <Grid item container md={4} >
                                <Grid item container xs={12} justifyContent="flex-end">
                                    <img style={{ width: '200px', margin: '10px 0 0 25px', borderRadius: '0.2rem' }} alt="FreighOpt" src={selectedQuote.carrierLogoUrl} />
                                </Grid>
                            </Grid>
                            <Grid item container xs={12}>
                                <Typography variant="overline" component="overline">
                                    Additional information
                                </Typography>
                                <Grid item xs={12} sx={{ border: "1px solid #eee", minHeight: "10rem", borderRadius: '0.25rem', backgroundColor: '#fcfcfc' }}>

                                    <Typography textAlign="left" sx={{ color: "#000", padding:'0.25rem' }} variant="body" mb={1} >
                                        {selectedQuote.additionalInformation}
                                    </Typography>

                                </Grid>

                            </Grid>
                            <Grid item container xs={6} justifyContent="space-between">
                                <Grid item md={12}>
                                    <Typography variant="overline" component="overline">
                                        Documents
                                    </Typography>
                                </Grid>
                                <Grid item container md={12}>
                                    {selectedQuote.file.map((x, i) =>
                                        <Grid item xs={12} key={i}>
                                            <Link href={x.path} ><FileDownloadIcon />{x.name}</Link>
                                        </Grid>

                                    )}
                                </Grid>
                              
                            </Grid>
                            {/* <Grid item container xs={6} justifyContent="flex-end" alignContent="flex-end">
                                    <Grid item >
                                        <SuiButton onClick={() => book(state.selectedQuote)} disabled={state.booked} variant="contained" color="primary">
                                            Book
                                        </SuiButton>

                                    </Grid>
                                </Grid> */}

                        </Grid>
                    }
                </Grid>
            </Grid>
        </>
    )
}