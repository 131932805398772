/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { forwardRef } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import InputAdornment from '@mui/material/InputAdornment';
// Custom styles for SuiInput
import SuiInputRoot from "_softUI/components/SuiInput/SuiInputRoot";
import SuiInputWithIconRoot from "_softUI/components/SuiInput/SuiInputWithIconRoot";
import SuiInputIconBoxRoot from "_softUI/components/SuiInput/SuiInputIconBoxRoot";
import SuiInputIconRoot from "_softUI/components/SuiInput/SuiInputIconRoot";

// Soft UI Dashboard PRO React contexts
import { useSoftUIController } from "_softUI/context";

const SuiInput = forwardRef(({ size, icon, error, success, disabled, ...rest }, ref) => {
  let template;
  const [controller] = useSoftUIController();
  const { direction } = controller;
  const iconDirection = icon.direction;

  if (icon.component && icon.direction === "left") {
    template = (
      <SuiInputWithIconRoot ref={ref} ownerState={{ error, success, disabled }}>
        <SuiInputIconBoxRoot ownerState={{ size }}>
          <SuiInputIconRoot fontSize="small" ownerState={{ size }}>
            {icon.component}
          </SuiInputIconRoot>
        </SuiInputIconBoxRoot>
        <SuiInputRoot
          {...rest}

          ownerState={{ size, error, success, iconDirection, direction, disabled }}
          
        />
      </SuiInputWithIconRoot>
    );
  } else if (icon.component && icon.direction === "right") {
    template = (
      <SuiInputWithIconRoot ref={ref} ownerState={{ error, success, disabled }}>
        <SuiInputRoot
          {...rest}
          ownerState={{ size, error, success, iconDirection, direction, disabled }}
          endAdornment={<InputAdornment sx={{height:'100%', position:'absolute',right:0,paddingRight:'0.5rem'}} position="end">{!error && icon.component}</InputAdornment>}
        />
        {/* <SuiInputIconBoxRoot ownerState={{ size }}>
          <SuiInputIconRoot fontSize="small" sx={{width:'1.5em',height:'unset'}} ownerState={{ size }}>
          {icon.component}
          </SuiInputIconRoot>
        </SuiInputIconBoxRoot> */}
      </SuiInputWithIconRoot>
    );
  } else {
    template = <SuiInputRoot sx={{mt:'-0.25rem'}} 
    {...rest} 
    ref={ref} 
    ownerState={{ size, error, success, disabled }}

    />;
  }

  return template;
});

// Setting default values for the props of SuiInput
SuiInput.defaultProps = {
  size: "medium",
  icon: {
    component: false,
    direction: "none",
  },
  error: false,
  success: false,
  disabled: false,

};

// Typechecking props for the SuiInput
SuiInput.propTypes = {
  size: PropTypes.oneOf(["small", "medium", "large"]),
  icon: PropTypes.shape({
    component: PropTypes.oneOfType([PropTypes.node, PropTypes.bool]),
    direction: PropTypes.oneOf(["none", "left", "right"]),
  }),
  error: PropTypes.bool,
  success: PropTypes.bool,
  disabled: PropTypes.bool,
};

export default SuiInput;
