import React from 'react';
import Grid from '@mui/material/Grid';
import { ShipmentInformationFormActions } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import SelectWithLabel from "_components/FormFields/SelectWithLabel";
import InputWithLabel from "_components/FormFields/InputWithLabel";

import { incoterms,incotermsInstantImport,incotermsInstantExport, transportationTypes} from '_helpers'
import Collapse from '@mui/material/Collapse';
import SuiButton from "_softUI/components/SuiButton";

import SuiBadge from "_softUI/components/SuiBadge";

import Label from '_components/FormFields/Label';



Date.prototype.addDays = function(days) {
  var date = new Date(this.valueOf());
  date.setDate(date.getDate() + days);
  return date;
}

export function BasicInfo({ instant, next, visible, validateLength }) {

  const stateParent = useSelector(state => state.ShipmentInformationForm)
  const state = stateParent.data

  let today = new Date()
  let deadlineDay = today.addDays(1);
  const deadlineDefault = deadlineDay.toISOString().slice(0,10)+'T16:00'

  const dispatch = useDispatch()
  function handleChange(event) {


    dispatch(ShipmentInformationFormActions.updateBasicValue(event.target.name, event.target.value));
  }

  const setDefault = (target, value) => new Promise((resolve, reject) => {
    console.log(state.deadlineForQuotes)
    if(state.deadlineForQuotes === null || state.deadlineForQuotes === ''){
      console.log(value)
      dispatch(ShipmentInformationFormActions.updateBasicValue(target, value));
    }

    resolve();
  })


  function handleChangeWithMaxLenght(event, label, maxLenght) {
    const newValue = validateLength(label, event.target.value, maxLenght)
    console.log(newValue)
    dispatch(ShipmentInformationFormActions.updateBasicValue(event.target.name, newValue));
  }

  function handleSelectChange(name, value) {
    if (name === 'transportationType') {
      dispatch(ShipmentInformationFormActions.resetGoods());
    }
    dispatch(ShipmentInformationFormActions.updateBasicValue(name, value));
  }



  function setCollapseUnvisible() {

    next('details');



  }

  const instantIncoterm = state.direction === 2 ? incotermsInstantExport: incotermsInstantImport



  const insurancePriceObject = state.pricingDetails.find(x => { return x.name === 'insurance' })
  const insurancePrice = insurancePriceObject ? insurancePriceObject.doubleValue : 0;

  const adrPriceObject = state.pricingDetails.find(x => { return x.name === 'adr' })
  const adrPrice = adrPriceObject ? adrPriceObject.doubleValue : 0;

  const tailLiftObject = state.pricingDetails.find(x => { return x.name === 'tailLift' })
  const tailLiftPrice = tailLiftObject ? tailLiftObject.doubleValue : 0;

  const declarationPriceObject = state.pricingDetails.find(x => { return x.name === 'impExpDeclaration' })
  const declarationPrice = declarationPriceObject ? declarationPriceObject.doubleValue : 0;

  const callOnPickupPriceObject = state.pricingDetails.find(x => { return x.name === 'callOnPickup' })
  const callOnPickupPrice = callOnPickupPriceObject ? callOnPickupPriceObject.doubleValue : 0;

  const callOnDeliveryPriceObject = state.pricingDetails.find(x => { return x.name === 'callOnDelivery' })
  const callOnDeliveryPrice = callOnDeliveryPriceObject ? callOnDeliveryPriceObject.doubleValue : 0;



  return (




    <Grid item xs={12} container spacing={2} justifyContent="space-evenly" alignItems="stretch" >


      <Grid item container xs={12} >

        {!visible &&
          <>
            <Grid item container xs={12} spacing={2} >
              <Grid item xs={4} md={3} sx={{ textAlign: 'center' }} >
                <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent="Goods ready" />
                <Label value={state.pickupStart} />
              </Grid>
              <Grid item xs={4} md={3} sx={{ textAlign: 'center' }}>
                <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent="Sender reference" />
                <Label value={state.senderReference} />

              </Grid>
              <Grid item xs={4} md={3} sx={{ textAlign: 'center' }}>
                <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent="Receiver reference" />
                <Label value={state.receiverReference} />

              </Grid>
              <Grid item xs={4} md={3} sx={{ textAlign: 'center' }}>
                <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent="Message to carrier" />
                <Label value={state.messageToCarrier.length > 20 ? state.messageToCarrier.substring(0, 15) + '...' : state.messageToCarrier} />

              </Grid>

            </Grid>
            <Grid item container xs={12} spacing={2}>
              <Grid item xs={6}>
                <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent="Additional services" />
                {state.taiLift && <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="secondary" badgeContent={"Tail lift loading / dismantling: " + tailLiftPrice + "€"} />}
                {state.insurance && <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="secondary" badgeContent={"Insured goods value " + state.goodsValue + ": " + insurancePrice + "€"} />}
                {state.adr && <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="secondary" badgeContent={"ADR Class " + state.adrClass + ": " + adrPrice + "€"} />}
                {state.impExpDeclaration && <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="secondary" badgeContent={"Import / export declarations: " + declarationPrice + "€"} />}


              </Grid>
            </Grid>

          </>
        }


        <Grid item container xs={12} sx={{pb:3}}>

          {/* <Collapse in={visible}> */}

            {/* </Grid> */}
            <Grid item xs={12}  >

              {/* </Grid> */}
              <Grid item xs={12}>
                <Grid container item xs={12} spacing={1}>
                  {!instant &&
                    <Grid item xs={12}>
                      <Grid container spacing={1}>
                        <Grid item xs={4}>
                          <InputWithLabel
                            label="Deadline for quotes"
                            name="deadlineForQuotes"
                            onChange={e => handleChange(e)}
                            fullWidth
                            value={state.deadlineForQuotes}
                            type="datetime-local"
                            setDefault={() => setDefault('deadlineForQuotes',deadlineDefault)}
                       
                            inputProps={{min:today.toISOString().slice(0,16)}}
                         
                            mandatory={true}
                            error={state.deadlineForQuotesError}
                          />
                          
                        </Grid>
                        <Grid item xs={2}>
                          <SelectWithLabel
                            label="Transportation type"
                            value={transportationTypes.find(obj => {
                              return obj.value === state.transportationType
                            })}
                            options={transportationTypes}
                            fullWidth
                            size="medium"
                            name="transportationType"
                            mandatory={true}
                            error={state.transportationTypeError}
                            inputProps={{min:today.toISOString().slice(0,16)}}
                            onChange={e => handleSelectChange('transportationType', e.value)}
                          />

                        </Grid>
                        <Grid item xs={2}>
                          <SelectWithLabel
                            label="Incoterm"
                            value={incoterms.find(obj => {
                              return obj.value === state.incoterm
                            })}
                            options={incoterms}
                            fullWidth
                            size="medium"
                            name="incoterm"
                            mandatory={true}
                            error={state.incotermError}
                            onChange={e => handleSelectChange('incoterm', e.value)}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <InputWithLabel
                            label="Incoterm location"
                            name="incotermLocation"
                            onChange={e => handleChange(e)}
                            fullWidth
                            value={state.incotermLocation}

                            placeholder="location"
                          />
                        </Grid>


                      </Grid>
                    </Grid>}
                  <Grid item container xs={12}>
                    <Grid container item spacing={1}>
                      <Grid item xs={4}>
                        <InputWithLabel
                          label="Goods ready for pickup"
                          name="pickupStart"
                          onChange={e => handleChange(e)}
                          fullWidth
                          value={state.pickupStart}
                          type="date"
                          mandatory={true}

                          error={state.pickupStartError}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <InputWithLabel
                          label="Sender reference"
                          name="senderReference"
                          onChange={e => handleChangeWithMaxLenght(e, "Sender reference", 35)}
                          fullWidth
                          value={state.senderReference}
                          maxlenght={35}
                          adornment="#"
                          adornmentPosition="end"
                          placeholder="refrence"

                        />

                      </Grid>
                      <Grid item xs={4}>
                        <InputWithLabel
                          label="Receiver reference"
                          name="receiverReference"
                          onChange={e => handleChangeWithMaxLenght(e, "Receiver reference", 35)}
                          fullWidth
                          value={state.receiverReference}

                          adornment="#"
                          adornmentPosition="end"
                          placeholder="refrence"
                        />
                      </Grid>
                      </Grid>
                      {instant && state.customsNeeded &&
                     <Grid item container xs={12}>
                     <Grid container item spacing={1}>
                      <Grid item xs={2}>
                          <SelectWithLabel
                            label="Incoterm"
                            value={instantIncoterm.find(obj => {
                              return obj.value === state.incoterm
                            })}
                            options={instantIncoterm}
                            fullWidth
                            size="medium"
                            name="incoterm"
                            mandatory={true}
                            error={state.incotermError}
                            onChange={e => handleSelectChange('incoterm', e.value)}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <InputWithLabel
                            label="Incoterm location"
                            name="incotermLocation"
                            onChange={e => handleChange(e)}
                            fullWidth
                            value={state.incotermLocation}

                            placeholder="location"
                          />
                        </Grid>
                        </Grid>
                        </Grid>
                    }
          
                  </Grid>


                  {next &&
                    <Grid item container justifyContent="flex-end">
                      <SuiButton color="secondary" variant="contained" onClick={setCollapseUnvisible}>
                        Apply
                      </SuiButton>
                    </Grid>
                  }

                </Grid>
              </Grid>

            </Grid>

          {/* </Collapse> */}
        </Grid>
      </Grid>

    </Grid>





  )
}