import React from 'react';
import Grid from '@mui/material/Grid';
import SuiTypography from "_softUI/components/SuiTypography";
import Checkbox from '@mui/material/Checkbox';

import SuiBox from '_softUI/components/SuiBox'
import Loader from 'react-dots-loader'


export default function AdditionalService({ title,currency, value, name, instructions, changeHandler, state, force,showPrice, ...rest }) {


    return (

        <Grid container sx={{ padding: '0.25rem'}}>
         
            <Grid item xs={1} container alignItems="flex-start">
                <Grid item xs={12} sx={{ paddinLeft: '1rem' }}>
                    <Checkbox disabled={force} checked={force ? true :  state} onChange={e => changeHandler(name, e.target.checked)}

                    />
                </Grid>
            </Grid>
            <Grid item container xs={11}>
        
                <Grid item container xs={9}>
                    <Grid item xs={8} container alignItems="center">
                        <SuiBox display="flex" flexDirection="column" lineHeight={0}>
                            <SuiTypography variant="button" fontWeight="bold" color={force ? "light" : "dark"}>
                                {title}
                            </SuiTypography>


                            <SuiTypography variant="caption" fontWeight="light" color={force ? "light" : "dark"}>
                                {instructions}
                            </SuiTypography>
                        </SuiBox>
                    </Grid>
                    <Grid item container xs={4} alignItems="center">
                        <Grid item xs={12}>
                            {rest.children}
                        </Grid>

                    </Grid>
                </Grid>
                {showPrice && 
                <Grid item xs={3} container alignItems="center" justifyContent="center">
                    <SuiTypography variant="button" fontWeight="bold" color={force ? "light" : "dark"}>
                        {rest.calculating &&
                            <Loader size={7} color="#183B59" />
                        }
                        {!rest.calculating && 
                             (value + " " + (!force ? currency : ""))
                         }
                    </SuiTypography>

                </Grid>
            }
            </Grid>
        </Grid>
    )
}