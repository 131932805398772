import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiBox from "_softUI/components/SuiBox";

import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import { adminActions } from '_actions/admin.actions';

import { CalculatorInput } from '_components/Admin/CalculatorInput';

import DataTable from '_components/Tables/DataTable';
import SuiButton from '_softUI/components/SuiButton';

export default function ManageUsers() {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const state = useSelector(state => state.admin);

    const data = {
        columns: [
            { Header: "ID", accessor: "id" },
            { Header: "NAME", accessor: "name" },
            { Header: "CUSTOMER", accessor: "customer" },
            { Header: "CARRIER", accessor: "carrier" },
            { Header: "COUNTRY", accessor: "country" },
            { Header: "TYPE", accessor: "calculatorType" },


        ],
        rows: state.calculators.map(x => (
            {
                'id': x.id,
                name: x.name,
                customer: x.customer,
                carrier: x.carrier,
                calculatorType: x.calculatorTypeName,
                country: x.country

            }))
    }


    useEffect(() => {
        dispatch(adminActions.initateCalculators());
        dispatch(adminActions.initateCarriers());
        dispatch(adminActions.initateCustomers());

    }, [])



    const save = () => {

        dispatch(adminActions.createOrUpdateCalculator(state.calculatorInput, handleModalClose));
    }

    const handleModalOpen = (type) => {

        if (type === 'new') {
            setModalTitle('Create a calculator')


        }

        setModalOpen(true);
    }

    const handleRowClick = (id) => {
        console.log(id)
        dispatch(adminActions.initateSelectedCalculator(id));
        setModalOpen(true);
    }

    const handleModalClose = () => {
        dispatch(adminActions.clearSelectedCalculator());
        setModalOpen(false);
    }



    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start" sx={{ height: '100%' }}>



            <Grid container item xs={12} alignItems="strecth" justifyContent="center">
                <Grid item xs={12} md={12}>
                    <Card sx={{ overflow: "visible", p: 3 }}>
                        <SuiBox>
                            <Grid container alignItems="strecth" justifyContent="center">
                                <Grid item xs={6}>
                                    <SuiTypography variant="h5">Manage calculators</SuiTypography>
                                </Grid>
                                <Grid item xs={6} dir="rtl">
                                    <SuiButton color="secondary" onClick={() => handleModalOpen('new')}>New calculator</SuiButton>
                                </Grid>
                            </Grid>


                            <SuiBox mt={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>

                                   {useMemo(()=>    <DataTable handleRowClick={handleRowClick} canSearch table={data} />
                                    ,[state.calculators])} 
                                    </Grid>
                                </Grid>

                            </SuiBox>
                        </SuiBox>
                        <CalculatorInput open={modalOpen} title={modalTitle} handleClose={handleModalClose} save={save}></CalculatorInput>
                    </Card>
                </Grid>
            </Grid>

        </Grid>
    )

}

