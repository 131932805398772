
import Grid from "@mui/material/Grid";

import { Paper } from "@mui/material";

export function InstantPricingContainer(props) {


  return (
   
    <Paper elevation={3}>
      <Grid container display="flex" direction="column" alignItems="stretch" alignContent="center" sx={{}}  >
        <Grid container sx={{}}>

          <Grid item p="1rem" mb="1rem" xs={12}  >

            <Grid container spacing={0} flexDirection="column" alignItems="center" >


              {props.children}

            </Grid>


          </Grid>


        </Grid>


      </Grid>
    </Paper>
    //   </SuiBox>

    // </SuiBox>
  );
}


