import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputWithLabel from '_components/FormFields/InputWithLabel';
import Switch from '@mui/material/Switch';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiButton from '_softUI/components/SuiButton';
import SuiBox from "_softUI/components/SuiBox";
import { adminActions } from '_actions/admin.actions';
import { useDispatch, useSelector } from 'react-redux';
import { useConfirm } from 'material-ui-confirm';
import { Loading } from '_components/Shared/LoadingSpinner'
import {carrierLabelDeliveryType} from '_helpers/selections'
import SelectWithLabel from '_components/FormFields/SelectWithLabel';


export function CarrierInput({open, handleClose, title, save }) {
  const dispatch = useDispatch();
  const confirm = useConfirm();
  const state = useSelector(state => state.admin);

  const carrierList = state.carriers && state.carriers.map(x => (
    {
      value: x.id,
      label: x.name
    }))

  function handleCarrierInputChange(event) {
    dispatch(adminActions.updateCarrierInputValue(event.target.name, event.target.value));
  }

  function handleSelectChange(event, name) {

    dispatch(adminActions.updateCarrierInputValue(name, event.value));
}

function handleSwitchChange(event) {
  dispatch(adminActions.updateCarrierInputValue(event.target.name, event.target.checked));
}

  const handleDeleteClick = () => {
    confirm({ description: 'Deleting a Carrier cannot be undone' })
      .then(() => { 
        
        dispatch(adminActions.deleteCarrier(state.selectedCarrier, handleClose)) 
      })
      .catch(() => { /* ... */ });
  };

  return (
    <React.Fragment>
      <Dialog
        fullWidth={true}
        maxWidth='sm'
        open={open}
        onClose={handleClose}
        aria-labelledby="max-width-dialog-title"
        scroll='body'

      >  
      {state.loading && 
      <Grid sx={{ marginTop: '32px' }}>
      <Loading />
      </Grid>
      }
      {!state.loading && <> 
      </>
      } 
      <DialogTitle>
      {title}
      </DialogTitle>
      
      <DialogContent >

          <Grid container spacing={2}>
            <Grid item >
          
            </Grid>
            <Grid item xs={12} >

              <InputWithLabel label="Name" name="name" value={state.carrierInput.name ?? ''} onChange={(e) => handleCarrierInputChange(e)} ></InputWithLabel>

            </Grid>
            <Grid item xs={12} >

              <InputWithLabel label="Logo Url" name="logoUrl" value={state.carrierInput.logoUrl ?? ''} onChange={(e) => handleCarrierInputChange(e)}  ></InputWithLabel>

            </Grid>
            <Grid item xs={12} md={6} >

<SelectWithLabel
    value={carrierLabelDeliveryType.find(obj => {
        return obj.value === state.carrierInput.carrierLabelDeliveryType
    })}

    onChange={e => handleSelectChange(e, 'carrierLabelDeliveryType')}
    label="Label delivery type" options={carrierLabelDeliveryType}
    name="carrierLabelDeliveryType" />

</Grid>
<Grid item xs={12} md={6} >

<SelectWithLabel
    value={carrierList.find(obj => {
        return obj.value === state.carrierInput.parentCarrierId
    })}

    onChange={e => handleSelectChange(e, 'parentCarrierId')}
    label="Parent Carrier" options={carrierList}
    name="parentCarrierId" />  

</Grid>

  <Grid item xs={12} md={6} sx={{ display: 'flex', alignItems: 'center' }}>
    <SuiTypography ml={0.5} component="label" variant="caption" textTransform="capitalize">
      FreightOpt Price?
    </SuiTypography>
  </Grid>
  <Grid item xs={12} md={6} sx={{ display: 'flex', justifyContent: 'center' }}>
    <Switch checked={state.carrierInput.isFreightoptPrice} onChange={e => handleSwitchChange(e)} name="isFreightoptPrice" />
  </Grid>

          </Grid>
        </DialogContent>
        <DialogActions>
          <Grid
            container
            justifyContent="space-between"
            alignItems="flex-start">
            <Grid item >
              <SuiButton onClick={handleClose} variant="contained" color="warning">
                Cancel
              </SuiButton>
            </Grid>

            {state.selectedCarrier > 0 && 
            <Grid item >
                <SuiButton onClick={handleDeleteClick} variant="contained" color="error">
                    Delete
                </SuiButton>
                </Grid>}

            <Grid item>

            <SuiButton onClick={save} variant="contained" color="primary">
                Save
              </SuiButton>
          
          
         

            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>

    </React.Fragment>
  )

}