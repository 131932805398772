/**
=========================================================
* Soft UI Dashboard PRO React - v3.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/soft-ui-dashboard-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// Soft UI Dashboard PRO React Base Styles
import colors from "_softUI/assets/theme/base/colors";
import borders from "_softUI/assets/theme/base/borders";
import boxShadows from "_softUI/assets/theme/base/boxShadows";

// Soft UI Dashboard PRO React Helper Function
import rgba from "_softUI/assets/theme/functions/rgba";

const { black, white } = colors;
const { borderWidth, borderRadius } = borders;
const { xxl } = boxShadows;

export default {
  styleOverrides: {
    root: {
      // display: "flex",
      // flexDirection: "column",
      // position: "relative",
      // minWidth: 0,
      // wordWrap: "break-word",
      // backgroundColor: white.main,
      // backgroundClip: "border-box",
      // border: `${borderWidth[0]} solid ${rgba(black.main, 0.125)}`,
      // borderRadius: borderRadius.xl,
      // boxShadow: xxl,
    },
  },
};
