import { dashboardConstants, alertConstants } from '../_constants';
import { alertActions } from '.';
import { dashboardService } from '../_services'

export const dashboardActions = {
    initate,
    setSelectedSearchHistory,
  };
  function failure(message) { return { type: alertConstants.ERROR, message } }  

  function initate() {
    return dispatch => {
      dispatch(loading());
  
      dashboardService.getDashboard()
        .then(
          data => {
            console.log(data)
            dispatch(initate(data))
          },
          error => {
            dispatch(failure(error))
          }
        )
  
      function loading() { return { type: dashboardConstants.LOADING } }
      function initate(data) { return { type: dashboardConstants.INITATE, data } }
    }
  }

function setSelectedSearchHistory(id, data){
  return {type: dashboardConstants.SET_SELECTED_SEARCH, id, data}
}
