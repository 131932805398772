import React, { useCallback } from 'react';
import Grid from '@mui/material/Grid';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import InputWithLabel from '_components/FormFields/InputWithLabel';
import SuiButton from '_softUI/components/SuiButton';
import { adminActions } from '_actions/admin.actions';
import { useDispatch, useSelector } from 'react-redux';
import { arrayBufferToBase64 } from '_helpers/file'
import SelectWithLabel from '_components/FormFields/SelectWithLabel';
import Dropzone from '_components/Common/Dropzone'
import ExcelCellInfoContainer from './ExcelCellInfoContainer';
import { useConfirm } from 'material-ui-confirm';
import { Loading } from '_components/Shared/LoadingSpinner'
import {countries} from '_helpers/selections'

export function CalculatorInput({ open, handleClose, title, save }) {
    const dispatch = useDispatch();
    const confirm = useConfirm();
    const state = useSelector(state => state.admin);

    function handleInputChange(event) {
        dispatch(adminActions.updateCalculatorInputValue(event.target.name, event.target.value));
    }

    function handleSelectChange(event, name) {

        dispatch(adminActions.updateCalculatorInputValue(name, event.value));
    }

    const handleFile = useCallback((acceptedFiles) => {
        acceptedFiles.forEach((file) => {
            const reader = new FileReader()

            reader.onabort = () => console.log('file reading was aborted')
            reader.onerror = () => console.log('file reading has failed')
            reader.onload = () => {

                const entry = {
                    name: file.path,
                    data: arrayBufferToBase64(reader.result),
                    type: file.type
                }

                dispatch(adminActions.storeFile(entry))
            }
            reader.readAsArrayBuffer(file)
        })

    }, [])

    const handleDeleteClick = () => {
        confirm({ description: 'Deleting a calculator cannot be undone' })
            .then(() => { dispatch(adminActions.deleteCalculator(state.selectedCalculator, handleClose)) })
            .catch(() => { /* ... */ });
    };


    const typeOptions = [
        { value: 0, label: 'Default' },
        { value: 1, label: 'Parcel' },
        { value: 2, label: 'Pallet' },
        { value: 3, label: 'Groupage' },
        { value: 4, label: 'Fedex Regional Economy' },
        { value: 5, label: 'Fedex International Priority' },
        { value: 6, label: 'Fedex International Economy' },
        { value: 7, label: 'UPS Worldwide Express' },
        { value: 8, label: 'UPS Standard' },
        { value: 9, label: 'UPS Expedited' },
        { value: 10, label: 'UPS Saver' },
        { value: 11, label: 'DHL Sweden parcel' },
        { value: 12, label: 'DHL Sweden pallet' },
        { value: 13, label: 'DHL Sweden kappaletavara' },
        { value: 14, label: 'DHL Sweden osakuorma' },
        { value: 15, label: 'DHL Sweden euroconnect/euroline' },
        { value: 17, label: 'DHL Finland euroconnect/euroline' },
        { value: 19, label: 'Schenker System Europe' },
        { value: 20, label: 'Schenker System Finland' },
        { value: 21, label: 'Schenker Parcel Finland' },

    ]
    const calculationRuleOptions = [
        { value: 0, label: 'Default' },
        { value: 1, label: 'DHL' },
        { value: 2, label: 'DSV' },
        { value: 3, label: 'Kuehne' },
        { value: 4, label: 'PostnordParcel' },
        { value: 5, label: 'PostnordPallet' },
        { value: 6, label: 'PostnordGroupage' },
        { value: 7, label: 'GLS' },
        { value: 8, label: 'JAS' },
        { value: 9, label: 'Varova' },
        { value: 10, label: 'Schenker' },
        { value: 11, label: 'Fedex' },
        { value: 12, label: 'Posti' },
        { value: 13, label: 'Ups' },
    ]
    const customerList = state.customers && state.customers.map(x => (
        {
            value: x.id,
            label: x.name
        }))

    const carrierList = state.carriers && state.carriers.map(x => (
        {
            value: x.id,
            label: x.name
        }))

    return (
        <React.Fragment>
            <Dialog
                fullWidth={true}
                maxWidth='lg'
                open={open}
                onClose={handleClose}
                aria-labelledby="max-width-dialog-title"
                scroll='body'
                sx={{ overflowY: 'unset', '.MuiPaper-root': { overflowY: 'unset' } }}

            >
                {state.loading &&
                    <Grid sx={{ marginTop: '32px' }}>
                        <Loading />
                    </Grid>
                }
                {!state.loading && <>
                </>
                }
                <DialogTitle>
                    {title}
                </DialogTitle>

                <DialogContent sx={{ overflowY: 'unset' }}  >

                    <Grid container spacing={2}>
                        <Grid item xs={12} md={6}>

                            <InputWithLabel
                                label="Name"
                                name="name"
                                value={state.calculatorInput.name ?? ''}
                                onChange={(e) => handleInputChange(e)} ></InputWithLabel>

                        </Grid>
                        <Grid item xs={12} md={6} >

                            <SelectWithLabel
                                value={typeOptions.find(obj => {
                                    return obj.value === state.calculatorInput.calculatorType
                                })}

                                onChange={e => handleSelectChange(e, 'calculatorType')}
                                label="Type" options={typeOptions}
                                name="calculatorType" />

                        </Grid>

                        <Grid item xs={12} md={6} >

<SelectWithLabel
    value={countries.find(obj => {
        return obj.value === state.calculatorInput.countryId
    })}

    onChange={e => handleSelectChange(e, 'countryId')}
    label="Country" options={countries}
    name="countryId" 

    />

</Grid>

                        <Grid item xs={12} md={6} >

                            <SelectWithLabel
                                value={customerList.find(obj => {
                                    return obj.value === state.calculatorInput.customerId
                                })}

                                onChange={e => handleSelectChange(e, 'customerId')}
                                label="Customer" options={customerList}
                                name="customerId" />

                        </Grid>
                        <Grid item xs={12} md={6} >

                            <SelectWithLabel
                                value={carrierList.find(obj => {
                                    return obj.value === state.calculatorInput.carrierId
                                })}

                                onChange={e => handleSelectChange(e, 'carrierId')}
                                label="Carrier" options={carrierList}
                                name="carrierId" />

                        </Grid>
                        <Grid item xs={12} md={6} >

                            <SelectWithLabel
                                value={calculationRuleOptions.find(obj => {
                                    return obj.value === state.calculatorInput.calculationRule
                                })}

                                onChange={e => handleSelectChange(e, 'calculationRule')}
                                label="Calcuation rule" options={calculationRuleOptions}
                                name="calculationRule" />

                        </Grid>
                        <Grid item xs={12} >

                            <Dropzone
                                action={handleFile}
                                files={[].concat(state.calculatorInput.file)}
                                singleFile={true}
                                title="Select calculator excel"
                                hint=""
                                showIcon={false}
                                acceptedFileFormats={['application/vnd.ms-excel', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet']} />

                        </Grid>
                        <Grid item xs={12} >
                            <ExcelCellInfoContainer state={state.calculatorInput.cellRows} />
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Grid
                        container
                        justifyContent="space-between"
                        alignItems="flex-start">
                        <Grid item >
                            <SuiButton onClick={handleClose} variant="contained" color="warning">
                                Close
                            </SuiButton>
                        </Grid>
                        {state.selectedCalculator > 0 &&
                            <Grid item >
                                <SuiButton onClick={handleDeleteClick} variant="contained" color="error">
                                    Delete
                                </SuiButton>
                            </Grid>}
                        <Grid item>

                            <SuiButton onClick={save} variant="contained" color="primary">
                                Save
                            </SuiButton>




                        </Grid>
                    </Grid>
                </DialogActions>
            </Dialog>

        </React.Fragment>
    )

}