import React, { useCallback } from 'react';

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Divider from '@mui/material/Divider';

import { connect } from 'react-redux';

import { shipmentActions, customerSpotRequestTableActions } from '_actions';
import freightoptLogoSmall from '../../_assets/images/newLogo.jpg'
import Grid from '@mui/material/Grid';
import { Loading, Success } from '../Shared'
import { useDispatch, useSelector } from 'react-redux';
import ShipmentInformationPreview from '_components/ShipmentInformationPreview/ShipmentInformationPreview'
import SuiButton from "_softUI/components/SuiButton";
import Tooltip from '@mui/material/Tooltip';
import { useState, useEffect, useRef } from "react";
import { useConfirm } from 'material-ui-confirm';

import { convertUTCDateToLocalDate } from '_helpers'
import BasicInfoView from '_components/ShipmentInformationPreview/BasicInfoView'
import LocationView from '_components/ShipmentInformationPreview/LocationView'
import breakpoints from "_softUI/assets/theme/base/breakpoints";
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import DescriptionIcon from '@mui/icons-material/DescriptionRounded';
import ConstructionRoundedIcon from '@mui/icons-material/ConstructionRounded';
import BeenhereRoundedIcon from '@mui/icons-material/BeenhereRounded';
import LocalOfferRoundedIcon from '@mui/icons-material/LocalOfferRounded';
import Cube from "_softUI/icons/Cube";
import Document from "_softUI/icons/Document";
import Settings from "_softUI/icons/Settings";
import DocumentsAndLabels from '_components/ShipmentDisplayModal/DocumentsAndLabels'
import Admin from '_components/ShipmentDisplayModal/Admin'
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Tracking from './Tracking';
import Quotes from './Quotes';
import { GoodsPreview } from '_components/Common/Goods/GoodsPreview';
import ShipmentPreviewSectionContainer from '_components/Common/ShipmentPreviewSectionContainer';
import Pricing from '_components/ShipmentInformationPreview/Pricing';
import AdditionalInformationView from '_components/ShipmentInformationPreview/AdditionalInformationView';
import { customerSpotRequestTable } from '_reducers/customerSpotRequestTable.reducer';
import { useHistory } from "react-router-dom";
import { ShipmentInformationFormActions } from '../../_actions';
import CopyIcon from '@mui/icons-material/ContentCopyOutlined';
import Typography from '@mui/material/Typography';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import generatePDF, { Margin}  from 'react-to-pdf';
import { TroubleshootRounded } from '@mui/icons-material';


export function ShipmentDisplayModal({ state, shipmentFamily }) {
    const confirm = useConfirm();
    const dispatch = useDispatch();
    const history = useHistory();

    const [tabsOrientation, setTabsOrientation] = useState("horizontal");


    const actions = shipmentFamily === 'spot' ? customerSpotRequestTableActions : shipmentActions

    var authState = useSelector(state => state.authentication);
    const isAdmin = authState.user.roles.find(x => x === 'Admin');
    const isSpot = state.selectedShipment.shipmentType === 1 || state.selectedShipment.shipmentType === 3;

    const handleClose = () => {
        dispatch(actions.closeModal());
    }

    const tabTitle = isSpot ? "Request" : "Booking"
    const defaultTab = isSpot ? 2 : 0
    console.log(defaultTab)
    const [tabValue, setTabValue] = useState(defaultTab);
    const targetRef = useRef();

    useEffect(() => {
        setTabValue(defaultTab)
    }, [isSpot])

    const handleCancelClick = () => {
        confirm({ description: 'Cancelling spot request cannot be undone' })
            .then(() => {
                dispatch(actions.cancelSpot(state.selectedShipment.id, state.selectedShipment.id, "Cancelled"));

            })
            .catch(() => { /* ... */ });
    };


    const handleSetTabValue = (event, newValue) => setTabValue(newValue);



    function HandleBookNow() {
        if (shipmentFamily !== 'spot') {
            dispatch(ShipmentInformationFormActions.copy(state.selectedShipment));
            history.push("/InstantBookingProcess", { fromPush: true });
        } else {
            dispatch(ShipmentInformationFormActions.copy(state.selectedShipment));
            history.push("/spotQuoteProcess", { fromPush: true });
        }

    }

    const deadlinePassed = () => {
        const deadlineDate = new Date(state.selectedShipment.deadline)
        const localDeadline = convertUTCDateToLocalDate(deadlineDate)

        if (new Date() > localDeadline) {
            return true;
        }
        else {
            return false;
        }
    }

    function book(id) {
        dispatch(customerSpotRequestTableActions.bookSpot(id, state.selectedShipment.id, "Booked"))
    }

    const selectedQuote = state.selectedShipment.quotes.find(quote => quote.id === state.selectedShipment.selectedQuote);
    const bookable = new Date(selectedQuote?.quoteValid) >=Date.now();

    const mod = () => {

        console.log('test')
    }

    const options = {

        page: {
           // margin is in MM, default is Margin.NONE = 0
           margin: Margin.MEDIUM,

        },

     };


    return (
        <React.Fragment>

            <Dialog
                // fullWidth={true}
                maxWidth='xl'


                open={state.modalOpen}
                onClose={() => handleClose}
                aria-labelledby="max-width-dialog-title"
            // scroll='body'


            >

                {state.modalLoading && <Loading text="Loading" size="h5"></Loading>}

                {!state.modalLoading &&

                    <>
                        <Grid container>
                            <Grid item xs={4}>
                                <img style={{ width: '200px', margin: '10px 0 0 10px' }} alt="FreighOpt" src={freightoptLogoSmall} />
                            </Grid>
                            <Grid item xs={8} alignItems="center" display='flex' justifyContent='flex-end'>
                                {!isSpot &&
                                    <Typography mr={4} variant="h5" fontWeight={200} sx={{ color: "#D1D5CE" }}>
                                        <span>Booking id:</span> {state.selectedShipment.id}
                                    </Typography>
                                }
                                {isSpot &&
                                    <Typography mr={4} variant="h5" fontWeight={200} sx={{ color: "#D1D5CE" }}>
                                        <span>Request id:</span> {state.selectedShipment.id}
                                    </Typography>
                                }
                            </Grid>

                        </Grid>
                        <Divider sx={{ m: 0 }} />
                        <Grid container justifyContent="flex-end" sx={{ backgroundColor: '#fff' }}>

                            <Grid item xs={8}>
                                <Tabs
                                    orientation={tabsOrientation}
                                    value={tabValue}

                                    onChange={handleSetTabValue}
                                    sx={{ background: "transparent" }}
                                >
                                    <Tab label={tabTitle} icon={<BeenhereRoundedIcon sx={{ color: "#A7936A" }} />} value={0} />
                                    {!isSpot && <Tab label="Track & trace" icon={<PlaceRoundedIcon sx={{ color: "#A7936A" }} />} value={1} />}
                                    {isSpot && <Tab label="Quotes" icon={<LocalOfferRoundedIcon sx={{ color: "#A7936A" }} />} value={2} />}
                                    <Tab label="Documents and labels" icon={<DescriptionIcon sx={{ color: "#A7936A" }} />} value={3} />
                                    {isAdmin && <Tab label="Admin" icon={<ConstructionRoundedIcon sx={{ color: "#A7936A" }} />} value={4} />}
                                </Tabs>
                            </Grid>
                        </Grid>

                        <Divider sx={{ m: 0 }} />

                        <DialogContent  sx={{ height: '900px', width: '1000px', overflow: 'auto', paddingRight: '2rem', paddingLeft: '2rem' }}>
                            {state.modalLoading &&
                                <Loading text="Loading" size="h5"></Loading>
                            }
                            {(!state.modalLoading) && <>
                                {tabValue === 0 &&
                                    <div ref={targetRef}>
                                        <BasicInfoView state={state.selectedShipment} instant={!isSpot} type={!isSpot ? "InstantBooking" : "SpotRequest"} currency={state.selectedShipment.currency} />

                                        <LocationView state={state.selectedShipment.locations[0]} />

                                        <LocationView state={state.selectedShipment.locations[1]} />
                                        <ShipmentPreviewSectionContainer title="Goods">
                                            <GoodsPreview goods={state.selectedShipment.goods} state={state.selectedShipment} />
                                        </ShipmentPreviewSectionContainer>
                                        {isSpot && <>
                                            <ShipmentPreviewSectionContainer title="Additional Information">
                                                <AdditionalInformationView state={state.selectedShipment.additionalInformation} />
                                            </ShipmentPreviewSectionContainer>
                                            <Grid
                                                container
                                                justifyContent="flex-end"
                                                alignItems="flex-end">
                                                <Grid item >
                                                    <SuiButton onClick={handleCancelClick} size="small" variant="contained" color="error" disabled={state.selectedShipment.cancelled || state.selectedShipment.booked || deadlinePassed()}>
                                                        Cancel request
                                                    </SuiButton>
                                                </Grid>

                                            </Grid>
                                        </>
                                        }
                                        {!isSpot &&
                                            <ShipmentPreviewSectionContainer title="Pricing">
                                                <Grid item container xs={7}>    </Grid>
                                                <Grid item container xs={5}>
                                                    <Pricing state={{ totalPrice: state.selectedShipment.totalPrice, rows: state.selectedShipment.pricingDetails, currency: state.selectedShipment.currency ?? "€" }} />
                                                </Grid>

                                            </ShipmentPreviewSectionContainer>

                                        }

                                    </div>
                                }
                                {tabValue === 1 &&
                                    <Tracking state={state.selectedShipment.shipmentStatuses} trackingUrl={state.selectedShipment.trackingUrl} />
                                }
                                {tabValue === 2 &&
                                    <Quotes state={state.selectedShipment} />
                                }
                                {tabValue === 3 &&
                                    <DocumentsAndLabels state={state.selectedShipment.file} id={state.selectedShipment.id} isSpot={isSpot} />
                                }
                                {tabValue === 4 && isAdmin &&
                                    <Admin id={state.selectedShipment.id} state={state.selectedShipment} />
                                }
                            </>
                            }
                        </DialogContent>
                        <Divider />
                        <DialogActions>
                            <Grid item xs={6} justifyContent='flex-start' sx={{ fontSize: '3rem', display: 'flex' }}>
                                <SuiButton onClick={HandleBookNow} variant="contained" color="primary" sx={{ width: 'fit-content' }}>
                                    <CopyIcon variant="contained" color="white" onClick={HandleBookNow} sx={{ marginRight: '5px' }}></CopyIcon>
                                    Create Copy
                                </SuiButton>
                                {tabValue === 0 && state.selectedShipment.booked && 
                                <SuiButton onClick={() => generatePDF(targetRef,options)} variant="contained" color="primary" sx={{ width: 'fit-content', marginLeft:'0.5rem' }}>
                                    <PictureAsPdfIcon variant="contained" color="white" onClick={() => generatePDF(targetRef,options)} sx={{ marginRight: '5px' }}></PictureAsPdfIcon>
                                    Save PDF
                                </SuiButton>
}
                            </Grid>
                            <Grid
                                container
                                justifyContent="flex-end"
                                alignItems="flex-end"
                            >
                                {state.selectedShipment.selectedQuote && !state.selectedShipment.booked && bookable &&
                                    <Grid container mr={4} xs={8} alignItems='flex-start' justifyContent='flex-end'>

                               
                                        <Grid item >
                                        <SuiButton onClick={() => book(state.selectedShipment.selectedQuote)} disabled={state.selectedShipment.booked} variant="contained" sx={{ padding: 0.25, paddingLeft:0.5, textTransform:'none'}} color="primary">

                                            <Grid container xs={12} alignItems='center' justifyContent='center' >
                                            <Grid item xs={6} sx={{background:'#fff', padding:0.5, borderRadius:'0.2rem'}}>
                                                    <img style={{ maxWidth: '80px', height: '20px', borderRadius: '0.2rem' }} alt="FreighOpt" src={selectedQuote.carrierLogoUrl} />
                                                </Grid>
                                                <Grid item container xs={6} alignItems='center' justifyContent='center'>
                                                    <Grid item container xs={12} alignItems='center' justifyContent='center'>
                                                        <Typography variant="caption" sx={{ color: '#fff', fontWeight: 'bold', textAlign: 'center' }}>
                                                            Book
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item container xs={12} alignItems='center' justifyContent='center'>
                                                        <Typography variant="caption" sx={{ color: '#fff', fontWeight: 'normal', textAlign: 'center' }}>
                                                            {selectedQuote.totalPrice.toFixed(2) + state.selectedShipment.currency}
                                                        </Typography>
                                                    </Grid>
                                                </Grid>
                                              
                                            </Grid>


                                        </SuiButton>
                                        </Grid>
                                    </Grid>
                                }

                            {state.selectedShipment.selectedQuote && !state.selectedShipment.booked && !bookable &&
                                  <Grid container mr={4} xs={8} alignItems='flex-start' justifyContent='flex-end'>

                               
                                  <Grid item >
                                  <SuiButton disabled={true} variant="contained" sx={{ padding:1,textTransform:'none'}} color="primary">

                                      <Typography variant="body2" sx={{ color: '#9d0000', fontWeight: 'bold', textAlign: 'center' }}>
                                                      Quote expired!
                                                  </Typography>

                                  </SuiButton>
                                  </Grid>
                              </Grid>
                            }

                                <Grid item >
                                    <SuiButton onClick={handleClose} variant="contained" color="secondary">
                                        Close
                                    </SuiButton>
                                   
                                </Grid>

                            </Grid>

                        </DialogActions>

                    </>}


        
            </Dialog>
  
        </React.Fragment>
    );

}





