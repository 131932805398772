
import React from 'react';
import { useEffect, useState } from 'react';

import LocationInputForm from '../Common/LocationInputForm'
import { locationActions } from '_actions/location.actions';
import { Paper, Modal } from '@mui/material';
import { Loading } from '_components/Shared/LoadingSpinner'
import { useSelector, useDispatch } from 'react-redux';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiButton from '_softUI/components/SuiButton';
import { alertActions } from '_actions';
import ReactCountryFlag from "react-country-flag"

export function AddressBookModal({ open, handleClose }) {

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(locationActions.initate());
  }, [])

  const state = useSelector(state => state.location);
  const countries = useSelector(state => state.location.countries);

  const countryOptions = countries.map((x, index) =>
    ({ value: x.alpha2, label: <React.Fragment key={x.name} ><span><ReactCountryFlag svg   countryCode={x.alpha2} width="20" /></span><span style={{ marginLeft: '10px' }}>{x.name}</span></React.Fragment> }))

  function handleLocationInputChange(event) {
    dispatch(locationActions.updateLocationInputValue(event.target.name, event.target.value));
  }

  function handleCountryChange(name, value, index) {
    dispatch(locationActions.updateLocationInputValue(name, value));
  }

  const location = state.locationInput

  function saveLocation() {
    if (state.locationInput.valid) {
      dispatch(locationActions.create(location, handleClose))
    } else {
      dispatch(locationActions.showHints())
      dispatch(alertActions.error('Please fill all the required fields!'))
    }
  }

  function deleteLocation() {
    dispatch(locationActions.deleteSavedLocation(location.id, handleClose))
  }

  function updateLocation() {
    if (state.locationInput.valid) {
    dispatch(locationActions.updateSavedLocation(location, handleClose));
    } else {
      dispatch(locationActions.showHints())
      dispatch(alertActions.error('Please fill all the required fields!'))
    }
  }

  function clearLocation() {
    dispatch(locationActions.clearSelectedLocation());
  }

  function handleLocationChangeWithMaxLenght(event, label, maxLenght) {

    dispatch(locationActions.updateLocationInputValue(event.target.name, validateLength(label, event.target.value, maxLenght)));
  }

  console.log('loading', state.loading)

  function validateLength(label, value, maxLength) {

    if (maxLength && value) {
      if (value.length > maxLength) {
        const newValue = value.substring(0, maxLength)
        dispatch(alertActions.error(label + ' max length is ' + maxLength + ' characters!'))
        return newValue;
      }
      else {
        return value
      }
    }
    else {

      return value
    }
  }

  return (

    <Modal
      open={open}
      onClose={handleClose}
      width='80%'
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center'}}
    >
      <Paper sx={{ mb: 2, maxWidth: 'xl', outline: 'none', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>

        {state.loading &&
          <Grid position="fixed">
            <Loading />
          </Grid>}

        <Grid item container xs={12} sx={{ p: 3, visibility: state.loading ? 'hidden' : 'visible' }}>
          <Grid item xs={12} display="flex" justifyContent="space-between">
            <Grid item xs={11} paddingLeft='2rem' paddingTop='1rem' >
              <SuiTypography variant="h5">
                {location.id ? 'Update Address' : 'New Address'}</SuiTypography>
            </Grid>
            
          </Grid>
          <Grid item container xs={12} sx={{ p: 3 }}>
            <LocationInputForm
              title="address"
              index={0}
              handleInputChange={e => handleLocationInputChange(e)}
              handleCountryChange={e => handleCountryChange('countryAlpha2', e.value)}
              handleLocationChangeWithMaxLenght={e => handleLocationChangeWithMaxLenght(e, " instructions", 100)}
              location={location}
              addressbook={true}
              countryOptions={countryOptions}
            />
          </Grid>
          
          <Grid item xs={12} container spacing={1} justifyContent="space-between" >
            <Grid item xs={1} container  sx={{ paddingTop: '0rem !important', pl: 1, ml: 2}}  alignItems="center">
              <SuiButton variant="text" startIcon={<CancelIcon />} size="small" fullWidth sx={{ fontSize: '0.7rem', boxShadow: 'none !important' }} color="text" onClick={handleClose} >
                Cancel
              </SuiButton>
            </Grid>
            <Grid item container xs={10} spacing={1} alignItems="center" justifyContent="flex-end" >
              <Grid item xs={2} sx={{ paddingTop: '0rem !important' }}>
                <SuiButton variant="text" startIcon={<DeleteIcon />} size="small" fullWidth sx={{ fontSize: '0.7rem', boxShadow: 'none !important' }} color="error" onClick={location.id ? deleteLocation : clearLocation} >
                  {location.id ? 'Delete Address' : 'Clear Fields'}
                </SuiButton>
              </Grid>
              <Grid item xs={2} sx={{ paddingTop: '0rem !important' }}>
                <SuiButton variant="text" startIcon={<SaveIcon />} size="small" fullWidth sx={{ fontSize: '0.7rem', boxShadow: 'none !important' }} color="text"
                  onClick={location.id ? updateLocation : saveLocation} >
                  Save Address
                </SuiButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Modal>

  )
}