import React, { useCallback } from 'react';
import { ShipmentInformationFormActions } from '../../_actions';
import Grid from '@mui/material/Grid';
import Label from '_components/FormFields/Label'
import { useDispatch, useSelector } from 'react-redux';
import SuiBox from "_softUI/components/SuiBox";
import CardMedia from "@mui/material/CardMedia";
import SuiTypography from "_softUI/components/SuiTypography";
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dropzone from '_components/Common/Dropzone'
import AddFiles from './AddFiles';
import Link from '@mui/material/Link';

import { arrayBufferToBase64 } from '_helpers/file'

export default function AcceptTerms({ state, handleAcceptTerms }) {
    const dispatch = useDispatch();
    function handleAcceptTerms(event) {
        console.log(event.target.checked)
        dispatch(ShipmentInformationFormActions.handleAcceptTerms(event.target.checked));
    }
    const showCarrierTerms = state.selectedQuote.carrierTerms !==''


    return (
      

                        <Grid item container xs={12} md={12} justifyContent="space-between" >
                    
                            <Grid item container xs={12} alignItems="stretch" direction="row" justifyContent="space-between">
                                <Grid item xs={12}>
                                    {/* <SuiTypography variant="h5" textAlign="left" fontWeight="bold" color="secondary" mb={1} >
                                        Book
                                    </SuiTypography> */}
                                    <SuiTypography textAlign="left" sx={{ color: "#000" }} variant="h5" mb={1}>
                                        Please accept service agreement:
                                    </SuiTypography>
                         
                                </Grid>
                                <Grid item xs={12} >
                                    <SuiTypography variant="caption">
                                        You are bookig a freight based on FreightOpt contract prices. Before proceeding with the booking you must accept the general service agreement
                                        {showCarrierTerms && " as well as  carrier specific terms"}. 
                                        You can find the documends here:
                                        <Grid item xs={12}>
                                        <Link  variant="subtitle2" sx={{color:'#323269'}} underline="always" target='_blank' href={state.selectedQuote.generalAgreements}>
                                            Service agreement                                        
                                        </Link>
                                        {showCarrierTerms && 
                                             " and "}

                                        {showCarrierTerms &&
                                        <Link  variant="subtitle2" sx={{color:'#323269'}} underline="always" target='_blank' href={state.selectedQuote.carrierTerms}>
                                          Carrier terms                                      
                                        </Link>
                                        }
                                        </Grid>
                                    </SuiTypography>
                                    <Grid item xs={12} md={12} container sx={{marginTop:'1rem'}}>
                                        <Grid item xs={6}>
                                        
                                     
                                        </Grid>
                                     
                                        <Grid item xs={12} sx={{  marginLeft: '1rem', marginTop: '1rem' }}>
                                            <FormControlLabel
                                                control={<Checkbox color="primary" onChange={e => handleAcceptTerms(e)}
                                                />}
                                                label="I accept the service agreement and carrier terms"
                                            />
                                        </Grid>
                                    </Grid>
                                </Grid>
                           

                            </Grid>



                        </Grid>
    
    );

}





