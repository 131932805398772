import React from 'react';
import Grid from '@mui/material/Grid';

import { useDispatch, useSelector } from 'react-redux';
import Paper from '@mui/material/Paper';

import { ShipmentInformationFormActions } from '../../_actions';

import CardMedia from "@mui/material/CardMedia";

import SuiBox from "_softUI/components/SuiBox";
import Label from "_components/FormFields/Label"
import SuiButton from "_softUI/components/SuiButton";

import Table from "_components/Tables/Table";
import SuiTypography from "_softUI/components/SuiTypography";
import BigDisplayWithLabel from '_components/FormFields/BigDisplayWithLabel';
import { useHistory } from "react-router-dom";
import Container from '@mui/material/Container';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';


export function ResultsRow({ row }) {
  const history = useHistory();

  const dispatch = useDispatch();
  const state = useSelector(state => state.instantPricing);


    function HandleBookNow() {


    const prefills = {
      originCountry: state.originCountry.value,
      originZip: state.originZip.value,
      originCity: state.originZip.city,
      originState: state.originZip.state,
      destinationCountry: state.destinationCountry.value,
      destinationZip: state.destinationZip.value,
      destinationCity: state.destinationZip.city,
      destinationState: state.destinationZip.state,
      goods: state.goods,
      logoUrl: row.logoUrl,
      carrierName: row.carrierName,
      carrierId: row.carrierId,
      calculatorId: row.calculatorId,
      price: row.totalPrice,
      deliveryTime: row.deliveryTime,
      weight: row.weight,
      calculationRule: row.calculationRule,
      tailLift : state.tailLift,
      insurance : state.insurance,
      adr : state.adr,
      goodsValue : state.goodsValue,
      adrClass: state.adrClass,
      availableAdditionalServices: row.availableAdditionalServices,
      callOnPickup: state.callOnPickup,
      callOnDelivery: state.callOnDelivery,
      currency: row.currency,
      importDeclaration: state.importDeclaration,
      exportDeclaration: state.exportDeclaration,
      direction: row.direction,
      productName: row.productName

    }
    dispatch(ShipmentInformationFormActions.open(prefills))
    
    history.push("/InstantBookingProcess");
  }





  const getRowItem = (row,currency) => {

    let val

    if (row.stringValue === 'Incl.') {
      val =  row.stringValue;
    }
    else if (row.valueType === 'string') {
      val = row.stringValue;
    }
    else if (row.valueType === 'double') {
      val = row.doubleValue.toString() + currency;
    }
    else if (row.valueType === 'percent') {
      val = row.doubleValue + "%"
    }
    else {
      val = row.doubleValue
    }

    if(val && val !== '0' + currency){
      return {'item':row.name, 'value':val}
    }



  }

    const NoMaxWidthTooltip = styled(({ className, ...props }) => (
        <Tooltip {...props} classes={{ popper: className }} arrow={true} TransitionComponent={Zoom}/>
      ))({
        '& th': {
            padding: '10px', 
            paddingBottom: '5px',
            marginRight: '10px',
            fontWeight: 'bold', 
            textAlign: 'left'
          },
        '& tr': {
            textAlign: 'left',
          },
        '& td': {
            paddingLeft: '10px',
            paddingRight: '10px'
          },
          '& .MuiTooltip-arrow': {
            color: 'rgba(255,255,255,1)', 
          },
          '& .MuiTooltip-tooltip': {
            opacity: 1,
            maxWidth: 'none',
            backgroundColor: 'rgba(255,255,255,1)',
            boxShadow: 'rgba(0, 0, 0, 0.3) 0 2px 10px',
            color: '#2B2B2B',
          }
      });

  return (


    <Paper elevation={1} sx={{my:1, width: '100%', minHeight: '100px'}}>
      <Container sx={{ overflow: 'unset', '.MuiPaper-root': { overflow: 'unset' }, justifyContent: 'center'}}>
      <Grid container spacing={1} m={1} pr={3} >
        <Grid item xs={2} container justifyContent="left" pr="2rem" pl="0 !important">
          <SuiBox position="relative" width="100%" maxWidth="200px" borderRadius="xl" textAlign="center" sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>

            <CardMedia
              src={row.logoUrl}
              component="img"
              title={row.carrierName}

              sx={{
                maxWidth: "120px",
                maxHeight: "30px",
                margin: 0,
                // boxShadow: ({ boxShadows: { md } }) => md,
                objectFit: "contain",
                objectPosition: "center",
                borderRadius: '0.5rem',
                textAlign: 'center'


              }}
            />
          </SuiBox>
          {row.productName &&
          <Grid item xs={12}>
          <SuiTypography variant='subtitle2' sx={{  color: 'text.secondary', textAlign:'center' }} >
                        
                                {row.productName}


                            </SuiTypography>


            </Grid>
 }
        </Grid>
        <Grid item xs={6.5} container justifyContent="center" pr={2}>
          <Grid item container xs={12} >
           

            <Grid item xs={7}>
              <BigDisplayWithLabel label="Estimated Delivery Time" value={row.deliveryTime}/>


            </Grid>
            <Grid item xs={5}>
              <BigDisplayWithLabel label="Billing Weight" value={row.weight + 'kg'} />

            </Grid>
          
          </Grid>
          <Grid item  xs={12} >
     
          </Grid>


        </Grid>
          <Grid item xs={1.75} container justifyContent="center" textAlign="right" >
            <Grid item xs={12} sx={{ textAlign: 'center', display: 'flex', alignItems: 'center' }}>
              <NoMaxWidthTooltip title={
                <Table
                  columns={[{ name: 'item', align: "left" }, { name: 'value', align: "left" }]}
                  rows={row.details.filter(getRowItem).map((x, index) => (getRowItem(x, row.currency)))}

                />
              } placement="bottom" >
                <InfoOutlinedIcon sx={{ position: 'relative', left: '-20px', color: '#A7936A' }} />
              </NoMaxWidthTooltip>
              <BigDisplayWithLabel label="Total Price" value={Math.round((row.totalPrice + Number.EPSILON) * 100) / 100 + " " + row.currency} sx={{ border: '1px solid blue' }} />


            </Grid>
            <Grid item container xs={12} justifyContent="center" sx={{ marginTop: '-0.75rem', paddingLeft: '0.2rem' }} >
              <SuiTypography variant="caption" textAlign="center" fontWeight="light" color="light">
                excl. VAT
              </SuiTypography>
            </Grid>

          </Grid>
          <Grid item xs={1.67} container justifyContent="flex-end" alignItems="center" textAlign="right" >
          <Grid item xs={12} >
            <SuiButton sx={{maxWidth:'150px' }}  variant="contained" color="secondary" onClick={HandleBookNow} >Book Now</SuiButton>
          </Grid>

        </Grid>






      </Grid>
      </Container>
      </Paper>


  )
}