import PropTypes from "prop-types";
import SuiBox from "_softUI/components/SuiBox";
import colors from "_softUI/assets/theme/base/colors";
import typography from "_softUI/assets/theme/base/typography";
import borders from "_softUI/assets/theme/base/borders";

export default function HeaderCell({ children }) {
    const { light } = colors;
    const { size, fontWeightBold } = typography;
    const { borderWidth } = borders;
  
    return (
      <SuiBox
        component="th"
        width="100%"
        textAlign="left"
        borderBottom={`${borderWidth[1]} solid ${light.main}`}
        py={1.5}
        pl={1}
        pr={3}
      >
        <SuiBox
          width="max-content"
          textAlign="left"
          fontSize={size.xxs}
          fontWeight={fontWeightBold}
          color="secondary"
          opacity={0.7}
          sx={{ textTransform: "uppercase" }}
        >
          {children}
        </SuiBox>
      </SuiBox>
    );
  }