import { Grid } from '@mui/material';
import React from 'react';
import Table from '_components/Tables/Table'
import {convertUTCDateToLocalDate} from '_helpers'
import SuiButton from "_softUI/components/SuiButton";
import Link from '@mui/material/Link';

export default function Tracking({ state, trackingUrl}) {

    function dateConvert(value){
        // const localTime = convertUTCDateToLocalDate(new Date(value));
        const localTime = new Date(value);
        return(localTime.toLocaleDateString() + '  ' + localTime.toLocaleTimeString() )
    }

    const headers = [{name:'source',align:'left'},{name:'status',align:'left'},{name:'location',align:'left'},{name:'time',align:'right'}]
    const rows = state && state.map((x,i) => ({source:x.source,status:x.status,location:x.location,time:dateConvert(x.time), hasBoarder:true}) )
    return (
        <>
            <Grid container>

            </Grid>
            {trackingUrl !== '' &&
            <Grid item xs={12} justifyContent='flex-end' sx={{fontSize: '3rem', display: 'flex', color: '#A7936A'}}>
                                    <SuiButton variant="outlined"   color="primary" sx={{width: 'fit-content'}}>
                                    {/* <Grid fullWidth variant="contained" color="white" onClick={HandleBookNow} sx={{marginRight: '5px'}}></Grid> */}
                                        
                                       <Link href={trackingUrl} target="_blank" sx={{color: "#A7936A !important",   '&:visited': {color: '#A7936A', }}} >Carrier tracking</Link> 
                                    </SuiButton>
                            
                        </Grid>
            }
            <Grid container>
                <Grid item xs={12}>
                    <Table columns={headers}  rows={rows}/>
                 


                </Grid>
            </Grid>


        </>
    )
}