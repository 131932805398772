import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiBox from "_softUI/components/SuiBox";
import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import { adminActions } from '_actions/admin.actions';
import { CustomerInput } from '_components/Admin/CustomerInput';

import DataTable from '_components/Tables/DataTable';
import SuiButton from '_softUI/components/SuiButton';


export default function ManageCustomers() {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const state = useSelector(state => state.admin);

    const data = {
        columns: [
            { Header: "ID", accessor: "id" },
            { Header: "NAME", accessor: "name" },
            { Header: "BIC", accessor: "bic" },
            { Header: "CMR ID", accessor: "cmrId" },
            { Header: "Country", accessor: "country" },
            { Header: "Margin", accessor: "margin4pl" },
        ],
        rows: state.customers.map(x => (
            {
                'id': x.id,
                name: x.name,
                bic: x.bic,
                cmrId: x.cmrId,
                country: x.countryId,
                margin4pl: x.margin4pl
            }))
    }


    useEffect(() => {
        dispatch(adminActions.initateUsers());
        dispatch(adminActions.initateCustomers());
        dispatch(adminActions.initateCarriers());

    }, [])

    const save = () => {
        dispatch(adminActions.createOrUpdateCustomer(state.customerInput, handleModalClose));
    }

    const handleNewModalOpen = () => {

        setModalTitle('Create a customer')
        dispatch(adminActions.clearSelectedCustomer(), adminActions.initateRoles());
        setModalOpen(true);
    }

    const handleRowClick = (id) => {
        dispatch(adminActions.initateSelectedCustomer(id));
        setModalTitle('Customer')
        setModalOpen(true);
    }

    const handleModalClose = () => {
        dispatch(adminActions.clearSelectedCustomer());
        setModalOpen(false);
    }

    const handleDelete = () => {
        dispatch(adminActions.deleteCustomer(state.customerInput));
    }


    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start" sx={{ height: '100%'}}>

            <Grid container item xs={12} alignItems="stretch" justifyContent="center">
                <Grid item xs={12} md={12}>
                    <Card sx={{ overflow: "visible", p: 3 }}>
                        <SuiBox>
                            <Grid container alignItems="strecth" justifyContent="center">
                                <Grid item xs={6}>
                                    <SuiTypography variant="h5">Manage customers</SuiTypography>
                                </Grid>
                                <Grid item xs={6} dir="rtl">
                                    <SuiButton color="secondary" onClick={handleNewModalOpen}>New customer</SuiButton>
                                </Grid>
                            </Grid>


                            <SuiBox mt={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>

                                    {useMemo(()=>         <DataTable handleRowClick={handleRowClick} canSearch table={data} />
                                     ,[state.customers])}
                                    </Grid>
                                </Grid>

                            </SuiBox>
                        </SuiBox>
                        <CustomerInput open={modalOpen} title={modalTitle} handleClose={handleModalClose} save={save}></CustomerInput>
                    </Card>
                </Grid>
            </Grid>

        </Grid>
    )

}