import React from 'react';
import Grid from '@mui/material/Grid';
import SuiTypography from "_softUI/components/SuiTypography";
import InputWithLabel from "_components/FormFields/InputWithLabel";


export default function AdditionalInformationView({ state}) {
   



    return (
      

                        <Grid item container xs={12} md={12} justifyContent="space-between" >
                    
                            <Grid item container xs={12} alignItems="stretch" direction="row" justifyContent="space-between">
                            <InputWithLabel

                    name="additionalInformation"
           
                    // sx={{readonly}}
                    fullWidth
                    multiline
                    value={state}
                    readOnly={true}
                    rows={4}
      
                  />
                          
                           

                            </Grid>



                        </Grid>
    
    );

}





