import { userConstants } from '../_constants';

const initialState = {
    loading: false,
    user: {
        password: '',
        passwordConfirm: '',
        emailUnsubscriptions:[]
    }
};


export function user(state = initialState, action) {
    switch (action.type) {
        case userConstants.LOADING:
            return {
                ...state,
                loading: action.value
            };

        case userConstants.SET_USER:
            return {
                ...state,
                loading: false,
                user: action.user,
            }

        case userConstants.UPDATE_USER:
            return {
                ...state,
                user: {
                    ...state.user,
                    [action.data.name]: action.data.value
                }
            };
            case userConstants.UPDATE_USER_EMAIL_NOTIFICATION:

            const newListWithoutCurrent = state.user.emailUnsubscriptions.filter(x => x.emailType !== action.data.emailType)

                return {
                    ...state,
                    user: {
                        ...state.user,
                        emailUnsubscriptions : newListWithoutCurrent.concat(action.data)
                    }
                };

        default:
            return state

    }

}



