import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";
import SuiSelect from "_softUI/components/SuiSelect";


function SelectWithLabel({ label,options,fontWeight,mandatory, ...rest }) {

  

    return (
        <>
            <SuiBox mb={0}>
                {label && 
                <SuiBox mb={0} ml={0} lineHeight={0}  sx={{marginBottom: '0rem'}} display="inline-block">
                    <SuiTypography
                        component="label"
                        variant="caption"
                        fontWeight={fontWeight}
                        textTransform="capitalize"
                    >
                        {label}{mandatory&&  <SuiTypography
          component="label"
          variant="caption"
          fontWeight='light'
          textTransform="capitalize"
          sx={{color:'#fd5c70'}}
         
        >
           *
        </SuiTypography>}
                    </SuiTypography>
                </SuiBox>
                }
                <SuiSelect
                // size="small"
                    sx={{marginTop:'0.25rem'}}
                    {...rest}
                    styles={{
                        control: (baseStyles, state) => ({
                          ...baseStyles,
                          borderColor: 'red',
                        }),
                      }}
                    options={options}
                />


            </SuiBox>
        </>
    );
}

// typechecking props for FormField
SelectWithLabel.propTypes = {
    label: PropTypes.string.isRequired,
};

export default SelectWithLabel;