import React, { useEffect, useState, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SuiTypography from "_softUI/components/SuiTypography";
import SuiBox from "_softUI/components/SuiBox";
import Card from "@mui/material/Card";
import Grid from '@mui/material/Grid';
import { adminActions } from '_actions/admin.actions';
import { UserInput } from '_components/Admin/UserInput';

import DataTable from '_components/Tables/DataTable';
import SuiButton from '_softUI/components/SuiButton';

export default function ManageUsers() {
    const dispatch = useDispatch();
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const state = useSelector(state => state.admin);

    const data = {
        columns: [
            { Header: "ID", accessor: "id" },
            { Header: "USERNAME", accessor: "username" },
            { Header: "EMAIL", accessor: "email" },
            { Header: "FIRST NAME", accessor: "firstName" },
            { Header: "LAST NAME", accessor: "lastName" },
            { Header: "CUSTOMER", accessor: "customer" },

        ],
        rows: state.users.map(x => (
            {
                'id': x.id,
                username: x.username,
                email: x.email,
                firstName: x.firstName,
                lastName: x.lastName,
                customer: x.customer && x.customer.name
            }))
    }


    useEffect(() => {

        dispatch(adminActions.initateUsers());
        dispatch(adminActions.initateCustomers());
        dispatch(adminActions.initateCarriers());

    }, [])


    const save = () => {
        dispatch(adminActions.createOrUpdateUser(state.userInput, handleModalClose, state.selectedUser));


    }

    const handleNewModalOpen = () => {
        setModalTitle('Create a user')
        dispatch(adminActions.initateRoles());
        setModalOpen(true);
    }

    const handleRowClick = (id) => {
        dispatch(adminActions.initateSelectedUser(id));
        setModalOpen(true);
    }

    const handleModalClose = () => {
        dispatch(adminActions.clearSelectedUser());
        setModalOpen(false);
    }



    const handleDelete = () => {
        dispatch(adminActions.deleteUser(state.selectedUser));
    }



    return (
        <Grid
            container
            direction="row"
            justifyContent="space-between"
            alignItems="flex-start" sx={{ height: '100%' }}>



            <Grid container item xs={12} alignItems="stretch" justifyContent="center">
                <Grid item xs={12} md={12}>
                    <Card sx={{ overflow: "visible", p: 3 }}>
                        <SuiBox>
                            <Grid container alignItems="stretch" justifyContent="center">
                                <Grid item xs={6}>
                                    <SuiTypography variant="h5">Manage users</SuiTypography>
                                </Grid>
                                <Grid item xs={6} dir="rtl">
                                    <SuiButton color="secondary" onClick={handleNewModalOpen}>New user</SuiButton>
                                </Grid>
                            </Grid>


                            <SuiBox mt={3}>
                                <Grid container spacing={3}>
                                    <Grid item xs={12} sm={12}>

                                    {useMemo(()=>           <DataTable handleRowClick={handleRowClick} canSearch table={data} />
                                    ,[state.users])}
                                    </Grid>
                                </Grid>

                            </SuiBox>
                        </SuiBox>

                        <UserInput open={modalOpen} title={modalTitle} handleClose={handleModalClose} save={save}></UserInput>
                    </Card>
                </Grid>
            </Grid>

        </Grid>
    )

}

