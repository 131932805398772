import { authHeader } from '../_helpers';
import { handleResponse } from './util'

export const dashboardService = {
    getDashboard,
}


function getDashboard(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_URL + 'Dashboard/GetDashboard', requestOptions).then(handleResponse)
}