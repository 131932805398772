import React from 'react';
import Grid from '@mui/material/Grid';
import Label from '_components/FormFields/Label'
import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";
import DomainRoundedIcon from '@mui/icons-material/DomainRounded';
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import MapRoundedIcon from '@mui/icons-material/MapRounded';
import FmdBadRoundedIcon from '@mui/icons-material/FmdBadRounded';
import PublicRoundedIcon from '@mui/icons-material/PublicRounded';
import PersonOutlineRoundedIcon from '@mui/icons-material/PersonOutlineRounded';
import EmailRoundedIcon from '@mui/icons-material/EmailRounded';
import LocalPhoneRoundedIcon from '@mui/icons-material/LocalPhoneRounded';
import AccessTimeRoundedIcon from '@mui/icons-material/AccessTimeRounded';
import SuiBadge from "_softUI/components/SuiBadge";
import ReactCountryFlag from "react-country-flag"

export default function LocationView({ state }) {


    return (
        <React.Fragment>
            <Grid container sx={{ mb: '1rem' }}>
                <SuiBox mb={0} mt={2} lineHeight={0} display="inline-block" borderRadius="0.75rem" sx={{ width: '100%', height: "100%" }} >

                    <Grid item container xs={12}>
                        <Grid item xs={2} mb={2}>
                            <SuiTypography variant="h5" fontWeight="bold"  >
                                {state.title}
                            </SuiTypography>

                        </Grid>

                        <Grid item xs={10} >
                            <Grid container spacing={1}>

                                <Grid container spacing={1}>
                                    <Grid item container xs={12} spacing={2} sx={{ marginLeft: '0rem', paddingLeft: '0 !important', paddingBottom: '0.5rem' }}>
                                        <Grid item xs={4} container sx={{ padding: '0.1rem', paddingTop: '0.5rem !important' }}   >

                                            <Grid item container spacing={1} xs={12} sx={{ backgroundColor: '#fff0', marginTop: '-0.25rem', paddingBottom: '0.5rem !important', paddingTop: '0 !important', borderRadius: '4px' }}>
                                                <Grid item xs={12} >


                                                         <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={"Location"} />
                                                    {/* <SuiTypography

                                                        variant="caption" fontWeight="bold" textTransform="uppercase" sx={{ fontSize: '0.65rem' }}
                                                    >
                                                        Location


                                                    </SuiTypography> */}
                                                    {/* <Divider  sx={{ m: 0 }} /> */}
                                                </Grid>

                                                <Grid item xs={12}>
                                                    <Label icon={<DomainRoundedIcon color="text" />} value={state.name} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Label icon={<PlaceRoundedIcon color="text" />} value={state.streetAddress} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    {state.addressInfo && <Label icon={<FmdBadRoundedIcon color="text" />} value={state.addressInfo} />}
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Label icon={<MapRoundedIcon color="text" />} value={state.zip + ', ' + state.city} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Label icon={<PublicRoundedIcon color="text" />} value={state.countryAlpha2} ><ReactCountryFlag svg   countryCode={state.countryAlpha2} width="20" style={{ marginLeft: '0.5rem' }} /></Label>
                                                </Grid>

                                            </Grid>


                                        </Grid>
                                        <Grid item xs={4} container sx={{ padding: '0.1rem', paddingTop: '0.5rem !important' }}   >

                                            <Grid item container spacing={1} xs={12} sx={{ backgroundColor: '#fff0', marginTop: '-0.25rem', paddingBottom: '0.5rem !important', paddingTop: '0 !important', borderRadius: '4px' }}>
                                                <Grid item xs={12}>

                                                    <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={"Contact"} />
                                                    {/* <SuiTypography

                                                        variant="caption" fontWeight="bold" textTransform="uppercase" sx={{ fontSize: '0.65rem' }}
                                                    >
                                                        Contact


                                                    </SuiTypography> */}
                                                </Grid>
                                                <Grid item xs={12}>

                                                    <Label icon={<PersonOutlineRoundedIcon color="text" />} value={state.contactName} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Label icon={<EmailRoundedIcon color="text" />} value={state.contactEmail} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Label icon={<LocalPhoneRoundedIcon color="text" />} value={state.contactPhone} />
                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Label icon={<AccessTimeRoundedIcon color="text" />} value={state.openingHoursFrom ? state.openingHoursFrom + ' - ' + state.openingHoursTo : state.openingHours} />
                                                </Grid>


                                            </Grid>


                                        </Grid>
                                        <Grid item xs={4} container sx={{ padding: '0.1rem', paddingTop: '0.5rem !important' }}   >

                                            <Grid item container spacing={1} xs={12} sx={{ backgroundColor: '#fff0', marginTop: '-0.25rem', paddingBottom: '0.5rem !important', paddingTop: '0 !important', borderRadius: '4px' }}>
                                                <Grid item xs={12}>

                                                    <SuiBadge container sx={{ zIndex: 0 }} variant="contained" size="xs" fullWidth={true} color="mainBlue" badgeContent={"Instructions"} />
                                                    {/* <SuiTypography

                                                        variant="caption" fontWeight="bold" textTransform="uppercase" sx={{ fontSize: '0.65rem' }}
                                                    >
                                                        Instructions


                                                    </SuiTypography> */}

                                                </Grid>
                                                <Grid item xs={12}>
                                                    <Label value={state.instructions} />
                                                </Grid>


                                            </Grid>


                                        </Grid>

                                    </Grid>


                                </Grid>



                            </Grid>
                        </Grid>
                    </Grid>
                </SuiBox>
            </Grid>




        </React.Fragment>
    );

}





