export const adrRowModel = {
    class: '', 
    description: '',
    grossWeight: '',
    measurementUnit: 1,
    netVolume: '',
    netWeight: '',
    quantity: '',
    unCode: '',
    packageType: '',
    packingGroup: '',
    subsidiaryRisk: '',
    technicalNameRequired: false,
    tunnelCode: '',
    technicalDescription: '',
    marinePollutant: false,
    limitedQuantity: false,
    unCodeOptions: [],
    adrDescriptionOptions: [],
    adrClassError: false,
    unCodeError: false,
    adrDescriptionError: false,
    adrQuantityError: false,
    adrPkgTypeError: false,
    adrNetWeightError: false,
    adrGrossWeightError: false,
    adrUnitError: false,
    adrNetVolumeError: false,
    technicalNameErrpr: false,
}

export const goodsRowModel = {
    typeCode: 1,
    itemType: 'Colli',
    quantity: 0,
    ldm: 0,
    volume: 0,
    weight: 0,
    height: 0,
    width: 0,
    length: 0,
    // totalWeight: 0,
    // totalLdm: 0,
    // totalVolume: 0,
    valid: false,
    stackable: false,
    adr: false,
    adrClass: [],
    adrRow: [],
    weightError: false,
    heightError: false,
    widthError: false,
    lengthError: false,
    quantityError: false,
    volumeError: false,
    ldmError: false,
    // billingWeight: 0,
    calculating: true,
    fullTrailerType: '',
    fullTrailerTypeError: false,
    containerType: '',
    containerTypeError: false,
    description:'', 
}

