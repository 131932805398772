import React from 'react';
import Grid from '@mui/material/Grid';

import Checkbox from '@mui/material/Checkbox';

import Typography from '@mui/material/Typography';
import { ShipmentInformationFormActions } from '../../_actions';
import { useDispatch, useSelector } from 'react-redux';
import Stack from '@mui/material/Stack';


export default function Customs({ }) {

    const state = useSelector(state => state.ShipmentInformationForm.data)
    const dispatch = useDispatch()
    function handleAdditionalServiceChange(name, value) {
        console.log(value)
        dispatch(ShipmentInformationFormActions.updateBasicValue(name, value));
    }

    const incoterm = state.direction === 2 ? "DDP" : "EXW"

    return (

        <Grid container sx={{ mt:1,border:state.customsValid ? 'none' : "1px solid #fd5c70", borderRadius:"0.3125rem", backgroundColor:'#f8f8f8', padding: '0.25rem', paddingLeft:'1rem' }}>

            <Grid item container md={6} sx={{}}>


                <Grid item xs={12} container alignItems="flex-start">
                    <Typography variant='subtitle2' sx={{ pl: 0.6, color: 'text.primary', fontWeight:'600' }} >
                        I want carrier to handle the following custom clearances
                    </Typography>
                    <Typography variant='subtitle2' sx={{ pl: 0.6, color: 'text.secondary', fontSize:"0.6em" }} >
                          If the incoterm is {incoterm} please select both declarations
                            </Typography>
                    <Grid item xs={12} sx={{ paddingLeft: '1rem', paddingTop:"0.4rem" }}>
                        <Stack direction="row" spacing={2} justifyContent="flex-start"
                            alignItems="center">
                            <Checkbox checked={state.importDeclaration} onChange={e => handleAdditionalServiceChange('importDeclaration', e.target.checked)}

                            />
                            <Typography variant='subtitle2' sx={{ pl: 0.6, color: 'text.secondary' }} >
                                Import declaration
                            </Typography>

                        </Stack>




                        <Stack direction="row" spacing={2} justifyContent="flex-start"
                            alignItems="center">
                            <Checkbox checked={state.exportDeclaration} onChange={e => handleAdditionalServiceChange('exportDeclaration', e.target.checked)}

                            />
                            <Typography variant='subtitle2' sx={{ pl: 0.6, color: 'text.secondary' }} >
                                Export declaration
                            </Typography> 
                        </Stack>
                    </Grid>
                    {/* <Grid item xs={12} sx={{ paddinLeft: '1rem' }}>
                    <Typography variant='subtitle2' sx={{ pl: 0.6, color: 'text.secondary', fontSize:"0.6em" }} >
                                To speed things up, please upload needed documents to the system in the preview page
                            </Typography>
                    </Grid> */}
                </Grid>
            </Grid>
            <Grid item container md={6} alignItems="center" justifyContent="center" sx={{paddingLeft:0.5,borderLeft:"1px solid #edeaea"}}>

                <Checkbox checked={state.customerHandlesClearances} onChange={e => handleAdditionalServiceChange('customerHandlesClearances', e.target.checked)}

                />
                <Typography variant='subtitle2' sx={{ pl: 0.6, color: 'text.primary' }} >
                    I will handle all the needed custom clearances
                </Typography>

            </Grid>
        </Grid>

    )

}