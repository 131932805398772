import LogoWhite from '_assets/landingPage/images/logo-white.svg'

export const Footer = () => (
    <footer id="footer">
      <div className="container">
        <div className="row">
          <div className="col-12 col-md-3">
            <div className="logo">
              <a href="#">
                <img src={LogoWhite} alt="" />
              </a>
            </div>
          </div>
          <div className="col-12 col-md-5">
            <address>
              <span>FreightOpt</span>
              <span>Suomalaistentie 7, 02270 Espoo</span>
              <span>
                <a href="tel:358401961317">+358 40 1961 317</a>
              </span>
              <span>
                <a href="mailto:info@freightopt.com">info@freightopt.com</a>
              </span>
            </address>
          </div>
          <div className="col-12 col-md-2">
            <nav className="f-nav">
              <ul>
                <li>
                  <a href="#">How it works</a>
                </li>
                <li>
                  <a href="#">About us</a>
                </li>
                <li>
                  <a href="#">Contact us</a>
                </li>
                <li>
                  <a href="#">FAQ</a>
                </li>
              </ul>
            </nav>
          </div>
          <div className="col-12 col-md-2">
            <nav className="f-nav">
              <ul>
                <li>
                  <a href="#">Login</a>
                </li>
                <li>
                  <a href="#">Sign Up</a>
                </li>
                <li>
                  <a href="#">Request a demo</a>
                </li>
              </ul>
            </nav>
          </div>
        </div>
        <span className="copyright">
          Copyright © 2024 FreightOpt Oy. All rights reserved.
        </span>
      </div>
    </footer>
  )
  