import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";
import Grid from '@mui/material/Grid';

function DisplayWithLabel({ label, fontWeight, value, icon,...props  }) {
    return (
        <>
            <SuiBox mb={0.5} pl={0} sx={{ borderRadius: '0rem', }}>

                <SuiBox mb={0} ml={0.5} display="inline-block">
                    <Grid container alignItems="flex-start" justifyContent="flex-start">

                        <Grid item xs={12} >
                            <SuiTypography

                                variant="caption" fontWeight="medium" textTransform="uppercase" sx={{fontSize:'0.65rem'}}
                            >
                                {label}


                            </SuiTypography>
                        </Grid>
                        <Grid item xs={12} sx={{ pt: 0.2, mt: '0.25rem' }}>
                            <SuiTypography

                                variant="button"
                                fontWeight="bold"
                                color="text"

                             
                            >
                                            {props.children}
                                {value === '' || value === null || value === undefined ? "n/a" : value}
                    
                            </SuiTypography>
                        </Grid>
                    </Grid>



                </SuiBox>


            </SuiBox>
        </>
    );
}



export default DisplayWithLabel;