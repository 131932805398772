import SuiInput from "_softUI/components/SuiInput";





export default function InputWithValidationText({errorText,...rest}){


    return (
        <>
        <SuiInput {...rest} />
        {errorText && <p style={{color:'red', fontSize:'0.7rem',fontWeight:300,paddingLeft:'1rem', paddingRight:'1rem'}}>{errorText}</p>}
        </>
    )
}