import { authHeader } from '../_helpers';
import { handleResponse } from './util'

export const userService = {
    getUserInformation,
    saveUserInformation,
    savePassword,
    getUserPreferences,
    getShipmentFormDataForUser
}


function getUserInformation(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_URL + 'User/GetUserInformation', requestOptions).then(handleResponse)
}

function saveUserInformation(model) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(model)
    };

    return fetch(process.env.REACT_APP_API_URL + 'User/UpdateUserInformation' , requestOptions).then(handleResponse)
}

function savePassword(model) {
    const requestOptions = {
        method: 'POST',
        headers: authHeader(),
        body: JSON.stringify(model)
    };

    return fetch(process.env.REACT_APP_API_URL + 'User/UpdateUserPassword' , requestOptions).then(handleResponse)
}

function getUserPreferences(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_URL + 'User/GetUserPreferences', requestOptions).then(handleResponse)
}

function getShipmentFormDataForUser(){
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(process.env.REACT_APP_API_URL + 'User/GetShipmentFormDataForUser', requestOptions).then(handleResponse)
}
