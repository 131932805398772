
import { useMemo, useEffect, useState, useRef } from "react";

import PropTypes from "prop-types";

import { useTable, usePagination, useGlobalFilter, useAsyncDebounce, useSortBy } from "react-table";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableRow from "@mui/material/TableRow";
import Icon from "@mui/material/Icon";

import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";
import SuiSelect from "_softUI/components/SuiSelect";
import SuiInput from "_softUI/components/SuiInput";
import SuiPagination from "_softUI/components/SuiPagination";

import DataTableHeadCell from "_components/Tables/DataTable/DataTableHeadCell";
import DataTableBodyCell from "_components/Tables/DataTable/DataTableBodyCell";
import {Loading} from '_components/Shared/LoadingSpinner'
import LinearProgress from '@mui/material/LinearProgress';
import { Box } from "@mui/material";
import Grid from '@mui/material/Grid';



function DataTable({
  entriesPerPage,
  canSearch,
  tableUpdateLoading,
  showTotalEntries,
  table,
  pagination,
  isSorted,
  noEndBorder,
  loading,
  handleRowClick = null,
  selected = null,
  title,
  subtitle,
  actionButton
}) {
  const defaultValue =  17;
  const entries =  [5, 10, 15,17, 20, 25];
  const columns = useMemo(() => table.columns, [table]);
  const data = useMemo(() => table.rows, [table]);

  const tableInstance = useTable(
    { columns, data, initialState: { pageIndex: 0, hiddenColumns: columns.filter(col => col.show === false).map(col => col.accessor)} },
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows,
    page,
    pageOptions,
    canPreviousPage,
    canNextPage,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = tableInstance;

  // Set the default value for the entries per page when component mounts

  const { innerWidth: width, innerHeight: height } = window;
  const rowHeightRef = useRef();
  const rowsN = (title === 'Spot requests') ? Math.floor(((height/(1/window.devicePixelRatio)-40) - 230) / 67) : Math.floor((height - 350) / 45)
  const size = useWindowSize()

  let tableBodyMaxHeight = (size.height/(1/window.devicePixelRatio)-40) - ( size.width > 1400 ? 290 : 290);

  function useWindowSize() {

    const [windowSize, setWindowSize] = useState({
      width: undefined,
      height: undefined,
    });
    useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  useEffect(()=> {

    tableBodyMaxHeight = height - ( width > 1999 ? 230 : 320);
  },[])

  useEffect(() => setPageSize(rowsN), [window]);

  // Set the entries per page value based on the select value
  const setEntriesPerPage = ({ value }) => setPageSize(value);

  // Render the paginations
  const renderPagination = pageOptions.map((option) => (
    <SuiPagination
      item
      key={option}
      onClick={() => gotoPage(Number(option))}
      active={pageIndex === option}
    >
      {option + 1}
    </SuiPagination>
  ));

  // Handler for the input to set the pagination index
  const handleInputPagination = ({ target: { value } }) =>
    value > pageOptions.length || value < 0 ? gotoPage(0) : gotoPage(Number(value));

  // Customized page options starting from 1
  const customizedPageOptions = pageOptions.map((option) => option + 1);
  // Setting value for the pagination input
  const handleInputPaginationValue = ({ target: value }) => gotoPage(Number(value.value - 1));

  // Search input value state
  const [search, setSearch] = useState(globalFilter);

  // Search input state handle
  const onSearchChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 100);

  useEffect(()=> {

    setGlobalFilter(globalFilter);
  },[table])

  // A function that sets the sorted value for the table
  const setSortedValue = (column) => {
    let sortedValue;

    if (isSorted && column.isSorted) {
      sortedValue = column.isSortedDesc ? "desc" : "asce";
    } else if (isSorted) {
      sortedValue = "none";
    } else {
      sortedValue = false;
    }

    return sortedValue;
  };

  // Setting the entries starting point
  const entriesStart = pageIndex === 0 ? pageIndex + 1 : pageIndex * pageSize + 1;
  // Setting the entries ending point
  let entriesEnd;

  if (pageIndex === 0) {
    entriesEnd = pageSize;
  } else if (pageIndex === pageOptions.length - 1) {
    entriesEnd = rows.length;
  } else {
    entriesEnd = pageSize * (pageIndex + 1);
  }


  return (
    <>
    <Grid item container xs={12}>
      <Grid item xs={6}>
      <SuiBox p={3} lineHeight={1}>
            <SuiTypography variant="h5" fontWeight="medium">
              {title}
            </SuiTypography>
            <SuiTypography variant="button" fontWeight="regular" color="text">
              {subtitle}
            </SuiTypography>
          </SuiBox>
      </Grid>

    <Grid item xs={6}>
      
    
      {entriesPerPage || canSearch ? (
        <SuiBox display="flex" justifyContent="space-between" alignItems="center" p={3}>

          {canSearch && (
            <SuiBox width="12rem" ml="auto">
              <SuiInput
                placeholder="Search..."
                value={search}
                onChange={({ currentTarget }) => {
                  setSearch(search);
                  onSearchChange(currentTarget.value);
                }}
              />
            </SuiBox>
          )}
          {actionButton &&            <SuiBox  ml={2}> {actionButton }      </SuiBox>}
        </SuiBox>
      ) : null}
      </Grid>
      </Grid>
      {tableUpdateLoading &&
       
       <Box sx={{ width: '100%', marginBottom: '1px' }}>
             <LinearProgress />
           </Box>
      }      
      <TableContainer sx={{ boxShadow: "none", borderRadius: '0.25rem',maxHeight:tableBodyMaxHeight + "px", backgroundColor: 'transparent !important' }}>
      <Table {...getTableProps() }
      stickyHeader
      >
        
        <SuiBox component="thead" sx={{position:'sticky', top:'0px', background:'#fefefe'}}>
          {headerGroups.map((headerGroup) => (
            <TableRow {...headerGroup.getHeaderGroupProps()}            >
              {headerGroup.headers.map((column) => (
                <DataTableHeadCell
                  {...column.getHeaderProps(isSorted && column.getSortByToggleProps())}
                  width={column.width ? column.width : "auto"}
                  align={column.align ? column.align : "left"}
                  sorted={setSortedValue(column)}
                >
                  {column.render("Header")}
                </DataTableHeadCell>
              ))}
            </TableRow>
          ))}
        </SuiBox>
        <TableBody {...getTableBodyProps()}>
          {page.map((row, key) => {

            let backgroundCol;
            
            if(selected && row.values.id === selected){
              backgroundCol = "#f1f1f1"
            }
            
            prepareRow(row);
            return (
              <TableRow {...row.getRowProps()}
              hover={handleRowClick ? true : false}

              sx={ {'&.MuiTableRow-hover:hover': {
                cursor: handleRowClick ? 'pointer' : 'unset',
              }, background:(backgroundCol)}}
              onClick={() =>handleRowClick && handleRowClick(row.values.id)}
            
            

              >
              
                {row.cells.map((cell, index) => (
                  <DataTableBodyCell
                    noBorder={noEndBorder && rows.length - 1 === key}
                    evenRow={key % 2 == 0}
                    align={cell.column.align ? cell.column.align : "left"}
                    {...cell.getCellProps()}
                    type={columns[index].type}
                  >
                    {cell.render("Cell")}
                  </DataTableBodyCell>
                ))}
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      </TableContainer>
      {loading && <Grid container item sx={{pt:'3rem'}} xs={12} alignItems="center" justifyContent="center" ><Grid item><Loading/></Grid></Grid>}
      <SuiBox
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems={{ xs: "flex-start", sm: "center" }}
        p={!showTotalEntries && pageOptions.length === 1 ? 0 : 3}
      >
        {showTotalEntries && (
          <SuiBox mb={{ xs: 3, sm: 0 }}>
            <SuiTypography variant="button" color="secondary" fontWeight="regular">
              Showing {entriesStart} to {entriesEnd} of {rows.length} entries
            </SuiTypography>
          </SuiBox>
        )}
        {pageOptions.length > 1 && (
          <SuiPagination
            variant={pagination.variant ? pagination.variant : "gradient"}
            color={pagination.color ? pagination.color : "info"}
          >
            {canPreviousPage && (
              <SuiPagination item onClick={() => previousPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_left</Icon>
              </SuiPagination>
            )}
            {renderPagination.length > 6 ? (
              <SuiBox width="5rem" mx={1}>
                <SuiInput
                  inputProps={{ type: "number", min: 1, max: customizedPageOptions.length }}
                  value={customizedPageOptions[pageIndex]}
                  onChange={(handleInputPagination, handleInputPaginationValue)}
                />
              </SuiBox>
            ) : (
              renderPagination
            )}
            {canNextPage && (
              <SuiPagination item onClick={() => nextPage()}>
                <Icon sx={{ fontWeight: "bold" }}>chevron_right</Icon>
              </SuiPagination>
            )}
          </SuiPagination>
        )}
      </SuiBox>
      </>
  );
}

// Setting default values for the props of DataTable
DataTable.defaultProps = {
  entriesPerPage: { defaultValue: 10, entries: [5, 10, 15, 20, 25] },
  canSearch: false,
  showTotalEntries: true,
  pagination: { variant: "gradient", color: "info" },
  isSorted: true,
  noEndBorder: true,
};

// Typechecking props for the DataTable
DataTable.propTypes = {
  entriesPerPage: PropTypes.oneOfType([
    PropTypes.shape({
      defaultValue: PropTypes.number,
      entries: PropTypes.arrayOf(PropTypes.number),
    }),
    PropTypes.bool,
  ]),
  canSearch: PropTypes.bool,
  showTotalEntries: PropTypes.bool,
  table: PropTypes.objectOf(PropTypes.array).isRequired,
  pagination: PropTypes.shape({
    variant: PropTypes.oneOf(["contained", "gradient"]),
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "light",
    ]),
  }),
  isSorted: PropTypes.bool,
  noEndBorder: PropTypes.bool,
};

export default DataTable;
