import React from 'react';
import Grow from '@mui/material/Grow';
import Grid from '@mui/material/Grid';
import { adrTypes } from '_helpers'
import { useDispatch, useSelector } from 'react-redux';

import {  instantPricingActions } from '../../_actions';

import AdditionalService from '_components/ShipmentInformationForm/AdditionalService';
import SelectWithLabel from "_components/FormFields/SelectWithLabel";
import InputWithLabel from "_components/FormFields/InputWithLabel";

import Typography from '@mui/material/Typography';
import { Paper } from "@mui/material";


export function AdditionalServiceContainer() {
    const dispatch = useDispatch()
    const state = useSelector(state => state.instantPricing)

    function handleAdditionalServiceChange(name, value) {

        dispatch(instantPricingActions.updateValue(name, value));
    }

    function handleSelectChange(name, value) {
        console.log(name)
        console.log(value)
        dispatch(instantPricingActions.updateValue(name, value));
    }
    
    function handleChange(event) {
        dispatch(instantPricingActions.updateValue(event.target.name, event.target.value));
    }

    

    const declarationsNeeded = ['NO', 'CH', 'GB']

    const offerDeclaration = declarationsNeeded.includes(state.originCountry.value) || declarationsNeeded.includes(state.destinationCountry.value)

    return (
        <Grow in={state.showAdditionalServices} sx={{ mt: 3, display: state.showAdditionalServices ? 'flex' : 'none' }}>
            <Paper elevation={3} sx={{}}>
                <Grid container display="flex" direction="column" alignItems="stretch" alignContent="center" sx={{}}  >
                    <Grid container p={2} item xs={12}>
                        <Grid item xs={12} >
                            <Typography variant='subtitle1' sx={{ pb: 1, width: '100%', color: 'text.secondary' }} gutterBottom={true}>
                                Additional services
                            </Typography>

                        </Grid>
                        <Grid container item xs={12} sx={{ borderBottom: '1px solid #eeeeee' }}>
                            <Grid item xs={1} container alignItems="flex-start">
                                <Typography variant='subtitle2' sx={{ pl: 0.2, color: 'text.secondary' }} >
                                    Select
                                </Typography>
                            </Grid>
                            <Grid item container xs={11}>

                                <Grid item container xs={9}>
                                    <Grid item xs={8} container alignItems="center">
                                        <Typography variant='subtitle2' sx={{ pl: 0.6, color: 'text.secondary' }} >
                                            Service
                                        </Typography>
                                    </Grid>
                                    <Grid item container xs={4} alignItems="center">
                                        <Typography variant='subtitle2' sx={{ pl: 1, color: 'text.secondary' }} >
                                            Input
                                        </Typography>
                                    </Grid>
                                </Grid>

                            </Grid>
                        </Grid>
                        <Grid container item xs={12} spacing={1}>

                            <Grid item xs={12}>
                                <AdditionalService
                                    title="Tail lift loading / dismantling"
                                    instructions="Automatically included in groupage cargo"
                                    state={state.tailLift}
                               
                                    name="tailLift"
                                    showPrice={false}
                                     force={state.groupage}
                                     value={state.groupage ? 'Included' : 'tailLiftPrice'}
                                    changeHandler={handleAdditionalServiceChange}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <AdditionalService
                                    title="Call before delivery"
                                    instructions="Contact person will be contacted before delivery"
                                    state={state.callOnDelivery}
                                    name="callOnDelivery"
                                    showPrice={false}

                                    changeHandler={handleAdditionalServiceChange}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <AdditionalService
                                    title="Call before pickup"
                                    instructions="Contact person will be contacted before pickup"
                                    state={state.callOnPickup}
                                    name="callOnPickup"
                                    showPrice={false}
                                    changeHandler={handleAdditionalServiceChange}
                                />

                            </Grid>
                            <Grid item xs={12}>
                                <AdditionalService title="Insurance (Packed, general goods)"
                                    showPrice={false}
                                    // value={insurancePrice} 
                                    state={state.insurance}
                                    // calculating={stateParent.calculatingInsurance} 
                                    instructions="Input the value of goods to calculate the price" name="insurance"
                                    changeHandler={handleAdditionalServiceChange}
                                >
                                    <InputWithLabel

                                        name="goodsValue"
                                        onChange={e => handleChange(e)}
                                        error={state.goodsValueError}
                                        value={state.goodsValue}
                                        placeholder="Value of the goods"
                                        adornment="€"
                                        adornmentPosition="end"
                                        label=""

                                    />

                                </AdditionalService>
                            </Grid>
                            {/* <Grid item xs={12}>
                                <AdditionalService title="ADR"
                                    showPrice={false}
                                    // value={adrPrice} 
                                    name="adr"
                                    state={state.adr}
                
                                    // calculating={stateParent.calculatingAdr} 
                                    instructions="Select the IMO class to calculate the price"
                                    changeHandler={handleAdditionalServiceChange}
                                >

                                    <SelectWithLabel

                                        // value={adrTypes.find(obj => {
                                        //   return obj.value === state.adrClass
                                        // })}
                                        value={state.adrClass}
                                        options={adrTypes}
                                        fullWidth
                                        size="medium"
                                        name="adrClass"
                                        error={state.adrClassError}
                                        isMulti={true}
                                        delimiter=","
                                        onChange={e => handleSelectChange('adrClass', e)}
                                        label=""
                                    />

                                </AdditionalService>

                            </Grid> */}
                            {offerDeclaration && <>
                                <Grid item xs={12}>

                                    <AdditionalService title="Export declaration" name="exportDeclaration"
                                        instructions="Required documents must be uploaded to the system"
                                        state={state.exportDeclaration} 
                           
                                        changeHandler={handleAdditionalServiceChange} 
                                    />

                                </Grid>
                                <Grid item xs={12}>

                                    <AdditionalService title="Import declaration" name="importDeclaration"
                                        instructions="Required documents must be uploaded to the system"
                                    state={state.importDeclaration} 
                           
                                    changeHandler={handleAdditionalServiceChange} 
                                    />

                                </Grid>
                            </>}


                        </Grid>
                    </Grid>
                </Grid>
            </Paper>
        </Grow>

    )

}

