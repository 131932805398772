import React, { useState } from 'react';
import Grid from '@mui/material/Grid';

import { useDispatch, useSelector } from 'react-redux';
import SelectWithLabel from "_components/FormFields/SelectWithLabel";
import InputWithLabel from "_components/FormFields/InputWithLabel";
import TodayIcon from '@mui/icons-material/Today';
import SuiBox from '_softUI/components/SuiBox'
import SuiTypography from "_softUI/components/SuiTypography";
import { incoterms, transportationTypes } from '_helpers'
import SuiButton from '_softUI/components/SuiButton';
import { spotRequestActions } from '_actions/spotRequest.actions';
import { Loading } from '_components';
import AddFiles from '_components/ShipmentInformationPreview/AddFiles';



export function QuoteRequest({currency}) {


  const state = useSelector(state => state.spotRequest)
  const shipment = useSelector(state => state.carrierSpotRequestTable.selectedShipment)
  const dispatch = useDispatch()

  function handleChange(event) {
    dispatch(spotRequestActions.updateValue(event.target.name, event.target.value))
  }

  function handleSave() {
    const model ={
      ...state.quote,
      shipmentId: state.shipmentId
    }
    dispatch(spotRequestActions.saveQuote(model))
  }


  return (




    <React.Fragment>
      <Grid container sx={{ mb: '1rem' }}>
        <SuiBox mb={0} ml={0.5} p={3} b lineHeight={0} display="inline-block" borderRadius="0.75rem" sx={{ width: '100%', height: "100%" }} >

          <Grid item container xs={12}>
            <Grid item xs={2}>
              <SuiTypography variant="h5" fontWeight="medium" textTransform="capitalize">
                Save Quote
              </SuiTypography>
            </Grid>
            <Grid item xs={10} >
              <Grid container spacing={2}>
                {state.loading && <Loading />}
                {!state.loading && 
                <Grid item container spacing={1} xs={12}>
                  <Grid item xs={4}>
                  <InputWithLabel
                    label="Quote valid"
                    name="quoteValid"
                    onChange={e => handleChange(e)}
                    fullWidth
                    value={state.quote.quoteValid.slice(0,10)}
                    type="date"
            
   
               
   
                  />
                    </Grid>
                    <Grid item xs={4}>
                    <InputWithLabel
                    label="Est. transit time"
                    name="estimatedTransitTime"
                    placeholder="Days from booking"
                    onChange={e => handleChange(e)}
                    fullWidth
                    value={state.quote.estimatedTransitTime}
                    adornment="days"
                    adornmentPosition="end"
                   
      
                  /> 
                    </Grid>
                    <Grid item xs={4}>
                    <InputWithLabel
                    label="Total price"
                    name="totalPrice"
                    placeholder="€"
                    onChange={e => handleChange(e)}
                    fullWidth
                    type="text" inputMode="numeric"
                    value={state.quote.totalPrice}
                    adornment={currency}
                    adornmentPosition="end"
   
                  />
                    </Grid>
            
              
         
          <Grid item container xs={12}>
                  <InputWithLabel
                    label="Additional information"
                    name="additionalInformation"
                    placeholder="Special terms and conditions"
                    onChange={e => handleChange(e)}
                    fullWidth
                    multiline
                    rows={4}
                    value={state.quote.additionalInformation}
              
      
                  />
                     </Grid> 
                     <Grid item container xs={12}>
          <AddFiles files={state.quote.file} storeFile={spotRequestActions.storeFile} deleteFile={spotRequestActions.deleteFile}/>
          </Grid>
                     <Grid item container xs={12} justifyContent="flex-end">
                  <SuiButton disabled={!shipment.quotable} color="primary" onClick={e => handleSave()}>Save quote</SuiButton>
                  </Grid>
                </Grid>
                }
              </Grid>

            </Grid>
          </Grid>


    </SuiBox >

    </Grid >

  </React.Fragment >





  )
}