import React from 'react';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import CircularProgress from '@mui/material/CircularProgress';




export function Loading({text,size}) {
 
  return (
    <React.Fragment>
      <Grid container justifyContent="center" direction="column"
        alignItems="center">
        <Grid item >
          <CircularProgress />
        </Grid>
        <Grid item >
          <Typography variant={size} gutterBottom>
            {text}
          </Typography>


        </Grid>
      </Grid>

    </React.Fragment>
  )

}