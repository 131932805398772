import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';
import { registerActions } from '../_actions';
import { Dialog, DialogContent, DialogTitle, Grid } from '@mui/material';
import logo from "_assets/images/logo3.png";

import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import SuiBox from "_softUI/components/SuiBox";
import SuiTypography from "_softUI/components/SuiTypography";
import SuiInput from "_softUI/components/SuiInput";
import SuiButton from "_softUI/components/SuiButton";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import InputWithValidationText from './FormFields/InputWithValidationText';
import { Loading, Success } from './Shared';
import SuiSelect from "_softUI/components/SuiSelect";



export default function Register({ show, hide }) {
  const dispatch = useDispatch();
  const state = useSelector(state => state.authentication);
  const [submitted, setSubmitted] = useState(false)



  function handleValueChange(name, value){

    dispatch(registerActions.updateValue(name,value))

  }
  

  function handleSubmit() {

    dispatch(registerActions.register(state))
  }

  function handleSetTerms(e){
    console.log(e)
    dispatch(registerActions.updateValue('terms',e.target.checked))
  }

  const countryOptions = [{value:'FI',label:'Finland'},{value:'SE',label:'Sweden'}]


  return (
    <>
      <Dialog open={show} maxWidth='xs' fullWidth>

        <DialogTitle>
          <Grid container justifyContent="space-between" alignItems="flex-start">

            <Grid item textAlign="center" xs={12}>
              <SuiTypography variant="h5" fontWeight="medium">
                Register
              </SuiTypography>
            </Grid>
            <Grid sx={{ position: 'absolute', right: 0, top: 0 }}>
              <IconButton onClick={() => hide(false)}>
                <CloseIcon style={{ fontSize: '1.2rem' }} />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {state.loading && <Loading />}
          {!state.loading && !state.saved && 
          <Card elevation={0}>
            <SuiBox pt={2} pb={3} px={3}>
              <SuiBox >
                <SuiBox mb={2}>
                  <InputWithValidationText placeholder="First name" value={state.firstName} error={state.firstNameError ? true : false} errorText={state.firstNameError} name="firstName" onChange={(e) => handleValueChange(e.target.name, e.target.value)}  />
                  {/* <SuiInput placeholder="First name" value={state.firstName} error={state.firstNameError} name="firstName" onChange={(e) => handleValueChange(e.target.name, e.target.value)} /> */}
                </SuiBox>
                <SuiBox mb={2}>
                  <InputWithValidationText placeholder="Last name" value={state.lastName} error={state.lastNameError ? true : false} errorText={state.lastNameError} name="lastName"  onChange={(e) => handleValueChange(e.target.name, e.target.value)} />
                </SuiBox>
                <SuiBox mb={2}>
                  <InputWithValidationText type="email" placeholder="Email" value={state.email} error={state.emailError ? true : false} errorText={state.emailError} name="email" onChange={(e) => handleValueChange(e.target.name, e.target.value)}  />
                </SuiBox>
                <SuiBox mb={2}>
                  <InputWithValidationText type="phone" placeholder="Phone number" error={state.phoneNumberError ? true : false} value={state.phoneNumber} errorText={state.phoneNumberError} name="phoneNumber" onChange={(e) => handleValueChange(e.target.name, e.target.value)} />
                </SuiBox>
                <SuiBox mb={2}>
                  <InputWithValidationText type="password" placeholder="Password" value={state.password} error={state.passwordError ? true : false} errorText={state.passwordError} name="password" onChange={(e) => handleValueChange(e.target.name, e.target.value)} />
                </SuiBox>
                <SuiBox mb={2}>
                  <InputWithValidationText type="password" placeholder="Confirmation password" value={state.passwordConfirm}  error={state.passwordConfirmError ? true : false} errorText={state.passwordConfirmError}  name="passwordConfirm" onChange={(e) => handleValueChange(e.target.name, e.target.value)} />
                </SuiBox>
                <SuiBox mb={2} mt={4}>
                  <InputWithValidationText placeholder="Company name" value={state.companyName} error={state.companyNameError ? true : false} errorText={state.companyNameError} name="companyName" onChange={(e) => handleValueChange(e.target.name, e.target.value)}  />
                </SuiBox>
                <SuiBox mb={2}>
                  <InputWithValidationText placeholder="Business Identity Code" value={state.bic} error={state.bicError ? true : false} errorText={state.bicError} name="bic" onChange={(e) => handleValueChange(e.target.name, e.target.value)}  />
                </SuiBox>
                <SuiBox mb={6}>
                <SuiSelect
                            name="country"
                            placeholder="Country"
                            onChange={(e) => handleValueChange('country', e.value)}
                            value={countryOptions.find(obj => {
                                return obj.value === state.type
                            })}

                            options={countryOptions}
                        />
                </SuiBox>
                <SuiBox display="flex" alignItems="center">
                  {/* <Checkbox /> */}
                  <Checkbox checked={state.terms} onChange={(e) => handleSetTerms(e)} />
                  <SuiTypography
                    variant="button"
                    fontWeight="regular"
                    onClick={(e) => handleSetTerms(e)}
                    sx={{ cursor: "pointer", userSelect: "none" }}
                  >
                    &nbsp;&nbsp;I agree the&nbsp;
                  </SuiTypography>
                  <SuiTypography component="a" href="https://freightopt2api.azurewebsites.net/Assets/Agreements/GDPR Privacy Policy.pdf" target="_blank" variant="button" fontWeight="bold" textGradient>
                    Privacy Policy
                  </SuiTypography>
                </SuiBox>
            
                <SuiBox mt={4} mb={1}>
                <SuiTypography textAlign="center" variant="body2" color="error"  >
                    {state.errorMessage}
                  </SuiTypography>
                  <SuiButton variant="gradient"   disabled={!state.valid} color="dark" fullWidth onClick={handleSubmit}>
                    Register
                  </SuiButton>
                </SuiBox>
                {/* <SuiBox mt={3} textAlign="center">
                  <SuiTypography variant="button" color="text" fontWeight="regular">
                    Already have an account?&nbsp;
                    <SuiTypography
                      component={Link}
                      to="/authentication/sign-in/basic"
                      variant="button"
                      color="dark"
                      fontWeight="bold"
                      textGradient
                    
                    >
                      Sign in
                    </SuiTypography>
                  </SuiTypography>
                </SuiBox> */}
              </SuiBox>
            </SuiBox>
          </Card>}
          {!state.loading && state.saved &&
          <Card elevation={0}>
            <Success title="Account created" />
            <SuiTypography type="body">We have sent an email with a confirmation link to your email address. In order to complete the sign-up process, please click the confirmation link.</SuiTypography>
          </Card>
          
          }



        </DialogContent>
      </Dialog>

      {/* </Layout> */}


    </>
  );

}


