import React, {useEffect} from 'react';
import { useDispatch} from 'react-redux';
import { ShipmentInformationForm } from '../_components';
import { ShipmentInformationFormActions } from '../_actions';
import { useLocation } from 'react-router-dom';



export function SpotQuoteProcess() {
  const dispatch = useDispatch();
  const location = useLocation();
  const isFromPush = location.state && location.state.fromPush;
  const copyBooking = isFromPush ? true : false

  useEffect(()=>{
    dispatch(ShipmentInformationFormActions.open());
  },[])
  

  return (
    <>
    <ShipmentInformationForm instant={false} copyBooking={copyBooking}/>
    </>
  )

}
