import React, { useCallback } from 'react';
import { ShipmentInformationFormActions } from '../../_actions';
import Grid from '@mui/material/Grid';
import Label from '_components/FormFields/Label'
import { useDispatch, useSelector } from 'react-redux';
import SuiBox from "_softUI/components/SuiBox";
import CardMedia from "@mui/material/CardMedia";
import SuiTypography from "_softUI/components/SuiTypography";
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button'
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Dropzone from '_components/Common/Dropzone'
import AddFiles from './AddFiles';

import { arrayBufferToBase64 } from '_helpers/file'

export default function Pricing({ state }) {

    return (











        <Grid item xs={12} md={12} container justifyContent="flex-end" alignItems="space-between" >
            <Grid item container xs={12} sx={{ paddingLeft: 1 }}>
                <SuiTypography variant="h6" fontWeight="bold">
                    Pricing Summary:
                </SuiTypography>
            </Grid>
            <Grid item container xs={12} sx={{ backgroundColor: '#f2f4f5', borderRadius: '0rem', padding: 1, pr: 1.5 }}>


                {state.rows.map((e, i) => {
                    if (e.value > 0) {
                        return (

                            <Grid key={i} item container xs={12} sx={{ paddingBottom: '0.2rem' }} >
                                <Grid item xs={8} sx={{ textAlign: 'left' }}>
                                    <SuiTypography fontWeight="medium" variant="caption">
                                        {e.name}
                                    </SuiTypography>
                                </Grid>
                                <Grid item xs={4} sx={{ textAlign: 'right' }} >
                                    <SuiTypography fontWeight="bold" variant="caption" >
                                        {e.value.toString() + " " + state.currency}
                                    </SuiTypography>
                                </Grid>
                            </Grid>
                        )
                    }
                }


                )}
            </Grid>
            <Grid item container xs={12} sx={{ pt: '0rem', pl: 1, pr: 0.5 }}>
                <Grid item xs={4} sx={{ textAlign: 'left' }}>
                    <SuiTypography fontWeight="light" variant="body1">
                        Total:
                    </SuiTypography>
                </Grid>
                <Grid item xs={8} sx={{ textAlign: 'right' }}>
                    <SuiTypography fontWeight="bold" variant="body1">
                        {Math.round((state.totalPrice + Number.EPSILON) * 100) / 100} {" "} {state.currency}
                    </SuiTypography>
                </Grid>
                <Grid item container xs={12} justifyContent="flex-end" sx={{marginTop:'-0.5rem',paddingLeft:'1.8rem'}} >
            <SuiTypography variant="caption"  fontWeight="light" color="light">
                                        excl. VAT
                                      </SuiTypography>
            </Grid>
            </Grid>








        </Grid>





    );

}





