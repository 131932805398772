import React from 'react';

import Grid from '@mui/material/Grid';
import {  useSelector } from 'react-redux';

import { ResultsRow } from './ResultsRow'
import Loader from 'react-dots-loader'
import 'react-dots-loader/index.css'


export function ResultsContainer() {


  const state = useSelector(state => state.instantPricing);



  return (
    <Grid container sx={{ overflow: 'unset', '.MuiPaper-root': { overflow: 'unset' } }}>

      {state.calculating && (



        <Grid container spacing={1} sx={{marginTop:'2rem'}} alignItems="center" alignContent="center" direction="column">
          <Grid item textAlign="center">
            <h1 style={{ 'color': '#bbb',marginBottom:'0rem' }}>Calculating</h1>
            <Loader  size={7} color="#183B59" distance={30}/>

          </Grid>
   
        </Grid>
       

      )}
      {!state.calculating && state.noResults && (
      <Grid container alignItems="center" sx={{pt:'2rem'}} alignContent="center" direction="column">
          <h2 style={{ 'color': '#bbb' }}>Sorry, no prices available!</h2>
        </Grid>
      )}

      {!state.calculating && !state.noResults && (
        <Grid container alignItems="center" sx={{pt:'2rem'}} alignContent="center" direction="column">
          {state.results.map((x, index) => <ResultsRow key={index} row={x} />)}
        </Grid>

      )}


    </Grid>
  )
}