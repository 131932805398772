import { useEffect } from "react";

// react-router-dom components
import { useLocation } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// Soft UI Dashboard PRO React components
import SuiBox from "_softUI/components/SuiBox";

// Soft UI Dashboard PRO React context
import { useSoftUIController, setLayout } from "_softUI/context";

function DashboardLayout({ children }) {
  const [controller, dispatch] = useSoftUIController();
  const { miniSidenav } = controller;
  const { pathname } = useLocation();

  useEffect(() => {
    setLayout(dispatch, "dashboard");
  }, [pathname]);

  return (
    <SuiBox
      sx={({ breakpoints, transitions, functions: { pxToRem } }) => ({
        pt: 0,
        pb: 0,
        position: "relative",
        height:'calc(100% - 65px)',
        maxWidth:'1280px',
        margin:'auto',
        
        float:'center',
        alignSelft:'center',
        alignItems:"center",
        justifyContent:"center",
       
      })}
    >
      {children}
    </SuiBox>
  );
}

// Typechecking props for the DashboardLayout
DashboardLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default DashboardLayout;